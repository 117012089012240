import React from 'react';
import { Col } from 'react-bootstrap';
import { Menu, Slider, Checkbox, Select } from 'antd';

const { loaded } = React
const { SubMenu } = Menu;
const { Option } = Select;


const FilterBoxMartFury = (props) => (
  <>
    <Col  >
      <h6>SHOP BY</h6>

      {
        props.params.ProductFilter && props.params.ProductFilter.PriceRanges ? (

          <>
            {
              props.params.ProductFilter.PriceRanges ? (
                <>
                  <hr />
                  <b>{props.params.ProductFilter.ProductFilterControls.Price.ControlName}</b>

                  <Slider range={{ draggableTrack: true }} onChange={props.onChange} min={props.params.ProductFilter.PriceRanges[0][2].Key === 'MinPrice' ? props.params.ProductFilter.PriceRanges[0][2].Value : props.params.ProductFilter.PriceRanges[0][1].Value} max={props.params.ProductFilter.PriceRanges[0][1].Key === 'MaxPrice' ? props.params.ProductFilter.PriceRanges[0][1].Value : props.params.ProductFilter.PriceRanges[0][2].Value} defaultValue={[props.params.firstload ? props.params.filterdpricerange[0] : props.params.filterdpricerange[0], props.params.filterdpricerange[1]]} />
                  Price: {localStorage.getItem('ecurrencyCode')} {props.params.ProductFilter.PriceRanges[0][2].Key == 'MinPrice' ? props.params.ProductFilter.PriceRanges[0][2].Value : props.params.ProductFilter.PriceRanges[0][1].Value} - {localStorage.getItem('ecurrencyCode')} {props.params.ProductFilter.PriceRanges[0][1].Key == 'MaxPrice' ? props.params.ProductFilter.PriceRanges[0][1].Value : props.params.ProductFilter.PriceRanges[0][2].Value}
                  <br /><br />
                </>
              ) : ''

            }

            {
              props.params.ProductFilter.BrandIDs ? (<>
                <>
                  {
                    props.params.brandlist.length ? (
                      <>
                        <span><b>{props.params.ProductFilter.ProductFilterControls.Brand.ControlName}</b><br /> </span>
                        <Checkbox.Group onChange={props.handleBrandChange}>
                          {
                            props.params.brandlist.map(brand =>
                              <>
                                <> {JSON.parse(sessionStorage.getItem('ProductFilter')).BrandIDs.includes(brand.BrandID) ? <li className="opacity03"><Checkbox value={brand.BrandID} >{brand.BrandName}</Checkbox><br /> </li> : <><li><Checkbox value={brand.BrandID} >{brand.BrandName}</Checkbox><br /></li></>}</>
                              </>
                            )}
                        </Checkbox.Group>

                        <br /><br />

                      </>

                    ) : ('')
                  }




                </>




              </>
              ) : ('')
            }






            {
              Object.keys(props.params.ProductFilter.ProductFilterControls.CustomArray).map(PF =>

                <>

                  {
                    props.params.ProductFilter.CustomArrays[props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlName].length ? (
                      <>
                        <br /><span><b>{props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlLabel}</b><br />
                        </span></>
                    ) : ''
                  }

                  {
                    (() => {
                      switch (props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlType) {
                        case 'CheckBox':
                          return <>
                            <Checkbox.Group onChange={props.handleCustomCheckboxChange.bind(this, props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlName)}>
                              {
                                props.params.ProductFilter.CustomArrays[props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlName].map(label =>
                                  <>

                                    <> {JSON.parse(sessionStorage.getItem('ProductFilter')).CustomArrays[props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlName].includes(label) ? <li className="opacity03"><Checkbox value={label} >{label}</Checkbox><br /> </li> : <><li ><Checkbox value={label} >{label}</Checkbox><br /></li></>}</>


                                  </>
                                )}
                            </Checkbox.Group>

                          </>;
                          break;

                        case 'Colour Plate':
                          return <>
                            <Checkbox.Group onChange={props.handleCustomCheckboxChange.bind(this, props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlName)}>
                              <div>
                                {
                                  props.params.ProductFilter.CustomArrays[props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlName].map((colour, id) =>
                                    <div className="ps-checkbox ps-checkbox--color  ps-checkbox--inline" style={{ 'background-color': colour }}>

                                      &nbsp;&nbsp;&nbsp;&nbsp;<> {JSON.parse(sessionStorage.getItem('ProductFilter')).CustomArrays[props.params.ProductFilter.ProductFilterControls.CustomArray[PF].ControlName].includes(colour) ? <Checkbox className="opacity03" value={colour} >{colour}</Checkbox> : <Checkbox value={colour} >{colour}</Checkbox>}</>




                                      <label htmlFor={'color' + id} />
                                    </div>

                                  )}
                                <br />
                              </div>
                            </Checkbox.Group>


                          </>;
                          break;

                        default: return ''; break;
                      }
                    }).call(this)
                  }


                </>
              )


            }
            {


            }
            {
              props.params.ProductFilter.Weight ? (
                <>

                  <br /><span><b>{props.params.ProductFilter.ProductFilterControls.Weight.ControlName}</b><br />
                  </span>
                  <Checkbox.Group name="chkWeight" onChange={props.handleChange}>
                    <ul className={props.params.readmore}>
                      {props.params.ProductFilter.Weight.map(weight =>


                        <> {JSON.parse(sessionStorage.getItem('ProductFilter')).Weight.includes(weight) ? <li className="opacity03"><Checkbox value={weight} >{weight}</Checkbox><br /> </li> : <><li ><Checkbox value={weight} >{weight}</Checkbox><br /></li></>}</>




                      )}
                    </ul>
                  </Checkbox.Group>
                  <br />
                  {
                    props.params.ProductFilter.Weight.length > 5 ? <><b><a onClick={props.handleMore}> {props.params.MoreLess}</a></b></> : ''
                  }
                  <br /><br />
                </>



              ) : ('')

            }

          </>

        ) : (
          <>

            <ul className="ps-list--categories">

              {props.params.categories.map(category =>
                <li className="menu-item-has-children"><a onClick={props.handleProductsClick.bind(this, category.ProductCategory.ProductCategoryID, category.ProductCategory.Category)}  >  {category.ProductCategory.Category}  </a></li>
              )}
            </ul>
          </>


        )
      }



    </Col>
  </>


);
export default FilterBoxMartFury;
