import React from 'react';
import { Modal, Form, Button, Input, Divider } from 'antd';
const AccountRegisterMartFury = (props) => (

    
      <>
           
           <Form
                  className="ps-form--account-setting"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={props.onFinish}
                >
                  <div className="ps-form__header">
                    <h3> Register Now for Online Shopping</h3>
                  </div>
                  <div className="ps-form__content">
                    <div className="form-group">
                      <label>Email</label>
                      
                      <Form.Item
                                name="Email"
                                label=""
                                rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
                              >
                                <Input placeholder="Please enter email" className="form-control" />
                              </Form.Item>

                    </div>
                    
                  </div>

                  
                  <div className="form-group submit">
                    <button className="ps-btn">
                    {props.disabled ? 'Please wait...' : 'Register Now'}

                    </button>
                  </div>
                  </Form>
         </>
  

);
export default AccountRegisterMartFury;
