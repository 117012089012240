import React, { Component, useState } from "react";

export function encrypt(data) {
    var CryptoJS = require("crypto-js");
    var key = 'U2FsdGVkX1y3pF1AfoHaMGdUJpg6nOMru98BUt';
    var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return cipherText;
}

export function decrypt(data) {
    var CryptoJS = require("crypto-js");
    var key = 'U2FsdGVkX1y3pF1AfoHaMGdUJpg6nOMru98BUt';
    var bytes = CryptoJS.AES.decrypt(data, key);
    var decText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decText;
}

   