import React from 'react'
import './css/spiceway.css'

const PrivacyPolicy = () => {
  return (
    <div className='policy'>
      <h1>PRIVACY POLICY</h1>
      <p className='privacy-com-name'>This website is owned by <b>Spiceway Wholesale Limited</b> (“Spiceway Wholesale”).</p>
      <p>Spiceway Wholesale is committed to ensuring your privacy is protected. This Privacy
        Policy describes our practices in relation to personal information about individuals
        (“information”) collected whether offline or online including through our website <a target="_blank" href=' https://spiceway.retailpacx.com/'>spicewaywholesale.com</a> in addition to software applications,
        branded pages on third party platforms or electronic messaging.</p>
      <p>If you have any questions or concerns about our use of your personal information,
        then please contact us using the contact details provided at the bottom of this
        Privacy Policy.</p>
      <h5>WHO WE ARE, WHAT WE DO AND HOW TO CONTACT US?</h5>
      <p>We are a food manufacturer, with interests in catering, online food shopping and
        restaurant services headquartered in the United Kingdom.</p>
      <p>Spiceway Wholesale is the data controller responsible for your information and is the
        registered data controller with the Information Commissioner’s Office (ICO) in
        England. We are an English company with company number 10558045 and our
        registered office address is:</p>
      <p>Unit 4, College Fields Business Centre, Prince Georges Road<br />
        Merton<br />
        United Kingdom<br />
        SW19 2PX<br />
      </p>
      <h5>WHAT PERSONAL INFORMATION DO WE COLLECT AND WHY?</h5>
      <p>The personal information that we may collect about you broadly falls into the following categories:</p>
      <ul>
        <li>
          <p><i><u>Information that you provide voluntarily</u></i></p>
          <p>Certain parts of our Website may ask you to provide personal information voluntarily:
            for example, we may ask you to provide your contact details in order to register an
            account with us, to subscribe to our newsletters and marketing communications from
            us, to submit enquiries to us and/or to comment on or evaluate our products or
            Website.</p>
          <p>The personal information that you are asked to provide, is so that we can support
            you in providing the necessary services requested.</p>
          <p>Our legal reasons for processing your personal information can be found under
            “Legal basis for processing personal information” heading below.</p>
        </li>
        <li>
          <p><i><u>Information that we collect automatically</u></i></p>
          <p>When you visit our Website, we may collect certain information automatically from
            your device. In some countries, including countries in the European Economic Area
            (EEA), this information may be considered personal information under applicable
            data protection laws.</p>
          <p>
            Specifically, the information we collect automatically may include information like
            your IP address, device type, unique device identification numbers, browser-type,
            broad geographic location (e.g. country or city-level location) and other technical
            information. We may also collect information about how your device has interacted
            with our Website, including the pages accessed and links clicked.
          </p>
          <p>
            Collecting this information enables us to better understand the visitors who come to
            our Website, where they come from, and what content on our Website are of interest
            to them. We use this information for our internal analytics purposes and to improve
            the quality and relevance of our Website to our visitors.
          </p>
          <p>
            Some of this information may be collected using cookies and similar tracking
            technology, as explained further under the heading “Cookies and similar tracking
            technology” below.
          </p>
        </li>
        <li>
          <p><i><u>Information that we obtain from third party sources</u></i></p>
          <p>From time to time, we may receive personal information about you from third party
            sources (including companies which provide marketing services to us), but only
            where we have checked that these third parties either have your consent or are
            otherwise legally permitted or required to disclose your personal information to us.</p>
          <p>
            The types of information we may collect from third parties may include your name,
            address, e-mail address and telephone number and we use the information we
            receive from these third parties to contact you if we have your consent to do so.
          </p>
        </li>
      </ul>
      <h5>WHO DO WE SHARE YOUR PERSONAL INFORMATION WITH?</h5>
      <p>We will not sell or share your personal information with any 3rd party organisation for
        market research or marketing purposes without specific consent from you.</p>
      <p>We use data processors who are third parties who provide elements of services for
        us, which includes other businesses in the Spiceway Wholesale group of
        businesses. We have contracts in place with our data processors. This means that
        they cannot do anything with your personal information unless we have instructed
        them to do it. They will not share your personal information with any organisation
        apart from us. They will hold it securely and retain it for the period we instruct.</p>
      <p>We may share data (including personal information) if we have a legal obligation to
        do so, or if we have to enforce or apply our terms of use and other agreements. This
        may include submitting information for legal reasons.</p>
      <p>Examples of third parties we may share your personal information with:<br />
        <ul>
          <li>Google Analytics for monitoring and analysing website performance</li>
          <li>Law enforcement or government authorities, or if we believe it is necessary for
            legal or public safety reasons; and/or</li>
          <li>the new owner of either a Spiceway Wholesale Limited company, or a Spiceway
            Wholesale Limited company's assets, in the event of a sale.</li>
        </ul>
      </p>
      <h5>LEGAL BASIS FOR PROCESSING PERSONAL INFORMATION</h5>
      <p>We may rely on one or more of the following legal grounds to process your personal information:</p>
      <ul>
        <li>
          <b>Contract:</b> Which relates to the processing necessary in the performance of a
          contract or to take steps at your request, before entering a contract. We will
          use if you become a customer and register to purchase goods from this
          Website or any of our other services.
        </li>
        <li>
          <b>Consent:</b> Which relies on your freely given consent at the time you provide
          your personal information to us. We will always make it clear what your
          personal information will be used for and provide a link or reference to our
          privacy policy and the ability to opt out or unsubscribe of communications or
          withdraw consent. For example, we will use this when we ask your consent
          when your sign-up to receive our newsletters.
        </li>
        <li>
          <b>Legal Obligation:</b> Which relates to processing necessary for compliance with
          a legal obligation to which we must comply with. For example, sharing with
          solicitor or police acting on our behalf due to a breach of contract.
        </li>
        <li>
          <b>Legitimate Interests:</b> Which relates to our legitimate interests communicating
          with our customers, and prospects to introduce them to products, services, or
          market similar products or services that would be of interest and benefit to
          them.
        </li>
      </ul>
      <p>If you have questions about or need further information concerning the legal basis on
        which we collect and use your personal information, please contact us using the
        contact details provided under the “How to contact us” heading below.</p>
      <p>You don't have to give us your information. But if you don't, we won't be able to
        communicate with you. Sometimes that means that we won't be able to respond to a
        question. It might also affect our ability to look into and report back to you on an
        issue you've raised.</p>
      <h5>DATA RETENTION</h5>
      <p>We retain personal information we collect from you where we have an ongoing
        legitimate business need to do so (for example, to provide you with information or a
        service you have requested or to comply with applicable legal, tax or accounting
        requirements).</p>
      <p>When we have no ongoing legitimate business need to process your personal
        information, we will either delete or anonymise it. If this is not possible (for example,
        because your personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further processing
        until deletion is possible.</p>
      <h5>INTERNATIONAL DATA TRANSFERS</h5>
      <p>We store personal information in digital format on secure cloud servers and systems
        hosted in the European Union (EU) and in the United States (US). Where information
        is stored on servers located in the US, we rely on the Privacy Shield Framework to
        transfer this information. Access to personal information is highly restricted internally
        for approved business purposes only.</p>
      <p>We require group companies and third parties which receive your information to
        provide adequate safeguards. By providing us with your information, you agree to us
        (or third parties acting on our behalf) transferring your information outside the EEA.</p>
      <h5>HOW DO WE KEEP YOUR PERSONAL INFORMATION SECURE?</h5>
      <p>We use appropriate technical and organisational measures to protect the personal
        information that we collect and process about you. The measures we use are
        designed to provide a level of security appropriate to the risk of processing your
        personal information.</p>
      <p>We have implemented information security policies, rules and technical measures to
        protect the personal information that we have under our control from:
        <ul>
          <li>unauthorised access;</li>
          <li>improper use or disclosure;</li>
          <li>unauthorised modification; and</li>
          <li>unlawful destruction or accidental loss.</li>
        </ul>
      </p>
      <p>All our employees and data processors (i.e. those who process your personal
        information on our behalf, for the purposes listed above), who have access to, and
        are associated with the processing of personal information, are obliged to respect
        the confidentiality of the personal information of all users of the Website.</p>
      <p>We keep your information only for as long as necessary to fulfil the purpose it was
        collected for. In considering how long to keep it, we will take into account its
        relevance to our business and our legal and regulatory obligations.</p>
      <h5>YOUR DATA PROTECTION RIGHTS</h5>
      <p>You can exercise your data protection rights and utilise our Data Subject Access
        Request (DSAR) procedure.</p>
      <p>You have the following data protection rights:
        <ul>
          <li>If you wish to <b>access, correct, update or request deletion</b> of your personal
            information, you can do so at any time by contacting us using the contact details
            provided under the “How to contact us” heading below.</li>
          <li>In addition, you can <b>object to processing</b> of your personal information, ask us
            to restrict processing of your personal information or <b>request portability</b> of your
            personal information. Again, you can exercise these rights by contacting us using
            the contact details provided under the “How to contact us” heading below.</li>
          <li>You have the right to <b>opt-out of marketing communications</b> we send you at any
            time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in
            the marketing e-mails we send you. To opt-out of other forms of marketing (such as
            postal marketing or telemarketing), then please contact us using the contact details
            provided under the “How to contact us” heading below.</li>
          <li>Similarly, if we have collected and processed your personal information with your
            consent, then you can withdraw your consent at any time. Withdrawing your
            consent will not affect the lawfulness of any processing we conducted prior to your
            withdrawal, nor will it affect processing of your personal information conducted in
            reliance on lawful processing grounds other than consent.</li>
        </ul>
      </p>
      <p>We respond to all requests we receive from individuals wishing to exercise their data
        protection rights in accordance with the Data Protection Act 2018 and the General
        Data Protection Regulation (GDPR).</p>
      <p>In the event you wish to complain about how we have handled your personal
        information, please contact the <b>Customer Services Manager</b> at
        <a href='mailto: info@spicewaywholesale.com'> info@spicewaywholesale.com</a>.</p>
      <p>Our <b>Customer Service Manager</b> will then investigate your complaint and work with you to resolve the matter.</p>
      <p>If you still feel that your personal information has not been handled appropriately
        according to the law, you can contact the Information Commissioner’s Office in the
        UK here: <a target="_blank" href='https://ico.org.uk/concerns/'>https://ico.org.uk/concerns/</a> and file a complaint with them.</p>
      <h5>CHILDREN’S PRIVACY</h5>
      <p>Our Website does not address and is not directed towards anyone under the age of
        13. We do not knowingly collect personal information from anyone under the age of 13.</p>
      <p>If you are a parent or guardian and you are aware that your Children has provided us
        with personal information, please contact us.</p>
      <p>If we become aware that we have collected personal information from children
        without verification of parental consent, we take steps to remove that personal
        information from our systems.</p>
      <h5>CHANGES OF BUSINESS OWNERSHIP</h5>
      <p>Spiceway Wholesale may sometimes expand or reduce its business, this may
        involve the sale of certain divisions or the transfer of control of certain divisions to
        other parties.</p>
      <p>Personal information provided by prospects, customers, and employees will be
        transferred along with that division and the new owner or newly controlling party (if
        necessary to that division) will, under the terms of this Policy, be permitted to use the
        personal information for the purposes for which it was supplied by you.</p>
      <p>In the event that any personal information submitted by prospects or customers will
        be transferred in such a manner, you will not be contacted about this in advance or
        then after by ourselves.</p>
      <h5>COOKIES AND SIMILAR TRACKING TECHNOLOGY</h5>
      <p>For information about how we use cookies on this website and our purposes for
        using them, please review our Cookie Policy: <a target="_blank" href='https://www.spicewaywholesale.com/cookie'>https://www.spicewaywholesale.com/cookie</a></p>
      <h5>OTHER WEBSITES</h5>
      <p>Our Website may contain links to other websites. This privacy policy does not cover
        the links within this site linking to other websites. We encourage you to read the
        privacy statements on the other websites you visit.</p>
      <p>Please remember that when you use a link to go from this Website to another
        website or you request a service from a third party, this Privacy Policy no longer
        applies.</p>
      <p>Your browsing and interaction on any other website, or your dealings with any other
        third-party service provider, is subject to that website's or third-party service
        provider's own rules and policies.</p>
      <p>We do not monitor, control, or endorse the privacy practices of any third parties and
        we encourage you to become familiar with the privacy practices of every website you
        visit or third party service provider that you deal with and to contact them if you have
        any questions about their respective privacy policies and practices.</p>
      <p>This Privacy Policy applies solely to personal information collected by us and does
        not apply to these third-party Website and third-party service providers.</p>
      <h5>UPDATES TO THIS PRIVACY POLICY</h5>
      <p>We keep our Privacy Policy under regular review, and we will place any updates on
        this web page. This privacy notice was last updated on 17th of November 2022</p>
      <h5>HOW TO CONTACT US</h5>
      <p>If you have any questions or concerns about our use of your personal information,
        please contact us using the address above or by contacting our Data Protection
        Officer <a href='mailto: info@spicewaywholesale.com'>info@spicewaywholesale.com</a> or the following telephone number: +44 (0)
        208 685 1122.</p>

    </div>
  )
}

export default PrivacyPolicy