import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import { Modal } from 'antd';
import ContactEmail from '../email/ContactEmail';
import { renderEmail } from 'postonents';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      companyid: config.get('companyID')
    }
  }

  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value })
  }

  onMsgChange(event) {
    this.setState({ message: event.target.value })
  }

  submitEmail(e) {
    e.preventDefault();
    //console.log(e)
    axios({
      method: "POST",
      url: config.get('backendURL') + 'contact/add',
      data: this.state
    }).then((response) => {
      if (response.data === 'Contact added!') {


        const maildata = {
          companyLogoCDN: config.get('companyLogoCDN'),
          companyPageTitle: config.get('companyPageTitle'),
          contact: this.state,
          SenderEmail: config.get('SenderEmail'),
          email: this.state.email
        }

        const emailBody = renderEmail(ContactEmail, { lang: 'en', maildata });

        async function postData(mail) {
          const ecoEmail = {
            "from": mail,
            "to": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
            "subject": "Contact details sent to " + config.get('companyPageTitle') + " via website",
            "html": emailBody
          };
          const response = await fetch(config.get('backendURL') + 'mail/send', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(ecoEmail)
          })
          return response.json();
        }
        postData(this.state.email)
          .then(data => {

            //  console.log(data)
            if (data == "SUCCESS") {
              Modal.success({
                title: 'Contact added.',
                content: 'Your details has been sent. We will look into it and get back to you shortly.',
                onOk() { },
              });
              this.resetForm()
            }
            else {
              Modal.error({
                title: 'Oops!! Something wrong',
                content: 'We are sorry, Something wrong with the process. Please try again later.',
                onOk() { window.location.href = "/account/forgot" },
              });
            }
          });

      }
    })


  }

  resetForm() {
    this.setState({ name: '', email: '', subject: '', message: '' })
  }




  render() {
    //console.log(this.state.vimage)

    return (
      <>
        {(() => {

          switch (config.get('Theme')) {
            case 'Apparel-casi':
              return (
                <>
                 
                 <form id="contact-form" onSubmit={this.submitEmail.bind(this)}
                            method="POST">
                 <div className="contact page-inner">
        <h4>Contact Us</h4>
        <div className="page-inner-content">
          <div className="contact-form">
            <div className="row">
              <div className="col-sm-12">
                <p>Let us know what you think! In order to provide better service, please do not hesitate to give us your feedback. Thank you.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="required">Name</label>
                  <input placeholder="Name" id="name" type="text"
                                    className="form-control" required value={this.state.name}
                                    onChange={this.onNameChange.bind(this)} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="required">Email</label>
                  <input placeholder="Email" id="email" type="email"
                                    className="form-control" aria-describedby="emailHelp"
                                    required value={this.state.email} onChange=
                                    {this.onEmailChange.bind(this)} />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="required">Subject</label>
                  <input placeholder="Subject" id="subject" type="text"
                                className="form-control" required value={this.state.subject}
                                onChange={this.onSubjectChange.bind(this)} />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="required">Message</label>
                  <textarea placeholder="Message" id="message"
                                className="form-control" rows="1"
                                required value={this.state.message}
                                onChange={this.onMsgChange.bind(this)} />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="btn-submit-wrapper">
                <button type="submit" className="primary-btn btn-primary submit">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>	
      </div>
                 
             </form>    
           
                </>
              )
            default:
              return (<>
                <div className="section">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="section-title">
                        <h3 className="title">Contact Us</h3>
                        <p>Let us know what you think! In order to provide better service,
                          please do not hesitate to give us your feedback. Thank you.</p><hr />
                        <form id="contact-form" onSubmit={this.submitEmail.bind(this)}
                          method="POST">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6">
                                <input placeholder="Name" id="name" type="text"
                                  className="form-control" required value={this.state.name}
                                  onChange={this.onNameChange.bind(this)} />
                              </div>
                              <div className="col-md-6">
                                <input placeholder="Email" id="email" type="email"
                                  className="form-control" aria-describedby="emailHelp"
                                  required value={this.state.email} onChange=
                                  {this.onEmailChange.bind(this)} />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <input placeholder="Subject" id="subject" type="text"
                              className="form-control" required value={this.state.subject}
                              onChange={this.onSubjectChange.bind(this)} />
                          </div>
                          <div className="form-group">
                            <textarea placeholder="Message" id="message"
                              className="form-control" rows="1"
                              required value={this.state.message}
                              onChange={this.onMsgChange.bind(this)} />
                          </div>
                          <button type="submit" className="primary-btn btn-primary submit">Submit</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              )
          }

        })()}

      </>

    )




  }
}

export default App;
