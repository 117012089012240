import React from 'react';

import { Email, Container, Row, Column, Header, FullWidth, Footer, Text, Link, PostonentsProvider } from 'postonents';

const VerificationEmail = ({ data }) => {

    const { verificationToken, email, companyPageTitle, companyLogoCDN, SenderEmail, DigitCode } = data;
    let Token = encodeURIComponent(verificationToken);

    return (
        <PostonentsProvider theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
            <Email
                title={`Verification email for ${email}`}
                headLinks={[
                    {
                        type: 'link',
                        props: {
                            rel: 'stylesheet',
                            href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                        },
                    },
                ]}>

                <Container alignment="center" style={{ backgroundColor: '#FFFFFF', padding: "20px" }}>
                    <Row>
                        <Column small={12}>
                            <Header style={{ backgroundColor: '#FFFFFF', padding: 5 }}
                                logo={companyLogoCDN}

                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Hello,</Text>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>We're happy you signed up for {companyPageTitle}. You just registered with the following email: {email}.</Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={12} >
                            <Text>Your {companyPageTitle} verification code is</Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={12} style={{  textAlign: 'center', 'background-color': '#f4f4f4'   }}>
                            <Text style={{ textAlign: 'center', fontSize: '25px'}}><b>{DigitCode}</b></Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={12}>
                            <Text >Please use this code to verify your email with the {companyPageTitle}.</Text>
                        </Column>
                    </Row>
                   <Row>
                        <Column small={12}>
                            <Text>Any other questions? We are happy to help!</Text>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={6}>
                            <Link href="https://www.ecologital.com/contact" fullWidth type="hollow">Help Center</Link>
                        </Column>
                        <Column small={6}>
                            <Link href={"mailto:" + SenderEmail} fullWidth type="hollow">Email</Link>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12} style={{ textAlign: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 300 }}>
                            Copyright ©  {" 2019-"} {new Date().getFullYear()} {companyPageTitle}, all rights reserved
                            </Column>
                    </Row>
                </Container>
            </Email>
        </PostonentsProvider>
    );
};

export default VerificationEmail;