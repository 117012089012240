import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import {Divider, Form, Input, Button, Modal } from 'antd';
import AccountLeftMenu from "./account-left-menu";
import config from 'react-global-configuration';
import {decrypt } from "../lib/decrypt";
import AccountRegisterVerifyMartFury from "../themes/martfury/account-register-verify"; 
import AccountRegisterVerifyApparel from "../themes/apparel/account-register-verify"; 
import AccountRegisterVerifyApparelCasi from "../themes/apparel-casi/account-register-verify"; 

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class Registration extends React.Component {
  constructor(props) {
    super(props);
   // console.log(this.props.match.params.id)
    this.initValues = decrypt(decodeURIComponent( sessionStorage.getItem('verificationtoken')));
    this.initValues = JSON.parse(this.initValues);
    
  }
  state = { visible: false,  submitState: "Register Now"};

  
  componentDidMount() {
    document.title = "Account Verification | " + config.get('companyPageTitle')
  }


  render() {
    const { submitState } = this.state;
   

    const onFinish = values => {
       
 
if (values.verificationcode==this.initValues) {
  sessionStorage.setItem('regeligible', true);
  Modal.success({
      title: 'Verified successfully',
      content: 'One more step to complete your profile',
      onOk() {window.location.href ="/account/register/yourdetails"},
    });
}
else {

  Modal.error({
      title: 'The email verification code is invalid',
      content: 'Did you recently request an account without verifying your email address? If your email address has not been verified, you may need to create a new account. If you are having trouble, see Account Help.',
      onOk() {window.location.href ="/account/register"},
    });
   
}

    };

    return (
      <>
        {(() => {

          switch (config.get('Theme')) {
            case 'Apparel':
              return (<>

<div class="ps-breadcrumb">
            <div className="container">
                <ul className="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li><a href="/account">Your Account</a></li>
                    <li><a href="/account/register">Register</a></li>
                </ul>
            </div>
        </div>
                <section className="ps-section--account">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <AccountLeftMenu />
                      </div>
                      <div className="col-lg-8">
                        <div className="ps-section__right">

                          <AccountRegisterVerifyApparel params={{ disabled: this.state.disabled}}  onFinish={onFinish} />




                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </>)

case 'Apparel-casi':
  return (<>

 

              <AccountRegisterVerifyApparelCasi params={{ disabled: this.state.disabled}}  onFinish={onFinish} />

 

  </>)

            case 'Martfury':
              return (<>

<div class="ps-breadcrumb">
            <div className="container">
                <ul className="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li><a href="/account">Your Account</a></li>
                    <li><a href="/account/register">Register</a></li>
                </ul>
            </div>
        </div>
                <section className="ps-section--account">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <AccountLeftMenu />
                      </div>
                      <div className="col-lg-8">
                        <div className="ps-section__right">

                          <AccountRegisterVerifyMartFury params={{ disabled: this.state.disabled}}  onFinish={onFinish} />




                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </>)
            default:
              return (
      <div>
      <Row>

        <Col sm={12} >
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account">
              Your Account
</Breadcrumb.Item> <Breadcrumb.Item href="/account/register">
              Register
</Breadcrumb.Item>
          </Breadcrumb>
        </Col>

        <Col sm={3}>
          <AccountLeftMenu />
        </Col>

        <Col sm={9}>
          <div className="fade alert alert-light show">



            <Row className="justify-content-md-center">

              <Col sm={6}>
                <Divider orientation="left"><h5>REGISTER FOR ONLINE SHOPPING</h5></Divider>
                <Form

name="basic"
initialValues={{ remember: true }}
onFinish={onFinish}
>
 <Form.Item
        name="verificationcode"
        label="Verification Code"
        rules={[{ required: true, message: 'Please enter a valid verification code' }]}
      >
        <Input placeholder="Please enter your verification code" />
      </Form.Item>
<Form.Item
  name="messagebox"
  label="Please wait"
  hasFeedback
  style={{ display: 'none' }}
  validateStatus="validating"
  help="The information is being validated..."
>

</Form.Item>


<Form.Item  >
  <Button type="primary" htmlType="submit" ref="btn" block danger >
    {this.state.disabled ? 'Please wait...' : 'Verify Now'}
  </Button>
</Form.Item>
</Form>
               

               
               
                </Col>
            </Row>

            <Row>

<Col sm={12} >
        We have sent an email with a verification to your email address. In order to complete the sign-up process, Please check your email and enter the verification code above.
        <br/>
If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form.
<br/>
If you need assistance, please contact us.
</Col>
</Row>



          </div>

        </Col>

        

      </Row>
    </div>
    )
  }

})()}
</>
)
}
}

export default Registration;