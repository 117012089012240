

import React, { useLayoutEffect, useState } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import * as _ from 'lodash';
import LayoutInclude from "./default/layout-include";
import axios from 'axios';
import config from 'react-global-configuration';
import { Form, Button, Row, Col } from 'react-bootstrap';
import {
  LoadingOutlined,
} from '@ant-design/icons';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function extractTranslateFromTransform(transform) {
  let translateValue = null;
  let translate = transform.match(/translate\(.*\)/)?.[0];
  if (translate) {
    translateValue = {};
    if (translate.indexOf(',') !== -1) {
      translateValue.x = parseFloat(translate.substring(translate.indexOf('(') + 1, translate.indexOf(',')));
      translateValue.y = parseFloat(translate.substring(translate.indexOf(',') + 1));
    } else {
      translateValue.x = translateValue.y = parseFloat(translate.substring(translate.indexOf('(') + 1));
    }
  }
  return translateValue;
}

export default class ResponsiveLocalStorageLayout extends React.PureComponent {


  static defaultProps = {
    className: "layout",
    margin: [5, 0],
    isDraggable: false,
    isResizable: false,
    cols: { lg: 12, md: 12, sm: 12, xs: 2, xxs: 2 },
    rowHeight: 39
  };

  constructor(props) {
    super(props);

    this.state = {
      pageid: '', id: '', pages: [], menus: [], layout: [], widgets: [],
      items: [], windowHeight: 0,
      windowWidth: 0, ploading: true,
      newCounter: 0
    };
  }

  resize = () => {

    if (window.innerWidth < 1681) {
      console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {

        for (var i = 0; i < ulElems.length; i++) {
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            var whight = ulElems[i].style.height
            ulElems[i].getElementsByClassName("w-100")[0].style.height = whight
          }
        }
      }, 1000);
    }


    if (window.innerWidth < 1200) {
      // console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          var newh = ulElems[i].style.height.replace('px', '') - 10
          ulElems[i].style.height = newh + "px"
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            ulElems[i].getElementsByClassName("w-100")[0].style.height = newh + "px"
          }
        }
      }, 1000);
    }
 
    if (window.innerWidth <= 1199) {

      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)
 
      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style=""
          ulElems[i].classList.add('newLayoutList');
          ulElems[i].style.position = "relative"
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove('react-grid-item', 'cssTransforms', 'react-resizable-hide', 'react-resizable');
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
      }, 1000);
    }


  }


  componentDidMount() {


    var url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)


    window.addEventListener('resize', this.resize)
    axios.get(config.get('backendURL') + 'pages/' + url.replace('#', '') + '/' + config.get('companyID'))
      .then(response => {
        this.setState({
          pagetitle: response.data.pagetitle,
          content: response.data.content
        })

        axios.get(config.get('backendURL') + 'homelayout/bypageid/' + response.data._id)
          .then(response => {
            //    console.log(response.data)
            if (response.data.length > 0) {
              this.setState({
                layout: response.data.map(function (i, key, list) {
                  return {
                    i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString(), i.pageid.toString()]),
                    x: i.x,
                    y: i.y,
                    w: i.width,
                    h: i.height,
                    add: i === (list.length - 1)
                  };
                }), loading: true,
                ploading: false
              })
            } else {
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            console.log(error);
          })


      })
      .catch(function (error) {
        console.log(error);
      })

    document.title = config.get('companyPageTitle');


    if (window.innerWidth < 1681) {
      console.log('md')
      var adjest = 0;
      
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {

        for (var i = 0; i < ulElems.length; i++) {
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            var hh = ulElems[i].style.height
            ulElems[i].getElementsByClassName("w-100")[0].style.height = hh
          }
        }
        
      }, 1000);
    }


    if (window.innerWidth < 1200) {
      // console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {

      var theight=0;
        for (var i = 0; i < ulElems.length; i++) {
          console.log(ulElems[i].clientHeight / 39);


         theight=theight + ulElems[i].clientHeight;
          console.log(ulElems[i].style.height.replace('px', ''))
          var newh = ulElems[i].style.height.replace('px', '') - 10
 
          ulElems[i].style.height = newh + "px"
 
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {

            ulElems[i].getElementsByClassName("w-100")[0].style.height = newh + "px"
          }

        }
        
      }, 1000);
    }
  
  
    if (window.innerWidth <= 1199) {
 
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)
 
      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style=""
          ulElems[i].classList.add('newLayoutList');
          ulElems[i].style.position = "relative"
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove('react-grid-item', 'cssTransforms', 'react-resizable-hide', 'react-resizable');
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
      }, 1000);
    }

    
  }

  resetLayout() {
    this.setState({ layouts: {} });
  }

  onPageLoad() {
  //  alert('this.props.params.pageid')
  }

  generateDOM(el) {

    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };
    const i = el.i;
    return (
      <div key={i} data-grid={el}>



        <LayoutInclude params={{ layoutid: JSON.parse(i)[0], width: el.x }} />



      </div>
    );

  }
  render() {
    return (

      <>
{(() => {

switch (config.get('Theme')) {
  case 'Apparel-casi':
    return (
      <>
           {
              this.state.ploading ? (<div className="divLoader">
                <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
Loading
              </div>) : (
                  <>
                    {_.map(this.state.layout, el => this.generateDOM(el))}
                   </>
                )
            }
      </>
    )
  default:
    return (<>
      <div>
        <Row>
          <Col sm={12}>


            {
              this.state.ploading ? (<div className="divLoader">
                <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
Loading
              </div>) : (
                  <ResponsiveReactGridLayout

                    breakpoints={{ lg: 1681, md: 1024, sm: 768, xs: 320, xxs: 0 }}
                    className="layout"
                    {...this.props}
                  >
                    {_.map(this.state.layout, el => this.generateDOM(el))}
                  </ResponsiveReactGridLayout>
                )
            }


          </Col>
        </Row>
      </div>
    </>
    )
}

})()}
      
      

      </>
    );
  }
}



