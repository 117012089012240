import React, { Component } from 'react';
import { Drawer } from 'antd';
import axios from 'axios';
import Carousel from "../default/carousel";
import ItemPicks from "../default/itempicks";
import SpecialSale from "../default/specialsale";
import BannerImage from "../default/bannerimage";
import Video from "../default/video";
import config from 'react-global-configuration';

export default class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
       visible:''
      };
  }
  componentDidMount() {
    axios.get(config.get('backendURL')+'homelayout/byid/'+this.props.params.layoutid)
    .then(response => {
        //console.log(response.data)
      this.setState({ datax: response.data})
    })
    .catch((error) => {
      console.log(error);
    })
  }

 
  render() {
    const { visible } = this.state;
    return (
      <>
       
       {
       this.state.datax ? (
           <>
           
      
            
            {
            this.state.datax.type == 'image' ?
            <BannerImage params={{ItemData:this.state.datax.itemdata, className : 'col-sm-'+this.props.params.width}}  />  
            : ''
            }
            {
            this.state.datax.type == 'carousel' ?
            <Carousel params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata, className : 'col-sm-'+this.props.params.width}} />
            : ''
            }
            {
            this.state.datax.type == 'toppicks' ?
            <ItemPicks params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata}}  />
            : ''
            }
             {
            this.state.datax.type == 'special' ?
            <SpecialSale params={{ ItemHeading: this.state.datax.itemheading, ItemData: this.state.datax.itemdata}}  />
            : ''
            }
            {
            this.state.datax.type == 'video' ?
            <Video params={{ ItemHeading: this.state.datax.itemheading,Item: this.state.datax.item, ItemData: this.state.datax.itemdata, className : 'col-sm-'+this.props.params.width}}  />
            : ''
            }
        </>
       ):(<></>)
  }
</>
    );
  }
}


