import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Col, Breadcrumb, Table, Modal } from 'react-bootstrap';
import { Skeleton, Button, Form, Input, Descriptions, Collapse } from 'antd';
import Grid from "@material-ui/core/Grid";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "../../shop/imagegallery";
import ProductTag from "../../shop/product-tag";
import config from 'react-global-configuration';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const ProductDetailsApparel = (props) => (


    <>




        {/* Breadcrumb */}
        <div className="breadcrumb-container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcrumb-in">
                <ul className="site-breadcrumb">
                  <li><a href="/">Home</a></li>
                  <li><a href="/products">Product Details</a></li>
                   
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
       
       

        {
            props.params.ploaded ? (
                props.params.productsdtlsbyid ? (
                    props.params.productsdtlsbyid.map(product =>
                         <>

                         {/* Product Details */}
        <div className="product-details">
          <div className="row">
            <div className="col-sm-6">
              <div className="prd-details-img-wrapper">
                <div className="prd-img-tbn">

                {
                                                        product.OnLineImages.PrimaryImages !== null ? (
                                                            <>
                                                                <Grid container  xs={12} spacing={4}>
                                                                    <Grid item xs={12}><ImageGallery params={{ ProductMainImage: product.OnLineImages.PrimaryImages, SpecialImages: product.OnLineImages.SpecialImages, productsdtlsbyid:props.params.productsdtlsbyid }} /></Grid>
                                                                    <Grid container spacing={2} item xs={12} direction="column">
                                                                        <Grid item>
                                                                            <div id="myPortal" />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <input type="hidden" id={'prodprimimage' + product.ProductID} value={product.OnLineImages.PrimaryImages.Main} />

                                                            </>) : (

                                                                <>
                                                                    <figure>
                                                                        <div className="ps-wrapper">
                                                                            <div className="ps-product__gallery" data-arrow="true">
                                                                                <div className="item"><img src="/images/no-image.jpg" alt={product.Name} height="220" /></div>
                                                                                <input type="hidden" id={'prodprimimage' + product.ProductID} value='' />
                                                                            </div>
                                                                        </div>
                                                                    </figure>

                                                                </>
                                                            )
                                                    }

                  
                </div>
               
              </div>
            </div>
            <div className="col-sm-6">
              <div className="prd-details-text">
                <p className="prd-title">{product.Name}</p>
                <div className={product.Discount != 0 ? 'priceleft col-sm-6' : 'priceleft col-sm-12'}>

<input type="hidden" id={'prodprice' + product.ProductID} value={product.SellingPricesArray.SellingPrice} />

<h4 className="ps-product__price">{localStorage.getItem('ecurrencyCode')} {String(product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio'))}</h4>


</div>
{
product.Discount != 0 ?
    <div className="priceright col-sm-6">was {localStorage.getItem('ecurrencyCode')}{(parseInt(product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(product.Discount)} <br /><span className="dispricetxt">save {localStorage.getItem('ecurrencyCode')}{product.Discount}</span></div>
    : ''
}
                <div className="prd-more-details">
                  <p>Sold By: Casipillai Designer Collection</p>
                  
                  <p>Product Ref No: {product.ProductRefNo}</p>
                </div>

                {product.ProductOnlineDescription ? (<>

<Descriptions title="Product Information">
    <Descriptions.Item><div dangerouslySetInnerHTML={{ __html: product.ProductOnlineDescription }} /></Descriptions.Item>
</Descriptions>


</>) : ('')
}


{
                                                            product.SellingPricesArray.UnitChartid > 0 ? (
                                                         
                                                    <Table striped bordered hover size="sm" width="50%">
                                                        <thead>
                                                            <tr>
                                                                <th>Pack Size</th>
                                                                <th>Weight</th>
                                                                <th>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                product.UnitCharts.length > 0 ? (
                                                                    product.UnitCharts.map(elu =>
                                                                        <tr>
                                                                            <td>{elu.Quantity}</td>
                                                                            <td>{product.Weight}</td>
                                                                            <td>{elu.UnitTypeName}</td>
                                                                        </tr>

                                                                    )
                                                                ) : (

                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>{product.Weight}</td>
                                                                            <td>EA</td>
                                                                        </tr>
                                                                    )
                                                            }


                                                        </tbody>
                                                    </Table>
):('')
                                                        }
                
                
                {product.Offers[0] ? (product.Offers[0].Offer.OfferType == 3 ? (<>

<Collapse
    bordered={true}

    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="multioffer"
>
    <Panel header="Multi Buy Offers - Click to View" key="1" className="site-collapse-custom-panel">
        <b class="text-danger">{product.Offers[0].Offer.OfferTypeDesc}</b><br />
        <b class="text-success">{product.Offers[0].Offer.PromotionName}</b><br /><br />

        {
            props.params.offerproducts.length > 2 ? (
                <><b>Buy Any {product.Offers[0].Offer.PromotionQty} of these</b></>)
                : (<><b>Buy {product.Offers[0].Offer.PromotionQty} of these</b></>)
        }
        <p>

            {

                props.params.offerproducts.map(PO =>
                    <>
                        {
                            PO[0].Name != null ? (
                                <><a href={'/product/' + PO[0].Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + PO[0].ProductID}>{PO[0].Name} - {PO[0].ProductRefNo}</a><br /></>
                            ) : ''
                        }
                    </>
                )
            }

        </p>
    </Panel>

</Collapse>
</>) : '') : ''}
                
                
                
                
{product.IsOutOfStock ? (<div className="text-center"> <p className="inputandbtn"><button className="notify" onClick={props.onNotify.bind(this, product.ProductID, product.Name, product.MaxOnlineOrderQty)} id={product.ProductID}><FontAwesomeIcon icon={faEnvelope} />Notify</button></p> <Modal
                                                            show={props.params.visible}
                                                            onHide={props.handleCancel}
                                                            centered
                                                            aria-labelledby="example-modal-sizes-title-sm"
                                                        >
                                                            <Modal.Header closeButton>
                                                                <Modal.Title id="example-modal-sizes-title-sm">
                                                                    Notify
          </Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form
                                                                    onFinish={props.onFinish}
                                                                    name="basic"
                                                                    initialValues={{ remember: true }}
                                                                >
                                                                    <Form.Item
                                                                        label="Email Address"
                                                                        name="email"
                                                                        rules={[{ required: true, type: 'email' }]}
                                                                    >
                                                                        <Input placeholder="Email Address" />
                                                                    </Form.Item>



                                                                    <Form.Item  >
                                                                        <Input name="prdt" type="hidden" id="prdt" value={product.ProductID} />
                                                                        <Button type="primary" htmlType="submit"  danger >
                                                                            Submit
                      </Button>
                                                                    </Form.Item>
                                                                </Form>

                                                            </Modal.Body>
                                                        </Modal></div>) : (
                                                                <>
                                                                   
                                                                   
                                                                   
                                                                   <div className="prd-count">
                  <span className="label-qty">Quantity:</span>
                  <div className="prd-count-inner">
                    <span className="qty-sub">
                      <button onClick={props.onQtyReduce.bind(this, product.ProductID)}>
                        <i className="fas fa-minus" />
                        {/* <i class="far fa-trash-alt"></i> */}
                      </button>
                    </span>
                    <span className="qty-no">
                  
                      <input   type="text" min={1} max={product.MaxOnlineOrderQty == 0 ? 10000 : product.MaxOnlineOrderQty} precision={0} id={'prodqty' + product.ProductID} value={1} snap placeholder={1} />
                    </span>
                    <span className="qty-add">
                      <button  onClick={props.onQtyIncrese.bind(this, product.ProductID)} >
                        <i className="fas fa-plus"/>
                      </button>
                    </span>
                  </div>
                </div>                       
                                                                   
                         
                                                                </>

                                                            )}


                
              



                <div className="prd-buttons">
                  <a  onClick={props.onAddItem.bind(this, 'add', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.offerdetails ? (product.offerdetails.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.offerdetails ? (product.offerdetails.Offer.PromotionQty) : '', product.offerdetails ? (product.offerdetails.Offer.Price) : '')} id={product.ProductID} className="btn-act-secondary mr10">ADD TO CART</a>
                  <a onClick={props.onAddItem.bind(this, 'buy', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.offerdetails ? (product.offerdetails.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.offerdetails ? (product.offerdetails.Offer.PromotionQty) : '', product.offerdetails ? (product.offerdetails.Offer.Price) : '')} className="btn-act-primary">BUY NOW</a>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="commercial-details">
                <div id="accordion">
                  <div className="card">
                    <div className="card-header">
                      <a data-toggle="collapse" href="#content-one" className="card-link" aria-expanded="false">
                        Shipping &amp; Return Policy
                      </a>
                    </div>
                    <div id="content-one" className="collapse show" data-parent="#accordion">
                      <div className="card-body">
                        <p>
                          We want you to be really happy with your purchase. In the exceptional circumstance that you're not, 
                          simply return the product to us by following the simple instructions below and we'll gladly exchange 
                          or refund it.
                        </p>
                        <p>
                          You can return your purchase to us within 7 days of receipt, unworn, tags &amp; label intact and in 
                          its original re-saleable condition.
                        </p>
                        <p>
                          Please take care of the sarees the moment you take the saree out of our store. We will not accept 
                          items that have been damaged. All items must be returned unworn, and in an original re-saleable condition. 
                        </p>
                        <ul>
                          <li>
                            Once an item has been altered in any way, this product can no longer be returned i.e the folding of the saree
                          </li>
                          <li>
                            If items being returned have been worn, then we reserve the right to send them back to you. The same applies to 
                            soiled, altered or damaged items.
                          </li>
                          <li>
                            Sarees can not be returned if the blouse piece has been cut off/removed or been stitched/altered.  
                            Please make sure you are 100% happy with your choice of outfit before authorising any alterations or selecting 
                            any tailoring options as this garment will not be covered under the exchange/return policy.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a data-toggle="collapse" href="#content-two" className="card-link collapsed" aria-expanded="false">
                        How to Return Items
                      </a>
                    </div>
                    <div id="content-two" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        <p>
                          Items must be returned in their original pre-sale condition -  unworn, tags attached and folded 
                          carefully in sufficient and appropriate packaging to make sure that the item(s) are not damaged in 
                          transit. Items must be returned to:
                        </p>
                        <address>
                          Casipillai Designer Collection<br />
                          20 Village Way East,<br />
                          Rayners Lane,<br />
                          Harrow HA2 7LU<br />
                          United Kingdom<br />
                        </address>
                        <p>
                          When you return items back, please send a copy of the invoice with goods, so we can process the 
                          returns easily. Failure to enclose the invoice with the goods means we are unable to identify whom 
                          the items are being returned from, meaning we will be unable to process the refund.
                        </p>
                        <p>
                          Send any returns back to us through a delivery service that is signed for so that there is confirmation 
                          of your returns. The responsibility of the goods remains yours until it has been received back in our premises.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a data-toggle="collapse" href="#content-three" className="card-link collapsed" aria-expanded="false">
                        Delivery
                      </a>
                    </div>
                    <div id="content-three" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        <p>
                          We offer a range of affordable delivery options to suit our clients. We deliver throughout 
                          the UK and any postal address around the world from our store. Your parcel will be sent tracked 
                          and signed for delivery to your shipping address.
                        </p>
                        <p>
                          Your order and shipping confirmation will be emailed to you which will allow you to track the 
                          status of your shipment. Your order will be delivered between Monday to Friday during working day. 
                          A signature will be required for the deliveries, so please make sure someone is at the delivery 
                          address to sign for the goods otherwise provide an address where someone will be available.  
                          If there is no one to take delivery, then a card from the courier will be left behind with 
                          instructions for collection/delivery of the parcel. 
                        </p>
                        <p>
                          Delivery times may vary, especially during peak periods. We will aim to dispatch and deliver within 
                          the time we estimate. However please take into consideration any weekends and national holidays 
                          such as Bank Holidays may result in courier delays.
                        </p>
                        <p>
                          Customs, import duties and taxes Orders for delivery outside the UK may be subject to customs fees, 
                          import duties, and taxes after the shipment reaches the destination country. Additional charges are 
                          the responsibility of the recipient because we have no control over these government-imposed charges 
                          and cannot determine what they may be. Customs policies vary greatly among countries. Please contact 
                          your local customs office for further information before placing your order.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Product Details */}

                         </>

                    )
                ) : (
                        <Col sm={12}><br />
                            <Skeleton active avatar paragraph={{ rows: 4 }} />
                        </Col>
                    )
            ) : (<Col sm={12}><br />
                <Skeleton active avatar paragraph={{ rows: 4 }} />
            </Col>)
        }

       
        




  
  
         

         

    </>

);
export default ProductDetailsApparel;
