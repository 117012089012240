import React from 'react'
// import './css/spiceway.css'

const TermsOfUse = () => {
  return (
    <div className='policy'>
      <h1>TERMS OF USE</h1>
      <p className='term-com-name'>Thank you for your interest in <b>Spiceway Wholesale Limited</b>.</p>
      <p>This site is provided to you by <b>Spiceway Wholesale Limited</b>, Unit 4 College Fields
        Business Centre, Prince Georges Road, Merton, United Kingdom, SW19 2PX
        (“Spiceway Wholesale” or “we” or “our”)</p>
      <h5>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</h5>
      <p>These terms of use (together with the documents referred to in it) tell you the terms
        of use on which you may make use of our website <a target="_blank" href='https://spiceway.retailpacx.com/'>spicewaywholesale.com</a>,
        whether as a guest or a registered user. Use of our Site includes accessing,
        browsing, or registering to use our Site.</p>
      <p>By using our Site, you confirm that you accept these terms of use and that you agree to comply with them.</p>
      <p>If you do not agree to these terms of use, you must not use our Site.</p>
      <h5>OTHER APPLICABLE TERMS</h5>
      <p>These terms of use refer to the following additional terms, which also apply to your use of our Site:
        <ul>
          <li>
            Our Privacy Policy, which sets out the terms on which we process any personal
            data we collect from you, or that you provide to us as well. By using our Site, you
            consent to such processing and you warrant that all data provided by you is
            accurate.
          </li>
          <li>
            Our Cookie Policy, which sets out information as to the types of cookies that we use on our Site.</li>
        </ul>
      </p>
      <h5>CHANGES TO THESE TERMS</h5>
      <p>
        These terms govern the provision and use of the Site and will form a legally binding
        contract between <i>Spiceway Wholesale</i>, and you so please do take the time to read
        these terms and make sure you understand them.
      </p>
      <p>We may revise these terms of use at any time by amending this page. Please check
        this page from time to time to take notice of any changes we made, as they are
        binding on you.</p>
      <h5>CHANGES TO OUR SITE</h5>
      <p>We may update our Site from time to time and may change the content or
        technology at any time. However, please note that any of the content on our Site
        may be out of date at any given time, and we are under no obligation to update it.
        We do not guarantee that our Site, or any content on it, will be free from errors or
        omissions.</p>
      <h5>ACCESSING OUR WEBSITE</h5>
      <p>Our Spiceway Wholesale website at <a target="_blank" href='www.spicewaywholesale.com'>www.spicewaywholesale.com</a> (the “Website”) is made available free of charge.</p>
      <p>We do not guarantee that our Website, or any content on it, will always be available
        or be uninterrupted. Access to our website is permitted on a temporary basis. We
        may suspend, withdraw, discontinue or change all or any part of our Website
        without notice. We will not be liable to you if for any reason our Website is
        unavailable at any time or for any period. You are responsible for making all
        arrangements necessary for you to have access to our Website. You are also
        responsible for ensuring that all persons who access our Website through your
        internet connection are aware of these terms of use and other applicable terms and
        conditions, and that they comply with them.</p>
      <h5>AVAILAIBILITY & USE OF THE SITE</h5>
      <h6>You acknowledge that we cannot guarantee that the Site will:</h6>
      <ol type="a">
        <li>stay the same as we might change the Site or remove it altogether;</li>
        <li>be compatible with all or any hardware or software which you may use;</li>
        <li>be available all the time or at any specific time;</li>
        <li>be accurate and up to date; or</li>
        <li>be error-free or free of viruses, electronic bugs, Trojan horses or other harmful components and you must take your own precautions accordingly.</li>
      </ol>
      <h6>You also acknowledge that:</h6>
      <ol type="a">
        <li>we cannot guarantee the performance or security of our Website or App; and</li>
        <li>we will not be responsible for any damage or loss you may suffer directly or
          indirectly as a result of any virus attack that can be traced to our Website or App to
          the fullest extent permissible by law.</li>
      </ol>
      <h6>You must not:</h6>
      <ol type='a'>
        <li>interfere with, or disrupt, the Site or any servers or networks connected to the
          Site, including by transmitting any worms, viruses, malware, spyware or any other
          code of a destructive, malicious or disruptive nature. You may not inject content or
          code or otherwise alter or interfere with the way any page of the Site is rendered or
          displayed in a user’s browser or device;</li>
        <li>access the Site via a means not authorised in writing in advance by Spiceway
          Wholesale, including but not limited to, automated devices, scripts, bots, spiders,
          crawlers or scrapers (except for standard search engine technologies);</li>
        <li>attempt to restrict another user of the Site from using or enjoying the Site and
          you must not encourage or facilitate the breach of these terms of Use by others;</li>
        <li>use the Site for any illegal or unauthorised purpose; or</li>
        <li>change, modify, adapt, or alter the Site or change, modify or alter another
          website so as to inaccurately imply an association with the Site or with Spiceway
          Wholesale.</li>
      </ol>
      <h5>YOUR PRIVACY & COOKIES</h5>
      <p>The privacy of your personal data is important to us. Please see our Privacy
        Policy and Cookie Policy for details of how we will process your personal data and
        how we use cookies and similar technologies.</p>
      <h5>YOUR ACCOUNT AND PASSWORD</h5>
      <p>If you choose, or you are provided with, a user identification, password or any other
        piece of information as part of our security procedures, you must treat such
        information as confidential. You must not disclose it to any third party. We have the
        right to disable any user identification code or password, whether chosen by you or
        allocated by us, at any time, if in our reasonable opinion you have failed to comply
        with any of the provisions of these terms of use. If you know or suspect that anyone
        other than you know your user identification code or password, you must promptly
        notify us.</p>
      <h5>INTELLECTUAL PROPERTY RIGHTS IN OUR WEBSITE</h5>
      <p>We are the owner or the licensee of all intellectual property rights in our Site, and in
        the material published on it. Those works are protected by copyright, trademarks,
        patents and other intellectual property rights and laws. All such rights are reserved.</p>
      <p>You may print off and may download extracts, of any page(s) from our Site for your
        personal use and you may draw the attention of others to content posted on our
        Site.</p>
      <p>You must not modify the paper or digital copies of any materials you have printed
        off or downloaded in any way, and you must not use any illustrations, photographs,
        video or audio sequences or any graphics separately from any accompanying text.</p>
      <p>Our status (and that of any identified contributors) as the authors of content on our
        Site must always be acknowledged.</p>
      <p>You must not use any part of the content on our site for commercial purposes
        without obtaining a licence to do so from us or our licensors.</p>
      <p>If you print off, copy or download any part of our site in breach of these terms of
        use, your right to use our Site will cease immediately and you must, at our option,
        return or destroy any copies of the materials you have made.</p>
      <h5>IF YOU BREACH THESE TERMS</h5>
      <p>If you are in breach of, or we suspect you are in breach of, these Terms then we
        may take any/all of the following actions:</p>
      <ol type='a'>
        <li>issue a warning to you;</li>
        <li>take legal action against you; and/or</li>
        <li>disclose such information to law enforcement authorities as we reasonably feel is
          necessary.</li>
      </ol>
      <p>The responses described above are not limited and we may take any action we reasonably deem appropriate.</p>
      <h5>NO RELIANCE ON INFORMATION</h5>
      <p>The content on our Site is provided for general information only. It is not intended to
        amount to advice on which you should rely. You must obtain professional or
        specialist advice before taking, or refraining from, any action on the basis of the
        content on our Site.</p>
      <p>Although we make reasonable efforts to update the information on our Site, we
        make no representations, warranties or guarantees, whether express or implied,
        that the content on our Site is accurate, complete or up to date.</p>
      <h5>LIMITATION OF OUR LIABILITY</h5>
      <p>Nothing in these terms of use excludes or limits our liability for death or personal
        injury arising from our negligence, or our fraud or fraudulent misrepresentation, or
        any other liability that cannot be excluded or limited by English law.</p>
      <p>To the extent permitted by law, we exclude all conditions, warranties,
        representations or other terms which may apply to our Site or any content on it,
        whether express or implied.</p>
      <p>We will not be liable to any user for any loss or damage, whether in contract, tort
        (including negligence), breach of statutory duty, or otherwise, even if foreseeable,
        arising under or in connection with:</p>
      <ol type='a'>
        <li>use of, or inability to use, our Site; or</li>
        <li>use of or reliance on any content displayed on our Site.</li>
      </ol>
      <p>Please note that in particular, we will not be liable for:</p>
      <ol type='a'>
        <li>loss of profits, sales, business, or revenue;</li>
        <li>business interruption;</li>
        <li>loss of anticipated savings;</li>
        <li>loss of business opportunity, goodwill or reputation; or</li>
        <li>any indirect or consequential loss or damage.</li>
      </ol>
      <p>We only provide our Site for domestic and private use. You agree not to use our
        Site for any commercial or business purposes, and we have no liability to you for
        any loss of profit, loss of business, business interruption, or loss of business
        opportunity.</p>
      <p>We will not be liable for any loss or damage caused by a virus, distributed denial-ofservice
        attack, or other technologically harmful material that may infect your
        computer equipment, computer programs, data or other proprietary material due to
        your use of our Site or to your downloading of any content on it, or on any website
        linked to it.</p>
      <p>We assume no responsibility for the content of websites linked on our Site. Such
        links should not be interpreted as endorsement by us of those linked websites. We
        will not be liable for any loss or damage that may arise from your use of them.</p>
      <h5>UPLOADING CONTENT TO OUR SITE</h5>
      <p>Whenever you make use of a feature that allows you to upload content to our Site,
        or to make contact with other users of our Site, you must comply with the content
        standards set out in this policy.</p>
      <p>You warrant that any such contribution does comply with our standards, and you
        will be liable to us and indemnify us for any breach of that warranty. This means you
        will be responsible for any loss or damage we suffer as a result of your breach of
        warranty.</p>
      <p>Any content you upload to our Site will be considered non-confidential and nonproprietary.
        You retain all of your ownership rights in your content, but you are
        required to grant us and other users of the Site a licence to use, store and copy that
        content and to distribute and make it available to third parties. The rights you
        licence to us are described in the next paragraph (Rights you licence).</p>
      <p>We also have the right to disclose your identity to any third party who is claiming
        that any content posted or uploaded by you to our Site constitutes a violation of
        their intellectual property rights, or of their right to privacy.</p>
      <p>We will not be responsible, or liable to any third party, for the content or accuracy of
        any content posted by you or any other user of our Site.</p>
      <p>We have the right to remove any posting you make on our Site if, in our opinion,
        your post does not comply with the Content Standards or our Acceptable Use requirements.</p>
      <p>The views expressed by other users on our Site do not represent our views or values.</p>
      <h5>RIGHTS YOU LICENCE</h5>
      <p>When you upload or post content to our Site, you grant a perpetual royalty free licence
        for Spiceway Wholesale to use any of the content that you post both on this Site and in any
        other advertising or for any other purpose that Spiceway Wholesale wishes to use it for.</p>
      <h5>LINKING TO OUR SITE</h5>
      <p>You may link to our page, provided you do so in a way that is fair and legal and
        does not damage our reputation or take advantage of it.</p>
      <p>You must not establish a link in such a way as to suggest any form of association,
        approval or endorsement on our part where none exists.</p>
      <p>Our Site must not be framed on any other Site.</p>
      <p>We reserve the right to withdraw linking permission without notice.</p>
      <p>The website in which you are linking must comply in all respects with the content
        standards set out in our Acceptable Use section.</p>
      <p>If you wish to make any use of content on our Site other than that set out above, please contact us.</p>
      <h5>THIRD PARTY LINKS AND RESOURCES IN OUR SITE</h5>
      <p>Where our Site contains links to other sites and resources provided by third parties,
        these links are provided for your information only.</p>
      <p>We have no control over the contents of those sites or resources.</p>
      <h5>ACCEPTABLE USE</h5>
      <p>You may use our Site only for lawful purposes. You may not use our Site:</p>
      <ul>
        <li>In any way that breaches any applicable local, national or international law or regulation.</li>
        <li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
        <li>For the purpose of harming or attempting to harm children in any way.</li>
        <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards.</li>
        <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
        <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses,
          worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer
          code designed to adversely affect the operation of any computer software or hardware.</li>
      </ul>
      <h5>CONTENT STANDARDS</h5>
      <p>These content standards apply to any and all material which you contribute to our
        Site (contributions), and to any interactive services associated with it.</p>
      <p>You must comply with the spirit and the letter of the following standards. The
        standards apply to each part of any contribution as well as to its whole.</p>
      <p>Contributions must:</p>
      <ul>
        <li>Be accurate (where they state facts).</li>
        <li>Be genuinely held (where they state opinions).</li>
        <li>Comply with applicable law in the UK and in any country from which they are posted.</li>
      </ul>
      <p>Contributions must not:</p>
      <ul>
        <li>Contain any material which is defamatory of any person.</li>
        <li>Contain any material which is obscene, offensive, hateful or inflammatory.</li>
        <li>Promote sexually explicit material.</li>
        <li>Promote violence.</li>
        <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
        <li>Infringe any copyright, database right or trademark of any other person.</li>
        <li>Be likely to deceive any person.</li>
        <li>Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
        <li>Promote any illegal activity.</li>
        <li>Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
        <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
        <li>Be used to impersonate any person, or to misrepresent your identity or affiliation with any person.</li>
        <li>Give the impression that they emanate from us, if this is not the case.</li>
        <li>Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
      </ul>
      <h5>APPLICABLE LAW</h5>
      <p>If you are a consumer, please note that these terms of use, its subject matter and its
        formation, are governed by English law. You and we both agree to that the courts of
        England and Wales will have non-exclusive jurisdiction. However, if you are a
        resident of Northern Ireland you may also bring proceedings in Northern Ireland,
        and if you are resident of Scotland, you may also bring proceedings in Scotland.</p>
      <p>If you are a business, these terms of use, its subject matter and its formation (and
        any non-contractual disputes or claims) are governed by English law. We both
        agree to the exclusive jurisdiction of the courts of England and Wales.</p>
      <h5>QUESTIONS OR COMPLAINTS?</h5>
      <p>If you have any queries at all regarding the Brolly Service, the items featured on the
        Brolly Service or any of the Terms, please contact us by:</p>
      <ol type='a'>
        <li>contacting us via <a href='mailto: info@spicewaywholesale.com'>info@spicewaywholesale.com</a></li>
        <li>calling us on +44 (0) 208 685 1122; or</li>
        <li>writing to us at Spiceway Wholesale Limited, Unicorn House, Unit 4 College
          Fields Business Centre, Prince Georges Road, Merton, United Kingdom, SW19 2PX,
          marking your letter for the attention of the Consumer Engagement Team.</li>
      </ol>
      <p>Thank you for visiting our Site.</p>
      <p>Last Updated: 17th of November 2022</p>
    </div>
  )
}

export default TermsOfUse