import React from 'react';
import config from 'react-global-configuration';
import { Email, Container, Row, Column, Header, FullWidth, Footer, Text, Link, PostonentsProvider } from 'postonents';

const OrderEmail = ({ Emaildata }) => {

    const { CustEmail, SenderEmail, ordernumber, companyPageTitle, companyLogo, ophours, phone, address, basketitem, subtotal, delivery, total, fullUserDetails, deliveryMethod, paymentType, offer } = Emaildata;

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return (
        <PostonentsProvider theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
            <Email
                title={`Order confirmation for ${ordernumber}`}
                headLinks={[
                    {
                        type: 'link',
                        props: {
                            rel: 'stylesheet',
                            href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                        },
                    },
                ]}>

                <Container alignment="center" style={{ backgroundColor: '#FFFFFF', paddingLeft: "20px", paddingRight: "20px" }}>
                    <Row>
                        <Column small={12}>
                            <Header style={{ backgroundColor: '#FFFFFF', padding: 5 }}
                                logo={'https://cdn.neurolage.com/retailpacx/' + config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() + '/' + companyLogo}
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Hello,</Text>
                        </Column>
                    </Row>
                    <Row>

                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>Thank you for shopping at {companyPageTitle} online.</Text>

                            <br /><br />
                            Payment Type: {paymentType}
                            <br /><br />
                            {
                                deliveryMethod == 'Delivery' ? (
                                    <>

                                        <Text>Order Type: {deliveryMethod} </Text>
                                        <br /><br />
                                        <Text>Please check that the delivery address listed below is correct.</Text>

                                        <br /><br />
                                        <b>Deliver To:</b>
                                        {

                                            JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                if (address.AddressType === "Delivery") {
                                                    return (
                                                        <>
                                                            {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                            {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                            {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                            {address.City ? (<>{address.City}<br /></>) : ''}
                                                            {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                            {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                            {address.Country ? (<>{address.Country} <br /></>) : ''}
                                                            {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                            {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                            {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                        </>
                                                    );
                                                }
                                            })
                                        }
                                    </>
                                ) : (<Text>Order Type: {deliveryMethod}</Text>)
                            }
                        </Column>
                    </Row>
                </Container>

                <Container alignment="center" style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px" }}>
                </Container>

                <Container alignment="center" style={{ backgroundColor: "#222222", color: "#FFF", paddingLeft: "20px", paddingRight: "20px" }}>
                    <Row>
                        <Column small={8} style={{ width: '50%' }}>

                            <b>Order Summary </b><br />
                            Placed: {new Date().getDate()} {monthNames[new Date().getMonth()]} {new Date().getFullYear()}
                        </Column>
                        <Column small={4} style={{ 'text-align': "right", width: '50%' }}>
                            #{ordernumber}
                        </Column>
                    </Row>
                </Container>
                <Container alignment="center" style={{ backgroundColor: "#FFF", paddingLeft: "20px", paddingRight: "20px" }}>
                    <Row>
                        <Column small={3} style={{ width: '25%' }}>
                            <b>Items:</b>
                        </Column>
                        <Column small={7} style={{ width: '58%' }}>
                            <b>Desc/Qty:</b>
                        </Column>
                        <Column small={2} style={{ 'text-align': "right", width: '17%' }}>
                            <b>Total</b>
                        </Column>
                    </Row>



                    {
                        basketitem.map(item => (
                            <Row>
                                <Column small={3} style={{ width: '25%' }}>
                                    {
                                        item.ProductImage !== '' ?
                                            <img src={item.ProductImage} width="80" />
                                            : <img src="https://www.throbox.com/images/noimage.jpg" alt="." width="80" />
                                    }
                                </Column>
                                <Column small={7} style={{ width: '58%' }}>
                                    {item.ProductName} <br /> Qty: {item.ProductQty}
                                </Column>
                                <Column small={2} style={{ 'text-align': "right", width: '17%' }}>
                                    {localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}
                                </Column>
                            </Row>
                        )
                        )
                    }
                    <Row>
                        <Column small={12}><hr /></Column>
                    </Row>

                    {
                        offer != 0 ? (
                            <Row>
                                <Column small={12}><b>Offer Discount Detail</b></Column>
                            </Row>
                        ) : ''
                    }
                    {
                        console.log(basketitem)
                    }
                    {
                        offer != 0 ? (
                            basketitem.map(item => (
                                item.OfferAmount != 0 ? (

                                    <Row>
                                        <Column small={10} style={{ width: '50%' }}>  {item.offerdetails[0].Offer.PromotionName}</Column>
                                        <Column small={2} style={{ 'text-align': "right", width: '50%' }}> {localStorage.getItem('ecurrencyCode')} -{item.OfferAmount.toFixed(2)}</Column>
                                    </Row>

                                ) : ''
                            )
                            )


                        ) : ''
                    }
                    <Row>
                        <Column small={10} style={{ width: '50%' }}>Subtotal:</Column>
                        <Column small={2} style={{ 'text-align': "right", width: '50%' }}>{localStorage.getItem('ecurrencyCode')} {subtotal}</Column>
                    </Row>



                    {
                        offer != 0 ? (
                            <Row>
                                <Column small={10} style={{ width: '50%' }}>Total Offer Discount:</Column>
                                <Column small={2} style={{ 'text-align': "right", width: '50%' }}>{localStorage.getItem('ecurrencyCode')} -{offer}</Column>
                            </Row>
                        ) : ''
                    }

                    <Row>
                        <Column small={10} style={{ width: '50%' }}>Delivery:</Column>
                        <Column small={2} style={{ 'text-align': "right", width: '50%' }}>{localStorage.getItem('ecurrencyCode')} {delivery}</Column>
                    </Row>
                    <Row>
                        <Column small={12}><hr /></Column>
                    </Row>
                    <Row>
                        <Column small={10} style={{ width: '50%' }}><b>Total:</b></Column>
                        <Column small={2} style={{ 'text-align': "right", width: '50%' }}><b>{localStorage.getItem('ecurrencyCode')} {(total - offer + delivery).toFixed(2)}</b></Column>
                    </Row>
                </Container>

                <Container alignment="center" style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px" }}>
                </Container>

                <Container alignment="center" style={{ backgroundColor: '#FFFFFF', paddingLeft: "20px", paddingRight: "20px" }}>
                    <Row>
                        <Column small={12}>
                            <Text>For further assistance to correct your address or to remove an item from your order, please call us as soon as possible on {phone} ({ophours}) or reply to this email and we'll be in touch as soon as we can. You will be notified by email of any delays/issues with your order.</Text>
                            <Text>Thanks for shopping with us. Hope you found everything you were looking for. <br />
                                Any other questions? We are happy to help!<br /><br />
                                See you again soon,<br /><br />
                                The Delivery Team<br />
                                {companyPageTitle}<br /><br />
                                Have any questions? – Contact our team
                            </Text>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={6}>
                            <Link href="https://www.ecologital.com/contact" fullWidth type="hollow">Help Center</Link>
                        </Column>
                        <Column small={6}>
                            <Link href={"mailto:" + SenderEmail} fullWidth type="hollow">Email</Link>
                        </Column>
                    </Row>


                </Container>
                <Container alignment="center" style={{ padding: "20px" }}>


                    <Row>
                        <Column small={12} style={{ marginBottom: "10px", textAlign: "center", fontSize: 11 }}>
                            <Text style={{ marginBottom: "10px", textAlign: "center", fontSize: 11 }}>
                                You are registered as {CustEmail}.<br />
                                This message was sent from: {SenderEmail}<br />

                                <b>{companyPageTitle}</b><br />

                                {address}<br />

                                {companyPageTitle} is committed to respecting your privacy.
                            </Text>
                        </Column>

                    </Row>
                    <Row>
                        <Column small={12} style={{ textAlign: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 300 }}>
                            Copyright ©  {" 2019-"} {new Date().getFullYear()} {companyPageTitle}, All rights reserved
                        </Column>
                    </Row>
                </Container>
            </Email>
        </PostonentsProvider>
    );
};

export default OrderEmail;