import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Button, Modal, Radio, Skeleton, message } from 'antd';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import config from 'react-global-configuration';
import {
    CloseOutlined
} from '@ant-design/icons';
import CartMartFury from "../themes/martfury/cart.jsx";
import CartApparel from "../themes/apparel/cart.jsx";
import CartApparelCasi from "../themes/apparel-casi/cart.jsx";
import CartMeatShop from "../themes/meatshop/cart.jsx";
class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = { company: [], categories: [], basketitem: [], value: 10, deliveryscheme: [], delivery: 0 };
    }
    onCloseClick = (ProductQty, ProductID, ProductName, product, MaxOnlineOrderQty, ProductCategoryID, offerItems, offerqty, offerprice, prodprice) => {
        const cachedProduct = localStorage.getItem('cart');
        var index = JSON.parse(cachedProduct).findIndex(a => a.ProductID === ProductID);
        let basketitem = this.state.basketitem;
        // basketitem[index]["OfferAmount"] = 0;

        if (index !== -1) {
            const newList = this.state.basketitem.splice(index, 1);
            if (product.offerdetails && product.offerdetails[0]) {
                setTimeout(() => {
                    //offer
                    var offeri = [];

                    offeri = JSON.parse("[" + product.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',') + "]");

                    let checker = (arr, target) => target.some(v => arr.includes(v));

                    //console.log(JSON.parse(localStorage.getItem('cart')));

                    let op = JSON.parse(localStorage.getItem('cart')).map(({ ProductID }) => ProductID)

                    //  console.log(op)
                    //   console.log(offeri)

                    var offer = 0;

                    //  console.log(checker(op, offeri))

                    if (checker(op, offeri)) {

                        var totalitems = 0;
                        var totalamounts = 0;
                        var newArray = offeri.map(function (el) {

                            var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);
                            //  console.log(cindex)

                            if (cindex !== -1) {
                                totalitems = totalitems + parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"])
                                totalamounts = totalamounts + (parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"]) * parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductPrice"]))
                                basketitem[cindex]["OfferAmount"] = 0;
                            }
                        });

                        //  console.log(totalitems)
                        var offerqty = product.offerdetails[0].Offer.PromotionQty
                        var offerprice = product.offerdetails[0].Offer.Price
                        //    console.log('offerqty' + parseFloat(offerqty))

                        var quotient = Math.floor(totalitems / offerqty);
                        var remainder = totalitems % offerqty;
                        // console.log('total:' + totalamounts)
                        //   console.log('offer total:' + quotient * offerprice)
                        //  console.log('remainder: ' + remainder)
                        offer = (parseFloat(totalamounts - (prodprice * remainder)) - parseFloat(quotient * offerprice))
                        //   console.log('singleprice:' + prodprice * remainder)

                        var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);

                        //   console.log('totalitems' + totalitems)


                        var x = 0;
                        var newArray = offeri.map(function (el) {

                            var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);




                            if (cindex > 0 && x === 0) {
                                basketitem[cindex]["OfferAmount"] = offer;
                                x = x + 1;
                                console.log(x)
                                console.log(cindex)
                            } else {
                                if (cindex > 0) {
                                    basketitem[cindex]["OfferAmount"] = 0;
                                }
                            }
                        });



                        this.setState({ basketitem: basketitem });
                        localStorage.setItem('cart', JSON.stringify(basketitem));

                        //  console.log(JSON.parse(localStorage.getItem('cart')))
                    }


                }, 100);

            }
        } else {





            this.setState({ basketitem: this.state.basketitem });
        }

        this.setState({ basketitem: this.state.basketitem });
        document.getElementById("cartcount").innerHTML = document.getElementById("cartcount").innerHTML - ProductQty;
        localStorage.setItem('cart', JSON.stringify(this.state.basketitem));
    };



    onQtyIncrese = (ProductID, ProductName, product, MaxOnlineOrderQty, ProductCategoryID, offerItems, offerqty, offerprice, prodprice) => {
        var index = this.state.basketitem.findIndex(a => a.ProductID === ProductID);
        const basketitem = this.state.basketitem;
        let prodqty = '';
        prodqty = document.getElementById("prodqty" + ProductID).value


        if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty <= (parseInt(prodqty))) {
            message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
            return;
        } else if (prodqty > 1000) {
            message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
            document.getElementById("prodqty" + ProductID).value = 1
            return;
        }

        if (product.offerdetails[0]) {

            setTimeout(() => {

                //offer
                var offeri = [];
                offeri = JSON.parse("[" + product.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',') + "]");

                let checker = (arr, target) => target.some(v => arr.includes(v));


                //console.log(JSON.parse(localStorage.getItem('cart')));


                let op = JSON.parse(localStorage.getItem('cart')).map(({ ProductID }) => ProductID)

                // console.log(op)
                //  console.log(offeri)

                var offer = 0;

                //   console.log(checker(op, offeri))

                if (checker(op, offeri)) {



                    var totalitems = 0;
                    var totalamounts = 0;
                    var newArray = offeri.map(function (el) {

                        var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);
                        //  console.log(cindex)

                        if (cindex !== -1) {
                            totalitems = totalitems + parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"])
                            totalamounts = totalamounts + (parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"]) * parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductPrice"]))
                            basketitem[cindex]["OfferAmount"] = 0;
                        }
                    });

                    //  console.log(totalitems)
                    var offerqty = product.offerdetails[0].Offer.PromotionQty
                    var offerprice = product.offerdetails[0].Offer.Price
                    //  console.log('offerqty' + parseFloat(offerqty))

                    var quotient = Math.floor(totalitems / offerqty);
                    var remainder = totalitems % offerqty;
                    //  console.log('total:' + totalamounts)
                    //   console.log('offer total:' + quotient * offerprice)
                    //   console.log('remainder: ' + remainder)
                    offer = (parseFloat(totalamounts - (prodprice * remainder)) - parseFloat(quotient * offerprice))
                    //    console.log('singleprice:' + prodprice * remainder)

                    var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);

                    //     console.log('totalitems' + totalitems)

                    if (product.offerdetails[0]) {
                        basketitem[clickedindex]["OfferAmount"] = offer;
                    }
                    this.setState({ basketitem: basketitem });
                    localStorage.setItem('cart', JSON.stringify(basketitem));

                    //     console.log(JSON.parse(localStorage.getItem('cart')))
                }


            }, 100);


        } else {
            this.setState({ basketitem: basketitem });
            localStorage.setItem('cart', JSON.stringify(basketitem));



        }
        basketitem[index]["ProductQty"] = parseFloat(basketitem[index]["ProductQty"]) + 1;

        this.setState({ basketitem: basketitem });
        localStorage.setItem('cart', JSON.stringify(basketitem));
        document.getElementById("cartcount").innerHTML = this.state.cartqty;


    };
    onQtyReduce = (ProductID, ProductName, product, MaxOnlineOrderQty, ProductCategoryID, offerItems, offerqty, offerprice, prodprice) => {

        //   console.log(ProductID)
        var index = this.state.basketitem.findIndex(a => a.ProductID === ProductID);


        const basketitem = this.state.basketitem;

        // console.log(basketitem[index]["ProductQty"])

        if (parseFloat(basketitem[index]["ProductQty"]) >= 1) {

            if (product.offerdetails[0]) {



                setTimeout(() => {

                    //offer
                    var offeri = [];
                    offeri = JSON.parse("[" + product.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',') + "]");

                    let checker = (arr, target) => target.some(v => arr.includes(v));


                    //console.log(JSON.parse(localStorage.getItem('cart')));


                    let op = JSON.parse(localStorage.getItem('cart')).map(({ ProductID }) => ProductID)

                    // console.log(op)
                    //  console.log(offeri)

                    var offer = 0;

                    //   console.log(checker(op, offeri))

                    if (checker(op, offeri)) {



                        var totalitems = 0;
                        var totalamounts = 0;
                        var newArray = offeri.map(function (el) {

                            var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);
                            //  console.log(cindex)

                            if (cindex !== -1) {
                                totalitems = totalitems + parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"])
                                totalamounts = totalamounts + (parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"]) * parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductPrice"]))
                                basketitem[cindex]["OfferAmount"] = 0;
                            }
                        });

                        //  console.log(totalitems)
                        var offerqty = product.offerdetails[0].Offer.PromotionQty
                        var offerprice = product.offerdetails[0].Offer.Price
                        //  console.log('offerqty' + parseFloat(offerqty))

                        var quotient = Math.floor(totalitems / offerqty);
                        var remainder = totalitems % offerqty;
                        //  console.log('total:' + totalamounts)
                        //   console.log('offer total:' + quotient * offerprice)
                        //   console.log('remainder: ' + remainder)
                        offer = (parseFloat(totalamounts - (prodprice * remainder)) - parseFloat(quotient * offerprice))
                        //    console.log('singleprice:' + prodprice * remainder)

                        var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);

                        //     console.log('totalitems' + totalitems)

                        if (product.offerdetails[0]) {
                            basketitem[clickedindex]["OfferAmount"] = offer;
                        }
                        this.setState({ basketitem: basketitem });
                        localStorage.setItem('cart', JSON.stringify(basketitem));

                        //     console.log(JSON.parse(localStorage.getItem('cart')))
                    }


                }, 100);
            } else {
                this.setState({ basketitem: basketitem });
                localStorage.setItem('cart', JSON.stringify(basketitem));
            }

            basketitem[index]["ProductQty"] = parseFloat(basketitem[index]["ProductQty"]) - 1;

            if (parseFloat(basketitem[index]["ProductQty"]) == 0) {
                const newList = this.state.basketitem.splice(index, 1);
            }

        } else {
            var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);
            basketitem[clickedindex]["OfferAmount"] = 0;
            const newList = this.state.basketitem.splice(index, 1);
        }
        this.setState({ basketitem: basketitem });
        localStorage.setItem('cart', JSON.stringify(basketitem));
        document.getElementById("cartcount").innerHTML = this.state.cartqty;
        // console.log(JSON.parse(localStorage.getItem('cart')))
    };
    state = {
        value: 1,
    };

    onChange = e => {
        if (e.target.value == 0) {
            this.setState({
                delivery: sessionStorage.getItem('deliverycost')
            });
            sessionStorage.setItem('deliverytype', 0);
        } else {
            this.setState({
                delivery: 0
            });
            sessionStorage.setItem('deliverycost', 0)
            sessionStorage.setItem('deliverytype', 1);
        }
        //    console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };


    ProductsByOffer = async (product) => {

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            "aud": config.get('ProductjwtAud'),
            "exp": timestamp,
            "iss": config.get('ProductjwtIss')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = config.get('ProductprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            }
        };


        const result = [];


        if (product.Offers[0] !== undefined) {
            return await fetch(config.get('rest_API') + 'offers/offerbyid/' + config.get('OrganisationID') + '/' + product.Offers[0].OfferProducts.OfferID, requestOptions)
                .then(response => response.json())
                .then(async data => {
                    if(data.Status) {
                        return data.Result
                    } else {
                        return []
                    }
                    // result.push(data.Result[0])

                    // console.log( result)

                    // return result;
                });
        } else {
            return [];
        }
    }


    ProductsOffer = async (product) => {



        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            "aud": config.get('ProductjwtAud'),
            "exp": timestamp,
            "iss": config.get('ProductjwtIss')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = config.get('ProductprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            },
            body: JSON.stringify({
                "OrgID": config.get('OrganisationID'),
                "ProductID": parseInt(product.ProductID),
                "LocationID": (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID)

            })
        };


        const result = [];



        return await fetch(config.get('rest_API_productregistry') + '/productid', requestOptions)
            .then(response => response.json())
            .then(async data => {

                // console.log(data)

                const listofproducts = await Promise.all(data.Result.map(async product => await this.ProductsByOffer(product, data)))

                if (data.Result && data.Result.length > 0) {
                    result.push({ ...product, "productdetails": data.Result[0], "offerdetails": listofproducts[0], available: true })
                } else {
                    result.push({ ...product, "productdetails": null, "offerdetails": null, available: false })
                }

                //console.log(result[0])
                //this.setState({ offerproducts: listofproducts, ploaded: true })

                // this.setState({ offerproducts: listofproducts, ploaded: true })

                return result[0];
            }).catch(async err => {
                console.log("ERROR", err)
                return {}
            });

    }


    async componentDidMount() {
        sessionStorage.setItem('deliverycost', 0)
        // localStorage.setItem('cart', '');
        this.setState({
            delivery: 0
        });
        sessionStorage.setItem('deliverytype', 0);

        const cachedProduct = localStorage.getItem('cart');
        if (cachedProduct) {

            const listofproducts = await Promise.all(JSON.parse(cachedProduct).map(async product => await this.ProductsOffer(product)))
            //   console.log(listofproducts)

            this.setState({ basketitem: listofproducts, ploaded: true });
            localStorage.setItem('cart', JSON.stringify(listofproducts));

        } else {
            const myArray = [];
            this.setState({ basketitem: myArray });
            localStorage.setItem('cart', JSON.stringify(myArray));
        }
        document.title = "My Cart | " + config.get('companyPageTitle')

        const timestamp = Math.floor(Date.now() / 1000) + 1000;

        var payload = {
            "aud": config.get('SalesjwtAud'),
            "exp": timestamp,
            "iss": config.get('SalesjwtIss')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = config.get('SalesprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);


        axios.get(config.get('API_salesregistry') + 'deliveryscheme/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
            .then(response => {
                this.setState({ deliveryscheme: response.data.Result });
                // console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onProceedCheckout = () => {
        if ((parseFloat(this.state.total) - this.state.offer + (this.state.value == 0 ? parseFloat(sessionStorage.getItem('deliverycost')) : 0)).toFixed(2) < 0) {
            Modal.error({
                title: 'Oops, something wrong',
                content: 'Please remove your cart items and add again',
                // onOk() {window.location.href ="/account/login"},
            });
        } else if (parseFloat(this.state.total) <= parseFloat(sessionStorage.getItem('MinimumInvoiceOrder'))) {
            Modal.error({
                title: 'Sorry, Minimum order limit not reached.',
                content: 'We have minimum order limit of ' + localStorage.getItem('ecurrencyCode') + sessionStorage.getItem('MinimumInvoiceOrder') + ' . Please add more items to proceed. ',
                // onOk() {window.location.href ="/account/login"},
            });
        } else if (this.state.value == 10) {
            Modal.error({
                title: 'Please select the order type.',
                content: 'You must select a order type. ',
                // onOk() {window.location.href ="/account/login"},
            });

        } else {
            if (sessionStorage.getItem('loggedin') == "true") {
                window.location.href = "/checkout";
            } else {
                window.location.href = "/login";
            }
        }

        ////sessionStorage.getItem('loggedin') == "true" ? ("/checkout") : ("/login")
    }
    render() {
        const { value, deliveryscheme } = this.state;
        this.state.offer = (this.state.basketitem.reduce((accumulator, currentofferValue) => accumulator + parseFloat(currentofferValue.OfferAmount * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
        this.state.total = (this.state.basketitem.filter(b => b.available).reduce((accumulator, currentValue) => accumulator + parseFloat((currentValue.ProductQty * currentValue.ProductPrice * localStorage.getItem('ecurrencyratio'))), 0)).toFixed(2)
        this.state.cartqty = (this.state.basketitem.reduce((accumulator, currentqtyValue) => accumulator + parseFloat(currentqtyValue.ProductQty), 0))

        const { basketitem, total, cartqty, offer } = this.state;


        const isBasketNotEmpty = this.state.basketitem.length;
        let button;

        if (isBasketNotEmpty) {
            button = <div >
                <Row>
                    <Col md={10} xs={7}>
                        <b>Total</b>
                    </Col>
                    <Col md={2} xs={5} className="text-right">
                        <Col md={12} className="text-right">
                            <b> {localStorage.getItem('ecurrencyCode')} {this.state.total}</b>
                        </Col> </Col>

                </Row><hr /><hr />
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>


                    </Col>
                    <Col md={2}></Col>
                </Row>
            </div>;
        } else {
            button = <div><Button href="/products/" type="primary">
                Start Shopping
            </Button></div>;
        }

        return (<>

            {(() => {

                switch (config.get('Theme')) {

                    case 'Martfury':
                        return (<CartMartFury params={{ basketitem: this.state.basketitem, deliveryscheme: this.state.deliveryscheme, ploaded: this.state.ploaded, value: this.state.value, total: this.state.total, cartqty: this.state.cartqty, offer: this.state.offer }} onCloseClick={this.onCloseClick} onProceedCheckout={this.onProceedCheckout} onQtyReduce={this.onQtyReduce} onChange={this.onChange} onQtyIncrese={this.onQtyIncrese} />)
                    case 'Apparel':
                        return (<CartApparel params={{ basketitem: this.state.basketitem, deliveryscheme: this.state.deliveryscheme, ploaded: this.state.ploaded, value: this.state.value, total: this.state.total, cartqty: this.state.cartqty, offer: this.state.offer }} onCloseClick={this.onCloseClick} onProceedCheckout={this.onProceedCheckout} onQtyReduce={this.onQtyReduce} onChange={this.onChange} onQtyIncrese={this.onQtyIncrese} />)
                    case 'Apparel-casi':
                        return (<CartApparelCasi params={{ basketitem: this.state.basketitem, deliveryscheme: this.state.deliveryscheme, ploaded: this.state.ploaded, value: this.state.value, total: this.state.total, cartqty: this.state.cartqty, offer: this.state.offer }} onCloseClick={this.onCloseClick} onProceedCheckout={this.onProceedCheckout} onQtyReduce={this.onQtyReduce} onChange={this.onChange} onQtyIncrese={this.onQtyIncrese} />)

                    case 'MeatShop':
                        return (<CartMeatShop params={{ basketitem: this.state.basketitem, deliveryscheme: this.state.deliveryscheme, ploaded: this.state.ploaded, value: this.state.value, total: this.state.total, cartqty: this.state.cartqty, offer: this.state.offer }} onCloseClick={this.onCloseClick} onProceedCheckout={this.onProceedCheckout} onQtyReduce={this.onQtyReduce} onChange={this.onChange} onQtyIncrese={this.onQtyIncrese} />)
                    default:
                        return (
                            <div style={{ backgroundColor: "#f5f5f5" }}>
                                {
                                    this.state.ploaded ? (
                                        <>
                                            <Row>

                                                <Col sm={12} >
                                                    <Breadcrumb>
                                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                                        <Breadcrumb.Item href="/cart/">
                                                            My Cart
                                                        </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col sm={1}>
                                                </Col>
                                                <Col sm={7}>

                                                    <div className="fade alert alert-light show">
                                                        {

                                                            this.state.basketitem.length ? (
                                                                this.state.basketitem.map(item => (
                                                                    <>
                                                                        {item.available && item.productdetails ?
                                                                            <Row>

                                                                            <Col md={2} xs={12}>{
                                                                                item.ProductImage !== '' ?
                                                                                    <img src={item.ProductImage} width="80" />
                                                                                    : <img src="/images/noimage.jpg" alt="." width="80" />
                                                                            }</Col>
                                                                            <Col xs={6} md={7}><b> <a href={'/product/' + item.ProductName.replace(/ /g, '-').toLowerCase() + '/' + item.ProductID}>{item.ProductName}</a></b><br />
                                                                                <b>Item Code:</b> {item.productdetails.ProductRefNo}<br />
                                                                                {localStorage.getItem('ecurrencyCode')} {item.ProductPrice} each<br /><br />
                                                                                <span className="homeMessage"><FontAwesomeIcon icon={faHeart} /> Add to Wishlist</span></Col>
                                                                            <Col xs={6} md={3}>
                                                                                <Col md={12} className="text-right">
                                                                                    <CloseOutlined onClick={this.onCloseClick.bind(this, item.ProductQty, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)} />

                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    &nbsp;
                                                                                </Col>
                                                                                <Col md={12} className="text-right">
                                                                                    {/* <b>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</b> */}
                                                                                    <b> {item.ProductQty * item.ProductPrice}</b>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    &nbsp;
                                                                                </Col>
                                                                                <Col md={12} className="text-right  float-right">
                                                                                    <div className="quantity">
                                                                                        Qty:&nbsp;
                                                                                        <button className="btn minus1" onClick={this.onQtyReduce.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>-</button>
                                                                                        <input className="quantity" id={'prodqty' + item.ProductID} min="0" name="form-0-quantity" value={item.ProductQty} type="number" />
                                                                                        <button className="btn add1" onClick={this.onQtyIncrese.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>+</button>
                                                                                    </div>

                                                                                </Col>
                                                                            </Col>

                                                                            <Col md={12}><hr></hr></Col>
                                                                        </Row>
                                                                            : 
                                                                            <Row>

                                                                                <Col md={2} xs={12}>{
                                                                                    item.ProductImage !== '' ?
                                                                                        <img src={item.ProductImage} width="80" />
                                                                                        : <img src="/images/noimage.jpg" alt="." width="80" />
                                                                                }</Col>
                                                                                <Col xs={6} md={7}><b> <a href={'/product/' + item.ProductName.replace(/ /g, '-').toLowerCase() + '/' + item.ProductID}>{item.ProductName}</a></b><br />
                                                                                    {/* <b>Item Code:</b> {item.productdetails.ProductRefNo}<br /> */}
                                                                                    {localStorage.getItem('ecurrencyCode')} {item.ProductPrice} each<br /><br />
                                                                                    <span className="homeMessage"><FontAwesomeIcon icon={faHeart} /> Add to Wishlist</span></Col>
                                                                                <Col xs={6} md={3}>
                                                                                    <Col md={12} className="text-right">
                                                                                        {/* <CloseOutlined onClick={this.onCloseClick.bind(this, item.ProductQty, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)} /> */}

                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        &nbsp;
                                                                                    </Col>
                                                                                    <Col md={12} className="text-right">
                                                                                        {/* <b>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</b> */}
                                                                                        <b> {item.ProductQty * item.ProductPrice}</b>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        &nbsp;
                                                                                    </Col>
                                                                                    <Col md={12} className="text-right  float-right">
                                                                                        <span>Not Available</span>

                                                                                    </Col>
                                                                                </Col>

                                                                                <Col md={12}><hr></hr></Col>
                                                                            </Row>
                                                                        }
                                                                    </>
                                                                )
                                                                )
                                                            ) : (
                                                                <>
                                                                    <span>
                                                                        <h6>Your cart is empty</h6>
                                                                        <p>Find hundred of quality products at bargain prices everyday!</p>
                                                                    </span>
                                                                    {button}
                                                                </>

                                                            )
                                                        }

                                                    </div>

                                                </Col>
                                                <Col sm={3}>

                                                    {this.state.basketitem.length ? (
                                                        <div className="fade alert alert-light show">
                                                            <Row className="justify-content-md-center">
                                                                <Col sm={12}>
                                                                    <h5>Delivery Charges</h5>
                                                                </Col>
                                                                <Col sm={12}><hr /></Col>
                                                                <Col sm={12}>

                                                                    <Radio.Group onChange={this.onChange} value={this.state.value}>

                                                                        {
                                                                            JSON.parse(localStorage.getItem('setting'))[0].SubOrderTypes.map(st => (
                                                                                <>

                                                                                    {
                                                                                        st.SubOrderTypeName == 'Click and Collect' && st.IsActive == true ? (

                                                                                            <Row>
                                                                                                <Col sm={9}>
                                                                                                    <Radio value={1} checked> Click & Collect</Radio>
                                                                                                </Col>
                                                                                                <Col sm={3}>FREE
                                                                                                </Col>
                                                                                            </Row>
                                                                                        ) : ''
                                                                                    }


                                                                                    {
                                                                                        st.SubOrderTypeName == 'Delivery' && st.IsActive == true ? (
                                                                                            <>

                                                                                                <Row>
                                                                                                    <Col sm={9}>
                                                                                                        <Radio value={0}>Delivery</Radio>
                                                                                                    </Col>
                                                                                                    <Col sm={3}>
                                                                                                        {
                                                                                                            this.state.deliveryscheme.map(ds => {

                                                                                                                //  console.log(ds)

                                                                                                                if (ds.CurrencyCode == localStorage.getItem('ecurrencythreeCode')) {
                                                                                                                    const Calculation = ds.Calculation;

                                                                                                                    if (Calculation == "ByInvoiceAmount") {

                                                                                                                        const vat = ds.ByInvoiceAmount;
                                                                                                                        // console.log(ds.ByInvoiceAmount)
                                                                                                                        // console.log(a)

                                                                                                                        {
                                                                                                                            [vat].map((e2, i2) => {



                                                                                                                                if (this.state.total <= 50 && [vat][i2][50]) {
                                                                                                                                    sessionStorage.setItem('deliverycost', [vat][i2][50]);

                                                                                                                                } else if (this.state.total <= 100 && [vat][i2][100]) {
                                                                                                                                    sessionStorage.setItem('deliverycost', [vat][i2][100]);

                                                                                                                                } else if (this.state.total <= 150 && [vat][i2][150]) {
                                                                                                                                    sessionStorage.setItem('deliverycost', [vat][i2][150]);

                                                                                                                                } else if (this.state.total <= 750 && [vat][i2][750]) {
                                                                                                                                    sessionStorage.setItem('deliverycost', [vat][i2][750]);

                                                                                                                                } else {
                                                                                                                                    sessionStorage.setItem('deliverycost', [vat][i2]['Above']);

                                                                                                                                }


                                                                                                                            })
                                                                                                                        }


                                                                                                                        sessionStorage.setItem('MinimumInvoiceOrder', ds.MinimumInvoiceOrder);


                                                                                                                    } else {
                                                                                                                        //to do other methods
                                                                                                                    }

                                                                                                                } else {
                                                                                                                    //to do other countries 
                                                                                                                }
                                                                                                            })
                                                                                                        }


                                                                                                        {localStorage.getItem('ecurrencyCode')}  {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}

                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <hr />
                                                                                            </>
                                                                                        ) : ''
                                                                                    }


                                                                                </>
                                                                            )
                                                                            )
                                                                        }




                                                                    </Radio.Group>

                                                                </Col>


                                                                <Col sm={12}><hr /></Col>
                                                                <Col sm={12}>
                                                                    <h5>Price Details</h5>
                                                                </Col>
                                                                <Col sm={8} xs={8}>

                                                                    Sub Total:
                                                                </Col>
                                                                <Col sm={4} xs={4}>
                                                                    {localStorage.getItem('ecurrencyCode')} {this.state.total}

                                                                </Col>


                                                                {this.state.offer != 0 ? (<>

                                                                    {

                                                                        this.state.basketitem.map(item => (
                                                                            item.OfferAmount != 0 ? (
                                                                                <>
                                                                                    <Col sm={12}><hr /></Col>
                                                                                    <Col sm={8} xs={8}>

                                                                                        <p class="text-danger">{item.offerdetails[0].Offer.PromotionName}</p>
                                                                                    </Col>
                                                                                    <Col sm={4} xs={4}>
                                                                                        <p class="text-danger">
                                                                                            {localStorage.getItem('ecurrencyCode')} -{item.OfferAmount.toFixed(2)}
                                                                                        </p>

                                                                                    </Col>
                                                                                </>
                                                                            ) : ''
                                                                        ))
                                                                    }

                                                                </>) : ''
                                                                }

                                                                {this.state.offer != 0 ? (<>
                                                                    <Col sm={12}><hr /></Col>
                                                                    <Col sm={8} xs={8}>

                                                                        <p class="text-danger"><b>Offer Discount:</b></p>
                                                                    </Col>
                                                                    <Col sm={4} xs={4}>
                                                                        <p class="text-danger">
                                                                            <b></b>  {localStorage.getItem('ecurrencyCode')} -{this.state.offer}</p>

                                                                    </Col></>) : ''
                                                                }



                                                                {this.state.value == 0 ? <><Col sm={8} xs={8}>

                                                                    Delivery:
                                                                </Col>
                                                                    <Col sm={4} xs={4}>
                                                                        {localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}
                                                                    </Col></> : ''}






                                                                <Col sm={12}><hr /></Col>
                                                                <Col sm={8} xs={8}>

                                                                    <b>TOTAL:</b>
                                                                </Col>
                                                                <Col sm={4} xs={4}>


                                                                    <b>{localStorage.getItem('ecurrencyCode')} {(parseFloat(this.state.total) - this.state.offer + (this.state.value == 0 ? parseFloat(sessionStorage.getItem('deliverycost')) : 0)).toFixed(2)}</b>


                                                                </Col>
                                                                <Col sm={12}>&nbsp;</Col>
                                                                <Col sm={2}>

                                                                </Col>


                                                                <Col sm={9}>


                                                                    <Button type="primary" onClick={this.onProceedCheckout.bind(this)} block danger>
                                                                        Proceed to Checkout
                                                                    </Button>
                                                                </Col>




                                                            </Row>

                                                        </div>

                                                    ) : (
                                                        <span>

                                                        </span>
                                                    )
                                                    }
                                                </Col>


                                            </Row>
                                        </>
                                    ) : (<Col sm={12}><br />
                                        <Skeleton active avatar paragraph={{ rows: 4 }} />
                                    </Col>)
                                }
                            </div>

                        )
                }

            })()}
        </>
        )
    }
}

export default Application;