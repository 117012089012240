import React, { Component } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import FooterInclude from "./footer.component.include"

const Page = props => (

  <li> <a href={'/pages/' + props.page.slug}>{props.page.pagetitle}</a></li>
)

export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = { menus: [], footer2: [] };
  }

  componentDidMount() {

    var primaryTextStyle = "color:#001a96; font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: 400; font-style: normal; font-size: 60px;";
    var secondaryTextStyle = "color: #e00099;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 36px;";
    var tertiaryTextStyle = "color: #3d3d3d;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 24px;";

   
 
      fetch(config.get('backendURL') + 'menus/footer/' + config.get('companyID'))
        .then(response => response.json())
        .then(data => this.onSetFooter(data));
    

    axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
          Email: response.data.Email,
          Phone: response.data.Phone,
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  onSetFooter = (result) => {
    this.setState({ menus: result })
   // console.log(result)
    sessionStorage.setItem('footer', JSON.stringify(result));
  };

  render() {
    return (
<>
<hr/>
<footer className="ps-footer">
        <div className="ps-container">
          <div className="ps-footer__widgets">
            <aside className="widget widget_footer widget_contact-us">
              <h4 className="widget-title">Contact us</h4>
              <div className="widget_content">
                <p>Call us on</p>
                <h3>{this.state.Phone}</h3>
                <p>  
                {this.state.Name ? this.state.Name : ''}<br />
          {this.state.AddressLine1 ? (<> {this.state.AddressLine1} <br/></>) : ''}
          {this.state.AddressLine2 ? (<> {this.state.AddressLine2} <br/></>) : ''}
          {this.state.City ? (<> {this.state.City} <br/></>) : ''}
          {this.state.StateProvinceRegion ? (<> {this.state.StateProvinceRegion} <br/></>) : ''}
          {this.state.PostZipCode ? (<> {this.state.PostZipCode} <br/></>) : ''}
          {this.state.Country ? (<> {this.state.Country} <br/></>) : ''} 
        

     
 <br /><a href={'mailto:'+this.state.Email}>{this.state.Email}</a></p>
                <ul className="ps-list--social">
                {
          
          config.get('SocialMedia') ? (
  JSON.parse(config.get('SocialMedia')).map(sm =>
   <>
   <li> <a href={sm.URL} key={sm._id} ><img src={sm.icons} alt={sm.Media} width="32" /></a></li>
    </>
  )
  ) : (
    <></>
    )}

    
                </ul>
              </div>
            </aside>
            
            

            {
          this.state.menus.map(menu => (
           
           
            <aside className="widget widget_footer">
              <h4 className="widget-title">{menu.description}</h4>
              <FooterInclude params={{ FooterItemId: menu._id }} />
            </aside>

          )
          )}

           
          
          
           



          </div>
 
          <div className="ps-footer__copyright">
            <p>Copyright &copy; {this.state.Name}.
All Rights Reserved.</p>
            <p><span>We Using Safe Payment For: &nbsp;</span>
 
            <img src="/images/visa.svg" alt="Visa" width="30" /> <img src="/images/mastercard.svg" alt="Visa" width="30" /> <img src="/images/amex.svg" alt="Visa" width="30" />
  
            </p>
          </div>
        </div>
      </footer>
 
      </>
    );
  }
}