import React from 'react';
import { Divider, Modal, Avatar, Form, Input, Button, Checkbox } from 'antd';
import config from 'react-global-configuration';
 

const AccountLeftMenuApparel = (props) => (

    
      <>
         <div className="ps-section__left">
        <aside className="ps-widget--account-dashboard">
          
          
        {sessionStorage.getItem('loggedin') == "true" ? (
<>
       

          <div className="ps-widget__content">
            <ul>
              <li><a href="/account/my-info"><i className="icon-user" /> Account Information</a></li>
              <li><a href="/account/order-history"><i className="icon-papers" /> Order History</a></li>
              <li><a href="/account/addresses"><i className="icon-map-marker" /> Address</a></li>
              <li><a href="/account/payment"><i className="icon-store" /> Payment Settings</a></li>
              <li><a href="/account/my-wishlists"><i className="icon-heart" /> View Your Wishlist</a></li>
              <li><a href="/account/login" onClick={props.onLogout.bind()}><i className="icon-power-switch" />Logout</a></li>
            </ul>

             
          </div>
          </>
 ) : (
  <div className="ps-widget__content">
            <ul>
            <li><a href="/account/register" ><i className="icon-user" /> Create an Account</a></li>
              <li><a href="/account/login"><i className="icon-lock" />Login</a></li>
            </ul>
          </div>
  )}

        </aside>
      </div>
      
     </>

   

);
export default AccountLeftMenuApparel;
