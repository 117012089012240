import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingCart, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import * as NumericInput from "react-numeric-input";
import { Row, Col, Breadcrumb, Table, Modal } from 'react-bootstrap';
import jwt from 'jsonwebtoken';
import LazyLoad from 'react-lazyload';
import LeftBrandMenu from "../themes/default/leftbrandmenu.component";
import LeftMartFuryBrandMenu from "../themes/martfury/leftbrandmenu.component";
import LeftApparelBrandMenu from "../themes/apparel/leftbrandmenu.component";
import { renderEmail } from 'postonents';
import { Skeleton, message, Button, Form, Input, Select } from 'antd';
import config from 'react-global-configuration';
import NotifyEmail from '../email/NotifyEmail';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import ProducBoxMartFury from '../themes/martfury/product-box';
import ProducBoxApparel from '../shop/product-box';
const key = 'updatable';


class Application extends React.Component {
  constructor(props) {
    super(props);

    this.state = { catid:this.props.match.params.id, brand:this.props.match.params.name, ploaded:true,products: [], basketitem: [], basketqty: [], loaded: false, perpage:12, regexp: /^[0-9\b]+$/ };
  }


  componentDidMount() {
    this.setState({ ploaded: false })
    const timestamp = Math.floor(Date.now()/1000) + 1000 ;  

    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };


    fetch(config.get('rest_API_productregistry_V2') + '/brand/pagination/' + config.get('OrganisationID')+"/"+ (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID) + '/'+this.props.match.params.id+'/0/12', requestOptions)
      .then(response => response.json())
      .then(async data => {
        console.log(data)
        const products = data.Result;
        this.setState({ products: products, ploaded: true })
      })


    fetch(config.get('rest_API_productregistry') + '/brand/count/' + config.get('OrganisationID') + '/' + this.state.catid, requestOptions)
      .then(responsecount => responsecount.json())
      .then(datacount => this.setState({ productscount: datacount.Result }));

    const cachedCart = localStorage.getItem('cart');
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem('cart', JSON.stringify(myArray));
    }
  }



  onChangeValue = event => {
    this.setState({ value: '1' });
    // document.getElementsByClassName("basketadded").style.visibility = "hidden"
  };

  onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
    this.setState({
      visible: true,
    });
  }
  handleCancel = e => {
    //  console.log(e);
    this.setState({
      visible: false,
    });
  };

  onAddItem = (buyoradd, ProductID, ProductName, MaxOnlineOrderQty, ProductCategoryID, offerItems, offerqty, offerprice) => {
    let prodqty = '';

    const basketitem = this.state.basketitem;



    const prodprice = document.getElementById("prodprice" + ProductID).value
    const prodprimimage = document.getElementById("prodprimimage" + ProductID).value

    var index = this.state.basketitem.findIndex(a => a.ProductID === ProductID);
    prodqty = document.getElementById("prodqty" + ProductID).value

    if (!this.state.regexp.test(prodqty)) {
      message.error('Please enter only valid number for the product quantity.');
      return;
    }

    if (index !== -1) {

      const aprodqty = basketitem[index]["ProductQty"];

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < (parseInt(prodqty) + parseInt(aprodqty))) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }


      basketitem[index]["ProductQty"] = parseFloat(basketitem[index]["ProductQty"]) + parseFloat(document.getElementById("prodqty" + ProductID).value);
      this.setState({ basketitem: basketitem });
      localStorage.setItem('cart', JSON.stringify(basketitem));
      document.getElementById("cartcount").innerHTML = parseInt(document.getElementById("cartcount").innerHTML) + parseInt(aprodqty);

    } else {
      prodqty = document.getElementById("prodqty" + ProductID).value

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }

      document.getElementById("cartcount").innerHTML = parseInt(document.getElementById("cartcount").innerHTML) + parseInt(prodqty);
      var menucategoriesList = JSON.parse(sessionStorage.getItem('menucategories'))
      var ProductsDtl = {
        ProductID: ProductID,
        ProductName: ProductName,
        ProductQty: prodqty,
        ProductPrice: prodprice,
        ProductImage: prodprimimage,
        OfferAmount: 0,
        VatRate: menucategoriesList.length > 0 ? menucategoriesList.find(category => category.ProductCategory.ProductCategoryID === ProductCategoryID).ProductCategory.VatRate : 0
        // VatRate: JSON.parse(sessionStorage.getItem('menucategories')).find(category => category.ProductCategory.ProductCategoryID === ProductCategoryID).ProductCategory.VatRate
      }

      //  console.log(ProductsDtl)
      this.setState(state => {
        {
          basketitem: state.basketitem.push(ProductsDtl)
          localStorage.setItem('cart', JSON.stringify(state.basketitem));
        }
      });
    }

    setTimeout(() => {

      //offer
      var offeri = [];
      offeri = JSON.parse("[" + offerItems + "]");


      let checker = (arr, target) => target.some(v => arr.includes(v));



      // console.log(offerItems);

      //console.log(JSON.parse(localStorage.getItem('cart')));


      let op = JSON.parse(localStorage.getItem('cart')).map(({ ProductID }) => ProductID)

      var offer = 0;

      if (checker(op, offeri)) {



        var totalitems = 0;
        var totalamounts = 0;
        var newArray = offeri.map(function (el) {

          var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);
          //  console.log(cindex)

          if (cindex !== -1) {
            totalitems = totalitems + parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"])
            totalamounts = totalamounts + (parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"]) * parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductPrice"]))
            basketitem[cindex]["OfferAmount"] = 0;
          }
        });

        var quotient = Math.floor(totalitems / offerqty);
        var remainder = totalitems % offerqty;
        //console.log('total:' + totalamounts)
        //console.log('offer total:' + quotient * offerprice)
        //console.log('remainder: ' + remainder)
        offer = (parseFloat(totalamounts - (prodprice * remainder)) - parseFloat(quotient * offerprice))
        //console.log('singleprice:' + prodprice * remainder)

        var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);

        basketitem[clickedindex]["OfferAmount"] = offer;

        if (totalitems < offerqty) {
          basketitem[clickedindex]["OfferAmount"] = 0;
        }

        this.setState({ basketitem: basketitem });
        localStorage.setItem('cart', JSON.stringify(basketitem));

        //console.log(localStorage.getItem('cart'))

      }
    }, 100);

    message.loading({ content: 'Adding...', key });
    setTimeout(() => {
      message.success({ content: 'Added to the cart!', key, duration: 2 });
      if(buyoradd=='buy'){
        window.location.href="/cart"
      }

    }, 800);
  };


  handlePageClick = (data) => {

    $("html, body").animate({ scrollTop: $(".container-fluid").offset().top - 40 }, "slow");

    this.setState({ ploaded: false })

    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perpage);

    // console.log(offset)


    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
        "OrgID": config.get('OrganisationID'),
        "ProductName": this.props.match.params.id,
        "Skip": offset,
        "Limit": 12
      })
    };
    fetch(config.get('rest_API_productregistry_V2') + '/pagination/name', requestOptions)
      .then(response => response.json())
      .then(async data => {
        const products = data.Result;
        this.setState({ products: products, ploaded: true })
      })

  };

  onQtyIncrese = (ProductID) => {
    document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) + 1
  };
  onQtyReduce = (ProductID) => {
    if (document.getElementById("prodqty" + ProductID).value > 1) {
      document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) - 1
    }
  };

  handleCallback = (childData, ploaded, brand, productscount, catid) =>{
    console.log(childData)
    this.setState({products: childData, brand:brand, productscount:productscount, catid:catid})
  }


  handlePageClick = (data) => {

    $("html, body").animate({ scrollTop: $(".container-fluid").offset().top - 40 }, "slow");

    this.setState({ ploaded:false})

    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perpage);

    //console.log(offset)


    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };


    fetch(config.get('rest_API_productregistry_V2') + '/brand/pagination/' + config.get('OrganisationID')+ '/' + (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID) + '/' + this.state.catid + '/'+offset+'/' + this.state.perpage , requestOptions)
      .then(response => response.json())
      .then(async data => {
        const products = data.Result;
        this.setState({ products: products, ploaded: true })
      })
  };

  render() {
    const onFinish = values => {

      const Notifydata = {
        companyPageTitle: config.get('companyPageTitle'),
        SenderEmail: config.get('SenderEmail'),
        companyLogoCDN:config.get('companyLogoCDN'),
        prdtName: document.getElementById("prdt").value
      }

      const emailBody = renderEmail(NotifyEmail, { lang: 'en', Notifydata });
      async function postData() {
        const ecoEmail = {
          "to": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
          "bcc": "suren.sivalingam@ecologital.com",
          "from": values.email,
          "subject": "Product Notification Request",
          "html": emailBody
        };

        const response = await fetch(config.get('backendURL') + 'mail/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(ecoEmail)
        })
        return response.json();
      }

      postData()
        .then(data => {
          message.success("Email has been sent to " + config.get('companyPageTitle'));
          this.handleCancel();
        });


    };

    const { products ,  productscount, perpage} = this.state;
    const { basketitem } = this.state;
    return (
      <>



        {(() => {

          switch (config.get('Theme')) {
            case 'Apparel':
              return (
                <div className="ps-layout--shop">
                  <LeftApparelBrandMenu parentCallback={this.handleCallback} />
                  <div className="ps-layout__right">
                    <div className="ps-shopping ps-tab-root">

                      <div className="ps-block__header">
                        <h3>{this.state.brand}</h3>
                      </div>


                      <div className="ps-shopping__header">
                        <p><strong> {this.state.productscount}</strong> Products found</p>
                        <div className="ps-shopping__actions">
                          <select className="ps-select select2-hidden-accessible" data-placeholder="Sort Items" data-select2-id={4} tabIndex={-1} aria-hidden="true">
                            <option data-select2-id={6}>Sort by latest</option>
                            <option>Sort by popularity</option>
                            <option>Sort by average rating</option>
                            <option>Sort by price: low to high</option>
                            <option>Sort by price: high to low</option>
                          </select>

                        </div>
                      </div>


                    </div>


                    <div className="row">

                      {
                        this.state.ploaded ? (
                          products ? (
                            products.map(product =>
                              <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 ">


                                <div className="list">


                                  <ProducBoxApparel params={{ product: product, visible:this.state.visible, loaded:this.state.loaded}} handleCancel={this.handleCancel} onNotify={this.onNotify} onAddItem={this.onAddItem} onQtyIncrese={this.onQtyIncrese} onQtyReduce={this.onQtyReduce} onFinish={this.onFinish}  />

                                </div>
                              </div>
                            )
                          ) : (
                            <div className="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 "><br />
                              <Skeleton active avatar paragraph={{ rows: 4 }} />
                            </div>
                          )
                        ) : (<div className="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 "><br />
                          <Skeleton active avatar paragraph={{ rows: 4 }} />
                        </div>)
                      }
                    </div>

                    <div>
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.productscount / this.state.perpage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                      />
                    </div>
                  </div>
                </div>
              )
            case 'Martfury':
              return (
                <div className="ps-layout--shop">
                  <LeftMartFuryBrandMenu parentCallback={this.handleCallback} />
                  <div className="ps-layout__right">
                    <div className="ps-shopping ps-tab-root">

                      <div className="ps-block__header">
                        <h3>{this.state.brand}</h3>
                      </div>


                      <div className="ps-shopping__header">
                        <p><strong> {this.state.productscount}</strong> Products found</p>
                        <div className="ps-shopping__actions">
                          <select className="ps-select select2-hidden-accessible" data-placeholder="Sort Items" data-select2-id={4} tabIndex={-1} aria-hidden="true">
                            <option data-select2-id={6}>Sort by latest</option>
                            <option>Sort by popularity</option>
                            <option>Sort by average rating</option>
                            <option>Sort by price: low to high</option>
                            <option>Sort by price: high to low</option>
                          </select>

                        </div>
                      </div>


                    </div>


                    <div className="row">

                      {
                        this.state.ploaded ? (
                          products ? (
                            products.map(product =>
                              <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 ">


                                <div className="list">


                                  <ProducBoxMartFury params={{ product: product, visible:this.state.visible, loaded:this.state.loaded}} handleCancel={this.handleCancel} onNotify={this.onNotify} onAddItem={this.onAddItem} onQtyIncrese={this.onQtyIncrese} onQtyReduce={this.onQtyReduce} onFinish={this.onFinish}  />

                                </div>
                              </div>
                            )
                          ) : (
                            <div className="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 "><br />
                              <Skeleton active avatar paragraph={{ rows: 4 }} />
                            </div>
                          )
                        ) : (<div className="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 "><br />
                          <Skeleton active avatar paragraph={{ rows: 4 }} />
                        </div>)
                      }
                    </div>

                    <div>
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.productscount / this.state.perpage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                      />
                    </div>
                  </div>
                </div>
              )
            default:
              return (
                <>
                  <Row>
                    <Col sm={12}>
                      <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/products/">
                          Products by Category
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>
                    <LeftBrandMenu parentCallback={this.handleCallback} />

                    <Col sm={9}>
                      <Col sm={12}>
                        <Row>
                          <Col sm={8}>
                            <h4>{this.state.brand}</h4>
                          </Col>
                          <Col sm={4} className="float-right">
                            <Form.Item
                              name="select"
                              label="Sort by:"

                            >
                              <Select placeholder="Please sort by" defaultValue="BrowseRelevance">
                                <option value="PriceAsc"> Price Low to High </option>
                                <option value="PriceDesc"> Price High to Low </option>
                                <option value="CUPAsc"> Unit Price Low to High </option>
                                <option value="BrowseRelevance">Relevance </option>
                              </Select>
                            </Form.Item>

                          </Col>
                        </Row>
                      </Col>
                      <Row>
                        <Col>
                          <div className="list">


                          </div>
                        </Col>
                      </Row>

                      <Row>

                        {
                          this.state.ploaded ? (
                            products ? (
                              products.map(product =>
                                <Col xs={6} md={6} lg="3">


                                  <div className="list">




                                    <LazyLoad>
                                      <div className="card">
                                        <div md={{ span: 3, offset: 3 }}>


                                          {product.Offers[0] ? (product.Offers[0].Offer.OfferType == 3 ? (<>  <span class="mulitistock">MULTI BUY</span></>) : '') : ''}


                                          {product.IsOutOfStock ? (<> <span class="outofstock">OUT OF STOCK</span></>) : ''}    <FontAwesomeIcon icon={faHeart} /></div>


                                        <a key={'pid' + product.ProductID} href={'/product/' + product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + product.ProductID}>
                                          {
                                            product.OnLineImages.PrimaryImages !== null ?
                                              <div style={{ width: 'auto' }}>
                                                {this.state.loaded ? null :
                                                  <div className="divLoader">
                                                    <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
                                                    Loading
                                                  </div>
                                                }
                                                <img src={product.OnLineImages.PrimaryImages.Main} alt={product.Name} onLoad={() => this.setState({ loaded: true })} style={this.state.loaded ? {} : { display: 'none' }} />
                                                <input type="hidden" id={'prodprimimage' + product.ProductID} value={product.OnLineImages.PrimaryImages.Main} />
                                              </div>
                                              : <div><img src="/images/noimage.jpg" alt={product.Name} />
                                                <input type="hidden" id={'prodprimimage' + product.ProductID} value='' /></div>
                                          }
                                        </a>

                                        <h4>{product.Name}</h4>
                                        <p><b>Item Code:</b><br /> {product.ProductRefNo}</p>



                                        <div className="row">
                                          <div className={product.Discount != 0 ? 'priceleft col-sm-6' : 'priceleft col-sm-12'}><p className="price"><sup className="pricex">{localStorage.getItem('ecurrencyCode')}</sup><sub>
                                            {String(product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).split('.')[0]}
                                            <input type="hidden" id={'prodprice' + product.ProductID} value={product.SellingPricesArray.SellingPrice} />
                                          </sub><sup>
                                              {
                                                String(product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).split('.')[1] != null ?
                                                  String((product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)).split('.')[1]
                                                  : '00'
                                              }
                                            </sup></p></div>
                                          {
                                            product.Discount != 0 ?
                                              <div className="priceright col-sm-6">was {localStorage.getItem('ecurrencyCode')}{(parseInt(product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(product.Discount)} <br /><span className="dispricetxt">save {localStorage.getItem('ecurrencyCode')}{product.Discount}</span></div>
                                              : ''
                                          }
                                        </div>




                                        <Table striped bordered hover size="sm" width="50%">
                                          <thead>
                                            <tr>
                                              <th>Pack Size</th>
                                              <th>Weight</th>
                                              <th>Type</th>
                                            </tr>
                                          </thead>
                                          <tbody>

                                            {

                                              product.UnitCharts.length > 0 ? (

                                                product.UnitCharts.map(elu =>
                                                  <tr>
                                                    <td>{elu.Quantity}</td>
                                                    <td>{product.Weight}</td>
                                                    <td>{elu.UnitTypeName}</td>
                                                  </tr>

                                                )
                                              ) : (

                                                <tr>
                                                  <td>1</td>
                                                  <td>{product.Weight}</td>
                                                  <td>EA</td>
                                                </tr>
                                              )
                                            }


                                          </tbody>
                                        </Table>



                                        {product.IsOutOfStock ? (<div className="text-center"> <p className="inputandbtn"><button className="notify" onClick={this.onNotify.bind(this, product.ProductID, product.Name, product.MaxOnlineOrderQty)} id={product.ProductID}><FontAwesomeIcon icon={faEnvelope} />Notify</button></p> <Modal
                                          show={this.state.visible}
                                          onHide={this.handleCancel}
                                          centered
                                          aria-labelledby="example-modal-sizes-title-sm"
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title id="example-modal-sizes-title-sm">
                                              Notify
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <Form
                                              onFinish={onFinish}
                                              name="basic"
                                              initialValues={{ remember: true }}
                                            >
                                              <Form.Item
                                                label="Email Address"
                                                name="email"
                                                rules={[{ required: true, type: 'email' }]}
                                              >
                                                <Input placeholder="Email Address" />
                                              </Form.Item>



                                              <Form.Item  >
                                                <Input name="prdt" type="hidden" id="prdt" value={product.ProductID} />
                                                <Button type="primary" htmlType="submit" ref="btn" danger >
                                                  Submit
                                                </Button>
                                              </Form.Item>
                                            </Form>

                                          </Modal.Body>
                                        </Modal></div>) : (
                                          <p className="inputandbtn"><NumericInput min={1} max={product.MaxOnlineOrderQty == 0 ? 10000 : product.MaxOnlineOrderQty} precision={0} className="inputqty" id={'prodqty' + product.ProductID} value={1} snap />
                                            <button onClick={this.onAddItem.bind(this, 'add', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.CurrentOffer.ID ? (product.CurrentOffer.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.CurrentOffer.ID ? (product.CurrentOffer.Offer.OfferQty) : '', product.CurrentOffer.ID ? (product.CurrentOffer.Offer.OfferPrice) : '')} id={product.ProductID}><FontAwesomeIcon icon={faShoppingCart} /><span>ADD</span></button></p>
                                        )}
                                      </div><br />

                                    </LazyLoad>


                                  </div>
                                </Col>
                              )
                            ) : (
                              <Col sm={12}><br />
                                <Skeleton active avatar paragraph={{ rows: 4 }} />
                              </Col>
                            )
                          ) : (<Col sm={12}><br />
                            <Skeleton active avatar paragraph={{ rows: 4 }} />
                          </Col>)
                        }
                      </Row>

                      <Col>
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={this.state.productscount / this.state.perpage}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                        />
                      </Col>
                    </Col>

                  </Row>
                </>
              )
          }

        })()}





      </>
    )
  }
}

export default Application;