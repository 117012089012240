import React from 'react';
import { Modal, Form, Button, Input, Divider } from 'antd';
const AccountRegisterApparel = (props) => (

    
      <>
           {/* Register */}

           <Form
                  className="ps-form--account-setting"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={props.onFinish}
                >
      <div className="register-verify">
        <div className="row">
          <div className="col-sm-6">
            <div className="register-details-wrapper">
              <ul className="list-group">
                <li className="list-group-item">
                  <i className="far fa-user" />
                  <a href="/account/register">Create an Account</a>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-sign-in-alt" />
                  <a href="/account/login">Login</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="register-text">
              <p className="text-header">Register Now for Online Shopping</p>
              <div className="form-group">
                <label>Email</label>
                <Form.Item
                                name="Email"
                                label=""
                                rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
                              >
                                <Input placeholder="" className="form-control" />
                              </Form.Item>
              </div>

              <button className="ps-btn btn-act-primary btn-verify">
                    {props.disabled ? 'Please wait...' : 'Register Now'}

                    </button>
                  </div>
          </div>
        </div>
      </div>
</Form>

      {/* /Register */}
            
         </>
  

);
export default AccountRegisterApparel;
