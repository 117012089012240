import React from 'react';
import { Col } from 'react-bootstrap';
import { Menu } from 'antd';
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import 'antd/dist/antd.css';
import $ from 'jquery';
const { SubMenu } = Menu;


class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = { brands: [] };

  }

 
  componentDidMount() {

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    const requestBrands = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };


    fetch(config.get('rest_API_productregistry') + '/onlinebrands/' + config.get('OrganisationID'), requestBrands)
      .then(response => response.json())
      .then(data => this.onSetBrandResult(data.Result, data.Result));
  }
  onSetBrandResult = (result, key) => {
    //console.log(result)
    this.setState({ brands: result })
    sessionStorage.setItem('menubrands', JSON.stringify(result));
  };

  handleClick = e => {
    // console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  
  handleProductsClick = (e,brand) => {
   
    window.history.replaceState(null, "New Page Title", '/brand/' + e + '/' + brand)
    
    
    
        $("html, body").animate({ scrollTop: $(".container-fluid").offset().top - 40 }, "slow");
    
     
    
        this.props.parentCallback('', false, brand,'','')
        
        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
          "aud": config.get('ProductjwtAud'),
          "exp": timestamp,
          "iss": config.get('ProductjwtIss')
        };
    
        var signOptions = {
          "algorithm": "RS256"
        };
    
        var privateKey = config.get('ProductprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);
        // console.log(token)
    
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + token,
          }
        };
    
        fetch(config.get('rest_API_productregistry') + '/brand/count/' + config.get('OrganisationID') + '/' + e, requestOptions)
        .then(responsecount => responsecount.json())
        .then(datacount => this.setState({ productscount: datacount.Result }));
    
    
        fetch(config.get('rest_API_productregistry_V2') + '/brand/pagination/' + config.get('OrganisationID') + '/' + (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID)+ '/' + e+'/0/12', requestOptions)
        .then(response => response.json())
          .then(async data => {
            const products = data.Result;
             this.setState({ products: products, ploaded: true })
            this.props.parentCallback(products, true, brand, this.state.productscount, e) 
          })
          
    
      };

      
  render() {


    const { products } = this.state;
    const { brands } = this.state;

    return (

       

<div className="ps-layout__left">
<aside className="widget widget_shop">
  <h4 className="widget-title">Brand</h4>
 
 
  <ul className="ps-list--categories">
   
  {brands.map(brand =>
    <li className="menu-item-has-children"><a onClick={this.handleProductsClick.bind(this, brand.BrandID, brand.BrandName)}  >  {brand.BrandName}  </a>
      
    </li>
    )}  
  </ul>
</aside>

</div>



    )
  }
}

export default Application; 