import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Modal, Form, Button, Radio, Popover, Steps, Input, Select, DatePicker, Tabs, message, Result } from 'antd';
import config from 'react-global-configuration';
import Card from "react-credit-cards";
import 'react-credit-cards/es/styles-compiled.css';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import axios from 'axios';
import { renderEmail } from 'postonents';
import moment from 'moment';
import jwt from 'jsonwebtoken';
import OrderEmail from '../email/OrderEmail';
import { ViewProductDetailById } from './viewproductdetailbyid';
import { Redirect } from 'react-router-dom'
import { CreditCardOutlined, MoneyCollectOutlined, SaveOutlined } from '@ant-design/icons';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
} from "./payment-utils";


const { TabPane } = Tabs;
const key = 'updatable';
function callback(key) {
    console.log(key);
}
function encrypt(data) {
    var CryptoJS = require("crypto-js");
    var key = 'U2FsdGVkX1y3pF1AfoHaMGdUJpg6nOMru98BUt';
    var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return cipherText;
}
const { MonthPicker } = DatePicker;
const { Step } = Steps;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 4 },
};
const formItemLayoutCC = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
};
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const cconfig = {
    rules: [{ type: 'object', required: true, message: 'Please select the credit card expiry date!' }],
};
const monthFormat = 'MM/YYYY';
const content = (
    <div>
        <p><b>Introduction</b><br />
            The card security code is a unique three or four digit, non embossed security number, <br />
            associated to your credit card number, as illustrated below.<br />
            <br />
            <b>Visa / MasterCard</b><br />
            The three-digit card verification code (CVV or CVC2) is non embossed and typically <br />
            printed on the signature panel of your card, immediately after the card's standard <br />
            number, as show in this example. In some cases you may see the last 4 digits of your <br />
            card number with the additional 3 digit security code following.
        </p>
    </div>
);

class Application extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            company: [], categories: [], basketitem: [], number: "",
            name: "",
            expiry: "",
            cvc: "",
            issuer: "",
            token: "",
            focused: "",
            formData: null,
            deliverymethod: '',
            ordernumber: new Date().valueOf(),
            country: '',
            region: '',
            value: 100,
            cardvalue: 0,
            total: 0,
            button: '',
            address: []
        };
    }
    handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            this.setState({ issuer });
        }
    };



    handleInputFocus = ({ target }) => {
        this.setState({
            focused: target.name
        });
    };

    handleInputChange = ({ target }) => {
        if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
            this.setState({ paymentnumber: target.value });
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
            this.setState({ paymentexpiry: target.value });
        } else if (target.name === "name") {
            this.setState({ paymentname: target.value });
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
            this.setState({ paymentcvc: target.value });
        }
        this.setState({ [target.name]: target.value });
    };


    handleSubmit = e => {
        e.preventDefault();
        sessionStorage.setItem('paymentType', 'Credit/Debit card');

        const curentDateTime = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
        const { issuer } = this.state;

        if (this.state.value === 100) {
            Modal.error({
                title: 'Billing Address',
                content: 'Please select or enter a billing address',
                onOk() {

                    return;
                },
            });
            return;
        }

        this.setState({ button: 'Please wait' });
        this.refs.btn.setAttribute("disabled", "disabled");



        const CardDetails = {
            reusable: true,
            paymentMethod: {
                name: this.state.paymentname,
                expiryMonth: this.state.paymentexpiry.split('/')[0],
                expiryYear: "20" + this.state.paymentexpiry.split('/')[1],
                issueNumber: 1,
                startMonth: 10,
                startYear: 2020,
                cardNumber: this.state.paymentnumber,
                type: 'Card',
                cvc: this.state.paymentcvc
            },
            clientKey: config.get('WorldPayClientKey')
        }


        var PIsales =
            this.state.basketitem.length ? (
                this.state.basketitem.map((item, index) => (
                    {
                        ProductID: item.ProductID,
                        ProductCategoryID: item.productdetails.ProductCategoryID,
                        SalesQuantity: parseFloat(this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty),
                        SalesPrice: parseFloat(parseFloat(item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        LineDiscount: 0,
                        LineVat: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat((((item.productdetails.SellingPricesArray.SellingPrice * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate / (1 + this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate))).toFixed(2)) : parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate).toFixed(2)),
                        LineAmount: parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty) * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        OriginalAmount: parseFloat((this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty * item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        PromotionAmount: 0,
                        SalesUnitTypeID: item.productdetails.SellingPricesArray.SalesUnitTypeID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                    }

                )
                )
            ) : (
                {}
            )

        var PIoffers =
            this.state.basketitem.map((item, index) => (

                item.OfferAmount != 0 ? (
                    {
                        ProductID: 0,
                        ProductCategoryID: 0,
                        SalesQuantity: 0,
                        SalesPrice: 0,
                        LineDiscount: 0,
                        LineVat: 0,
                        LineAmount: 0,
                        OriginalAmount: 0,
                        SalesUnitTypeID: 0,
                        PromotionAmount: parseFloat(-Math.abs(item.OfferAmount).toFixed(2)),
                        PromotionName: item.offerdetails[0].Offer.PromotionName,
                        PromotionID: item.offerdetails[0].Offer.OfferID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID')
                    }
                ) : ''
            )
            )


        const formSales = {
            ProformaSales: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                OrderType: "OnLine",
                DeliveryInstruction: sessionStorage.getItem('DeliveryIns') == ' : undefined' ? '' : sessionStorage.getItem('DeliveryIns'),
                SubTotal: parseFloat(this.state.total),
                BulkDiscount: 0,
                VatAmount: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.totalvat) : parseFloat(this.state.totalvat),
                TotalInvoiceValue: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat),
                OfferDiscount: parseFloat(this.state.offer),
                Description: '',
                OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                AdditionalCharges: '{\'DeliveryCharge\':' + parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2) + '}',
                SubOrderType: sessionStorage.getItem('deliverymethod'),
                DeliveryCharge: parseFloat(sessionStorage.getItem('deliverycost')),
                CurrencyID: parseInt(localStorage.getItem('ecurrencyid')),
                CartReference: sessionStorage.getItem('ordernumber'),
                PaymentToken: '',
                DeliveryAddress: {
                    AddressType: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressType,
                    AddressLine1: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressLine1,
                    AddressLine2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressLine2,
                    City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].City,
                    StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].StateProvinceRegion,
                    PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].PostZipCode,
                    Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Country,
                    Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Phone,
                    Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Fax,
                    Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
                BillingAddress: {
                    AddressType: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressType,
                    AddressLine1: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine1,
                    AddressLine2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine2,
                    City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].City,
                    StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].StateProvinceRegion,
                    PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].PostZipCode,
                    Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Country,
                    Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Phone,
                    Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Fax,
                    Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
            },


            ProformaInvoiceDetail: [...PIsales, ...PIoffers.filter(function (e) { return e })]
            ,
            Customer: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                CompanyName: JSON.parse(localStorage.getItem('fullUserDetails')).CompanyName,
                FirstName: JSON.parse(localStorage.getItem('fullUserDetails')).FirstName,
                LastName: JSON.parse(localStorage.getItem('fullUserDetails')).LastName,
                UserName: JSON.parse(localStorage.getItem('fullUserDetails')).UserName,
                Email: JSON.parse(localStorage.getItem('fullUserDetails')).Email,
                Avatar: JSON.parse(localStorage.getItem('fullUserDetails')).Avatar,
                UserID: JSON.parse(localStorage.getItem('fullUserDetails')).UserID,
                OriginatedOrganizationID: JSON.parse(localStorage.getItem('fullUserDetails')).OriginatedOrganizationID,
                Addresses: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses
            }
        }


        //console.log(formSales)

        this.setState({ button: 'Please wait...' });

        formSales["OrderStatus"] = 'Order Received';

        const orderoptions = {
            url: config.get('backendURL') + 'orders/add',
            method: 'POST',
            data: formSales
        };

        axios(orderoptions)
            .then(response => {
                // console.log(response.data)
                sessionStorage.setItem('insertedId', response.data.insertedId);
            }).catch(err => {

            })


        const formlogs = {
            CloudCustomerID: sessionStorage.getItem('UserID'),
            OriginatedOrganizationID: sessionStorage.getItem('OriginatedOrganizationID'),
            CartReference: sessionStorage.getItem('ordernumber'),
            Logs: 'Order submitted',
        }

        const orderlogoptions = {
            url: config.get('backendURL') + 'orders/logs/add',
            method: 'POST',
            data: formlogs
        };

        axios(orderlogoptions)
            .then(response => {

            }).catch(err => {

            })




        fetch('https://api.worldpay.com/v1/tokens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(CardDetails)
        })
            .then(data => {

                formlogs["Logs"] = 'WorldPay Token Submitted';
                const orderlogoptions = {
                    url: config.get('backendURL') + 'orders/logs/add',
                    method: 'POST',
                    data: formlogs
                };

                axios(orderlogoptions)
                    .then(response => {

                    }).catch(err => {

                    })

                this.setState({ button: 'Please wait.. Validating your credit card.' });

                //   console.log(data)
                if (data.ok) {
                    data.json().then(result => {


                        formlogs["Logs"] = 'WorlPay token Received';
                        const orderlogoptions = {
                            url: config.get('backendURL') + 'orders/logs/add',
                            method: 'POST',
                            data: formlogs
                        };

                        axios(orderlogoptions)
                            .then(response => {

                            }).catch(err => {

                            })

                        //  console.log(result.token)
                        this.setState({
                            token: result.token
                        });

                        const options = {
                            url: config.get('backendURL') + 'payment/' + result.token,
                            method: 'POST',
                            data: {
                                "token": result.token,
                                "orderType": "ECOM",
                                "orderDescription": "Online Goods sales",
                                "amount": ((JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total - this.state.offer) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total - this.state.offer) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat)) * 100).toFixed(0),
                                "currencyCode": localStorage.getItem('ecurrencythreeCode'),
                                "name": "3D",
                                "customerOrderCode": sessionStorage.getItem('ordernumber'),
                                "billingAddress": { "address1": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine1, "address2": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine2, "postalCode": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].PostZipCode, "city": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].City, "state": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].StateProvinceRegion, "countryCode": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Country },
                                "customerIdentifiers": { "email": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].email },
                                "is3DSOrder": true,
                                "WorldPayServiceKey": config.get('WorldPayServiceKey'),
                                "authorizeOnly": true
                            }
                        };


                        axios(options)
                            .then(response => {

                                console.log(response)
                                formlogs["Logs"] = 'WorldPay payment submitted for authorization';
                                const orderlogoptions = {
                                    url: config.get('backendURL') + 'orders/logs/add',
                                    method: 'POST',
                                    data: formlogs
                                };

                                axios(orderlogoptions)
                                    .then(response1 => {

                                    }).catch(err => {

                                    })

                                this.setState({ button: 'Please wait.. Authorising your payment' });
                                const updateorderoptions = {
                                    url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                    method: 'POST',
                                    data: { "OrderStatus": "Payment Authorised" }
                                };

                                axios(updateorderoptions)
                                    .then(response2 => {

                                    }).catch(err => {

                                    })


                                if (response.status == 200) {
                                    const values = [];
                                    if (response.data.paymentStatus == 'AUTHORIZED') {

                                        var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));
                                        //  console.log(fullUserDetails)
                                        this.setState({ button: 'Please wait.. Payment athorised' });

                                        if (fullUserDetails["PaymentCardDetails"] == null) {
                                            fullUserDetails["PaymentCardDetails"] = fullUserDetails["PaymentCardDetails"] || [];
                                            fullUserDetails["PaymentCardDetails"].push({ "Token": this.state.token, "CardHolderName": response.data.paymentResponse.name, "CardType": response.data.paymentResponse.cardType, "CardNumber": response.data.paymentResponse.maskedCardNumber, "ExpDate": response.data.paymentResponse.expiryMonth + '/' + response.data.paymentResponse.expiryYear })
                                        } else {
                                            const countindex = JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails.length;
                                            var index = JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails.findIndex(a => a.CardNumber === response.data.paymentResponse.maskedCardNumber);
                                            if (index == -1) {
                                                fullUserDetails["PaymentCardDetails"].push({ "Token": this.state.token, "CardHolderName": response.data.paymentResponse.name, "CardType": response.data.paymentResponse.cardType, "CardNumber": response.data.paymentResponse.maskedCardNumber, "ExpDate": response.data.paymentResponse.expiryMonth + '/' + response.data.paymentResponse.expiryYear })
                                            } else {
                                                fullUserDetails["PaymentCardDetails"][index].Token = this.state.token;
                                                fullUserDetails["PaymentCardDetails"][index].CardHolderName = response.data.paymentResponse.name;
                                                fullUserDetails["PaymentCardDetails"][index].CardType = response.data.paymentResponse.cardType;
                                                fullUserDetails["PaymentCardDetails"][index].CardNumber = response.data.paymentResponse.maskedCardNumber;
                                                fullUserDetails["PaymentCardDetails"][index].ExpDate = response.data.paymentResponse.expiryMonth + '/' + response.data.paymentResponse.expiryYear;
                                            }
                                        }

                                        localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));

                                        const vtimestamp = Math.floor(Date.now() / 1000) + 1000;

                                        var payload = {
                                            "aud": config.get('CustjwtAud'),
                                            "exp": vtimestamp,
                                            "iss": config.get('CustjwtIss'),
                                            "usertoken": sessionStorage.getItem('AccessToken')
                                        };


                                        var signOptions = {
                                            "algorithm": "RS256"
                                        };

                                        var privateKey = config.get('LoginprivateKey');
                                        var vtoken = jwt.sign(payload, privateKey, signOptions);


                                        const requestUserDtlsOptions = {
                                            method: 'PUT',
                                            headers: {
                                                'Access-Control-Allow-Origin': '*',
                                                'Content-Type': 'application/json',
                                                Authorization: `Bearer ` + vtoken,
                                            },
                                            body: JSON.stringify(fullUserDetails)
                                        };

                                        fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
                                            .then(response => response.json())
                                            .then(function (data) {
                                                //     console.log(data.Result);


                                                formlogs["Logs"] = 'Card details saved for future use';
                                                const orderlogoptions = {
                                                    url: config.get('backendURL') + 'orders/logs/add',
                                                    method: 'POST',
                                                    data: formlogs
                                                };

                                                axios(orderlogoptions)
                                                    .then(wresponse => {

                                                    }).catch(err => {

                                                    })

                                            });




                                        values.Email = sessionStorage.getItem('useremail');
                                        values.ordernumber = sessionStorage.getItem('ordernumber');

                                        let date = new Date();
                                        values.ExpireDate = date.setHours(date.getHours() + 24, date.getMinutes(), 0, 0);
                                        let token = encrypt(JSON.stringify(values));

                                        //   console.log(response.data)


                                        formlogs["Logs"] = 'Payment successfully authorized';
                                        const orderlogoptions = {
                                            url: config.get('backendURL') + 'orders/logs/add',
                                            method: 'POST',
                                            data: formlogs
                                        };

                                        axios(orderlogoptions)
                                            .then(wresponse => {

                                            }).catch(err => {

                                            })

                                        const timestamp = Math.floor(Date.now() / 1000) + 1000;

                                        var payload = {
                                            "aud": config.get('SalesjwtAud'),
                                            "exp": timestamp,
                                            "iss": config.get('SalesjwtIss')
                                        };

                                        var signOptions = {
                                            "algorithm": "RS256"
                                        };

                                        var privateKey = config.get('SalesprivateKey');

                                        var SalesAuthorizationKey = jwt.sign(payload, privateKey, signOptions);


                                        formSales["ProformaSales"]["PaymentToken"] = response.data.orderCode;


                                        //   console.log(formSales)

                                        fetch(config.get('API_salesregistry') + 'proformainvoice/add', {
                                            method: 'POST',
                                            headers: {
                                                'Access-Control-Allow-Origin': '*',
                                                'Content-Type': 'application/json',
                                                Authorization: `Bearer ` + SalesAuthorizationKey,
                                            },
                                            body: JSON.stringify(formSales)
                                        })
                                            .then(data => {

                                                this.setState({ button: 'Please wait.. Sending your order' });
                                                formlogs["Logs"] = 'Order submitted to API';
                                                const orderlogoptions = {
                                                    url: config.get('backendURL') + 'orders/logs/add',
                                                    method: 'POST',
                                                    data: formlogs
                                                };

                                                axios(orderlogoptions)
                                                    .then(response => {

                                                    }).catch(err => {

                                                    })


                                                if (data.ok) {

                                                    formlogs["Logs"] = 'Order sent to API successfully';
                                                    const orderlogoptions = {
                                                        url: config.get('backendURL') + 'orders/logs/add',
                                                        method: 'POST',
                                                        data: formlogs
                                                    };

                                                    axios(orderlogoptions)
                                                        .then(response => {

                                                        }).catch(err => {

                                                        })

                                                    const updateorderoptions = {
                                                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                        method: 'POST',
                                                        data: { "OrderStatus": "Order Sent to API" }
                                                    };

                                                    axios(updateorderoptions)
                                                        .then(response => {

                                                        }).catch(err => {

                                                        })



                                                    data.json().then(result => {


                                                        this.setState({ button: 'Please wait.. Sending your order mail' });
                                                        if (result.Status) {
                                                            const Emaildata = {
                                                                companyPageTitle: config.get('companyPageTitle'),
                                                                companyLogo: config.get('companyLogo'),
                                                                ordernumber: sessionStorage.getItem('ordernumber'),
                                                                ophours: this.state.OpenningHours,
                                                                SenderEmail: this.state.Email,
                                                                CustEmail: sessionStorage.getItem('useremail'),
                                                                phone: this.state.Phone,
                                                                subtotal: this.state.total,
                                                                offer: this.state.offer,
                                                                fullUserDetails: localStorage.getItem('fullUserDetails'),
                                                                deliveryMethod: sessionStorage.getItem('deliverymethod'),
                                                                paymentType: sessionStorage.getItem('paymentType'),
                                                                delivery: parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2),
                                                                total: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat),
                                                                basketitem: this.state.basketitem,
                                                                address: this.state.AddressLine1 + ", " + this.state.AddressLine2 + ", " + this.state.City + ", " + this.state.StateProvinceRegion + " " + this.state.PostZipCode + ", " + this.state.Country,
                                                                email: sessionStorage.getItem('useremail')
                                                            }

                                                            const emailBody = renderEmail(OrderEmail, { lang: 'en', Emaildata });


                                                            async function postData() {

                                                                formlogs["Logs"] = 'Order Mail request sent';
                                                                const orderlogoptions = {
                                                                    url: config.get('backendURL') + 'orders/logs/add',
                                                                    method: 'POST',
                                                                    data: formlogs
                                                                };

                                                                axios(orderlogoptions)
                                                                    .then(response => {

                                                                    }).catch(err => {

                                                                    })

                                                                const ecoEmail = {
                                                                    "to": sessionStorage.getItem('useremail'),
                                                                    "bcc": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                                                                    "from": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                                                                    "subject": config.get('companyPageTitle') + " - Order Confirmation " + sessionStorage.getItem('ordernumber'),
                                                                    "html": emailBody
                                                                };
                                                                const response = await fetch(config.get('backendURL') + 'mail/send', {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'Content-Type': 'application/json'
                                                                    },
                                                                    body: JSON.stringify(ecoEmail)
                                                                })
                                                                return response.json();
                                                            }

                                                            postData()
                                                                .then(data => {
                                                                    const updateorderoptions = {
                                                                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                                        method: 'POST',
                                                                        data: { "OrderStatus": "Order Mail Sent" }
                                                                    };

                                                                    axios(updateorderoptions)
                                                                        .then(response => {

                                                                        }).catch(err => {

                                                                        })
                                                                    //     console.log('Mail sent')


                                                                    formlogs["Logs"] = 'Order Mail sent successfully';
                                                                    const orderlogoptions = {
                                                                        url: config.get('backendURL') + 'orders/logs/add',
                                                                        method: 'POST',
                                                                        data: formlogs
                                                                    };

                                                                    axios(orderlogoptions)
                                                                        .then(response => {

                                                                        }).catch(err => {

                                                                        })


                                                                    localStorage.setItem('cart', '');
                                                                    sessionStorage.setItem('deliverycost', '');
                                                                    window.location.href = "/order-success"


                                                                }).catch(err => {

                                                                    const updateorderoptions = {
                                                                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                                        method: 'POST',
                                                                        data: { "OrderStatus": "Order Mail Not Sent" }
                                                                    };

                                                                    axios(updateorderoptions)
                                                                        .then(response => {

                                                                        }).catch(err => {

                                                                        })

                                                                    formlogs["Logs"] = 'Order Mail failed to send';
                                                                    const orderlogoptions = {
                                                                        url: config.get('backendURL') + 'orders/logs/add',
                                                                        method: 'POST',
                                                                        data: formlogs
                                                                    };

                                                                    axios(orderlogoptions)
                                                                        .then(response => {

                                                                        }).catch(err => {

                                                                        })

                                                                })

                                                        } else {


                                                            Modal.success({
                                                                title: 'Order successfully sent.',
                                                                content: 'Your order has been successfully received, however due to some technical issue you will not receive your order email immediately, our team will work on this order and update you as soon as possible.',
                                                                onOk() {
                                                                    window.location.href = "/order-success"
                                                                },
                                                            });

                                                            formlogs["Logs"] = 'Order not sent to API due to data error: ' + result.Result;
                                                            const orderlogoptions = {
                                                                url: config.get('backendURL') + 'orders/logs/add',
                                                                method: 'POST',
                                                                data: formlogs
                                                            };

                                                            axios(orderlogoptions)
                                                                .then(response => {

                                                                }).catch(err => {

                                                                })

                                                        }

                                                    })
                                                } else {

                                                    const updateorderoptions = {
                                                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                        method: 'POST',
                                                        data: { "OrderStatus": "Order not sent to API" }
                                                    };

                                                    axios(updateorderoptions)
                                                        .then(response => {

                                                        }).catch(err => {

                                                        })

                                                    Modal.error({
                                                        title: 'Oops! Something went wrong.',
                                                        content: 'Please try again.',
                                                        onOk() {
                                                            window.location.href = "/payment";
                                                        },
                                                    });



                                                }
                                            })
                                            .catch(err => {

                                                const updateorderoptions = {
                                                    url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                    method: 'POST',
                                                    data: { "OrderStatus": response.data.message }
                                                };

                                                axios(updateorderoptions)
                                                    .then(response => {

                                                    }).catch(err => {

                                                    })


                                                formlogs["Logs"] = 'Order Not send to API';
                                                const orderlogoptions = {
                                                    url: config.get('backendURL') + 'orders/logs/add',
                                                    method: 'POST',
                                                    data: formlogs
                                                };

                                                axios(orderlogoptions)
                                                    .then(response => {

                                                    }).catch(err => {

                                                    })

                                                Modal.error({
                                                    title: 'Oops! Something went wrong.',
                                                    content: 'Please try again.',
                                                    onOk() {

                                                    },
                                                });
                                            })
                                    } else {

                                        const updateorderoptions = {
                                            url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                            method: 'POST',
                                            data: { "OrderStatus": response.data.message }
                                        };

                                        axios(updateorderoptions)
                                            .then(response8 => {

                                            }).catch(err => {

                                            })

                                        Modal.error({
                                            title: 'Oops! Something wrong.',
                                            content: 'Please check your credit card details and try again.',
                                            onOk() {
                                                window.location.href = "/payment";
                                            },
                                        });

                                        formlogs["Logs"] = response.data.message;
                                        const orderlogoptions = {
                                            url: config.get('backendURL') + 'orders/logs/add',
                                            method: 'POST',
                                            data: formlogs
                                        };

                                        axios(orderlogoptions)
                                            .then(response4 => {

                                            }).catch(err => {

                                            })

                                    }
                                } else {
                                    Modal.error({
                                        title: 'Oops! Something wrong.',
                                        content: 'Please check your credit card details and try again.',
                                        onOk() {
                                            window.location.href = "/payment";
                                        },
                                    });
                                    this.setState({ disabled: false });
                                    this.refs.btn.removeAttribute("disabled", "disabled");

                                    formlogs["Logs"] = response.data.message;
                                    const orderlogoptions = {
                                        url: config.get('backendURL') + 'orders/logs/add',
                                        method: 'POST',
                                        data: formlogs
                                    };

                                    axios(orderlogoptions)
                                        .then(response => {

                                        }).catch(err => {

                                        })

                                }


                            }).catch(err => {

                                const updateorderoptions = {
                                    url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                    method: 'POST',
                                    data: { "OrderStatus": "Payment Failed" }
                                };

                                axios(updateorderoptions)
                                    .then(response => {

                                    }).catch(err => {

                                    })

                                //  console.log(err)
                                Modal.error({
                                    title: 'Oops! Something went wrong.',
                                    content: 'Please try again.',
                                    onOk() {
                                        window.location.href = "/payment";
                                    },
                                });

                                formlogs["Logs"] = 'Payment failed due to some technical issue';
                                const orderlogoptions = {
                                    url: config.get('backendURL') + 'orders/logs/add',
                                    method: 'POST',
                                    data: formlogs
                                };

                                axios(orderlogoptions)
                                    .then(response => {

                                    }).catch(err => {

                                    })

                            })
                            ;
                    })


                } else {
                    Modal.error({
                        title: 'Oops! Something wrong.',
                        content: 'Please check your credit card details and try again.',
                        onOk() { window.location.href = "/payment" },
                    });
                    this.setState({ disabled: false });
                    this.refs.btn.removeAttribute("disabled", "disabled");

                    formlogs["Logs"] = 'Credit card toekn authorization is failed due to invalid card';
                    const orderlogoptions = {
                        url: config.get('backendURL') + 'orders/logs/add',
                        method: 'POST',
                        data: formlogs
                    };

                    axios(orderlogoptions)
                        .then(response => {

                        }).catch(err => {

                        })

                }
                // console.log(data)
            })
            .catch(err => console.log('err', err))
    };

    handleSavedCardSubmit = values => {



        if (this.state.value === 100) {
            Modal.error({
                title: 'Billing Address',
                content: 'Please select or enter a billing address',
                onOk() {

                    return;
                },
            });
            return;
        } else if (this.state.cardvalue === '') {
            Modal.error({
                title: 'Select a saved card',
                content: 'Please select a card from the list to pay',
                onOk() {

                    return;
                },
            });
            return;
        }

        this.setState({ button: 'Please wait' });
        sessionStorage.setItem('paymentType', 'Saved Card');

        this.setState({ disabled: true });
        this.refs.btn.setAttribute("disabled", "disabled");
        const curentDateTime = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");

        var PIsales =
            this.state.basketitem.length ? (
                this.state.basketitem.map((item, index) => (
                    {
                        ProductID: item.ProductID,
                        ProductCategoryID: item.productdetails.ProductCategoryID,
                        SalesQuantity: parseFloat(this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty),
                        SalesPrice: parseFloat(parseFloat(item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        LineDiscount: 0,
                        LineVat: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat((((item.productdetails.SellingPricesArray.SellingPrice * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate / (1 + this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate))).toFixed(2)) : parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate).toFixed(2)),
                        LineAmount: parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty) * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        OriginalAmount: parseFloat((this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty * item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        PromotionAmount: 0,
                        SalesUnitTypeID: item.productdetails.SellingPricesArray.SalesUnitTypeID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                    }

                )
                )
            ) : (
                {}
            )

        var PIoffers =
            this.state.basketitem.map((item, index) => (

                item.OfferAmount != 0 ? (
                    {
                        ProductID: 0,
                        ProductCategoryID: 0,
                        SalesQuantity: 0,
                        SalesPrice: 0,
                        LineDiscount: 0,
                        LineVat: 0,
                        LineAmount: 0,
                        OriginalAmount: 0,
                        SalesUnitTypeID: 0,
                        PromotionAmount: parseFloat(-Math.abs(item.OfferAmount).toFixed(2)),
                        PromotionName: item.offerdetails[0].Offer.PromotionName,
                        PromotionID: item.offerdetails[0].Offer.OfferID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID'),

                    }
                ) : ''
            )
            )


        const formSales = {
            ProformaSales: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                OrderType: "OnLine",
                DeliveryInstruction: sessionStorage.getItem('DeliveryIns') == ' : undefined' ? '' : sessionStorage.getItem('DeliveryIns'),
                SubTotal: parseFloat(this.state.total),
                BulkDiscount: 0,
                VatAmount: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.totalvat) : parseFloat(this.state.totalvat),
                TotalInvoiceValue: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat),
                OfferDiscount: parseFloat(this.state.offer),
                Description: '',
                OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                AdditionalCharges: '{\'DeliveryCharge\':' + parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2) + '}',
                SubOrderType: sessionStorage.getItem('deliverymethod'),
                DeliveryCharge: parseFloat(sessionStorage.getItem('deliverycost')),
                CurrencyID: parseInt(localStorage.getItem('ecurrencyid')),
                CartReference: sessionStorage.getItem('ordernumber'),
                PaymentToken: '',
                DeliveryAddress: {
                    AddressType: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressType,
                    AddressLine1: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressLine1,
                    AddressLine2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressLine2,
                    City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].City,
                    StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].StateProvinceRegion,
                    PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].PostZipCode,
                    Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Country,
                    Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Phone,
                    Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Fax,
                    Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
                BillingAddress: {
                    AddressType: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressType,
                    AddressLine1: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine1,
                    AddressLine2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine2,
                    City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].City,
                    StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].StateProvinceRegion,
                    PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].PostZipCode,
                    Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Country,
                    Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Phone,
                    Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Fax,
                    Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
            },
            ProformaInvoiceDetail: [...PIsales, ...PIoffers.filter(function (e) { return e })]
            ,
            Customer: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                CompanyName: JSON.parse(localStorage.getItem('fullUserDetails')).CompanyName,
                FirstName: JSON.parse(localStorage.getItem('fullUserDetails')).FirstName,
                LastName: JSON.parse(localStorage.getItem('fullUserDetails')).LastName,
                UserName: JSON.parse(localStorage.getItem('fullUserDetails')).UserName,
                Email: JSON.parse(localStorage.getItem('fullUserDetails')).Email,
                Avatar: JSON.parse(localStorage.getItem('fullUserDetails')).Avatar,
                UserID: JSON.parse(localStorage.getItem('fullUserDetails')).UserID,
                OriginatedOrganizationID: JSON.parse(localStorage.getItem('fullUserDetails')).OriginatedOrganizationID,
                Addresses: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses
            }
        }




        formSales["OrderStatus"] = 'Order Received';

        const orderoptions = {
            url: config.get('backendURL') + 'orders/add',
            method: 'POST',
            data: formSales
        };

        axios(orderoptions)
            .then(response => {
                // console.log(response.data)
                sessionStorage.setItem('insertedId', response.data.insertedId);
            }).catch(err => {

            })


        const formlogs = {
            CloudCustomerID: sessionStorage.getItem('UserID'),
            OriginatedOrganizationID: sessionStorage.getItem('OriginatedOrganizationID'),
            CartReference: sessionStorage.getItem('ordernumber'),
            Logs: 'Order submitted',
        }

        const orderlogoptions = {
            url: config.get('backendURL') + 'orders/logs/add',
            method: 'POST',
            data: formlogs
        };

        axios(orderlogoptions)
            .then(response => {

            }).catch(err => {

            })


        const CardDetails = {
            cvc: values.scvc,
            clientKey: config.get('WorldPayClientKey')
        }

        var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));
        //  console.log(fullUserDetails)

        this.setState({ button: 'Please wait.. Validating your card.' });

        fetch('https://api.worldpay.com/v1/tokens/' + fullUserDetails["PaymentCardDetails"][this.state.cardvalue].Token, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(CardDetails)
        })
            .then(data => {

                formlogs["Logs"] = 'WorldPay CVC validated';
                const orderlogoptions = {
                    url: config.get('backendURL') + 'orders/logs/add',
                    method: 'POST',
                    data: formlogs
                };

                axios(orderlogoptions)
                    .then(response => {

                    }).catch(err => {

                    })


                formlogs["Logs"] = 'WorlPay token Received';
                const orderlogoptions3 = {
                    url: config.get('backendURL') + 'orders/logs/add',
                    method: 'POST',
                    data: formlogs
                };

                axios(orderlogoptions3)
                    .then(response => {

                    }).catch(err => {

                    })

                //  console.log(result.token)
                this.setState({
                    token: fullUserDetails["PaymentCardDetails"][this.state.cardvalue].Token
                });

                const options = {
                    url: config.get('backendURL') + 'payment/' + fullUserDetails["PaymentCardDetails"][this.state.cardvalue].Token,
                    method: 'POST',
                    data: {
                        "token": fullUserDetails["PaymentCardDetails"][this.state.cardvalue].Token,
                        "orderType": "ECOM",
                        "orderDescription": "Online Goods sales",
                        "amount": ((JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total - this.state.offer) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total - this.state.offer) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat)) * 100).toFixed(0),
                        "currencyCode": localStorage.getItem('ecurrencythreeCode'),
                        "name": "3D",
                        "customerOrderCode": sessionStorage.getItem('ordernumber'),
                        "billingAddress": { "address1": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine1, "address2": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].AddressLine2, "postalCode": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].PostZipCode, "city": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].City, "state": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].StateProvinceRegion, "countryCode": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].Country },
                        "customerIdentifiers": { "email": JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.state.value].email },
                        "is3DSOrder": true,
                        "WorldPayServiceKey": config.get('WorldPayServiceKey'),
                        "authorizeOnly": true
                    }
                };


                axios(options)
                    .then(response => {

                        //console.log(response)
                        formlogs["Logs"] = 'WorldPay payment submitted for authorization';
                        const orderlogoptions = {
                            url: config.get('backendURL') + 'orders/logs/add',
                            method: 'POST',
                            data: formlogs
                        };

                        axios(orderlogoptions)
                            .then(response1 => {

                            }).catch(err => {

                            })


                        const updateorderoptions = {
                            url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                            method: 'POST',
                            data: { "OrderStatus": "Payment Authorised" }
                        };

                        axios(updateorderoptions)
                            .then(response2 => {

                            }).catch(err => {

                            })


                        if (response.status == 200) {
                            const values = [];
                            if (response.data.paymentStatus == 'AUTHORIZED') {

                                this.setState({ button: 'Please wait.. Payment Authorised.' });

                                var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));
                                //  console.log(fullUserDetails)


                                if (fullUserDetails["PaymentCardDetails"] == null) {
                                    fullUserDetails["PaymentCardDetails"] = fullUserDetails["PaymentCardDetails"] || [];
                                    fullUserDetails["PaymentCardDetails"].push({ "Token": this.state.token, "CardHolderName": response.data.paymentResponse.name, "CardType": response.data.paymentResponse.cardType, "CardNumber": response.data.paymentResponse.maskedCardNumber, "ExpDate": response.data.paymentResponse.expiryMonth + '/' + response.data.paymentResponse.expiryYear })
                                } else {
                                    const countindex = JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails.length;
                                    var index = JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails.findIndex(a => a.CardNumber === response.data.paymentResponse.maskedCardNumber);
                                    if (index == -1) {
                                        fullUserDetails["PaymentCardDetails"].push({ "Token": this.state.token, "CardHolderName": response.data.paymentResponse.name, "CardType": response.data.paymentResponse.cardType, "CardNumber": response.data.paymentResponse.maskedCardNumber, "ExpDate": response.data.paymentResponse.expiryMonth + '/' + response.data.paymentResponse.expiryYear })
                                    } else {
                                        fullUserDetails["PaymentCardDetails"][index].Token = this.state.token;
                                        fullUserDetails["PaymentCardDetails"][index].CardHolderName = response.data.paymentResponse.name;
                                        fullUserDetails["PaymentCardDetails"][index].CardType = response.data.paymentResponse.cardType;
                                        fullUserDetails["PaymentCardDetails"][index].CardNumber = response.data.paymentResponse.maskedCardNumber;
                                        fullUserDetails["PaymentCardDetails"][index].ExpDate = response.data.paymentResponse.expiryMonth + '/' + response.data.paymentResponse.expiryYear;
                                    }
                                }

                                localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));

                                const vtimestamp = Math.floor(Date.now() / 1000) + 1000;

                                var payload = {
                                    "aud": config.get('CustjwtAud'),
                                    "exp": vtimestamp,
                                    "iss": config.get('CustjwtIss'),
                                    "usertoken": sessionStorage.getItem('AccessToken')
                                };


                                var signOptions = {
                                    "algorithm": "RS256"
                                };

                                var privateKey = config.get('LoginprivateKey');
                                var vtoken = jwt.sign(payload, privateKey, signOptions);


                                const requestUserDtlsOptions = {
                                    method: 'PUT',
                                    headers: {
                                        'Access-Control-Allow-Origin': '*',
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ` + vtoken,
                                    },
                                    body: JSON.stringify(fullUserDetails)
                                };

                                fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
                                    .then(response => response.json())
                                    .then(function (data) {
                                        // console.log(data.Result);


                                        formlogs["Logs"] = 'Card details saved for future use';
                                        const orderlogoptions = {
                                            url: config.get('backendURL') + 'orders/logs/add',
                                            method: 'POST',
                                            data: formlogs
                                        };

                                        axios(orderlogoptions)
                                            .then(wresponse => {

                                            }).catch(err => {

                                            })

                                    });




                                values.Email = sessionStorage.getItem('useremail');
                                values.ordernumber = sessionStorage.getItem('ordernumber');

                                let date = new Date();
                                values.ExpireDate = date.setHours(date.getHours() + 24, date.getMinutes(), 0, 0);
                                let token = encrypt(JSON.stringify(values));

                                //   console.log(response.data)


                                formlogs["Logs"] = 'Payment successfully authorized';
                                const orderlogoptions = {
                                    url: config.get('backendURL') + 'orders/logs/add',
                                    method: 'POST',
                                    data: formlogs
                                };

                                axios(orderlogoptions)
                                    .then(wresponse => {

                                    }).catch(err => {

                                    })

                                const timestamp = Math.floor(Date.now() / 1000) + 1000;

                                var payload = {
                                    "aud": config.get('SalesjwtAud'),
                                    "exp": timestamp,
                                    "iss": config.get('SalesjwtIss')
                                };

                                var signOptions = {
                                    "algorithm": "RS256"
                                };

                                var privateKey = config.get('SalesprivateKey');

                                var SalesAuthorizationKey = jwt.sign(payload, privateKey, signOptions);


                                formSales["ProformaSales"]["PaymentToken"] = response.data.orderCode;


                                //   console.log(formSales)

                                fetch(config.get('API_salesregistry') + 'proformainvoice/add', {
                                    method: 'POST',
                                    headers: {
                                        'Access-Control-Allow-Origin': '*',
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ` + SalesAuthorizationKey,
                                    },
                                    body: JSON.stringify(formSales)
                                })
                                    .then(data => {
                                        formlogs["Logs"] = 'Order submitted to API';
                                        const orderlogoptions = {
                                            url: config.get('backendURL') + 'orders/logs/add',
                                            method: 'POST',
                                            data: formlogs
                                        };

                                        axios(orderlogoptions)
                                            .then(response => {

                                            }).catch(err => {

                                            })


                                        if (data.ok) {
                                            this.setState({ button: 'Please wait.. Sending your order.' });
                                            formlogs["Logs"] = 'Order sent to API successfully';
                                            const orderlogoptions = {
                                                url: config.get('backendURL') + 'orders/logs/add',
                                                method: 'POST',
                                                data: formlogs
                                            };

                                            axios(orderlogoptions)
                                                .then(response => {

                                                }).catch(err => {

                                                })

                                            const updateorderoptions = {
                                                url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                method: 'POST',
                                                data: { "OrderStatus": "Order Sent to API" }
                                            };

                                            axios(updateorderoptions)
                                                .then(response => {

                                                }).catch(err => {

                                                })



                                            data.json().then(result => {



                                                if (result.Status) {
                                                    const Emaildata = {
                                                        companyPageTitle: config.get('companyPageTitle'),
                                                        companyLogo: config.get('companyLogo'),
                                                        ordernumber: sessionStorage.getItem('ordernumber'),
                                                        ophours: this.state.OpenningHours,
                                                        SenderEmail: this.state.Email,
                                                        CustEmail: sessionStorage.getItem('useremail'),
                                                        phone: this.state.Phone,
                                                        subtotal: this.state.total,
                                                        offer: this.state.offer,
                                                        fullUserDetails: localStorage.getItem('fullUserDetails'),
                                                        deliveryMethod: sessionStorage.getItem('deliverymethod'),
                                                        paymentType: sessionStorage.getItem('paymentType'),
                                                        delivery: parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2),
                                                        total: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat),
                                                        basketitem: this.state.basketitem,
                                                        address: this.state.AddressLine1 + ", " + this.state.AddressLine2 + ", " + this.state.City + ", " + this.state.StateProvinceRegion + " " + this.state.PostZipCode + ", " + this.state.Country,
                                                        email: sessionStorage.getItem('useremail')
                                                    }

                                                    const emailBody = renderEmail(OrderEmail, { lang: 'en', Emaildata });


                                                    async function postData() {

                                                        formlogs["Logs"] = 'Order Mail request sent';
                                                        const orderlogoptions = {
                                                            url: config.get('backendURL') + 'orders/logs/add',
                                                            method: 'POST',
                                                            data: formlogs
                                                        };

                                                        axios(orderlogoptions)
                                                            .then(response => {

                                                            }).catch(err => {

                                                            })

                                                        const ecoEmail = {
                                                            "to": sessionStorage.getItem('useremail'),
                                                            "bcc": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                                                            "from": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                                                            "subject": config.get('companyPageTitle') + " - Order Confirmation " + sessionStorage.getItem('ordernumber'),
                                                            "html": emailBody
                                                        };
                                                        const response = await fetch(config.get('backendURL') + 'mail/send', {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify(ecoEmail)
                                                        })
                                                        return response.json();
                                                    }

                                                    postData()
                                                        .then(data => {
                                                            const updateorderoptions = {
                                                                url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                                method: 'POST',
                                                                data: { "OrderStatus": "Order Mail Sent" }
                                                            };

                                                            this.setState({ button: 'Please wait.. Order mail sent.' });
                                                            axios(updateorderoptions)
                                                                .then(response => {

                                                                }).catch(err => {

                                                                })
                                                            //     console.log('Mail sent')


                                                            formlogs["Logs"] = 'Order Mail sent successfully';
                                                            const orderlogoptions = {
                                                                url: config.get('backendURL') + 'orders/logs/add',
                                                                method: 'POST',
                                                                data: formlogs
                                                            };

                                                            axios(orderlogoptions)
                                                                .then(response => {

                                                                }).catch(err => {

                                                                })


                                                            localStorage.setItem('cart', '');
                                                            sessionStorage.setItem('deliverycost', '');
                                                            window.location.href = "/order-success"


                                                        }).catch(err => {

                                                            const updateorderoptions = {
                                                                url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                                method: 'POST',
                                                                data: { "OrderStatus": "Order Mail Not Sent" }
                                                            };

                                                            axios(updateorderoptions)
                                                                .then(response => {

                                                                }).catch(err => {

                                                                })

                                                            formlogs["Logs"] = 'Order Mail failed to send';
                                                            const orderlogoptions = {
                                                                url: config.get('backendURL') + 'orders/logs/add',
                                                                method: 'POST',
                                                                data: formlogs
                                                            };

                                                            axios(orderlogoptions)
                                                                .then(response => {

                                                                }).catch(err => {

                                                                })

                                                        })

                                                } else {


                                                    Modal.success({
                                                        title: 'Order successfully sent.',
                                                        content: 'Your order has been successfully received, however due to some technical issue you will not receive your order email immediately, our team will work on this order and update you as soon as possible.',
                                                        onOk() {
                                                            window.location.href = "/order-success"
                                                        },
                                                    });

                                                    formlogs["Logs"] = 'Order not sent to API due to data error: ' + result.Result;
                                                    const orderlogoptions = {
                                                        url: config.get('backendURL') + 'orders/logs/add',
                                                        method: 'POST',
                                                        data: formlogs
                                                    };

                                                    axios(orderlogoptions)
                                                        .then(response => {

                                                        }).catch(err => {

                                                        })

                                                }

                                            })
                                        } else {

                                            const updateorderoptions = {
                                                url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                                method: 'POST',
                                                data: { "OrderStatus": "Order not sent to API" }
                                            };

                                            axios(updateorderoptions)
                                                .then(response => {

                                                }).catch(err => {

                                                })

                                            Modal.error({
                                                title: 'Oops! Something went wrong.',
                                                content: 'Please try again.',
                                                onOk() {
                                                    window.location.href = "/payment";
                                                },
                                            });



                                        }
                                    })
                                    .catch(err => {

                                        const updateorderoptions = {
                                            url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                            method: 'POST',
                                            data: { "OrderStatus": response.data.message }
                                        };

                                        axios(updateorderoptions)
                                            .then(response => {

                                            }).catch(err => {

                                            })


                                        formlogs["Logs"] = 'Order Not send to API';
                                        const orderlogoptions = {
                                            url: config.get('backendURL') + 'orders/logs/add',
                                            method: 'POST',
                                            data: formlogs
                                        };

                                        axios(orderlogoptions)
                                            .then(response => {

                                            }).catch(err => {

                                            })

                                        Modal.error({
                                            title: 'Oops! Something went wrong.',
                                            content: 'Please try again.',
                                            onOk() {

                                            },
                                        });
                                    })
                            } else {

                                const updateorderoptions = {
                                    url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                    method: 'POST',
                                    data: { "OrderStatus": response.data.message }
                                };

                                axios(updateorderoptions)
                                    .then(response8 => {

                                    }).catch(err => {

                                    })

                                Modal.error({
                                    title: 'Oops! Something wrong.',
                                    content: 'Please check your credit card details and try again.',
                                    onOk() {
                                        window.location.href = "/payment";
                                    },
                                });

                                formlogs["Logs"] = response.data.message;
                                const orderlogoptions = {
                                    url: config.get('backendURL') + 'orders/logs/add',
                                    method: 'POST',
                                    data: formlogs
                                };

                                axios(orderlogoptions)
                                    .then(response4 => {

                                    }).catch(err => {

                                    })

                            }
                        } else {
                            Modal.error({
                                title: 'Oops! Something wrong.',
                                content: 'Please check your credit card details and try again.',
                                onOk() {
                                    window.location.href = "/payment";
                                },
                            });
                            this.setState({ disabled: false });
                            this.refs.btn.removeAttribute("disabled", "disabled");

                            formlogs["Logs"] = response.data.message;
                            const orderlogoptions = {
                                url: config.get('backendURL') + 'orders/logs/add',
                                method: 'POST',
                                data: formlogs
                            };

                            axios(orderlogoptions)
                                .then(response => {

                                }).catch(err => {

                                })

                        }


                    }).catch(err => {

                        const updateorderoptions = {
                            url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                            method: 'POST',
                            data: { "OrderStatus": "Payment Failed" }
                        };

                        axios(updateorderoptions)
                            .then(response => {

                            }).catch(err => {

                            })

                        console.log(err)
                        Modal.error({
                            title: 'Oops! Something went wrong.',
                            content: 'Please try again.',
                            onOk() {
                                window.location.href = "/payment";
                            },
                        });

                        formlogs["Logs"] = 'Payment failed due to some technical issue';
                        const orderlogoptions = {
                            url: config.get('backendURL') + 'orders/logs/add',
                            method: 'POST',
                            data: formlogs
                        };

                        axios(orderlogoptions)
                            .then(response => {

                            }).catch(err => {

                            })

                    })
                    ;

            })

    }


    handleCashSubmit = e => {
        e.preventDefault();
        sessionStorage.setItem('paymentType', 'Cash');
        this.setState({ disabled: true });
        this.refs.btn.setAttribute("disabled", "disabled");
        const curentDateTime = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");


        var PIsales =
            this.state.basketitem.length ? (
                this.state.basketitem.map((item, index) => (
                    {
                        ProductID: item.ProductID,
                        ProductCategoryID: item.productdetails.ProductCategoryID,
                        SalesQuantity: parseFloat(this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty),
                        SalesPrice: parseFloat(parseFloat(item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        LineDiscount: 0,
                        LineVat: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat((((item.productdetails.SellingPricesArray.SellingPrice * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate / (1 + this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate))).toFixed(2)) : parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].VatRate).toFixed(2)),
                        LineAmount: parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty) * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        OriginalAmount: parseFloat((this.state.basketitem[this.state.basketitem.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty * item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        PromotionAmount: 0,
                        SalesUnitTypeID: item.productdetails.SellingPricesArray.SalesUnitTypeID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                    }

                )
                )
            ) : (
                {}
            )

        var PIoffers =
            this.state.basketitem.map((item, index) => (

                item.OfferAmount != 0 ? (
                    {
                        ProductID: 0,
                        ProductCategoryID: 0,
                        SalesQuantity: 0,
                        SalesPrice: 0,
                        LineDiscount: 0,
                        LineVat: 0,
                        LineAmount: 0,
                        OriginalAmount: 0,
                        SalesUnitTypeID: 0,
                        PromotionAmount: parseFloat(-Math.abs(item.OfferAmount).toFixed(2)),
                        PromotionName: item.offerdetails[0].Offer.PromotionName,
                        PromotionID: item.offerdetails[0].Offer.OfferID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID'),

                    }
                ) : ''
            )
            )

        const formSales = {
            ProformaSales: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                OrderType: "OnLine",
                DeliveryInstruction: sessionStorage.getItem('DeliveryIns') == ' : undefined' ? '' : sessionStorage.getItem('DeliveryIns'),
                SubTotal: parseFloat(this.state.total),
                BulkDiscount: 0,
                VatAmount: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.totalvat) : parseFloat(this.state.totalvat),
                TotalInvoiceValue: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat),
                OfferDiscount: parseFloat(this.state.offer),
                Description: '',
                OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                AdditionalCharges: '{\'DeliveryCharge\':' + parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2) + '}',
                SubOrderType: sessionStorage.getItem('deliverymethod'),
                DeliveryCharge: parseFloat(sessionStorage.getItem('deliverycost')),
                CurrencyID: parseInt(localStorage.getItem('ecurrencyid')),
                CartReference: sessionStorage.getItem('ordernumber'),
                PaymentToken: '',
                DeliveryAddress: {
                    AddressType: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressType,
                    AddressLine1: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressLine1,
                    AddressLine2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].AddressLine2,
                    City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].City,
                    StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].StateProvinceRegion,
                    PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].PostZipCode,
                    Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Country,
                    Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Phone,
                    Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Fax,
                    Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[sessionStorage.getItem('deliveryaddress')].Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
                BillingAddress: {
                    AddressType: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].AddressType,
                    AddressLine1: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].AddressLine1,
                    AddressLine2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].AddressLine2,
                    City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].City,
                    StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].StateProvinceRegion,
                    PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].PostZipCode,
                    Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].Country,
                    Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].Phone,
                    Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].Fax,
                    Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[0].Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
            },
            ProformaInvoiceDetail: [...PIsales, ...PIoffers.filter(function (e) { return e })]
            ,
            Customer: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                CompanyName: JSON.parse(localStorage.getItem('fullUserDetails')).CompanyName,
                FirstName: JSON.parse(localStorage.getItem('fullUserDetails')).FirstName,
                LastName: JSON.parse(localStorage.getItem('fullUserDetails')).LastName,
                UserName: JSON.parse(localStorage.getItem('fullUserDetails')).UserName,
                Email: JSON.parse(localStorage.getItem('fullUserDetails')).Email,
                Avatar: JSON.parse(localStorage.getItem('fullUserDetails')).Avatar,
                UserID: JSON.parse(localStorage.getItem('fullUserDetails')).UserID,
                OriginatedOrganizationID: JSON.parse(localStorage.getItem('fullUserDetails')).OriginatedOrganizationID,
                Addresses: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses
            }
        }

        formSales["OrderStatus"] = 'Order Received';

        const orderoptions = {
            url: config.get('backendURL') + 'orders/add',
            method: 'POST',
            data: formSales
        };

        axios(orderoptions)
            .then(response => {
                //  console.log(response.data)
                sessionStorage.setItem('insertedId', response.data.insertedId);
            }).catch(err => {

            })


        const formlogs = {
            CloudCustomerID: sessionStorage.getItem('UserID'),
            OriginatedOrganizationID: sessionStorage.getItem('OriginatedOrganizationID'),
            CartReference: sessionStorage.getItem('ordernumber'),
            Logs: 'Order submitted',
        }

        const orderlogoptions = {
            url: config.get('backendURL') + 'orders/logs/add',
            method: 'POST',
            data: formlogs
        };

        axios(orderlogoptions)
            .then(response => {

            }).catch(err => {

            })



        const values = [];

        values.Email = sessionStorage.getItem('useremail');
        values.ordernumber = sessionStorage.getItem('ordernumber');

        let date = new Date();
        values.ExpireDate = date.setHours(date.getHours() + 24, date.getMinutes(), 0, 0);


        axios(orderlogoptions)
            .then(wresponse => {

            }).catch(err => {

            })

        const timestamp = Math.floor(Date.now() / 1000) + 1000;

        var payload = {
            "aud": config.get('SalesjwtAud'),
            "exp": timestamp,
            "iss": config.get('SalesjwtIss')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = config.get('SalesprivateKey');

        var SalesAuthorizationKey = jwt.sign(payload, privateKey, signOptions);


        formSales["ProformaSales"]["PaymentToken"] = '';


        // console.log(formSales)

        fetch(config.get('API_salesregistry') + 'proformainvoice/add', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + SalesAuthorizationKey,
            },
            body: JSON.stringify(formSales)
        })
            .then(data => {


                formlogs["Logs"] = 'Order submitted to API';
                const orderlogoptions = {
                    url: config.get('backendURL') + 'orders/logs/add',
                    method: 'POST',
                    data: formlogs
                };

                axios(orderlogoptions)
                    .then(response => {

                    }).catch(err => {

                    })


                if (data.ok) {

                    formlogs["Logs"] = 'Order sent to API successfully';
                    const orderlogoptions = {
                        url: config.get('backendURL') + 'orders/logs/add',
                        method: 'POST',
                        data: formlogs
                    };

                    axios(orderlogoptions)
                        .then(response => {

                        }).catch(err => {

                        })

                    const updateorderoptions = {
                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                        method: 'POST',
                        data: { "OrderStatus": "Order Sent to API" }
                    };

                    axios(updateorderoptions)
                        .then(response => {

                        }).catch(err => {

                        })

                    data.json().then(result => {

                        if (result.Status) {
                            const Emaildata = {
                                companyPageTitle: config.get('companyPageTitle'),
                                companyLogo: config.get('companyLogo'),
                                ordernumber: sessionStorage.getItem('ordernumber'),
                                ophours: this.state.OpenningHours,
                                SenderEmail: this.state.Email,
                                CustEmail: sessionStorage.getItem('useremail'),
                                phone: this.state.Phone,
                                subtotal: this.state.total,
                                offer: this.state.offer,
                                fullUserDetails: localStorage.getItem('fullUserDetails'),
                                deliveryMethod: sessionStorage.getItem('deliverymethod'),
                                paymentType: sessionStorage.getItem('paymentType'),
                                delivery: parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2),
                                total: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) : parseFloat(this.state.total) + parseFloat(sessionStorage.getItem('deliverycost')) + parseFloat(this.state.totalvat),
                                basketitem: this.state.basketitem,
                                address: this.state.AddressLine1 + ", " + this.state.AddressLine2 + ", " + this.state.City + ", " + this.state.StateProvinceRegion + " " + this.state.PostZipCode + ", " + this.state.Country,
                                email: sessionStorage.getItem('useremail')
                            }

                            const emailBody = renderEmail(OrderEmail, { lang: 'en', Emaildata });


                            async function postData() {

                                formlogs["Logs"] = 'Order Mail request sent';
                                const orderlogoptions = {
                                    url: config.get('backendURL') + 'orders/logs/add',
                                    method: 'POST',
                                    data: formlogs
                                };

                                axios(orderlogoptions)
                                    .then(response => {

                                    }).catch(err => {

                                    })

                                const ecoEmail = {
                                    "to": sessionStorage.getItem('useremail'),
                                    "bcc": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                                    "from": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                                    "subject": config.get('companyPageTitle') + " - Order Confirmation " + sessionStorage.getItem('ordernumber'),
                                    "html": emailBody
                                };
                                const response = await fetch(config.get('backendURL') + 'mail/send', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(ecoEmail)
                                })
                                return response.json();
                            }

                            postData()
                                .then(data => {
                                    const updateorderoptions = {
                                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                        method: 'POST',
                                        data: { "OrderStatus": "Order Mail Sent" }
                                    };

                                    axios(updateorderoptions)
                                        .then(response => {

                                        }).catch(err => {

                                        })
                                    //     console.log('Mail sent')


                                    formlogs["Logs"] = 'Order Mail sent successfully';
                                    const orderlogoptions = {
                                        url: config.get('backendURL') + 'orders/logs/add',
                                        method: 'POST',
                                        data: formlogs
                                    };

                                    axios(orderlogoptions)
                                        .then(response => {

                                        }).catch(err => {

                                        })


                                    localStorage.setItem('cart', '');
                                    sessionStorage.setItem('deliverycost', '');
                                    window.location.href = "/order-success"


                                }).catch(err => {

                                    const updateorderoptions = {
                                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                                        method: 'POST',
                                        data: { "OrderStatus": "Order Mail Not Sent" }
                                    };

                                    axios(updateorderoptions)
                                        .then(response => {

                                        }).catch(err => {

                                        })

                                    formlogs["Logs"] = 'Order Mail failed to send';
                                    const orderlogoptions = {
                                        url: config.get('backendURL') + 'orders/logs/add',
                                        method: 'POST',
                                        data: formlogs
                                    };

                                    axios(orderlogoptions)
                                        .then(response => {

                                        }).catch(err => {

                                        })

                                })



                        } else {
                            Modal.success({
                                title: 'Order successfully sent.',
                                content: 'Your order has been successfully received, however due to some technical issue you will not receive your order email immediately, our team will work on this order and update you as soon as possible.',
                                onOk() {
                                    window.location.href = "/order-success"
                                },
                            });

                            formlogs["Logs"] = 'Order not sent to API due to data error: ' + result.Result;
                            const orderlogoptions = {
                                url: config.get('backendURL') + 'orders/logs/add',
                                method: 'POST',
                                data: formlogs
                            };

                            axios(orderlogoptions)
                                .then(response => {

                                }).catch(err => {

                                })
                        }
                    })
                } else {

                    const updateorderoptions = {
                        url: config.get('backendURL') + 'orders/update/' + sessionStorage.getItem('insertedId'),
                        method: 'POST',
                        data: { "OrderStatus": "Order not sent to API" }
                    };

                    axios(updateorderoptions)
                        .then(response => {

                        }).catch(err => {

                        })

                    Modal.error({
                        title: 'Oops! Something went wrong.',
                        content: 'Please try again.',
                        onOk() {
                            window.location.href = "/payment";
                        },
                    });



                }
            })

        // console.log(data)

    };



    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    onCardChange = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
            cardvalue: e.target.value,
        });
    };

    onCartLoadItems = (basketitem) => {

        const myArrayItems = [];

        basketitem.map(item => (
            ViewProductDetailById(item.ProductID, item.ProductQty)
        )
        )

    };

    onPaymentTypeChange = e => {
        if (e.target.value == 0) {
            this.setState({
                delivery: sessionStorage.getItem('deliverycost')
            });
            sessionStorage.setItem('deliverytype', 0);
        } else {
            this.setState({
                delivery: 0
            });
            sessionStorage.setItem('deliverytype', 1);
        }
        this.setState({
            value: e.target.value,
        });
    };

    selectAddress = (props, index) => {
        //   console.log(JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index])

        this.formRef.current.setFieldsValue({
            Address: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].AddressLine1,
            Address2: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].AddressLine2,
            City: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].City,
            StateProvinceRegion: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].StateProvinceRegion,
            PostZipCode: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].PostZipCode,
            Country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Country,
            Email: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Email,
            Phone: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Phone,
            Fax: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[index].Fax,
        });
    };

    ProductsByOffer = async (product) => {

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            "aud": config.get('ProductjwtAud'),
            "exp": timestamp,
            "iss": config.get('ProductjwtIss')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = config.get('ProductprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            }
        };


        const result = [];


        if (product.Offers[0] !== undefined) {
            return await fetch(config.get('rest_API') + 'offers/offerbyid/' + config.get('OrganisationID') + '/' + product.Offers[0].OfferProducts.OfferID, requestOptions)
                .then(response => response.json())
                .then(async data => {
                    result.push(data.Result[0])

                    // console.log( result)

                    return result;
                });
        } else {
            return product;
        }
    }

    ProductsOffer = async (product) => {

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
            "aud": config.get('ProductjwtAud'),
            "exp": timestamp,
            "iss": config.get('ProductjwtIss')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = config.get('ProductprivateKey');
        var token = jwt.sign(payload, privateKey, signOptions);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + token,
            },
            body: JSON.stringify({
                "OrgID": config.get('OrganisationID'),
                "ProductID": parseInt(product.ProductID),
                "LocationID": (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID)

            })
        };


        const result = [];



        return await fetch(config.get('rest_API_productregistry') + '/productid', requestOptions)
            .then(response => response.json())
            .then(async data => {



                const listofproducts = await Promise.all(data.Result.map(async product => await this.ProductsByOffer(product, data)))


                result.push({ ...product, "productdetails": data.Result[0], "offerdetails": listofproducts[0] })

                //console.log(result[0])
                //this.setState({ offerproducts: listofproducts, ploaded: true })

                // this.setState({ offerproducts: listofproducts, ploaded: true })

                return result[0];
            });

    }


    async componentDidMount() {

        axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
            .then(response => {
                this.setState({
                    Name: response.data.Name,
                    AddressLine1: response.data.AddressLine1,
                    AddressLine2: response.data.AddressLine2,
                    City: response.data.City,
                    Email: response.data.Email,
                    StateProvinceRegion: response.data.StateProvinceRegion,
                    PostZipCode: response.data.PostZipCode,
                    Phone: response.data.Phone,
                    OpenningHours: response.data.OpenningHours,
                    Country: response.data.Country,
                })
            })
            .catch(function (error) {
                console.log(error);
            })

        this.setState({ address: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses });
        this.setState({ button: 'PAY NOW' });
        const cachedProduct = localStorage.getItem('cart');

        if (cachedProduct) {
            const listofproducts = await Promise.all(JSON.parse(cachedProduct).map(async product => await this.ProductsOffer(product)))
            this.setState({ basketitem: listofproducts, ploaded: true });
            //  this.onCartLoadItems(JSON.parse(cachedProduct));
            localStorage.setItem('basketItems', listofproducts)
        } else {
            const myArray = [];
            this.setState({ basketitem: myArray });
            localStorage.setItem('cart', JSON.stringify(myArray));
            //  this.onCartLoadItems(myArray);
        }
        document.title = "Checkout - Payments | " + config.get('companyPageTitle')
        sessionStorage.setItem('ordernumber', this.state.ordernumber);
        // address.AddressType == "Billing"
        let billingAddresses = JSON.parse(localStorage.getItem('fullUserDetails')).Addresses
        if (billingAddresses.filter(address => address.AddressType === "Billing").length > 0) {
            this.setState({
                value: billingAddresses.findIndex(address => address.AddressType === "Billing"),
            });
        }
    }

    onChange = e => {
        //console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };


    onCardChange = e => {
        //   console.log('radio checked', e.target.value);
        this.setState({
            cardvalue: e.target.value,
        });
    };


    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ region: val });
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };


    handleCancel = () => {
        this.setState({
            visible: false
        });

    };
    render() {

        const { button, value, cardvalue, visible, name, number, expiry, cvc, focused, issuer, country, region } = this.state;
        this.state.total = (this.state.basketitem.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.ProductQty * currentValue.ProductPrice * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
        this.state.cartqty = (this.state.basketitem.reduce((accumulator, currentqtyValue) => accumulator + parseFloat(currentqtyValue.ProductQty), 0))
        this.state.totalvat = JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? ((this.state.basketitem.reduce((accumulator, currentValue) => accumulator + parseFloat((currentValue.ProductPrice * currentValue.ProductQty * parseFloat(localStorage.getItem('ecurrencyratio'))) * (currentValue.VatRate / (1 + currentValue.VatRate))), 0)).toFixed(2)) : ((this.state.basketitem.reduce((accumulator, currentValue) => accumulator + parseFloat((currentValue.ProductPrice * currentValue.ProductQty * parseInt(localStorage.getItem('ecurrencyratio'))) * (currentValue.VatRate)), 0)).toFixed(2))
        this.state.offer = (this.state.basketitem.reduce((accumulator, currentofferValue) => accumulator + parseFloat(currentofferValue.OfferAmount * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)

        const { basketitem, total, cartqty, offer } = this.state;

        //   console.log((this.state.basketitem.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.ProductQty * currentValue.ProductPrice * localStorage.getItem('ecurrencyratio')), 0)))
        const onCreateAddress = values => {


            this.setState({ adisabled: true });
            this.refs.addressbtn.setAttribute("disabled", "disabled");

            //console.log(values.AddressType);
            var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));

            //   console.log(values.AddressType);
            const index = JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.length;

            fullUserDetails["Addresses"].push({ "AddressType": values.AddressType, "AddressLine1": values.Address, "AddressLine2": values.Address2, "City": values.City, "StateProvinceRegion": values.StateProvinceRegion, "PostZipCode": values.PostZipCode, "Country": values.Country, "Phone": values.Phone, "Fax": values.Fax, "Email": values.Email })

            localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));

            this.setState({ address: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses });

            //   console.log(fullUserDetails);


            const timestamp = Math.floor(Date.now() / 1000) + 1000;

            var payload = {
                "aud": config.get('CustjwtAud'),
                "exp": timestamp,
                "iss": config.get('CustjwtIss'),
                "usertoken": sessionStorage.getItem('AccessToken')
            };


            var signOptions = {
                "algorithm": "RS256"
            };

            var privateKey = config.get('LoginprivateKey');
            var token = jwt.sign(payload, privateKey, signOptions);
            // console.log(token);
            this.setState({ visible: false });
            Modal.success({
                title: 'Address Details',
                content: 'Address has been added, Please choose a address',
                onOk() {



                },
            });

            const requestUserDtlsOptions = {
                method: 'PUT',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ` + token,
                },
                body: JSON.stringify(fullUserDetails)
            };

            //   console.log(fullUserDetails)
            fetch(config.get('API_URL_cust') + 'update/' + config.get('OrganisationID') + '/' + fullUserDetails.UserID, requestUserDtlsOptions)
                .then(response => response.json())
                .then(function (data) {
                    // console.log(data.Result);

                    message.loading({ content: 'Saving...', key, marginTop: '20vh' });
                    setTimeout(() => {
                        message.success({ content: 'Address has been added, Please choose a address', key, duration: 2, marginTop: '20vh' });
                    }, 800);

                });
        };

        //console.log(localStorage.getItem('cart'))
        if (localStorage.getItem('cart') == '[]') {

            Modal.error({
                title: 'Oops! Empty basket.',
                content: 'Please add some items to your cart and try to checkout.',
                onOk() { window.location.href = "/account/" },
            });
            return <Redirect to='/account/' />;
        }

        return (
            <div style={{ backgroundColor: "#f5f5f5" }}>
                {
                    sessionStorage.getItem('deliveryaddress') == -1 ? sessionStorage.setItem('deliveryaddress', 0) : ''
                }
                {
                    console.log(this.state.basketitem)
                }
                <Row>
                    <Col sm={12} >
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="payment/">
                                Payment
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col sm={1} >
                    </Col>
                    <Col sm={9}>
                        <div className="fade alert alert-light show">

                            <Steps current={1}>
                                <Step title={sessionStorage.getItem('deliverymethod').toUpperCase()} description="Delivery/Pickup." />
                                <Step title="PAYMENT / PLACE ORDER" description="Payment details." />
                                <Step title="THANK YOU" description="For choosing us." />
                            </Steps>

                        </div>
                    </Col>
                    <Col sm={2} >
                    </Col>

                    <Col sm={1}>
                    </Col>
                    <Col sm={7}>

                        {

                            JSON.parse(localStorage.getItem('setting'))[0].SubOrderTypes.map(st => (

                                st.SubOrderTypeName == sessionStorage.getItem('deliverymethod') ? (
                                    <div className="fade alert alert-light show">
                                        <Tabs defaultActiveKey="1"
                                            onChange={callback}
                                        >

                                            {
                                                st.AvailablePaymentTypes.indexOf('Card') >= 0 && st.IsActive == true ? (


                                                    <TabPane
                                                        tab={
                                                            <span>
                                                                <b><CreditCardOutlined /> Pay by Card</b>
                                                            </span>
                                                        }
                                                        key="1"
                                                    >



                                                        <Col sm={12}>&nbsp;</Col>

                                                        <Row>
                                                            <Col sm={5}>
                                                                <Card
                                                                    number={number}
                                                                    name={name}
                                                                    expiry={expiry}
                                                                    cvc={cvc}
                                                                    focused={focused}
                                                                    callback={this.handleCallback}
                                                                />
                                                            </Col>
                                                            <Col sm={7}>


                                                                <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
                                                                    <div className="form-group">
                                                                        <input
                                                                            type="tel"
                                                                            name="number"
                                                                            className="form-control"
                                                                            placeholder="Card Number"
                                                                            pattern="[\d| ]{16,22}"
                                                                            required
                                                                            onChange={this.handleInputChange}
                                                                            onFocus={this.handleInputFocus}
                                                                        />

                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input
                                                                            type="text"
                                                                            name="name"
                                                                            className="form-control"
                                                                            placeholder="Name"
                                                                            required
                                                                            onChange={this.handleInputChange}
                                                                            onFocus={this.handleInputFocus}
                                                                        />
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <input
                                                                                type="tel"
                                                                                name="expiry"
                                                                                className="form-control"
                                                                                placeholder="Valid until"
                                                                                pattern="\d\d/\d\d"
                                                                                required
                                                                                onChange={this.handleInputChange}
                                                                                onFocus={this.handleInputFocus}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <input
                                                                                type="tel"
                                                                                name="cvc"
                                                                                className="form-control"
                                                                                placeholder="CVC"
                                                                                pattern="\d{3,4}"
                                                                                required
                                                                                onChange={this.handleInputChange}
                                                                                onFocus={this.handleInputFocus}
                                                                            /><Popover content={content} className="homeMessage" title="CVC Details">
                                                                                <u>What is this?</u>
                                                                            </Popover>
                                                                        </div>
                                                                    </div>
                                                                    <input type="hidden" name="issuer" value={issuer} />
                                                                    <Col sm={12}>&nbsp;</Col>


                                                                    <div className="form-actions">

                                                                        <Button type="primary" htmlType="submit" ref="btn" block danger >
                                                                            {this.state.button}
                                                                        </Button>

                                                                    </div>
                                                                </form>

                                                            </Col>

                                                        </Row>

                                                        <Row>
                                                            <Col>

                                                                <div className="fade alert alert-light show">
                                                                    <Row className="justify-content-md-center">
                                                                        <Col sm={12}>
                                                                            <h5>BILLING ADDRESS </h5>
                                                                        </Col>

                                                                        <Col sm={12}><hr /></Col>
                                                                        <Col sm={12}>


                                                                            <Button color="primary" onClick={this.showModal.bind(this, 100)} style={{ marginBottom: '1rem', float: 'right' }}>
                                                                                Create a new address
                                                                            </Button>

                                                                            <Modal
                                                                                visible={visible}
                                                                                title="NEW DELIVERY ADDRESS INFORMATION"
                                                                                width="1000px"
                                                                                onCancel={this.handleCancel}
                                                                                footer={[
                                                                                    <Button key="back" onClick={this.handleCancel}>
                                                                                        Return
                                                                                    </Button>,

                                                                                ]}
                                                                            >

                                                                                <>

                                                                                    <Form layout="vertical"
                                                                                        name="register"
                                                                                        ref={this.formRef}
                                                                                        onFinish={onCreateAddress} >
                                                                                        <Form.Item
                                                                                            name="SelectAddress"
                                                                                            extra="Please click below add new button to save the address"
                                                                                            label="Select an existing address to add"
                                                                                        >

                                                                                            <Select
                                                                                                onChange={(val) => this.selectAddress(this, val)}
                                                                                            >
                                                                                                {

                                                                                                    JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                                                                        return (
                                                                                                            <>

                                                                                                                {
                                                                                                                    <>

                                                                                                                        {address.AddressType != "Billing" ? (

                                                                                                                            <Option value={index}>
                                                                                                                                {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''} {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''} {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                                                                {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                                                                {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                                                                {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                                                                {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                                                                {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                                                                {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                                                            </Option>

                                                                                                                        ) : ''
                                                                                                                        }
                                                                                                                    </>


                                                                                                                }
                                                                                                            </>
                                                                                                        );
                                                                                                    })
                                                                                                }


                                                                                            </Select>

                                                                                        </Form.Item>
                                                                                        <Form.Item
                                                                                            name="AddressType"
                                                                                            label="Address Type"
                                                                                            initialValue="Billing"
                                                                                            rules={[{ required: true, message: 'Please enter a address type' }]}
                                                                                        >

                                                                                            <Select value="Billing"  >
                                                                                                <Option value="Billing">Billing</Option>

                                                                                            </Select>

                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="Address"
                                                                                            label="Address"
                                                                                            rules={[{ required: true, message: 'Please enter address' }]}
                                                                                        >
                                                                                            <Input placeholder="Please enter address" />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="Address2"
                                                                                            label="Address 2"
                                                                                            rules={[{ required: false, message: 'Please enter address 2' }]}
                                                                                        >
                                                                                            <Input placeholder="Please enter address 2" />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="City"
                                                                                            label="City"
                                                                                            rules={[{ required: true, message: 'Please enter city' }]}
                                                                                        >
                                                                                            <Input placeholder="Please enter city" />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="StateProvinceRegion"
                                                                                            label="State / Province / Region"
                                                                                            rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                                                                        >
                                                                                            <RegionDropdown
                                                                                                country={country}
                                                                                                countryValueType="short"
                                                                                                value={region}
                                                                                                classes="ant-input"
                                                                                                onChange={(val) => this.selectRegion(val)} />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="PostZipCode"
                                                                                            label="Post / Zip Code"
                                                                                            rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                                                                        >
                                                                                            <Input placeholder="Please enter post / zip code" />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="Country"
                                                                                            label="Country"
                                                                                            rules={[{ required: true, message: 'Please enter country' }]}
                                                                                        >
                                                                                            <CountryDropdown
                                                                                                value={country}
                                                                                                valueType="short"
                                                                                                classes="ant-input"
                                                                                                onChange={(val) => this.selectCountry(val)} />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="Phone"
                                                                                            label="Phone"
                                                                                            rules={[{ required: true, message: 'Please enter valid phone' }]}
                                                                                        >
                                                                                            <Input placeholder="Please enter phone" />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="Fax"
                                                                                            label="Fax"
                                                                                            rules={[{ message: 'Please enter valid fax' }]}
                                                                                        >
                                                                                            <Input placeholder="Please enter fax" />
                                                                                        </Form.Item>

                                                                                        <Form.Item
                                                                                            name="Email"
                                                                                            label="Email"
                                                                                            rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                                                                        >
                                                                                            <Input placeholder="Please enter email" />
                                                                                        </Form.Item>

                                                                                        <Form.Item  >


                                                                                            <Button type="primary" htmlType="submit" ref="addressbtn" danger >
                                                                                                {this.state.adisabled ? 'Please wait...' : 'Add New Address'}
                                                                                            </Button>


                                                                                        </Form.Item>
                                                                                    </Form>

                                                                                </>

                                                                            </Modal>
                                                                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                                                                {this.state.newadd}
                                                                                {

                                                                                    this.state.address.map((address, index) => {
                                                                                        return (
                                                                                            <>

                                                                                                {address.AddressType == "Billing" ? (
                                                                                                    <>
                                                                                                        <Radio value={index}>
                                                                                                            {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                                                                            {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                                                                            {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                                                                            {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                                            {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                                            {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                                            {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                                            {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                                            {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                                            {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                                        </Radio>
                                                                                                        <hr />
                                                                                                    </>
                                                                                                ) : ('')

                                                                                                }
                                                                                            </>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Radio.Group>


                                                                        </Col>


                                                                    </Row>

                                                                </div>






                                                            </Col>

                                                        </Row>


                                                    </TabPane>
                                                ) : ('')

                                            }


                                            {
                                                JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails ? (

                                                    <TabPane
                                                        tab={
                                                            <span>
                                                                <SaveOutlined /> Pay by saved card
                                                            </span>
                                                        }
                                                        key="3"
                                                    >


                                                        {

                                                            JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails ? (
                                                                <>

                                                                    <Row>
                                                                        <Col sm={12}>
                                                                            <Radio.Group onChange={this.onCardChange} value={cardvalue}>
                                                                                {
                                                                                    JSON.parse(localStorage.getItem('fullUserDetails')).PaymentCardDetails.map((paymentdtl, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Radio value={index}>
                                                                                                    {paymentdtl.CardHolderName}<br />
                                                                                                    {paymentdtl.CardType}<br />
                                                                                                    {paymentdtl.CardNumber}<br />
                                                                                                    {paymentdtl.ExpDate}<br />
                                                                                                </Radio>
                                                                                                <hr />

                                                                                            </>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Radio.Group>
                                                                        </Col>
                                                                        <Col sm={12}>
                                                                            <Col sm={12}>
                                                                                <h5>BILLING ADDRESS </h5>
                                                                            </Col>
                                                                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                                                                {this.state.newadd}
                                                                                {

                                                                                    this.state.address.map((address, index) => {
                                                                                        return (
                                                                                            <>

                                                                                                {address.AddressType == "Billing" ? (
                                                                                                    <>
                                                                                                        <Radio value={index}>
                                                                                                            {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                                                                            {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                                                                            {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                                                                            {address.City ? (<>{address.City}<br /></>) : ''}
                                                                                                            {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                                                                            {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                                                                            {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                                                                            {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                                                                            {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                                                                            {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}
                                                                                                        </Radio>
                                                                                                        <hr />
                                                                                                    </>
                                                                                                ) : ('')

                                                                                                }
                                                                                            </>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Radio.Group>
                                                                        </Col>
                                                                    </Row>

                                                                    <Form ref={this.formRef} name="xcontrol-ref" onFinish={this.handleSavedCardSubmit}>
                                                                        <Row>
                                                                            <Col sm={3}>
                                                                                CVC Number
                                                                            </Col>
                                                                            <Col sm={4}>

                                                                                <Form.Item
                                                                                    type="tel"
                                                                                    name="scvc"
                                                                                    className="form-control"
                                                                                    placeholder="CVC"
                                                                                    pattern="\d{3,4}"
                                                                                    onChange={this.handleInputChange}
                                                                                    onFocus={this.handleInputFocus}
                                                                                    rules={[{ required: true }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>


                                                                            </Col>
                                                                            <Col sm={2}>
                                                                                <Popover content={content} className="homeMessage" title="CVC Details">
                                                                                    <u>What is this?</u>
                                                                                </Popover>
                                                                            </Col>


                                                                            <Col sm={3}>


                                                                                <div className="form-actions">

                                                                                    <Button type="primary" htmlType="submit" ref="btn" block danger >
                                                                                        {this.state.button}
                                                                                    </Button>

                                                                                </div>

                                                                            </Col>

                                                                        </Row>
                                                                    </Form>

                                                                </>

                                                            ) : (
                                                                <span>

                                                                </span>
                                                            )}


                                                    </TabPane>

                                                ) : ('')

                                            }

                                            {
                                                st.AvailablePaymentTypes.indexOf('Cash') >= 0 && st.IsActive == true ? (

                                                    <TabPane
                                                        tab={
                                                            <span>
                                                                <MoneyCollectOutlined /> Pay by Cash
                                                            </span>
                                                        }
                                                        key="2"
                                                    >
                                                        <p>You need to pay before you Collect the item from our stores.</p>
                                                        <Row>
                                                            <Col sm={4}>

                                                            </Col> <Col sm={4}>


                                                                <form ref={c => (this.form = c)} onSubmit={this.handleCashSubmit}>


                                                                    <div className="form-actions">

                                                                        <Button type="primary" htmlType="submit" ref="btn" block danger >
                                                                            {this.state.disabled ? 'Please wait...' : 'ORDER NOW'}
                                                                        </Button>

                                                                    </div>
                                                                </form>
                                                            </Col>
                                                            <Col sm={4}>

                                                            </Col>
                                                        </Row>
                                                    </TabPane>

                                                ) : ('')

                                            }


                                        </Tabs>

                                    </div>
                                ) : ('')
                            )
                            )
                        }

                    </Col>
                    <Col sm={4}>

                        {this.state.basketitem.length ? (
                            <div className="fade alert alert-light show">
                                <Row className="justify-content-md-center">
                                    <Col sm={12}>
                                        <h5>ORDER SUMMARY </h5>
                                    </Col>
                                    <Col sm={12}><hr /></Col>
                                    <Col sm={12}>
                                        <div className="fade alert alert-light show">
                                            {this.state.basketitem.length ? (
                                                this.state.basketitem.map(item => (
                                                    <Row>

                                                        <Col md={3}>{
                                                            item.ProductImage !== '' ?
                                                                <img src={item.ProductImage} width="80" />
                                                                : <img src="/images/noimage.jpg" alt="." width="80" />
                                                        }</Col>
                                                        <Col xs={7} md={9}><b>{item.ProductName}</b>
                                                            <Row>
                                                                <Col md={6}>Qty: {item.ProductQty}</Col>
                                                                <Col md={6} className="text-right"><b>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</b></Col>
                                                            </Row>
                                                        </Col>

                                                        <Col md={12}><hr></hr></Col>



                                                    </Row>
                                                )
                                                )
                                            ) : (
                                                <span>
                                                    <h6>Your cart is empty</h6>
                                                    <p>Find hundred of quality products at bargain prices everyday!</p>
                                                </span>
                                            )}

                                        </div>

                                    </Col>

                                    <Col sm={12}>
                                        <h5>Price Details</h5>
                                    </Col>
                                    <Col sm={9}>

                                        Subtotal:
                                    </Col>
                                    <Col sm={3}>
                                        {localStorage.getItem('ecurrencyCode')} {this.state.total}
                                    </Col>

                                    {
                                        sessionStorage.getItem('deliverytype') == 1 ? (
                                            <>
                                                <Col sm={9} xs={8}>

                                                    Click and Collect:
                                                </Col>
                                                <Col sm={3} xs={4}>
                                                    <b>FREE</b>
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                <Col sm={9}>

                                                    Delivery:
                                                </Col>
                                                <Col sm={3}>
                                                    {localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}
                                                </Col>
                                            </>
                                        )
                                    }


                                    {this.state.offer != 0 ? (<>
                                        <Col sm={9} >

                                            <p class="text-danger">Offer Discount:</p>
                                        </Col>
                                        <Col sm={3}>
                                            <p class="text-danger">
                                                {localStorage.getItem('ecurrencyCode')} -{this.state.offer}</p>

                                        </Col></>) : ''
                                    }



                                    <Col sm={12}><hr /></Col>
                                    <Col sm={9}>

                                        <b>TOTAL:</b>
                                    </Col>
                                    <Col sm={3}>
                                        <b>{localStorage.getItem('ecurrencyCode')} {(parseFloat(this.state.total) - this.state.offer + parseFloat(sessionStorage.getItem('deliverycost'))).toFixed(2)}</b>
                                    </Col>
                                    <Col sm={12}>&nbsp;</Col>
                                    <Col sm={2}>

                                    </Col>


                                </Row>

                            </div>

                        ) : (
                            <span>

                            </span>
                        )}





                        {sessionStorage.getItem('deliverytype') == 0 ? (
                            <div className="fade alert alert-light show">
                                <Row className="justify-content-md-center">
                                    <Col sm={12}>
                                        <h5>DELIVERY ADDRESS </h5>
                                    </Col>

                                    <Col sm={12}><hr /></Col>
                                    <Col sm={12}>


                                        {

                                            JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.map((address, index) => {
                                                if (index == sessionStorage.getItem('deliveryaddress')) {
                                                    return (
                                                        <>
                                                            {address.AddressType ? (<><b> {address.AddressType} </b><br /></>) : ''}
                                                            {address.AddressLine1 ? (<>{address.AddressLine1}<br /></>) : ''}
                                                            {address.AddressLine2 ? (<>{address.AddressLine2}<br /></>) : ''}
                                                            {address.City ? (<>{address.City}<br /></>) : ''}
                                                            {address.StateProvinceRegion ? (<>{address.StateProvinceRegion}&nbsp;</>) : ''}
                                                            {address.PostZipCode ? (<>{address.PostZipCode}<br /></>) : ''}
                                                            {address.Country ? (<>{address.Country}<br /> </>) : ''}
                                                            {address.Email ? (<><b>Email: </b>{address.Email}<br /></>) : ''}
                                                            {address.Phone ? (<><b>Phone: </b>{address.Phone}<br /></>) : ''}
                                                            {address.Fax ? (<><b>Fax: </b>{address.Fax}<br /></>) : ''}


                                                            <hr />

                                                        </>
                                                    );
                                                }
                                            })
                                        }

                                    </Col>


                                </Row>

                            </div>


                        ) : (
                            <span>

                            </span>
                        )}

                    </Col>



                </Row>


            </div>
        )
    }
}

export default Application;
