import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import config from 'react-global-configuration';
const photos = [];
 
function App(props) {

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  const ItemData = props.params.ItemData.split(',')

  let photos =[]
  
  ItemData.map((img, index) => {
    let dataArr = [];
    dataArr["src"] = 'https://cdn.neurolage.com/retailpacx/'+config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase()+'/'+img.split(':')[0]
    dataArr["width"] = img.split(':')[1].split("x")[0]
    dataArr["height"] = img.split(':')[1].split("x")[1]

    photos.push(dataArr)
  })

 console.log(photos)
 // const photos = dataArr
  const [items, setItems] = useState(photos);
 

  return (
    <div>
    <Gallery photos={photos}   />
 
  </div>
  );
}

export default App;
