import React from 'react';
import { Radio, Skeleton } from 'antd';
const CartApparel = (props) => (

  props.params.ploaded ? (
    <>

      <div className="container checkout">


        <div class="header-wrapper"><h2>Shopping Cart</h2></div>
        {
          props.params.basketitem.length ? (
            <>
              <p className='cart-item-count'>Cart Items <span className='badge cart-badge'>{props.params.cartqty}</span></p>

              <div className="row">

                <div className="col-xl-4 mob-order">

                  <div className='cart-details'>
                    <div className="cart-sub-total">

                      <div className="align-option">
                        <div className="st-item st-label">Sub Total</div>
                        <div className="st-item st-label">
                          <div className="align-option">
                            <span>{localStorage.getItem('ecurrencyCode')} {props.params.total}</span>
                          </div>
                        </div>
                      </div>
                     


                      {props.params.value == 0 ? <>

                        <div className="align-option">
                        <div className="st-item st-label">Delivery Charge</div>
                        <div className="st-item st-label">
                          <div className="align-option">
                            <span>{localStorage.getItem('ecurrencyCode')} {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}</span>
                          </div>
                        </div>
                      </div>
 
</> : ''}



                      {props.params.offer != 0 ? (<>
                        <div className="align-option">
                          <div className="st-item st-label">Discount</div>
                          <div className="st-item st-label">
                            <div className="align-option">
                              <span>- {localStorage.getItem('ecurrencyCode')} -{props.params.offer}</span>
                            </div>
                          </div>
                        </div>

                      </>) : ''
                      }

                      <div className="align-option total-to-pay">
                        <div className="st-item st-label">Total to Pay</div>
                        <div className="st-item st-label">
                          <div className="align-option">
                            <span>{localStorage.getItem('ecurrencyCode')} {(parseFloat(props.params.total) - props.params.offer + (props.params.value == 0 ? parseFloat(sessionStorage.getItem('deliverycost')) : 0)).toFixed(2)}</span>
                          </div>

                        </div>
                      </div>

                      <Radio.Group onChange={props.onChange} value={props.params.value}>

                    {
                      JSON.parse(localStorage.getItem('setting'))[0].SubOrderTypes.map(st => (
                        <>

                          {
                            st.SubOrderTypeName == 'Click and Collect' && st.IsActive == true ? (
                              <>

                                <div className="form-check">
                                  <Radio value={1} checked > Click & Collect</Radio>
                                  <label className="form-check-label">FREE</label>
                                </div>

                              </>

                            ) : ''
                          }


                          {
                            st.SubOrderTypeName == 'Delivery' && st.IsActive == true ? (
                              <>

                                <div className="form-check mr10">
                                  <Radio value={0}>Delivery</Radio>
                                  {
                                    props.params.deliveryscheme.map(ds => {

                                      //  console.log(ds)

                                      if (ds.CurrencyCode == localStorage.getItem('ecurrencythreeCode')) {
                                        const Calculation = ds.Calculation;

                                        if (Calculation == "ByInvoiceAmount") {

                                          const vat = ds.ByInvoiceAmount;
                                          // console.log(ds.ByInvoiceAmount)
                                          // console.log(a)

                                          {
                                            [vat].map((e2, i2) => {



                                              if (props.params.total <= 50 && [vat][i2][50]) {
                                                sessionStorage.setItem('deliverycost', [vat][i2][50]);

                                              } else if (props.params.total <= 100 && [vat][i2][100]) {
                                                sessionStorage.setItem('deliverycost', [vat][i2][100]);

                                              } else if (props.params.total <= 150 && [vat][i2][150]) {
                                                sessionStorage.setItem('deliverycost', [vat][i2][150]);

                                              } else if (props.params.total <= 750 && [vat][i2][750]) {
                                                sessionStorage.setItem('deliverycost', [vat][i2][750]);

                                              } else {
                                                sessionStorage.setItem('deliverycost', [vat][i2]['Above']);

                                              }


                                            })
                                          }


                                          sessionStorage.setItem('MinimumInvoiceOrder', ds.MinimumInvoiceOrder);


                                        } else {
                                          //to do other methods
                                        }

                                      } else {
                                        //to do other countries 
                                      }
                                    })
                                  }


                                  {localStorage.getItem('ecurrencyCode')}  {parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2)}
                                </div>

                              </>
                            ) : ''
                          }


                        </>
                      )
                      )
                    }




                  </Radio.Group>
                    </div>
                  </div>

                </div>


                <div className="col-xl-8">

                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Product</th>
                          <th scope="col">Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          props.params.basketitem.map(item => (
                            <tr>
                              <th scope="row">
                                <div className='prd-item-wrap'>
                                  {
                                    item.ProductImage !== '' ?
                                      <img src={item.ProductImage} width="80" />
                                      : <img src="/images/noimage.jpg" alt="." width="80" />
                                  }
                                  <span className='prd-item-name'><a href={'/product/' + item.ProductName.replace(/ /g, '-').toLowerCase() + '/' + item.ProductID} className="product-name">{item.ProductName}</a></span>
                                </div>
                              </th>
                              <td>£5.35</td>
                              <td>
                                <div className='select-qty prd-count-inner'>
                                  <div className='qty-wrapper'>

                                  <span className="qty-sub">
                                    <button  onClick={props.onQtyReduce.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>
                                      <i className="fas fa-minus" />
                                    </button>
                                  </span>
 
                                    <span className="qty-no a-fix">
                                      <input id={'prodqty' + item.ProductID} min="0" name="form-0-quantity" className='a-fix' value={item.ProductQty} type="number" placeholder={1} defaultValue={1} />
                                    </span>
                                    <span className="qty-add icon-add a-fix">
                                      
                                      <button onClick={props.onQtyIncrese.bind(this, item.ProductID, item.ProductName, item, item.productdetails.MaxOnlineOrderQty, item.productdetails.ProductCategoryID, item.productdetails.offerdetails ? (item.offerdetails[0].OfferProducts.map((prt) => prt.ProductID).join(',')) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.PromotionQty) : '', item.offerdetails.Offer ? (item.offerdetails[0].Offer.Price) : '', item.ProductPrice)}>
                                      <i className="fas fa-plus" />
                                    </button>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</td>
                            </tr>
                          )
                          )
                        }


                      </tbody>
                    </table>
                  </div>



                </div>

              </div>
            </>
          ) : (<>
            <div className="show-cart-count">
              <h6>Your cart is empty</h6>
              <p>Find hundred of quality products at bargain prices everyday!</p>
            </div>

          </>)
        }

        <div className='row'>
          <div className='col'>
            <div className="cart-buttons">
              <a href="/products" className="crt-btn-cont">continue shopping</a>
              <a onClick={props.onProceedCheckout.bind(this)} className="crt-btn-checkout">Checkout now</a>
            </div>
          </div>
        </div>


      </div>

 

    </>
  ) : (
    <>
      <Skeleton active avatar paragraph={{ rows: 4 }} />

    </>

  )

);
export default CartApparel;
