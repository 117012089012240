
import React, { useLayoutEffect, useState } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import * as _ from 'lodash';
import LayoutInclude from "./default/layout-include";
import axios from 'axios';
import config from 'react-global-configuration';
import { Form, Button, Row, Col } from 'react-bootstrap';
import jwt from 'jsonwebtoken';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function extractTranslateFromTransform(transform) {
  let translateValue = null;
  let translate = transform.match(/translate\(.*\)/)?.[0];
  if (translate) {
    translateValue = {};
    if (translate.indexOf(',') !== -1) {
      translateValue.x = parseFloat(translate.substring(translate.indexOf('(') + 1, translate.indexOf(',')));
      translateValue.y = parseFloat(translate.substring(translate.indexOf(',') + 1));
    } else {
      translateValue.x = translateValue.y = parseFloat(translate.substring(translate.indexOf('(') + 1));
    }
  }
  return translateValue;
}

export default class ResponsiveLocalStorageLayout extends React.PureComponent {


  static defaultProps = {
    className: "layout",
    margin: [5, 0],
    isDraggable: false,
    isResizable: false,
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 2 },
    rowHeight: 39
  };

  constructor(props) {
    super(props);

    this.state = {
      id: '', pages: [], menus: [], layout: [], widgets: [],
      items: [], windowHeight: 0,
      windowWidth: 0,
      newCounter: 0
    };



  }
  onBreakpointChange = () => {

  }

  resize = () => {
    if (window.innerWidth < 1682 && window.innerWidth > 1024) {
      console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");

      for (var i = 0; i < ulElems.length; i++) {
        console.log(ulElems[i].clientHeight / 39);
        ulElems[i].style.height = "auto";

        if (adjest == 0) {
          if (extractTranslateFromTransform(ulElems[i].style.transform).y > 0) {
            adjest = adjest + extractTranslateFromTransform(ulElems[i].style.transform).y;
            var cvx = extractTranslateFromTransform(ulElems[i].style.transform).x;
            var cvy = extractTranslateFromTransform(ulElems[i].style.transform).y - 20;
            var xis = "translate(" + cvx + "px, " + cvy + "px)";

            console.log(xis)
            ulElems[i].style.transform = xis;
          }
        }
      }



    }
    if (window.innerWidth <= 1199) {
 
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)
 
      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style=""
          ulElems[i].classList.add('newLayoutList');
          ulElems[i].style.position = "relative"
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove('react-grid-item', 'cssTransforms', 'react-resizable-hide', 'react-resizable');
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
      }, 1000);
    }
    
    //  console.log(window.innerWidth)
  };


  componentDidMount() {
    window.addEventListener('resize', this.resize)
    axios.get(config.get('backendURL') + 'homelayout/' + config.get('companyID'))
      .then(response => {
        //    console.log(response.data)
        if (response.data.length > 0) {
          this.setState({
            layout: response.data.map(function (i, key, list) {
              return {
                i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString()]),
                x: i.x,
                y: i.y,
                w: i.width,
                h: i.height,
                add: i === (list.length - 1)
              };
            }), loading: true
          })
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      })


    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('SalesjwtAud'),
      "exp": timestamp,
      "iss": config.get('SalesjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('SalesprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    axios.get(config.get('API_salesregistry') + 'deliveryscheme/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        this.setState({ deliveryscheme: response.data.Result });
        //  console.log(response.data) 
      })
      .catch((error) => {
        console.log(error);
      })


    if (window.innerWidth < 1681) {
      console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {

        for (var i = 0; i < ulElems.length; i++) {
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            var hh = ulElems[i].style.height
            ulElems[i].getElementsByClassName("w-100")[0].style.height = hh
          }
        }
      }, 1000);
    }


    if (window.innerWidth < 1200) {
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");

      setTimeout(function () {

        for (var i = 0; i < ulElems.length; i++) {
          console.log(ulElems[i].clientHeight / 39);
          console.log(ulElems[i].style.height.replace('px', ''))
          var newh = ulElems[i].style.height.replace('px', '') - 10

          ulElems[i].style.height = newh + "px"
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            ulElems[i].getElementsByClassName("w-100")[0].style.height = newh + "px"
          }
        }
      }, 1000);
    }
    if (window.innerWidth <= 1199) {
 
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)
 
      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style=""
          ulElems[i].classList.add('newLayoutList');
          ulElems[i].style.position = "relative"
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove('react-grid-item', 'cssTransforms', 'react-resizable-hide', 'react-resizable');
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
      }, 1000);
    }

  }

  resetLayout() {
    this.setState({ layouts: {} });
  }



  generateDOM(el) {

    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };
    const i = el.i;
    return (
      <div key={i} data-grid={el}>



        <LayoutInclude params={{ layoutid: JSON.parse(i)[0], width: el.x }} />



      </div>
    );

  }
  render() {
    return (
      <div>
        <Row>
          <Col sm={12}>



            <ResponsiveReactGridLayout
              onBreakpointChange={this.onBreakpointChange}
              breakpoints={{ lg: 1681, md: 1024, sm: 768, xs: 320, xxs: 0 }}
              className="layout"
              {...this.props}
            >
              {_.map(this.state.layout, el => this.generateDOM(el))}
            </ResponsiveReactGridLayout>



          </Col>
        </Row>
      </div>
    );
  }
}



