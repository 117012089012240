import React from 'react'
import './css/spiceway.css'

const CookiesPolicy = () => {
  return (
    <div className='policy'>
      <h1>COOKIE POLICY</h1>
      <p>This Cookie Statement explains how Spiceway Wholesale Limited (collectively
        “Spiceway Wholesale”, "we", "us", and "ours") use cookies and similar technologies
        to recognise you when you visit our websites (<a target="_blank" href="https://spiceway.retailpacx.com/">https://spiceway.retailpacx.com</a>). It explains what these
        technologies are and why we use them, as well as your rights to control our use of
        them.</p>
      <p>By continuing to use our Websites you are deemed to consent to our use of the
        cookies described in this Cookie Policy.</p>
      <h5>WHAT ARE COOKIES</h5>
      <p>
        Cookies are small data files that are placed on your computer or mobile device when
        you visit a website. Cookies are widely used by website owners in order to make
        their websites work, or to work more efficiently, as well as to provide reporting
        information.</p><p>
        Cookies set by the website owner are called "first party cookies". Cookies set by
        parties other than the website owner are called "third party cookies". Third party
        cookies enable third party features or functionality to be provided on or through the
        website (e.g. like advertising, interactive content and analytics). The parties that set
        these third-party cookies can recognise your computer both when it visits the website
        in question and also when it visits certain other websites.
      </p>
      <h5>WHY DO WE USE COOKIES?</h5>
      <p>
        We use first party and third-party cookies for several reasons. Some cookies are
        required for technical reasons in order for our Websites to operate, and we refer to
        these as "essential" or "strictly necessary" cookies. Other cookies also enable us to
        track and target the interests of our users to enhance the experience on our
        Websites. Third parties may serve cookies through our Websites for advertising,
        analytics and other purposes. This is described in more detail below.</p><p></p><p>
        The specific types of first and third-party cookies served through our Websites and
        the purposes they perform are described in the table below (please note that the
        specific cookies served may vary depending on the specific Website you visit):
      </p>
      <table>
        <tr>
          <th>Types of cookie</th>
          <th>Who serves these cookies</th>
          <th>How to refuse</th>
        </tr>
        <tr>
          <td><p>Analytics and customisation cookies: These cookies collect information that is used either in aggregate form to
            help us understand how our Websites are being used or how effective are marketing campaigns are, or to help us customise our Websites for you.</p></td>
          <td>Google Analytics - <a target="_blank" href='http://www.google.com/intl/en/about.html'>http://www.google.com/intl/en/about.html</a></td>
          <td><p>To refuse these cookies, please follow the instructions below under the heading "How can I control cookies?"</p></td>
        </tr>
        <tr>
          <td><p>Advertising cookies: These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the
            same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.</p></td>
          <td>DoubleClick - <a target="_blank" href='https://support.google.com/dfp_premium/answer/2839090?hl=en'>https://support.google.com/dfp_premium/answer/2839090?hl=en</a></td>
          <td><p>To refuse these cookies, please follow the instructions below under the heading "How can I control cookies?"</p></td>
        </tr>
      </table>

      <h5>WHAT ABOUT OTHER TRACKING TECHNOLOGIES, LIKE WEB BEACONS?</h5>
      <p>
        Cookies are not the only way to recognise or track visitors to a website. We may use
        other, similar technologies from time to time, like web beacons (sometimes called
        "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique
        identifier that enable us to recognise when someone has visited our Websites. This
        allows us, for example, to monitor the traffic patterns of users from one page within
        our Websites to another, to deliver or communicate with cookies, to understand
        whether you have come to our Websites from an online advertisement displayed on
        a third-party website, to improve site performance, and to measure the success of email
        marketing campaigns. In many instances, these technologies are reliant on
        cookies to function properly, and so declining cookies will impair their functioning.
      </p>
      <h5>DO YOU SERVE TARGETED ADVERTISING?</h5>
      <p>
        Third parties may serve cookies on your computer or mobile device to serve
        advertising through our Websites. These companies may use information about your
        visits to this and other websites in order to provide relevant advertisements about
        goods and services that you may be interested in. They may also employ technology
        that is used to measure the effectiveness of advertisements. This can be
        accomplished by them using cookies or web beacons to collect information about
        your visits to this and other sites in order to provide relevant advertisements about
        goods and services of potential interest to you. The information collected through this
        process does not enable us or them to identify your name, contact details or other
        personally identifying details unless you choose to provide these.
      </p>
      <h5>HOW CAN I CONTROL COOKIES?</h5>
      <p>
        You have the right to decide whether to accept or reject cookies. You can exercise
        your cookie preferences by clicking on the appropriate opt-out links provided in the
        cookie table above.</p><p>
        You can set or amend your web browser controls to accept or refuse cookies. If you
        choose to reject cookies, you may still use our website though your access to some
        functionality and areas of our website may be restricted. As the means by which
        you can refuse cookies through your web browser controls vary from browser-tobrowser,
        you should visit your browser's help menu for more information.
        For more information on cookie management and blocking cookies for a wide variety
        of browsers, visit <a target="_blank" href='All-About-Cookies'>All About Cookies.</a></p><p>
        In addition, most advertising networks offer you a way to opt out of targeted
        advertising. If you would like to find out more information, please
        visit <a target="_blank" href='http://www.aboutads.info/choices/'>http://www.aboutads.info/choices/ </a> or <a target="_blank" href='http://www.youronlinechoices.com'>http://www.youronlinechoices.com.</a>
      </p>
      <h5>WHERE CAN I GET FURTHER INFORMATION?</h5>
      <p>If you have any questions about our use of cookies or other technologies, please contact us on +44 (0) 208 685 1122</p>
      <h5>UPDATES TO THIS PRIVACY POLICY</h5>
      <p>We may update this Cookie Statement from time to time in order to reflect, for
        example, changes to the cookies we use or for other operational, legal or regulatory
        reasons. Please therefore re-visit this Cookie Statement regularly to stay informed
        about our use of cookies and related technologies.</p><p>
        This policy was last updated on 17th of November 2022</p>
    </div>
  )
}

export default CookiesPolicy