import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';

class App extends React.Component {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = { vimage: [], loaded: false };
  }
  componentDidMount() { 
    this._isMounted = true;
    axios.get(config.get('backendURL')+'homelayout/video/' + this.props.params.ItemData)
    .then(response => {
      //console.log((response.data)[0].img)
      this.setState({
        vtype: (response.data)[0].img.contentType,
        videoName: (response.data)[0].meta_data.filename,
        vimage: (response.data)[0].img.data.toString()
      })
    })
    .catch(function (error) {
      console.log(error);
    })

};

componentWillUnmount() {
  this._isMounted = false;
}
 
  render() {
      return (
        <>


{(() => {

switch (config.get('Theme')) {
  case 'Apparel':
    return (
        
  <div className={this.props.params.className} >
  <div class="containerz">
    
  <video autostart autoPlay loop muted="muted" poster="" src={'https://cdn.neurolage.com/retailpacx/'+config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase()+'/'+this.state.videoName} style={{width: '100%'}}></video> 
    <div class="overlayz">
      <h1>{this.props.params.ItemHeading}</h1>
      <h3>{this.props.params.Item}</h3>
    </div>
</div></div>)  
  default:
    return ( 
      <>
      {/* Video */}
      <div className="video-responsive">
        <video autoPlay="autoplay" loop="loop" src={'https://cdn.neurolage.com/retailpacx/'+config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase()+'/'+this.state.videoName}  muted="muted" width="100%" height="auto">
          <source src={'https://cdn.neurolage.com/retailpacx/'+config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase()+'/'+this.state.videoName} type="video/mp4" />
        </video>
      </div>
      {/* /Video */}
</>
)
}

})()}

      </>
      )
  }
}

export default App;
