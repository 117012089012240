import React from 'react';

import { Email, Container, Row, Column, Header, FullWidth, Footer, Text, Link, PostonentsProvider } from 'postonents';

const ContactEmail = ({ maildata }) => {

    const { companyLogoCDN, companyPageTitle, contact, SenderEmail } = maildata;

    return (
        <PostonentsProvider theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
            <Email
                title={`Contact Details`}
                headLinks={[
                    {
                        type: 'link',
                        props: {
                            rel: 'stylesheet',
                            href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                        },
                    },
                ]}>

                <Container alignment="center" style={{ backgroundColor: '#FFFFFF', padding: "20px" }}>
                    <Row>
                        <Column small={12}>
                            <Header style={{ backgroundColor: '#FFFFFF', padding: 5 }}
                                logo={companyLogoCDN}
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Hello Admin,</Text>
                        </Column>
                    </Row>

                    <Row>

                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text> {companyPageTitle} recently received a contact details via the web.</Text>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={6}>
                            <Text>Name:</Text>
                        </Column>
                        <Column small={6}>
                            <Text>{contact.name}</Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={6}>
                            <Text>Email:</Text>
                        </Column>
                        <Column small={6}>
                            <Text>{contact.email}</Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={6}>
                            <Text>Subject:</Text>
                        </Column>
                        <Column small={6}>
                            <Text>{contact.subject}</Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={6}>
                            <Text>Message:</Text>
                        </Column>
                        <Column small={6}>
                            <Text>{contact.message}</Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={12} style={{ textAlign: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 300 }}>
                            Copyright ©  {" 2019-"} {new Date().getFullYear()} {companyPageTitle}, all rights reserved
                            </Column>
                    </Row>
                </Container>
            </Email>
        </PostonentsProvider>
    );
};

export default ContactEmail;