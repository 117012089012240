import React, { useEffect, useState } from 'react'
import moment from 'moment';
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import { renderEmail } from 'postonents';
import axios from 'axios';
import OrderEmail from '../email/OrderEmail';



const PaymentSuccess = (props) => {
    const [companyDetails, setCompanyDetails] = useState({})
    const searchParams = new URLSearchParams(props.location.search)
    const orderKey = searchParams.get("orderKey")
    const paymentStatus = searchParams.get("paymentStatus")
    const paymentAmount = searchParams.get("paymentAmount") / 100
    const paymentCurrency = searchParams.get("paymentCurrency")
    const paymentReference = searchParams.get("mac2")
    const deliveryMethod = searchParams.get("dm")
    const custEmail = searchParams.get("email")
    const deliveryCost = searchParams.get("dc")
    const orderNumber = new Date().valueOf().toString()


    const postLogData = (status) => {
        const formlogs = {
            CloudCustomerID: sessionStorage.getItem('UserID'),
            OriginatedOrganizationID: sessionStorage.getItem('OriginatedOrganizationID'),
            CartReference: orderNumber,
            Logs: status,
        }

        const orderlogoptions = {
            url: config.get('backendURL') + 'orders/logs/add',
            method: 'POST',
            data: formlogs
        };

        axios(orderlogoptions)
            .then(response => {

            }).catch(err => {

            })
    }
    const fetchCompanyDetails = () => {
        axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
            .then(response => {
                setCompanyDetails({
                    Name: response.data.Name,
                    AddressLine1: response.data.AddressLine1,
                    AddressLine2: response.data.AddressLine2,
                    City: response.data.City,
                    Email: response.data.Email,
                    StateProvinceRegion: response.data.StateProvinceRegion,
                    PostZipCode: response.data.PostZipCode,
                    Phone: response.data.Phone,
                    OpenningHours: response.data.OpenningHours,
                    Country: response.data.Country,
                })
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    useEffect(() => {
        postLogData("Payment successfully authorized")
        const deliveryAddress = JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.filter(add => add.AddressType === "Delivery")
        const billingAddress = JSON.parse(localStorage.getItem('fullUserDetails')).Addresses.filter(add => add.AddressType === "Billing")
        const cart = JSON.parse(localStorage.getItem("cart"))
        const curentDateTime = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
        fetchCompanyDetails()

        const total = (cart.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.ProductQty * currentValue.ProductPrice * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
        const offer = (cart.reduce((accumulator, currentofferValue) => accumulator + parseFloat(currentofferValue.OfferAmount * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)

        var PIsales =
            cart.length ? (
                cart.map((item, index) => (
                    {
                        ProductID: item.ProductID,
                        ProductCategoryID: item.productdetails.ProductCategoryID,
                        SalesQuantity: parseFloat(cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty),
                        SalesPrice: parseFloat(parseFloat(item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        LineDiscount: 0,
                        LineVat: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat((((item.productdetails.SellingPricesArray.SellingPrice * (cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].VatRate / (1 + cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].VatRate))).toFixed(2)) : parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * (cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty)) * (localStorage.getItem('ecurrencyratio'))) * (cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].VatRate).toFixed(2)),
                        LineAmount: parseFloat(((item.productdetails.SellingPricesArray.SellingPrice * cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty) * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        OriginalAmount: parseFloat((cart[cart.findIndex(pid => pid.ProductID === item.ProductID)].ProductQty * item.productdetails.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)),
                        PromotionAmount: 0,
                        SalesUnitTypeID: item.productdetails.SellingPricesArray.SalesUnitTypeID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                    }

                )
                )
            ) : (
                {}
            )

        var PIoffers =
            cart.map((item, index) => (

                item.OfferAmount != 0 ? (
                    {
                        ProductID: 0,
                        ProductCategoryID: 0,
                        SalesQuantity: 0,
                        SalesPrice: 0,
                        LineDiscount: 0,
                        LineVat: 0,
                        LineAmount: 0,
                        OriginalAmount: 0,
                        SalesUnitTypeID: 0,
                        PromotionAmount: parseFloat(-Math.abs(item.OfferAmount).toFixed(2)),
                        PromotionName: item.offerdetails[0].Offer.PromotionName,
                        PromotionID: item.offerdetails[0].Offer.OfferID,
                        OrgID: sessionStorage.getItem('OriginatedOrganizationID')
                    }
                ) : ''
            )
            )
        const formSales = {
            ProformaSales: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                OrderType: "OnLine",
                DeliveryInstruction: sessionStorage.getItem('DeliveryIns') == ' : undefined' ? '' : sessionStorage.getItem('DeliveryIns'),
                SubTotal: parseFloat(paymentAmount),
                BulkDiscount: 0,
                // VatAmount: JSON.parse(localStorage.getItem('setting'))[0].IsVatIncludedInSellingPrices ? parseFloat(this.state.totalvat) : parseFloat(this.state.totalvat),
                TotalInvoiceValue: parseFloat(paymentAmount),
                // OfferDiscount: parseFloat(this.state.offer),
                Description: '',
                OrgID: sessionStorage.getItem('OriginatedOrganizationID'),
                AdditionalCharges: '{\'DeliveryCharge\':' + parseFloat(sessionStorage.getItem('deliverycost')).toFixed(2) + '}',
                SubOrderType: sessionStorage.getItem('deliverymethod'),
                DeliveryCharge: parseFloat(sessionStorage.getItem('deliverycost')),
                CurrencyID: parseInt(localStorage.getItem('ecurrencyid')),
                CartReference: orderNumber,
                PaymentToken: '',
                DeliveryAddress: {
                    AddressType: deliveryAddress.AddressType,
                    AddressLine1: deliveryAddress.AddressLine1,
                    AddressLine2: deliveryAddress.AddressLine2,
                    City: deliveryAddress.City,
                    StateProvinceRegion: deliveryAddress.StateProvinceRegion,
                    PostZipCode: deliveryAddress.PostZipCode,
                    Country: deliveryAddress.Country,
                    Phone: deliveryAddress.Phone,
                    Fax: deliveryAddress.Fax,
                    Email: deliveryAddress.Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
                BillingAddress: {
                    AddressType: billingAddress.AddressType,
                    AddressLine1: billingAddress.AddressLine1,
                    AddressLine2: billingAddress.AddressLine2,
                    City: billingAddress.City,
                    StateProvinceRegion: billingAddress.StateProvinceRegion,
                    PostZipCode: billingAddress.PostZipCode,
                    Country: billingAddress.Country,
                    Phone: billingAddress.Phone,
                    Fax: billingAddress.Fax,
                    Email: billingAddress.Email,
                    CreatedDate: curentDateTime,
                    LastModifiedDate: curentDateTime
                },
            },


            ProformaInvoiceDetail: [...PIsales, ...PIoffers.filter(function (e) { return e })]
            ,
            Customer: {
                CloudCustomerID: sessionStorage.getItem('UserID'),
                CompanyName: JSON.parse(localStorage.getItem('fullUserDetails')).CompanyName,
                FirstName: JSON.parse(localStorage.getItem('fullUserDetails')).FirstName,
                LastName: JSON.parse(localStorage.getItem('fullUserDetails')).LastName,
                UserName: JSON.parse(localStorage.getItem('fullUserDetails')).UserName,
                Email: JSON.parse(localStorage.getItem('fullUserDetails')).Email,
                Avatar: JSON.parse(localStorage.getItem('fullUserDetails')).Avatar,
                UserID: JSON.parse(localStorage.getItem('fullUserDetails')).UserID,
                OriginatedOrganizationID: JSON.parse(localStorage.getItem('fullUserDetails')).OriginatedOrganizationID,
                Addresses: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses
            }
        }
        const timestamp = Math.floor(Date.now() / 1000) + 1000;

        var payload = {
            "aud": config.get('SalesjwtAud'),
            "exp": timestamp,
            "iss": config.get('SalesjwtIss')
        };

        var signOptions = {
            "algorithm": "RS256"
        };

        var privateKey = config.get('SalesprivateKey');

        var SalesAuthorizationKey = jwt.sign(payload, privateKey, signOptions);


        formSales["ProformaSales"]["PaymentToken"] = paymentReference;


        postLogData("Order Sent to API")
        fetch(config.get('API_salesregistry') + 'proformainvoice/add', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + SalesAuthorizationKey,
            },
            body: JSON.stringify(formSales)
        })
            .then(data => {
                if (data.status === 200) {
                    postLogData("Order sent to API successfully")

                    const Emaildata = {
                        companyPageTitle: config.get('companyPageTitle'),
                        companyLogo: config.get('companyLogo'),
                        ordernumber: orderNumber,
                        ophours: companyDetails.OpenningHours,
                        SenderEmail: companyDetails.Email,
                        CustEmail: custEmail,
                        phone: companyDetails.Phone,
                        subtotal: total,
                        offer: offer,
                        fullUserDetails: localStorage.getItem('fullUserDetails'),
                        deliveryMethod: deliveryMethod,
                        paymentType: "Credit/Debit Card",
                        delivery: parseFloat(deliveryCost || 0),
                        total: total,
                        basketitem: cart,
                        address: companyDetails.AddressLine1 + ", " + companyDetails.AddressLine2 + ", " + companyDetails.City + ", " + companyDetails.StateProvinceRegion + " " + companyDetails.PostZipCode + ", " + companyDetails.Country,
                        email: sessionStorage.getItem('useremail')
                    }

                    const emailBody = renderEmail(OrderEmail, { lang: 'en', Emaildata });



                    postLogData("Order Email sent")

                    const ecoEmail = {
                        "to": custEmail,
                        "bcc": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                        "from": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                        "subject": config.get('companyPageTitle') + " - Order Confirmation " + orderNumber,
                        "html": emailBody
                    };
                    const response = fetch(config.get('backendURL') + 'mail/send', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(ecoEmail)
                    })
                    localStorage.setItem('cart', []);

                }
                else {

                }
            })
    }, [])
    return <div>
        <p>Thank you for your payment, you order is being processed.</p>
        <h5>{paymentStatus}</h5>
        <h5>{orderKey}</h5>
        <h5>{`${paymentCurrency}${paymentAmount}`}</h5>
    </div>
}

export default PaymentSuccess