import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser'
import PrdImg1 from '../spiceway/images/prd_vegi.png';


const objItems = {
	0: {
		items: 1
	},
	576: {
		items: 2
	},
	768: {
		items: 3
	},
	992: {
		items: 4
	},
	1600: {
		items: 5
	}
}

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = { itemdata: '' };
	}

	componentDidMount() {
		if ($.isFunction('owlCarousel')) {
			$(".owl-testimonials").owlCarousel({
				navigation: true
			});
		}
		this._isMounted = true;
		axios.get(config.get('backendURL') + 'homelayout/byid/' + this.props.params.id)
			.then(response => {
				console.log(response)
				this.setState({
					itemdata: response.data.itemdata,
					itemheading: response.data.itemheading,
					item: response.data.item
				})
			})
			.catch(function (error) {
				console.log(error);
			})
	};
	render() {
		return (
			<>
			 

			 <div className="section brands">

        <div className="header-wrapper">
            <h3>{this.state.item}</h3>
            <span className="bottom-hr"></span>
        </div>

        <div className="container text-center">
            <OwlCarousel    
                loop
                margin={20} 
                autoplay
                responsive={objItems}
                navText={['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']}
                className='owl-theme'
            >
                 {ReactHtmlParser(this.state.itemdata)}
            </OwlCarousel>
        </div>
        
    </div>

			 </>
		)
	}
}

export default App;
