import React from 'react';

import { Email, Container, Row, Column, Header, FullWidth, Footer, Text, Link, PostonentsProvider } from 'postonents';

const ForgotPasswordEmail = ({ maildata }) => {

    const { PasswordLink, companyPageTitle, companyLogoCDN, SenderEmail } = maildata;

    return (
        <PostonentsProvider theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
            <Email
                title={`Reset password`}
                headLinks={[
                    {
                        type: 'link',
                        props: {
                            rel: 'stylesheet',
                            href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                        },
                    },
                ]}>

                <Container alignment="center" style={{ backgroundColor: '#FFFFFF', padding: "20px" }}>
                    <Row>
                        <Column small={12}>
                            <Header style={{ backgroundColor: '#FFFFFF', padding: 5 }}
                                logo={companyLogoCDN}
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Hello,</Text>
                        </Column>
                    </Row>

                    <Row>

                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>{companyPageTitle} recently received a request to change your password.</Text>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12} style={{ marginBottom: 24, textAlign: 'center' }}>
                            <Text>To change your {companyPageTitle} password, please click the link below:</Text><br /><br /><Link href={PasswordLink} type="primary">Reset your password</Link>
                        </Column>

                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Any other questions? We are happy to help!</Text>
                            <Text>If you did NOT request to change your password, you don't need to take any action.</Text>
                            <Text>If the link is not clickable in your e-mail reader please copy and paste it into the address (or location) area of your browser.</Text>
                            <p> Attempting to verify the email while still in the spam folder may result in errors. Please move the email to your inbox before following the instructions to verify.</p>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={6}>
                            <Link href="https://www.ecologital.com/contact" type="hollow">Help Center</Link>
                        </Column>
                        <Column small={6}>
                            <Link href={"mailto:" + SenderEmail} type="hollow">Email</Link>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12} style={{ textAlign: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 300 }}>
                            Copyright ©  {" 2019-"} {new Date().getFullYear()} {companyPageTitle}, all rights reserved
                            </Column>
                    </Row>
                </Container>
            </Email>
        </PostonentsProvider>
    );
};

export default ForgotPasswordEmail;