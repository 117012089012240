import React from 'react';
import { Menu } from 'antd';
import { SecurityScanOutlined, UserSwitchOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import AccountLeftMenuMartFury from "../themes/martfury/account-left-menu.jsx";
import AccountLeftMenuApparel from "../themes/apparel/account-left-menu.jsx";

import config from 'react-global-configuration';

const { SubMenu } = Menu;


class Application extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = e => {
    console.log('click ', e);
  };
  componentDidMount() {

  }
  onLogout = () => {
    sessionStorage.setItem('loggedin', false);
    sessionStorage.setItem('username', "");
    localStorage.setItem('fullUserDetails', "");
    sessionStorage.setItem('deliverycost', '');
    window.location.reload();
  };
  render() {


    return (
      <>
        {(() => {

          switch (config.get('Theme')) {

            case 'Martfury':
              return (<AccountLeftMenuMartFury onLogout={this.onLogout} />)
            case 'Apparel':
              return (<AccountLeftMenuApparel onLogout={this.onLogout} />)
            default:
              return (

                <Menu
                  onClick={this.handleClick}
                  style={{ width: 256 }}

                  defaultOpenKeys={['sub1']}
                  mode="inline"
                >

                  {sessionStorage.getItem('loggedin') == "true" ? (

                    <SubMenu
                      key="sub1"
                      title={
                        <span>
                          <SafetyCertificateOutlined />
                          <span>Your Account</span>
                        </span>
                      }
                    >

                      <Menu.Item key="aa1"><a href="/account/my-info" >Your Info & Password</a></Menu.Item>
                      <Menu.Item key="aa2"><a href="/account/addresses" >Addresses</a></Menu.Item>
                      <Menu.Item key="aa3"><a href="/account/payment" >Payment Settings</a></Menu.Item>
                      <Menu.Item key="aa4"><a href="/account/order-history" >Order History</a></Menu.Item>
                      <Menu.Item key="aa5"><a href="/account/my-wishlists" >View Your Wishlist</a></Menu.Item>
                      <Menu.Item key="aa6"><a href="/account/login" onClick={this.onLogout.bind()}>Logout</a></Menu.Item>

                    </SubMenu>
                  ) : (

                      <SubMenu
                        key="sub1"
                        title={
                          <span>
                            <UserSwitchOutlined />
                            <span>Account Settings</span>
                          </span>
                        }
                      >



                        <Menu.Item key="1"><a href="/account/register" >Create an Account</a></Menu.Item>
                        <Menu.Item key="2"><a href="/account/login">Login</a></Menu.Item>

                      </SubMenu>

                    )}



                  <SubMenu key="sub2" icon={<SecurityScanOutlined />} title="Shop Confidently">
                    <Menu.Item key="5">Terms & Conditions</Menu.Item>
                    <Menu.Item key="6">Refund Policy</Menu.Item>
                  </SubMenu>
                </Menu>


              )
          }

        })()}
      </>
    )
  }
}

export default Application;