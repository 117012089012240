import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faEnvelope, faHeart, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import * as NumericInput from "react-numeric-input";
import { Row, Col, Breadcrumb, Table, Modal } from 'react-bootstrap';
import { Skeleton, message, Button, Form, Input, Descriptions, Collapse } from 'antd';
import Grid from "@material-ui/core/Grid";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "../../shop/imagegallery";
import ProductTag from "../../shop/product-tag";
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { renderEmail } from 'postonents';
import NotifyEmail from '../../email/NotifyEmail';
import { renderTextBox } from "../../lib/desc";
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const ProductDetailsSpiceway = (props) => (


    <>


    
 <div className="hero-banner inner products">
        <div className="hero-text-wrapper">
            <h3>Our Products</h3>
            <h6>All Products are hygienically packaged with the help of our technically 
                advanced packaging machine. An air-tight packing is done with caution to let 
                it be moisture free. This keeps our spices fresh and bacteria free.</h6>
        </div>
    </div>


    <div className='section products'>

<div className='container-fluid'>
 

  <div className='row'>

    <div className='col-md-4 col-lg-3 col-xl-2'>
      <div class="list-group d-none d-md-block">
    
 
       
      </div>

 

    </div>

    <div className='col-md-8 col-lg-9 col-xl-10'>

 

      <div >
 
 


      {
            props.params.ploaded ? (
                props.params.productsdtlsbyid ? (
                    props.params.productsdtlsbyid.map(product =>
                        <div className="ps-page--product">
                            <div className="ps-container">
                                <div className="ps-page__container">
                                    <div className="ps-page__left">
                                        <div className="ps-product--detail ps-product--fullwidth">
                                            <div className="ps-product__header">

                                             
                                                <div className="ps-product__thumbnail" data-vertical="true">

                                                    {
                                                        product.OnLineImages.PrimaryImages !== null ? (
                                                            <>
                                                                <Grid container spacing={4}>
                                                                    <Grid item xs={12}><ImageGallery params={{ ProductMainImage: product.OnLineImages.PrimaryImages, SpecialImages: product.OnLineImages.SpecialImages, productsdtlsbyid:props.params.productsdtlsbyid  }} /></Grid>
                                                                    <Grid container spacing={2} item xs={12} direction="column">
                                                                        <Grid item>
                                                                            <div id="myPortal" />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <input type="hidden" id={'prodprimimage' + product.ProductID} value={product.OnLineImages.PrimaryImages.Main} />

                                                            </>) : (

                                                                <>
                                                                    <figure>
                                                                        <div className="ps-wrapper">
                                                                            <div className="ps-product__gallery" data-arrow="true">
                                                                                <div className="item"><img src="/images/no-image.jpg" alt={product.Name} height="220" /></div>
                                                                                <input type="hidden" id={'prodprimimage' + product.ProductID} value='' />
                                                                            </div>
                                                                        </div>
                                                                    </figure>

                                                                </>
                                                            )
                                                    }
                                                </div>


                                                <div className="ps-product__info"> <div id="bigImg" style={{ position: "absolute", 'z-index': '1111' }}></div>
                                                    <h1>{product.Name}</h1>



                                                    {sessionStorage.getItem('loggedin') == "true" ? (<>
                                                            <div className="row">
                                                                <div className={product.Discount != 0 ? 'priceleft col-sm-6' : 'priceleft col-sm-12'}>

                                                                    <input type="hidden" id={'prodprice' + product.ProductID} value={product.SellingPricesArray.SellingPrice} />

                                                                    <h4 className="ps-product__price">{localStorage.getItem('ecurrencyCode')} {String(product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio'))}</h4>


                                                                </div>
                                                                {
                                                                    product.Discount != 0 ?
                                                                        <div className="priceright col-sm-6">was {localStorage.getItem('ecurrencyCode')}{(parseInt(product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(product.Discount)} <br /><span className="dispricetxt">save {localStorage.getItem('ecurrencyCode')}{product.Discount}</span></div>
                                                                        : ''
                                                                }
                                                            </div>
                                                   </>):('') }

                                                    <div className="ps-product__desc">
                                                        <p>Sold By: {config.get('companyPageTitle')}</p>


                                                        {product.ProductOnlineDescription ? (<>

                                                            <Descriptions title="Product Information">
                                                                <Descriptions.Item><div dangerouslySetInnerHTML={{ __html: product.ProductOnlineDescription }} /></Descriptions.Item>
                                                            </Descriptions>


                                                        </>) : ('')
                                                        }


                                                    </div>


                                                    <Table striped bordered hover size="sm" width="50%">
                                                        <thead>
                                                            <tr>
                                                                <th>Pack Size</th>
                                                                <th>Weight</th>
                                                                <th>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                product.UnitCharts.length > 0 ? (
                                                                    product.UnitCharts.map(elu =>
                                                                        <tr>
                                                                            <td>{elu.Quantity}</td>
                                                                            <td>{product.Weight}</td>
                                                                            <td>{elu.UnitTypeName}</td>
                                                                        </tr>

                                                                    )
                                                                ) : (

                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>{product.Weight}</td>
                                                                            <td>EA</td>
                                                                        </tr>
                                                                    )
                                                            }


                                                        </tbody>
                                                    </Table>


                                                    {product.Offers[0] ? (product.Offers[0].Offer.OfferType == 3 ? (<>

                                                        <Collapse
                                                            bordered={true}

                                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                            className="multioffer"
                                                        >
                                                            <Panel header="Multi Buy Offers - Click to View" key="1" className="site-collapse-custom-panel">
                                                                <b class="text-danger">{product.Offers[0].Offer.OfferTypeDesc}</b><br />
                                                                <b class="text-success">{product.Offers[0].Offer.PromotionName}</b><br /><br />

                                                                {
                                                                    props.params.offerproducts.length > 2 ? (
                                                                        <><b>Buy Any {product.Offers[0].Offer.PromotionQty} of these</b></>)
                                                                        : (<><b>Buy {product.Offers[0].Offer.PromotionQty} of these</b></>)
                                                                }
                                                                <p>
 
                                                                    {

                                                                        props.params.offerproducts.map(PO =>
                                                                            <>
                                                                                {
                                                                                    PO[0].Name != null ? (
                                                                                        <><a href={'/product/' + PO[0].Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + PO[0].ProductID}>{PO[0].Name} - {PO[0].ProductRefNo}</a><br /></>
                                                                                    ) : ''
                                                                                }
                                                                            </>
                                                                        )
                                                                    }

                                                                </p>
                                                            </Panel>

                                                        </Collapse>
                                                    </>) : '') : ''}

                                                    <div className="ps-product__shopping">



                                                        {product.IsOutOfStock ? (<div className="text-center"> <p className="inputandbtn"><button className="notify" onClick={props.onNotify.bind(this, product.ProductID, product.Name, product.MaxOnlineOrderQty)} id={product.ProductID}><FontAwesomeIcon icon={faEnvelope} />Notify</button></p> <Modal
                                                            show={props.params.visible}
                                                            onHide={props.handleCancel}
                                                            centered
                                                            aria-labelledby="example-modal-sizes-title-sm"
                                                        >
                                                            <Modal.Header closeButton>
                                                                <Modal.Title id="example-modal-sizes-title-sm">
                                                                    Notify
          </Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form
                                                                    onFinish={props.onFinish}
                                                                    name="basic"
                                                                    initialValues={{ remember: true }}
                                                                >
                                                                    <Form.Item
                                                                        label="Email Address"
                                                                        name="email"
                                                                        rules={[{ required: true, type: 'email' }]}
                                                                    >
                                                                        <Input placeholder="Email Address" />
                                                                    </Form.Item>



                                                                    <Form.Item  >
                                                                        <Input name="prdt" type="hidden" id="prdt" value={product.ProductID} />
                                                                        <Button type="primary" htmlType="submit"  danger >
                                                                            Submit
                      </Button>
                                                                    </Form.Item>
                                                                </Form>

                                                            </Modal.Body>
                                                        </Modal></div>) : (
                                                             
                                                             
                                                             
                                                             <>
                                                                  
                                                                  
                                                                  {sessionStorage.getItem('loggedin') == "true" ? (<>
                                                                    <figure>
                                                                        <figcaption>Quantity</figcaption>
                                                                        <div className="form-group--number">
                                                                            <button className="up" onClick={props.onQtyIncrese.bind(this, product.ProductID)}><FontAwesomeIcon icon={faPlus} /></button>
                                                                            <button className="down" onClick={props.onQtyReduce.bind(this, product.ProductID)}><FontAwesomeIcon icon={faMinus} /></button>
                                                                            <input className="form-control" type="text" min={1} max={product.MaxOnlineOrderQty == 0 ? 10000 : product.MaxOnlineOrderQty} precision={0} id={'prodqty' + product.ProductID} value={1} snap placeholder={1} />
                                                                        </div>



                                                                    </figure>
                                                                    <a className="ps-btn ps-btn--black" onClick={props.onAddItem.bind(this, 'add', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.offerdetails ? (product.offerdetails.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.offerdetails ? (product.offerdetails.Offer.PromotionQty) : '', product.offerdetails ? (product.offerdetails.Offer.Price) : '')} id={product.ProductID} href="#">Add to cart</a>   |   <a className="ps-btn" onClick={props.onAddItem.bind(this, 'buy', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.offerdetails ? (product.offerdetails.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.offerdetails ? (product.offerdetails.Offer.PromotionQty) : '', product.offerdetails ? (product.offerdetails.Offer.Price) : '')} href="#">Buy Now</a>
                                                                    </>):('')

                                                                  }

                                                                </>





                                                            )}
                                                    </div>
                                                    <div className="ps-product__specification">
                                                        <p><strong>Product Ref No:</strong> {product.ProductRefNo}</p>
                                                        <p className="tags"><strong> Tags</strong>{product.ProductTag}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {
                                    product.ProductTag ? (
                                        <ProductTag params={{ id: props.params.id, tagname: product.ProductTag, ProductID: product.ProductID }} />
                                    ) : ('')
                                }
                            </div>
                        </div>

                    )
                ) : (
                        <Col sm={12}><br />
                            <Skeleton active avatar paragraph={{ rows: 4 }} />
                        </Col>
                    )
            ) : (<Col sm={12}><br />
                <Skeleton active avatar paragraph={{ rows: 4 }} />
            </Col>)
        }



      </div>

       
    
      
    </div>
  </div>

</div>

</div>




 

    </>

);
export default ProductDetailsSpiceway;
