import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import LayoutInclude from "./layout-include";

export default class mainbody extends Component {
  constructor(props) {
    super(props);
    this.state = { basketitem: [], basketqty: [], datax: [] };
  }

  componentDidMount() {
    axios.get(config.get('backendURL') + 'layouts/layout/' + config.get('companyID'))
      .then(response => {
        this.setState({ datax: JSON.parse(response.data[0].itemdetails).sort((a, b) => (a.order - b.order)), slayoutid: response.data[0]._id })
      })
      .catch((error) => {
        console.log(error);
      })



  };



  render() {
    return (
<div className="main">
      <Row>
        
        {this.state.datax.map((layout, index) => (
          <>
           
            <LayoutInclude params={{ layoutid: layout.layoutid, width: layout.width }} />

          </>
        ))}
        <Col sm={12} >   &nbsp;
          </Col>
          
        <Col sm={12} >
          <Container>
            <Row>

            </Row>
          </Container>

        </Col>
   
   

      </Row>
      </div>
    );
  }
}