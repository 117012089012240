import React from 'react';
import { Row, Col, } from 'react-bootstrap';
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';


class App extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { productsdtlsbyid: []};
  }
  componentDidMount() {
    this._isMounted = true;

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');

    var token = jwt.sign(payload, privateKey, signOptions);


    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({ "OrgID": config.get('OrganisationID'), 
      "ProductID": parseInt(this.props.params.ProductID),
      "LocationID": (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID)
    })
    };

    

    fetch(config.get('rest_API_productregistry') + '/productid', requestOptions)
      .then(response => response.json())
      .then(data => this.onSetProductResult(data, data));
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  onSetProductResult = (result, key) => {
    this.setState({ productsdtlsbyid: result })

    localStorage.setItem('savedcart'+this.props.params.ProductID, JSON.stringify(result));

    
  };

 
  render() {
    const { productsdtlsbyid } = this.state;

    return (



      <div className={this.props.params.ProductID}>
 
              <Row>   
          {productsdtlsbyid.Result ? (
            productsdtlsbyid.Result.map(product =>
              <Col> 
                <Row>    
                  <Col md={7}>{product.Name}</Col>
                  <Col md={1}>{this.props.params.SalesQuantity}</Col>
                  <Col md={2}>{localStorage.getItem('ecurrencyCode')} {product.SellingPricesArray.SellingPrice}</Col>
                  <Col md={2} className="text-right"> 
                    {localStorage.getItem('ecurrencyCode')} {(this.props.params.SalesQuantity * product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</Col>
                </Row>
              </Col>
            )
          ) : ('')
          }
        </Row>
      </div>
    )
  }
}

export default App;
