import config from 'react-global-configuration';
config.set({
  companyID: '',
  OrganisationID: '',
  companyPageTitle: '',
  SenderEmail: '',
  favicon: '',
  Theme: '',
  companyLogo: '',
  companyLogoCDN: '',
  LocationID: '',
  WorldPayServiceKey: '',
  WorldPayClientKey: '',
  WishListEnabled: '',
  HelpPage: '',
  SocialMedia: '',
  DeliveryMessage: '',
  ShopNowItems: '',
  FBappid: '',
  FBpageid: '',
  CDNPath: '',
  backendURL: process.env.REACT_APP_backendURL,
  /******Product JWT variables *******/
  API_productregistry: process.env.REACT_APP_API_productregistry,
  ProductjwtAud: process.env.REACT_APP_ProductjwtAud,
  ProductjwtIss: process.env.REACT_APP_ProductjwtIss,
  ProductprivateKey: process.env.REACT_APP_ProductprivateKey.replace(/\\n/g, '\n'),
  /******Sales JWT variables *******/
  API_salesregistry: process.env.REACT_APP_API_salesregistry,
  SalesjwtAud: process.env.REACT_APP_SalesjwtAud,
  SalesjwtIss: process.env.REACT_APP_SalesjwtIss,
  SalesprivateKey: process.env.REACT_APP_SalesprivateKey.replace(/\\n/g, '\n'),
  /******Cust JWT variables *******/
  API_URL_cust: process.env.REACT_APP_API_URL_cust,
  CustjwtAud: process.env.REACT_APP_CustjwtAud,
  CustjwtIss: process.env.REACT_APP_CustjwtIss,
  rest_API_productregistry: process.env.REACT_APP_rest_API_productregistry,
  rest_API_productregistry_V2: process.env.REACT_APP_rest_API_productregistry_V2,
  rest_API: process.env.REACT_APP_rest_API,
  CustjwtExp: process.env.REACT_APP_CustjwtExp,
  LoginprivateKey: process.env.REACT_APP_LoginprivateKey.replace(/\\n/g, '\n'),
  LoginAuthorizationKey: process.env.REACT_APP_LoginAuthorizationKey,
  WorldPayUrl: process.env.REACT_APP_WorldPayUrl,
  WorldPayTransactionMode: process.env.REACT_APP_WorldPay_Transaction_Mode
}, { freeze: false });

