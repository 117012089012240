import React, { Component } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import FooterInclude from "./footer.component.include"
import { Divider } from 'antd';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const Page = props => (

  <li> <a href={'/pages/' + props.page.slug}>{props.page.pagetitle}</a></li>
)

export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = { menus: [], footer1: [],  footer2: []};
  }

  componentDidMount() {

    axios.get(config.get('backendURL')+'menus/main/'+config.get('companyID'))
    .then(response => {
    //  console.log(response.data[0]._id )
     
 
      const requestOne = axios.get(config.get('backendURL')+'pages/byid/'+response.data[0]._id+'/'+config.get('companyID'))
      .then(response => {
      //  console.log(response)
        this.setState({ footer1: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
 
    })
    .catch((error) => {
      console.log(error);
    })


    
       
      const cachedFooter = sessionStorage.getItem('footer');
    if (cachedFooter) {
      this.setState({ menus: JSON.parse(cachedFooter) });
    } else {
      fetch(config.get('backendURL')+'menus/footer/' + config.get('companyID'))
        .then(response => response.json())
        .then(data => this.onSetFooter(data));
    }

 
      axios.get(config.get('backendURL')+'company/'+ config.get('companyID'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  

  pageList() {
    return this.state.footer1.map(currentpage => {
      return <Page page={currentpage} key={currentpage._id} />;
    })
  }

  onSetFooter = (result) => {
    this.setState({ menus: result })
   // console.log(result)
    sessionStorage.setItem('footer', JSON.stringify(result));
  };

  onFooterMenu = (result) => {
   
    axios.get(config.get('backendURL')+'menus/main/'+config.get('companyID'))
    .then(response => {
     // console.log(response.data[0]._id )
     
 


      const requestOne = axios.get(config.get('backendURL')+'pages/byid/'+ response.data[0]._id +'/'+config.get('companyID'))
      .then(response => {
        this.setState({ footer1: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
  



    })
    .catch((error) => {
      console.log(error);
    })

  };



  render() {
    return (

<footer>
<Divider />
   <Row >

{
   this.state.menus.map(menu => (
        <Col sm={2}  key={menu._id}>
          <Nav defaultActiveKey="/home" className="flex-column">
            <div className="linkGroup_3qKNM">
   <h5>{menu.description}</h5>
   <FooterInclude params={{ FooterItemId: menu._id}} />
            </div>
          </Nav>
        </Col>

    )
   )}


        <Col sm={3} >

          <div className="linkGroup_3qKNM">
            <h5>Follow Us</h5>
            <ul>
              <li><img src="/images/instagram.svg"  alt="Instagram" width="30"/> <img src="/images/facebook.svg" alt="Facebook" width="30"/> <img src="/images/linkedin.svg" alt="Linkedin" width="30"/></li></ul>
          </div>
          <div className="linkGroup_3qKNM">
            <h5>We Accept</h5>
            <ul>
              <li><img src="/images/visa.svg" alt="Visa" width="30"/> <img src="/images/mastercard.svg" alt="Visa" width="30"/> <img src="/images/amex.svg" alt="Visa" width="30"/></li>
              </ul>
          </div>

        </Col>


        <Col sm={3}  >

    {this.state.Name}<br />
    {this.state.AddressLine1}<br />{this.state.AddressLine2}<br />{this.state.City} <br />{this.state.StateProvinceRegion} {this.state.PostZipCode}<br />{this.state.Country}


        </Col>
        <Divider />
        <Col sm={12} className="copyright">
        Copyright &copy; {this.state.Name}.
All Rights Reserved.
        </Col>
      </Row>
      <MessengerCustomerChat
  pageId="146841862093310"
  appId="146841862093310"
  htmlRef={window.location.pathname}
/>
      
      </footer>
    );
  }
}