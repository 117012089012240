import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import LayoutInclude from "./layout-include";
import jwt from 'jsonwebtoken';
import _ from "lodash";
import { Responsive, WidthProvider } from 'react-grid-layout';
import $ from 'jquery';
 


const ResponsiveGridLayout = WidthProvider(Responsive);

export default class mainbody extends Component {

  static defaultProps = {
    className: "layout",
    isDraggable: false,
    rowHeight: 20,
    isResizable: true
  };

  constructor(props) {
    super(props);
    this.state = { basketitem: [], basketqty: [], datax: [], deliveryscheme: [] };
  }
 
  componentDidMount() {
 


 
    var element = document.getElementsByClassName("collection-img");
    

    setTimeout(function () {


      

      $('.collection-img').mouseover(function () {
        $(this).find('img').css('transform', 'scale(1.3)');
      });
    
      $('.collection-img').mouseout(function () {
        $(this).find('img').css('transform', 'scale(1)');
      });

  
      for (var i = 0; i < element.length; i++) {
    // element[i].style.transform='scale(1.3)';
      }
   
    },3000);
 
 
    var adjest = 0;
    var ulElems = document.getElementsByClassName("react-resizable");
    // console.log(ulElems.length)

 

    window.addEventListener('resize', this.resize)
    this.setState(() => {
      axios.get(config.get('backendURL') + 'homelayout/' + config.get('companyID'))
        .then(response => {
          //    console.log(response.data)
          if (response.data.length > 0) {
            this.setState({
              layout: response.data.map(function (i, key, list) {
                return {
                  i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString()]),
                  x: i.x,
                  y: i.y,
                  w: i.width,
                  h: i.height,
                  add: i === (list.length - 1)
                };
              }), loading: true
            })
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    });

    axios.get(config.get('backendURL') + 'layouts/layout/' + config.get('companyID'))
      .then(response => {
        this.setState({ datax: JSON.parse(response.data[0].itemdetails).sort((a, b) => (a.order - b.order)), slayoutid: response.data[0]._id })
      })
      .catch((error) => {
        console.log(error);
      })

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('SalesjwtAud'),
      "exp": timestamp,
      "iss": config.get('SalesjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('SalesprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    axios.get(config.get('API_salesregistry') + 'deliveryscheme/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        this.setState({ deliveryscheme: response.data.Result });
        //  console.log(response.data) 
      })
      .catch((error) => {
        console.log(error);
      })


 
  };



  generateDOM(el) {

    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };
    const i = el.i;
    return (
      <div key={JSON.parse(i)[0]} data-grid={el}>
        <LayoutInclude params={{ layoutid: JSON.parse(i)[0], width: 'auto' }} />
      </div>
    );

  }


 

 

  render() {

    const { layout, deliveryscheme } = this.state;

    return (

 <>



        
          {_.map(this.state.layout, el => this.generateDOM(el))}
        
        </>

    
    );
  }
}