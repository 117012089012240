import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Modal, Form, Button, Input, Select, Divider } from 'antd';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { UncontrolledCollapse } from 'reactstrap';
import config from 'react-global-configuration';
const { Option } = Select;


const AccountRegisterYourDetailsApparel = (props) => (

    
    <>

        <Divider orientation="left"><h5>CREATE AN ACCOUNT</h5></Divider>

        <Divider orientation="left"><h6>NAME</h6></Divider>

        <Form layout="vertical"
            name="register"
            onFinish={props.onFinish} >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="FirstName"
                        label="First Name"
                        rules={[{ required: true, message: 'Please enter first name' }]}
                    >
                        <Input placeholder="Please enter first name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="LastName"
                        label="Last Name"
                        rules={[{ required: true, message: 'Please enter last name' }]}
                    >
                        <Input placeholder="Please enter last name" />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left"><h6>EMAIL/LOG IN INFORMATION</h6></Divider>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="Email"
                        label="Email"

                    >
                        <Input defaultValue={sessionStorage.getItem('tmpEmail')} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="UserName"
                        label="User Name"
                        rules={[{ required: true, message: 'Please enter user name' }]}
                    >
                        <Input placeholder="Please enter user name" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="Password"
                        label="Password"
                        rules={[{ required: true, message: 'Please enter password' }]}
                    >
                        <Input.Password placeholder="Please enter password" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="RePassword"
                        label="Confirm Password"
                        dependencies={['Password']}
                        rules={[{ required: true, message: 'Please enter a value for confirm password' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('Password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),]}
                    >
                        <Input.Password placeholder="Please enter confirm password" />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left"><h6>ADDRESS INFORMATION</h6></Divider>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="AddressType"
                        label="Address Type"
                        rules={[{ required: true, message: 'Please enter a address type' }]}
                    >

                        <Select defaultValue=""  >
                            <Option value="">Please Select</Option>
                            <Option value="Billing">Billing</Option>
                            <Option value="Delivery">Delivery</Option>
                            <Option value="Home">Home</Option>
                            <Option value="Other">Other</Option>
                        </Select>

                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Button type="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                        Create an additional address
    </Button>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="Address"
                        label="Address"
                        rules={[{ required: true, message: 'Please enter address' }]}
                    >
                        <Input placeholder="Please enter address" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="Address2"
                        label="Address 2"
                        rules={[{ required: false, message: 'Please enter address 2' }]}
                    >
                        <Input placeholder="Please enter address 2" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="City"
                        label="City"
                        rules={[{ required: true, message: 'Please enter city' }]}
                    >
                        <Input placeholder="Please enter city" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="StateProvinceRegion"
                        label="State / Province / Region"
                        rules={[{ required: true, message: 'Please enter state / province / region' }]}
                    >
                        <RegionDropdown
                            country={props.params.country}
                            countryValueType="short"
                            value={props.params.region}
                            classes="ant-input"
                            onChange={(val) => props.selectRegion(val)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="PostZipCode"
                        label="Post / Zip Code"
                        rules={[{ required: true, message: 'Please enter post / zip code' }]}
                    >
                        <Input placeholder="Please enter post / zip code" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="Country"
                        label="Country"
                        rules={[{ required: true, message: 'Please enter country' }]}
                    >

                        <CountryDropdown
                            value={props.params.country}
                            valueType="short"
                            classes="ant-input"
                            onChange={(val) => props.selectCountry(val)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="Phone"
                        label="Phone"
                        rules={[{ required: true, message: 'Please enter valid phone' }]}
                    >
                        <Input placeholder="Please enter phone" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="Fax"
                        label="Landline"
                        rules={[{ message: 'Please enter valid landline' }]}
                    >
                        <Input placeholder="Please enter landline" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="AddressEmail"
                        label="Email"
                        rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                    >
                        <Input placeholder="Please enter email" />
                    </Form.Item>
                </Col>
                <Col span={12}>

                </Col>
            </Row>


            <UncontrolledCollapse toggler="#toggler">
                <>
                    <Divider orientation="left"><h6>ADDITIONAL ADDRESS INFORMATION</h6></Divider>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="OAddressType"
                                label="Address Type"
                                rules={[{ required: false, message: 'Please enter a address type' }]}
                            >
                                <Select defaultValue="Delivery"  >
                                    <Option value="Billing">Billing</Option>
                                    <Option value="Delivery">Delivery</Option>
                                    <Option value="Home">Home</Option>
                                    <Option value="Other">Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="OAddress"
                                label="Address"
                                rules={[{ required: false, message: 'Please enter address' }]}
                            >
                                <Input placeholder="Please enter address" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="OAddress2"
                                label="Address 2"
                                rules={[{ required: false, message: 'Please enter address 2' }]}
                            >
                                <Input placeholder="Please enter address 2" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="OCity"
                                label="City"
                                rules={[{ required: false, message: 'Please enter city' }]}
                            >
                                <Input placeholder="Please enter city" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="OStateProvinceRegion"
                                label="State / Province / Region"
                                rules={[{ required: false, message: 'Please enter state / province / region' }]}
                            >
                                <RegionDropdown
                                    country={props.params.country}
                                    value={props.params.region}
                                    countryValueType="short"
                                    classes="ant-input"
                                    onChange={(val) => props.selectRegion1(val)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="OPostZipCode"
                                label="Post / Zip Code"
                                rules={[{ required: false, message: 'Please enter post / zip code' }]}
                            >
                                <Input placeholder="Please enter post / zip code" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="OCountry"
                                label="Country"
                                rules={[{ required: false, message: 'Please enter country' }]}
                            >
                                <CountryDropdown
                                    value={props.params.country}
                                    valueType="short"
                                    classes="ant-input"
                                    onChange={(val) => props.selectCountry1(val)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="OPhone"
                                label="Phone"
                                rules={[{ required: false, message: 'Please enter valid phone' }]}
                            >
                                <Input placeholder="Please enter phone" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="OFax"
                                label="Landline"
                                rules={[{ message: 'Please enter valid landline' }]}
                            >
                                <Input placeholder="Please enter landline" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="OAddressEmail"
                                label="Email"
                                rules={[{ type: 'email', required: false, message: 'Please enter valid email' }]}
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                        </Col>
                    </Row>



                </>
            </UncontrolledCollapse>

            <Row gutter={16}>
                {config.get('companyPageTitle')} is committed to respecting your privacy. By continuing you are accepting our&nbsp; Terms and Conditions, and our&nbsp;   Privacy Policy.
            </Row>
            <br />
            <Form.Item  >


                <Button type="primary" htmlType="submit" danger >
                    {props.params.disabled ? 'Please wait...' : 'Complete Registration'}
                </Button>


            </Form.Item>

        </Form>

    </>


);
export default AccountRegisterYourDetailsApparel;
