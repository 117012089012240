import React from 'react';

import {Email, Container, Row, Column, Header, FullWidth, Footer, Text, Link, PostonentsProvider } from 'postonents';

const NotifyEmail = ({ Notifydata }) => {
    
    const { SenderEmail, companyPageTitle, prdtName, prdtURL, companyLogoCDN } = Notifydata;
     
    return (
        <PostonentsProvider theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
            <Email
                title={`Reset password`}
                headLinks={[
                    {
                        type: 'link',
                        props: {
                            rel: 'stylesheet',
                            href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                        },
                    },
                ]}>

                <Container alignment="center" style={{ backgroundColor: '#FFFFFF', padding: "20px" }}>
                    <Row>
                        <Column small={12}>
                            <Header style={{ backgroundColor: '#FFFFFF', padding: 5 }}
                                logo={companyLogoCDN}
                                
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Hello Admin,</Text>
                        </Column>
                        </Row>

                    <Row>
                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>A user subscribed for a product notification</Text>
                        </Column>
                        </Row>

                    <Row>
                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>Email : {SenderEmail}</Text>
                        </Column>
                        </Row>

                    <Row>
                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>Requested Product : {prdtName}</Text>
                        </Column>
                        </Row>

                    <Row>
                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>Requested URL : {prdtURL}</Text>
                        </Column>
                        
                    </Row>

                 
                    <Row>
                        <Column small={12} style={{ textAlign: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 300 }}>
            Copyright ©  {" 2019-"} {new Date().getFullYear()} {companyPageTitle}, all rights reserved
                            </Column>
                    </Row>
                </Container>
            </Email>
        </PostonentsProvider>
    );
};

export default NotifyEmail;