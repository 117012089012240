import React from 'react';
import { Divider, Modal, Avatar, Form, Input, Button, Checkbox } from 'antd';
import config from 'react-global-configuration';
import Password from 'antd/lib/input/Password';

const LoginApparel = (props) => (

    
      <>

{/* Login */}
<div className="login">
        <div className="row">
          <div className="col-sm-6">
            <div className="login-tabs-wrapper">
              <div className="login-tabs">				
                <ul className="nav nav-tabs tab-container">
                  <li className="nav-item"><a data-toggle="tab" href="#tab1" className="nav-link active">Login</a></li>								
                  <li className="nav-item"><a data-toggle="tab" href="#tab2" className="nav-link">Register</a></li>
                </ul>
                {/* Tab Content Wrapper */}
                <div className="tab-content">
                  {/* Tab Content 1  */}
                  <div id="tab1" className="tab-pane active show">
                    <div className="login-form user-login">
                     
                     
                    <Form
                  
                  onFinish={props.onFinish}
                  name="basic"
                  initialValues={{ remember: true }}

                >
                        <div className="form-group">
                          <label>Email</label>
                          <Form.Item
                      
                      name="username"
                      
                      rules={[{type: 'email', required: true, message: 'Please enter a valid email!' }]}
                    >
                      <Input placeholder="" className="form-control" />
                    </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <Form.Item
                       
                       name="password"
                       rules={[{ required: true, message: 'Please input your password!' }]}
                     >
                       <Password  className="form-control"  placeholder="" />
                     </Form.Item>
                        </div>
                         
                        <button className="btn-act-primary btn-login" id="btn"  >{props.params.disabled ? 'Please wait...' : 'Login'}</button>
                        <div className="forgot-password">
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" />
                            <label className="form-check-label">Remember me</label>
                          </div>
                          <a href="/account/forgot" className="comn-link pw-forgot">Forgot your password?</a>
                        </div>
                      </Form>



                    </div>
                  </div>
                  {/* Tab Content 2 */}
                  <div id="tab2" className="tab-pane fade">
                    <div className="login-form user-login">
                    <Form
                  
                  onFinish={props.onRegFinish}
                  name="basic"
                  initialValues={{ remember: true }}

                >
                        <div className="form-group">
                          <label>Email</label>
                          <Form.Item
                                name="Email"
                                label=""
                                rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
                              >
                                <Input placeholder=""  className="form-control" />
                              </Form.Item>
                        </div>
                        <p>
                          Your personal data will be used to support your experience throughout this website, 
                          to manage access to your account, and for other purposes described in our <a href="#" className="link-privacy-policy">privacy policy.</a>
                        </p>
                      
                        <button className="btn-act-primary btn-login" type="submit">Register</button>
                        </Form>
                    </div>
                  </div>				
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="signup-benefits">
              <p className="text-header">Benefits of Signing up today</p>
              <p>Casipillai Designer Collection Buyer Protection has you covered from click to delivery. 
                Sign up or sign in and you will be able to:</p>
              <div className="signup-item">
                <i className="fas fa-cash-register" />
                <p>SPEED YOUR WAY THROUGH CHECKOUT</p>
              </div>
              <div className="signup-item">
                <i className="far fa-calendar-check" />
                <p>TRACK YOUR ORDERS EASILY</p>
              </div>
              <div className="signup-item">
                <i className="fas fa-shopping-bag" />
                <p>KEEP A RECORD OF ALL YOUR PURCHASES</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login */}

         
      
     </>

   

);
export default LoginApparel;
