import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Modal, Form, Button, Input, Select, Divider } from 'antd';
import { UncontrolledCollapse } from 'reactstrap';
import AccountLeftMenu from "./account-left-menu";
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';


import { PasswordInput } from 'antd-password-input-strength';

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import AccountRegisterYourDetailsMartFury from "../themes/martfury/account-register-yourdetails";
import AccountRegisterYourDetailsApparel from "../themes/apparel/account-register-yourdetails";

const { Option } = Select;
class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.regEligible = sessionStorage.getItem('regeligible') === 'true';
    this.state = { country: '', region: '', country1: '', region1: '' };
  }
  state = { visible: false };

  componentDidMount() {
    document.title = "Account Registration | " + config.get('companyPageTitle')

    axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          Logo: response.data.Logo,
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  selectCountry1(val) {
    this.setState({ country1: val });
  }

  selectRegion1(val) {
    this.setState({ region1: val });
  }
  render() {

    if (!this.regEligible) {
      return <Redirect to='/account/register' />;
    }

    const onFinish = values => {
      this.setState({ disabled: true });
      this.refs.btn.setAttribute("disabled", "disabled");



      var secondAddresss = { "FirstName": values.FirstName, "LastName": values.LastName, "UserName": values.UserName, "Password": values.Password, "Email": sessionStorage.getItem('tmpEmail'), "Avatar": "", "OriginatedOrganizationID": config.get('OrganisationID'), "Addresses": [{ "AddressType": values.AddressType, "AddressLine1": values.Address, "AddressLine2": values.Address2, "City": values.City, "StateProvinceRegion": values.StateProvinceRegion, "PostZipCode": values.PostZipCode, "Country": values.Country, "Phone": values.Phone, "Fax": values.Fax, "Email": values.AddressEmail, "CreatedDate": Date.now, "LastModifiedDate": Date.now }] };
      if (values.OAddress != '') {
        secondAddresss = { "FirstName": values.FirstName, "LastName": values.LastName, "UserName": values.UserName, "Password": values.Password, "Email": sessionStorage.getItem('tmpEmail'), "Avatar": "", "OriginatedOrganizationID": config.get('OrganisationID'), "Addresses": [{ "AddressType": values.AddressType, "AddressLine1": values.Address, "AddressLine2": values.Address2, "City": values.City, "StateProvinceRegion": values.StateProvinceRegion, "PostZipCode": values.PostZipCode, "Country": values.Country, "Phone": values.Phone, "Fax": values.Fax, "Email": values.AddressEmail, "CreatedDate": Date.now, "LastModifiedDate": Date.now }, { "AddressType": values.OAddressType, "AddressLine1": values.OAddress, "AddressLine2": values.OAddress2, "City": values.OCity, "StateProvinceRegion": values.OStateProvinceRegion, "PostZipCode": values.OPostZipCode, "Country": values.OCountry, "Phone": values.OPhone, "Fax": values.OFax, "Email": values.OEmail }] };
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + config.get('LoginAuthorizationKey'),
        },
        body: JSON.stringify(secondAddresss)
      };


      const requestEUserDtlsOptions = {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + config.get('LoginAuthorizationKey'),
        },
        body: JSON.stringify({ "Email": sessionStorage.getItem('tmpEmail').toLowerCase(), "OrganizationID": config.get('OrganisationID') })
      };

      //  console.log(requestEUserDtlsOptions)
      fetch(config.get('API_URL_cust') + 'globalfind', requestEUserDtlsOptions)
        .then(response => response.json())
        .then(function (eudata) {

          //   console.log(eudata)

          if (eudata.Status) {

            //   console.log('eudata')


            var secondAddresss = { "FirstName": values.FirstName, "LastName": values.LastName, "UserName": values.UserName, "Password": values.Password, "Email": sessionStorage.getItem('tmpEmail'), "Avatar": "", "UserID": eudata.Result, "OriginatedOrganizationID": config.get('OrganisationID'), "Addresses": [{ "AddressType": values.AddressType, "AddressLine1": values.Address, "AddressLine2": values.Address2, "City": values.City, "StateProvinceRegion": values.StateProvinceRegion, "PostZipCode": values.PostZipCode, "Country": values.Country, "Phone": values.Phone, "Fax": values.Fax, "Email": values.AddressEmail, "CreatedDate": Date.now, "LastModifiedDate": Date.now }] };
            if (values.OAddress != '') {
              secondAddresss = { "FirstName": values.FirstName, "LastName": values.LastName, "UserName": values.UserName, "Password": values.Password, "Email": sessionStorage.getItem('tmpEmail'), "Avatar": "", "UserID": eudata.Result, "OriginatedOrganizationID": config.get('OrganisationID'), "Addresses": [{ "AddressType": values.AddressType, "AddressLine1": values.Address, "AddressLine2": values.Address2, "City": values.City, "StateProvinceRegion": values.StateProvinceRegion, "PostZipCode": values.PostZipCode, "Country": values.Country, "Phone": values.Phone, "Fax": values.Fax, "Email": values.AddressEmail, "CreatedDate": Date.now, "LastModifiedDate": Date.now }, { "AddressType": values.OAddressType, "AddressLine1": values.OAddress, "AddressLine2": values.OAddress2, "City": values.OCity, "StateProvinceRegion": values.OStateProvinceRegion, "PostZipCode": values.OPostZipCode, "Country": values.OCountry, "Phone": values.OPhone, "Fax": values.OFax, "Email": values.OEmail }] };
            }
            const requestJoinOptions = {
              method: 'POST',
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + config.get('LoginAuthorizationKey'),
              },
              body: JSON.stringify(secondAddresss)
            };


            fetch(config.get('API_URL_cust') + 'join', requestJoinOptions)
              .then(response => response.json())
              .then(function (data2) {

                sessionStorage.setItem('AccessToken', data2.Result.AccessToken);
                sessionStorage.setItem('RefreshToken', data2.Result.RefreshToken);
                sessionStorage.setItem('loggedin', "true");
                //console.log(data.Result)
                const expireTime = new Date().getTime() + (config.get('CustjwtExp') * 1000)

                var payload = {
                  "aud": config.get('CustjwtAud'),
                  "exp": expireTime,
                  "iss": config.get('CustjwtIss'),
                  "usertoken": data2.Result.AccessToken
                };


                var signOptions = {
                  "algorithm": "RS256"
                };

                var privateKey = config.get('LoginprivateKey');
                var token = jwt.sign(payload, privateKey, signOptions);
                //  console.log(token);

                const requestUserDtlsOptions = {
                  method: 'POST',
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ` + token,
                  },
                  body: JSON.stringify({ "Email": sessionStorage.getItem('tmpEmail').toLowerCase(), "OrganizationID": config.get('OrganisationID') })
                };

                // console.log(requestUserDtlsOptions)
                fetch(config.get('API_URL_cust') + 'fetch', requestUserDtlsOptions)
                  .then(response => response.json())
                  .then(function (data) {
                    //  console.log(data);

                    sessionStorage.setItem('username', data.Result.UserName);
                    sessionStorage.setItem('userfirstname', data.Result.FirstName);
                    sessionStorage.setItem('userlastname', data.Result.LastName);
                    sessionStorage.setItem('useremail', values.username);
                    sessionStorage.setItem('UserID', data.Result.UserID);
                    sessionStorage.setItem('OriginatedOrganizationID', data.Result.OriginatedOrganizationID);
                    localStorage.setItem('fullUserDetails', JSON.stringify(data.Result));
                    //window.location.href = "/account/member"

                    Modal.success({
                      title: 'Account registration success',
                      content: 'Click ok to continue to your account',
                      onOk() { window.location.href = "/account/member" },
                    });

                  });


              })





          } else {




            //  console.log(secondAddresss)
            fetch(config.get('API_URL_cust') + 'add', requestOptions)
              .then(response => response.json())
              .then(function (data) {
                //  console.log(data);


                if (data.Status === false) {

                  Modal.error({
                    title: 'Sorry Something went wrong',
                    content: 'Account registration failed, Please try again',
                    onOk() { window.location.href = "/account/login" },
                  });
                } else if (data.Result == "Customer Already Exist in Global. Cannot insert again") {

                  Modal.error({
                    title: 'Please login to continue',
                    content: 'Account already in our system, please click login to access our services',
                    onOk() { window.location.href = "/account/login" },
                  });
                } else {


                  sessionStorage.setItem('AccessToken', data.Result.AccessToken);
                  sessionStorage.setItem('RefreshToken', data.Result.RefreshToken);
                  sessionStorage.setItem('loggedin', "true");
                  //console.log(data.Result)
                  const expireTime = new Date().getTime() + (config.get('CustjwtExp') * 1000)

                  var payload = {
                    "aud": config.get('CustjwtAud'),
                    "exp": expireTime,
                    "iss": config.get('CustjwtIss'),
                    "usertoken": data.Result.AccessToken
                  };


                  var signOptions = {
                    "algorithm": "RS256"
                  };

                  var privateKey = config.get('LoginprivateKey');
                  var token = jwt.sign(payload, privateKey, signOptions);
                  // console.log(token);

                  const requestUserDtlsOptions = {
                    method: 'POST',
                    headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ` + token,
                    },
                    body: JSON.stringify({ "Email": sessionStorage.getItem('tmpEmail').toLowerCase(), "OrganizationID": config.get('OrganisationID') })
                  };

                  //  console.log(requestUserDtlsOptions)
                  fetch(config.get('API_URL_cust') + 'fetch', requestUserDtlsOptions)
                    .then(response => response.json())
                    .then(function (data) {
                      //    console.log(data);

                      sessionStorage.setItem('username', data.Result.UserName);
                      sessionStorage.setItem('userfirstname', data.Result.FirstName);
                      sessionStorage.setItem('userlastname', data.Result.LastName);
                      sessionStorage.setItem('useremail', values.username);
                      sessionStorage.setItem('UserID', data.Result.UserID);
                      sessionStorage.setItem('OriginatedOrganizationID', data.Result.OriginatedOrganizationID);
                      localStorage.setItem('fullUserDetails', JSON.stringify(data.Result));
                      //window.location.href = "/account/member"

                      Modal.success({
                        title: 'Account registration success',
                        content: 'Click ok to continue to your account',
                        onOk() { window.location.href = "/account/member" },
                      });

                    });
                }

              })



          }

        })




      //  console.log('Received values of form: ', values);
    };

    const { country, region } = this.state;
    return (

      <>
        {(() => {

          switch (config.get('Theme')) {
            case 'Apparel':
              return (<>

                <div class="ps-breadcrumb">
                  <div className="container">
                    <ul className="breadcrumb">
                      <li><a href="/">Home</a></li>
                      <li><a href="/account">Your Account</a></li>
                      <li><a href="/account/register">Register</a></li>
                    </ul>
                  </div>
                </div>
                <section className="ps-section--account">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <AccountLeftMenu />
                      </div>
                      <div className="col-lg-8">
                        <div className="ps-section__right">



                          <div className="fade alert alert-light show">
                            <Row className="justify-content-md-center">

                              <Col sm={12}>
                                <div className="ps-form__header">
                                  <h3> Create an Account</h3>
                                </div>

                                <Divider orientation="left"><h6>NAME</h6></Divider>

                                <Form layout="vertical"
                                  name="register"
                                  onFinish={onFinish} >
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="FirstName"
                                        label="First Name"
                                        rules={[{ required: true, message: 'Please enter first name' }]}
                                      >
                                        <Input placeholder="Please enter first name" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="LastName"
                                        label="Last Name"
                                        rules={[{ required: true, message: 'Please enter last name' }]}
                                      >
                                        <Input placeholder="Please enter last name" />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Divider orientation="left"><h6>EMAIL/LOG IN INFORMATION</h6></Divider>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Email"
                                        label="Email"

                                      >
                                        <Input defaultValue={sessionStorage.getItem('tmpEmail')} readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="UserName"
                                        label="User Name"
                                        rules={[{ required: true, message: 'Please enter user name' }]}
                                      >
                                        <Input placeholder="Please enter user name" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Password"
                                        label="Password"
                                        rules={[{ required: true, message: 'Please enter password' }]}
                                      >
                                        <Input.Password placeholder="Please enter password" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="RePassword"
                                        label="Confirm Password"
                                        dependencies={['Password']}
                                        rules={[{ required: true, message: 'Please enter a value for confirm password' },
                                        ({ getFieldValue }) => ({
                                          validator(rule, value) {
                                            if (!value || getFieldValue('Password') === value) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                          },
                                        }),]}
                                      >
                                        <Input.Password placeholder="Please enter confirm password" />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Divider orientation="left"><h6>ADDRESS INFORMATION</h6></Divider>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="AddressType"
                                        label="Address Type"
                                        rules={[{ required: true, message: 'Please enter a address type' }]}
                                      >

                                        <Select defaultValue=""  >
                                          <Option value="">Please Select</Option>
                                          <Option value="Billing">Billing</Option>
                                          <Option value="Delivery">Delivery</Option>
                                          <Option value="Home">Home</Option>
                                          <Option value="Other">Other</Option>
                                        </Select>

                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Button type="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                                        Create an additional address
                                      </Button>
                                    </Col>
                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Address"
                                        label="Address"
                                        rules={[{ required: true, message: 'Please enter address' }]}
                                      >
                                        <Input placeholder="Please enter address" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Address2"
                                        label="Address 2"
                                        rules={[{ required: false, message: 'Please enter address 2' }]}
                                      >
                                        <Input placeholder="Please enter address 2" />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="City"
                                        label="City"
                                        rules={[{ required: true, message: 'Please enter city' }]}
                                      >
                                        <Input placeholder="Please enter city" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="PostZipCode"
                                        label="Post / Zip Code"
                                        rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                      >
                                        <Input placeholder="Please enter post / zip code" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>

                                    <Col span={12}>
                                      <Form.Item
                                        name="Country"
                                        label="Country"
                                        rules={[{ required: true, message: 'Please enter country' }]}
                                      >

                                        <CountryDropdown
                                          value={country}
                                          valueType="short"
                                          classes="ant-input"
                                          onChange={(val) => this.selectCountry(val)} />
                                      </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                      <Form.Item
                                        name="StateProvinceRegion"
                                        label="State / Province / Region"
                                        rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                      >
                                        <RegionDropdown
                                          country={country}
                                          countryValueType="short"
                                          value={region}
                                          classes="ant-input"
                                          onChange={(val) => this.selectRegion(val)} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Phone"
                                        label="Phone"
                                        rules={[{ required: true, message: 'Please enter valid phone' }]}
                                      >
                                        <Input placeholder="Please enter phone" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Fax"
                                        label="Landline"
                                        rules={[{ message: 'Please enter valid landline' }]}
                                      >
                                        <Input placeholder="Please enter landline" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="AddressEmail"
                                        label="Email"
                                        rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                      >
                                        <Input placeholder="Please enter email" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>

                                    </Col>
                                  </Row>


                                  <UncontrolledCollapse toggler="#toggler">
                                    <>
                                      <Divider orientation="left"><h6>ADDITIONAL ADDRESS INFORMATION</h6></Divider>

                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddressType"
                                            label="Address Type"
                                            rules={[{ required: false, message: 'Please enter a address type' }]}
                                          >
                                            <Select defaultValue="Delivery"  >
                                              <Option value="Billing">Billing</Option>
                                              <Option value="Delivery">Delivery</Option>
                                              <Option value="Home">Home</Option>
                                              <Option value="Other">Other</Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>

                                      </Row>

                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddress"
                                            label="Address"
                                            rules={[{ required: false, message: 'Please enter address' }]}
                                          >
                                            <Input placeholder="Please enter address" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddress2"
                                            label="Address 2"
                                            rules={[{ required: false, message: 'Please enter address 2' }]}
                                          >
                                            <Input placeholder="Please enter address 2" />
                                          </Form.Item>
                                        </Col>
                                      </Row>

                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OCity"
                                            label="City"
                                            rules={[{ required: false, message: 'Please enter city' }]}
                                          >
                                            <Input placeholder="Please enter city" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OStateProvinceRegion"
                                            label="State / Province / Region"
                                            rules={[{ required: false, message: 'Please enter state / province / region' }]}
                                          >
                                            <RegionDropdown
                                              country={country}
                                              value={region}
                                              countryValueType="short"
                                              classes="ant-input"
                                              onChange={(val) => this.selectRegion1(val)} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OPostZipCode"
                                            label="Post / Zip Code"
                                            rules={[{ required: false, message: 'Please enter post / zip code' }]}
                                          >
                                            <Input placeholder="Please enter post / zip code" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OCountry"
                                            label="Country"
                                            rules={[{ required: false, message: 'Please enter country' }]}
                                          >
                                            <CountryDropdown
                                              value={country}
                                              valueType="short"
                                              classes="ant-input"
                                              onChange={(val) => this.selectCountry1(val)} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OPhone"
                                            label="Phone"
                                            rules={[{ required: false, message: 'Please enter valid phone' }]}
                                          >
                                            <Input placeholder="Please enter phone" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OFax"
                                            label="Landline"
                                            rules={[{ message: 'Please enter valid landline' }]}
                                          >
                                            <Input placeholder="Please enter landline" />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddressEmail"
                                            label="Email"
                                            rules={[{ type: 'email', required: false, message: 'Please enter valid email' }]}
                                          >
                                            <Input placeholder="Please enter email" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>

                                        </Col>
                                      </Row>



                                    </>
                                  </UncontrolledCollapse>

                                  <Row gutter={16}>
                                    {this.state.Name} is committed to respecting your privacy. By continuing you are accepting our&nbsp; Terms and Conditions, and our&nbsp;   Privacy Policy.
                                  </Row>
                                  <br />
                                  <Form.Item  >

                                    <div className="form-group submit">
                                      <button className="ps-btn ps-btn--fullwidth" ref="btn"  > {this.state.disabled ? 'Please wait...' : 'Complete Registration'}</button>
                                    </div>




                                  </Form.Item>

                                </Form>




                              </Col>
                            </Row>

                          </div>




                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </>)
            case 'Martfury':
              return (<>

                <div class="ps-breadcrumb">
                  <div className="container">
                    <ul className="breadcrumb">
                      <li><a href="/">Home</a></li>
                      <li><a href="/account">Your Account</a></li>
                      <li><a href="/account/register">Register</a></li>
                    </ul>
                  </div>
                </div>
                <section className="ps-section--account">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <AccountLeftMenu />
                      </div>
                      <div className="col-lg-8">
                        <div className="ps-section__right">



                          <div className="fade alert alert-light show">
                            <Row className="justify-content-md-center">

                              <Col sm={12}>
                                <div className="ps-form__header">
                                  <h3> Create an Account</h3>
                                </div>

                                <Divider orientation="left"><h6>NAME</h6></Divider>

                                <Form layout="vertical"
                                  name="register"
                                  onFinish={onFinish} >
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="FirstName"
                                        label="First Name"
                                        rules={[{ required: true, message: 'Please enter first name' }]}
                                      >
                                        <Input placeholder="Please enter first name" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="LastName"
                                        label="Last Name"
                                        rules={[{ required: true, message: 'Please enter last name' }]}
                                      >
                                        <Input placeholder="Please enter last name" />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Divider orientation="left"><h6>EMAIL/LOG IN INFORMATION</h6></Divider>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Email"
                                        label="Email"

                                      >
                                        <Input defaultValue={sessionStorage.getItem('tmpEmail')} readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="UserName"
                                        label="User Name"
                                        rules={[{ required: true, message: 'Please enter user name' }]}
                                      >
                                        <Input placeholder="Please enter user name" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Password"
                                        label="Password"
                                        rules={[{ required: true, message: 'Please enter password' }]}
                                      >
                                        <Input.Password placeholder="Please enter password" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="RePassword"
                                        label="Confirm Password"
                                        dependencies={['Password']}
                                        rules={[{ required: true, message: 'Please enter a value for confirm password' },
                                        ({ getFieldValue }) => ({
                                          validator(rule, value) {
                                            if (!value || getFieldValue('Password') === value) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                          },
                                        }),]}
                                      >
                                        <Input.Password placeholder="Please enter confirm password" />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Divider orientation="left"><h6>ADDRESS INFORMATION</h6></Divider>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="AddressType"
                                        label="Address Type"
                                        rules={[{ required: true, message: 'Please enter a address type' }]}
                                      >

                                        <Select defaultValue=""  >
                                          <Option value="">Please Select</Option>
                                          <Option value="Billing">Billing</Option>
                                          <Option value="Delivery">Delivery</Option>
                                          <Option value="Home">Home</Option>
                                          <Option value="Other">Other</Option>
                                        </Select>

                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Button type="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                                        Create an additional address
                                      </Button>
                                    </Col>
                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Address"
                                        label="Address"
                                        rules={[{ required: true, message: 'Please enter address' }]}
                                      >
                                        <Input placeholder="Please enter address" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Address2"
                                        label="Address 2"
                                        rules={[{ required: false, message: 'Please enter address 2' }]}
                                      >
                                        <Input placeholder="Please enter address 2" />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="City"
                                        label="City"
                                        rules={[{ required: true, message: 'Please enter city' }]}
                                      >
                                        <Input placeholder="Please enter city" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="StateProvinceRegion"
                                        label="State / Province / Region"
                                        rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                      >
                                        <RegionDropdown
                                          country={country}
                                          countryValueType="short"
                                          value={region}
                                          classes="ant-input"
                                          onChange={(val) => this.selectRegion(val)} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="PostZipCode"
                                        label="Post / Zip Code"
                                        rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                      >
                                        <Input placeholder="Please enter post / zip code" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Country"
                                        label="Country"
                                        rules={[{ required: true, message: 'Please enter country' }]}
                                      >

                                        <CountryDropdown
                                          value={country}
                                          valueType="short"
                                          classes="ant-input"
                                          onChange={(val) => this.selectCountry(val)} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Phone"
                                        label="Phone"
                                        rules={[{ required: true, message: 'Please enter valid phone' }]}
                                      >
                                        <Input placeholder="Please enter phone" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="Fax"
                                        label="Landline"
                                        rules={[{ message: 'Please enter valid landline' }]}
                                      >
                                        <Input placeholder="Please enter landline" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="AddressEmail"
                                        label="Email"
                                        rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                      >
                                        <Input placeholder="Please enter email" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>

                                    </Col>
                                  </Row>


                                  <UncontrolledCollapse toggler="#toggler">
                                    <>
                                      <Divider orientation="left"><h6>ADDITIONAL ADDRESS INFORMATION</h6></Divider>

                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddressType"
                                            label="Address Type"
                                            rules={[{ required: false, message: 'Please enter a address type' }]}
                                          >
                                            <Select defaultValue="Delivery"  >
                                              <Option value="Billing">Billing</Option>
                                              <Option value="Delivery">Delivery</Option>
                                              <Option value="Home">Home</Option>
                                              <Option value="Other">Other</Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>

                                      </Row>

                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddress"
                                            label="Address"
                                            rules={[{ required: false, message: 'Please enter address' }]}
                                          >
                                            <Input placeholder="Please enter address" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddress2"
                                            label="Address 2"
                                            rules={[{ required: false, message: 'Please enter address 2' }]}
                                          >
                                            <Input placeholder="Please enter address 2" />
                                          </Form.Item>
                                        </Col>
                                      </Row>

                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OCity"
                                            label="City"
                                            rules={[{ required: false, message: 'Please enter city' }]}
                                          >
                                            <Input placeholder="Please enter city" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OStateProvinceRegion"
                                            label="State / Province / Region"
                                            rules={[{ required: false, message: 'Please enter state / province / region' }]}
                                          >
                                            <RegionDropdown
                                              country={country}
                                              value={region}
                                              countryValueType="short"
                                              classes="ant-input"
                                              onChange={(val) => this.selectRegion1(val)} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OPostZipCode"
                                            label="Post / Zip Code"
                                            rules={[{ required: false, message: 'Please enter post / zip code' }]}
                                          >
                                            <Input placeholder="Please enter post / zip code" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OCountry"
                                            label="Country"
                                            rules={[{ required: false, message: 'Please enter country' }]}
                                          >
                                            <CountryDropdown
                                              value={country}
                                              valueType="short"
                                              classes="ant-input"
                                              onChange={(val) => this.selectCountry1(val)} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OPhone"
                                            label="Phone"
                                            rules={[{ required: false, message: 'Please enter valid phone' }]}
                                          >
                                            <Input placeholder="Please enter phone" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OFax"
                                            label="Landline"
                                            rules={[{ message: 'Please enter valid landline' }]}
                                          >
                                            <Input placeholder="Please enter landline" />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row gutter={16}>
                                        <Col span={12}>
                                          <Form.Item
                                            name="OAddressEmail"
                                            label="Email"
                                            rules={[{ type: 'email', required: false, message: 'Please enter valid email' }]}
                                          >
                                            <Input placeholder="Please enter email" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>

                                        </Col>
                                      </Row>



                                    </>
                                  </UncontrolledCollapse>

                                  <Row gutter={16}>
                                    {this.state.Name} is committed to respecting your privacy. By continuing you are accepting our&nbsp; Terms and Conditions, and our&nbsp;   Privacy Policy.
                                  </Row>
                                  <br />
                                  <Form.Item  >

                                    <div className="form-group submit">
                                      <button className="ps-btn ps-btn--fullwidth" ref="btn"  > {this.state.disabled ? 'Please wait...' : 'Complete Registration'}</button>
                                    </div>




                                  </Form.Item>

                                </Form>




                              </Col>
                            </Row>

                          </div>




                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </>)
            default:
              return (
                <div>
                  <Row>

                    <Col sm={12} >
                      <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/account">
                          Your Account
                        </Breadcrumb.Item> <Breadcrumb.Item href="/account/register">
                          Register
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>

                    <Col sm={3}>

                      <AccountLeftMenu />
                    </Col>

                    <Col sm={8}>
                      <div className="fade alert alert-light show">
                        <Row className="justify-content-md-center">

                          <Col sm={12}>
                            <Divider orientation="left"><h5>CREATE AN ACCOUNT</h5></Divider>

                            <Divider orientation="left"><h6>NAME</h6></Divider>

                            <Form layout="vertical"
                              name="register"
                              onFinish={onFinish} >
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="FirstName"
                                    label="First Name"
                                    rules={[{ required: true, message: 'Please enter first name' }]}
                                  >
                                    <Input placeholder="Please enter first name" />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="LastName"
                                    label="Last Name"
                                    rules={[{ required: true, message: 'Please enter last name' }]}
                                  >
                                    <Input placeholder="Please enter last name" />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Divider orientation="left"><h6>EMAIL/LOG IN INFORMATION</h6></Divider>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="Email"
                                    label="Email"

                                  >
                                    <Input defaultValue={sessionStorage.getItem('tmpEmail')} readOnly />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="UserName"
                                    label="User Name"
                                    rules={[{ required: true, message: 'Please enter user name' }]}
                                  >
                                    <Input placeholder="Please enter user name" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>

                                  <Form.Item
                                    name="Password"
                                    label="Password"
                                    rules={[{ required: true, message: 'Please enter password' }]}
                                  >
                                    <PasswordInput inputProps={{}} />
                                  </Form.Item>

                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="RePassword"
                                    label="Confirm Password"
                                    dependencies={['Password']}
                                    rules={[{ required: true, message: 'Please enter a value for confirm password' },
                                    ({ getFieldValue }) => ({
                                      validator(rule, value) {
                                        if (!value || getFieldValue('Password') === value) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                      },
                                    }),]}
                                  >
                                    <Input.Password placeholder="Please enter confirm password" />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Divider orientation="left"><h6>ADDRESS INFORMATION</h6></Divider>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="AddressType"
                                    label="Address Type"
                                    rules={[{ required: true, message: 'Please enter a address type' }]}
                                  >

                                    <Select defaultValue=""  >
                                      <Option value="">Please Select</Option>
                                      <Option value="Billing">Billing</Option>
                                      <Option value="Delivery">Delivery</Option>
                                      <Option value="Home">Home</Option>
                                      <Option value="Other">Other</Option>
                                    </Select>

                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Button type="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                                    Create an additional address
                                  </Button>
                                </Col>
                              </Row>

                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="Address"
                                    label="Address"
                                    rules={[{ required: true, message: 'Please enter address' }]}
                                  >
                                    <Input placeholder="Please enter address" />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="Address2"
                                    label="Address 2"
                                    rules={[{ required: false, message: 'Please enter address 2' }]}
                                  >
                                    <Input placeholder="Please enter address 2" />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="City"
                                    label="City"
                                    rules={[{ required: true, message: 'Please enter city' }]}
                                  >
                                    <Input placeholder="Please enter city" />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="StateProvinceRegion"
                                    label="State / Province / Region"
                                    rules={[{ required: true, message: 'Please enter state / province / region' }]}
                                  >
                                    <RegionDropdown
                                      country={country}
                                      countryValueType="short"
                                      value={region}
                                      classes="ant-input"
                                      onChange={(val) => this.selectRegion(val)} />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="PostZipCode"
                                    label="Post / Zip Code"
                                    rules={[{ required: true, message: 'Please enter post / zip code' }]}
                                  >
                                    <Input placeholder="Please enter post / zip code" />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="Country"
                                    label="Country"
                                    rules={[{ required: true, message: 'Please enter country' }]}
                                  >

                                    <CountryDropdown
                                      value={country}
                                      valueType="short"
                                      classes="ant-input"
                                      onChange={(val) => this.selectCountry(val)} />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="Phone"
                                    label="Phone"
                                    rules={[{ required: true, message: 'Please enter valid phone' }]}
                                  >
                                    <Input placeholder="Please enter phone" />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="Fax"
                                    label="Landline"
                                    rules={[{ message: 'Please enter valid landline' }]}
                                  >
                                    <Input placeholder="Please enter landline" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="AddressEmail"
                                    label="Email"
                                    rules={[{ type: 'email', required: true, message: 'Please enter valid email' }]}
                                  >
                                    <Input placeholder="Please enter email" />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>

                                </Col>
                              </Row>


                              <UncontrolledCollapse toggler="#toggler">
                                <>
                                  <Divider orientation="left"><h6>ADDITIONAL ADDRESS INFORMATION</h6></Divider>

                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OAddressType"
                                        label="Address Type"
                                        rules={[{ required: false, message: 'Please enter a address type' }]}
                                      >
                                        <Select defaultValue="Delivery"  >
                                          <Option value="Billing">Billing</Option>
                                          <Option value="Delivery">Delivery</Option>
                                          <Option value="Home">Home</Option>
                                          <Option value="Other">Other</Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>

                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OAddress"
                                        label="Address"
                                        rules={[{ required: false, message: 'Please enter address' }]}
                                      >
                                        <Input placeholder="Please enter address" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OAddress2"
                                        label="Address 2"
                                        rules={[{ required: false, message: 'Please enter address 2' }]}
                                      >
                                        <Input placeholder="Please enter address 2" />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OCity"
                                        label="City"
                                        rules={[{ required: false, message: 'Please enter city' }]}
                                      >
                                        <Input placeholder="Please enter city" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OStateProvinceRegion"
                                        label="State / Province / Region"
                                        rules={[{ required: false, message: 'Please enter state / province / region' }]}
                                      >
                                        <RegionDropdown
                                          country={country}
                                          value={region}
                                          countryValueType="short"
                                          classes="ant-input"
                                          onChange={(val) => this.selectRegion1(val)} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OPostZipCode"
                                        label="Post / Zip Code"
                                        rules={[{ required: false, message: 'Please enter post / zip code' }]}
                                      >
                                        <Input placeholder="Please enter post / zip code" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OCountry"
                                        label="Country"
                                        rules={[{ required: false, message: 'Please enter country' }]}
                                      >
                                        <CountryDropdown
                                          value={country}
                                          valueType="short"
                                          classes="ant-input"
                                          onChange={(val) => this.selectCountry1(val)} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OPhone"
                                        label="Phone"
                                        rules={[{ required: false, message: 'Please enter valid phone' }]}
                                      >
                                        <Input placeholder="Please enter phone" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OFax"
                                        label="Landline"
                                        rules={[{ message: 'Please enter valid landline' }]}
                                      >
                                        <Input placeholder="Please enter landline" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="OAddressEmail"
                                        label="Email"
                                        rules={[{ type: 'email', required: false, message: 'Please enter valid email' }]}
                                      >
                                        <Input placeholder="Please enter email" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>

                                    </Col>
                                  </Row>



                                </>
                              </UncontrolledCollapse>

                              <Row gutter={16}>
                                {this.state.Name} is committed to respecting your privacy. By continuing you are accepting our&nbsp; Terms and Conditions, and our&nbsp;   Privacy Policy.
                              </Row>
                              <br />
                              <Form.Item  >


                                <Button type="primary" htmlType="submit" ref="btn" danger >
                                  {this.state.disabled ? 'Please wait...' : 'Complete Registration'}
                                </Button>


                              </Form.Item>

                            </Form>




                          </Col>
                        </Row>

                      </div>

                    </Col>



                  </Row>
                </div>
              )
          }

        })()}
      </>
    )
  }
}

export default Registration;