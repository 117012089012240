import React, { Component } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import FooterInclude from "./footer.component.include"

const Page = props => (

  <li> <a href={'/pages/' + props.page.slug}>{props.page.pagetitle}</a></li>
)

export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = { menus: [], footer2: [] };
  }

  componentDidMount() {

    var primaryTextStyle = "color:#001a96; font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: 400; font-style: normal; font-size: 60px;";
    var secondaryTextStyle = "color: #e00099;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 36px;";
    var tertiaryTextStyle = "color: #3d3d3d;font-family: 'Haas Grot Text Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 24px;";

    console.group("Work with us!");
    console.log("%cEcologital%c is hiring.", primaryTextStyle, tertiaryTextStyle);
    console.log("%c Go to%c https://www.ecologital.com %cto apply!", tertiaryTextStyle, secondaryTextStyle, tertiaryTextStyle);
    console.groupEnd();
 
      fetch(config.get('backendURL') + 'menus/footer/' + config.get('companyID'))
        .then(response => response.json())
        .then(data => this.onSetFooter(data));
    

    axios.get(config.get('backendURL') + 'company/' + config.get('companyID'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
          Phone: response.data.Phone,
          Fax: response.data.Fax,
          Email: response.data.Email,
          OpenningHours: response.data.OpenningHours,
        })
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  onSetFooter = (result) => {
    this.setState({ menus: result })
   // console.log(result)
    sessionStorage.setItem('footer', JSON.stringify(result));
  };

  render() {
    return (

      <Row >
 
        {
          this.state.menus.map(menu => (
            <Col className="footer" key={menu._id} sm={12} md={this.state.menus.length >= 3 ? 2 : 12/(2+this.state.menus.length)}> 
              <Nav defaultActiveKey="/home" className="flex-column">
                <div className="linkGroup_3qKNM">
                  <h5><b>{menu.description}</b></h5>
                  <FooterInclude params={{ FooterItemId: menu._id }} />
                </div>
              </Nav>
            </Col>

          )
          )}


        <Col className="footer" sm={12} md={this.state.menus.length >= 3 ? 3 : 12/(2+this.state.menus.length)}>

          <div className="linkGroup_3qKNM SoFtr">
            <h5><b>Follow Us</b></h5>
            {
          
          config.get('SocialMedia') ? (
  JSON.parse(config.get('SocialMedia')).map(sm =>
   <>
    <a href={sm.URL} key={sm._id} ><img src={sm.icons} alt={sm.Media} width="32" /></a>&nbsp;&nbsp;
    </>
  )
  ) : (
    <></>
    )}
    
          </div>
          <div className="linkGroup_3qKNM">
            <h5><b>We Accept</b></h5>
            <ul>
              <li><img src="/images/visa.svg" alt="Visa" width="30" /> <img src="/images/mastercard.svg" alt="Visa" width="30" /> <img src="/images/amex.svg" alt="Visa" width="30" /></li>
            </ul>
          </div>

        </Col>


        <Col className="footer" sm={12} md={this.state.menus.length >= 3 ? 3 : 12/(2+this.state.menus.length)}>

          {this.state.Name ? this.state.Name : ''}<br />
          {this.state.AddressLine1 ? (<> {this.state.AddressLine1} <br/></>) : ''}
          {this.state.AddressLine2 ? (<> {this.state.AddressLine2} <br/></>) : ''}
          {this.state.City ? (<> {this.state.City} <br/></>) : ''}
          {this.state.StateProvinceRegion ? (<> {this.state.StateProvinceRegion} <br/></>) : ''}
          {this.state.PostZipCode ? (<> {this.state.PostZipCode} <br/></>) : ''}
          {this.state.Country ? (<> {this.state.Country} <br/></>) : ''} 
          {this.state.Phone ? (<> <b>Phone</b> : {this.state.Phone} <br/></>) : ''} 
          {this.state.Fax ? (<> <b>Fax</b> : {this.state.Fax} <br/></>) : ''} 
          {this.state.Email ? (<> <b>Email</b> : {this.state.Email} <br/></>) : ''} 
          {this.state.OpenningHours ? (<> <b>Openning Hours</b> : {this.state.OpenningHours} <br/></>) : ''} 
        </Col>
        <Col sm={12} className="copyright">
          Copyright &copy; {this.state.Name}.
All Rights Reserved.
        </Col>
      </Row>
    );
  }
}