import React, { Component } from 'react';
import config from 'react-global-configuration';
import { SearchOutlined } from '@ant-design/icons';

export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
 

  componentDidMount() { 
    if (config.get('Theme') == 'Witmeg') {
        require('./css/style.css');
      }
  }

 
  render() {
  
    return (
      <>
         <header>
  <h2>Resource Center  | <SearchOutlined /></h2>
</header>


      </>

    );
  }
}