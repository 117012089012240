import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown, Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { Modal, Drawer, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faInfoCircle, faUserShield, faUserLock, faUserEdit, faShoppingCart, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import moment from 'moment';

const Page = props => (
  <Nav.Link href={'/pages/' + props.page.slug}>{props.page.pagetitle.toUpperCase()}</Nav.Link>
)

export default class Navbar2 extends Component {
  constructor(props) {
    super(props);
    this.state = { menusize: 0, menus: '', pages: [], categories: [], brands: [], basketitem: [] };
  }
  resize = () => {
    if (window.innerWidth > 1681) {
      this.setState({ menusize: 5 })
    } else if (window.innerWidth < 1400 && window.innerWidth > 1150) {
      this.setState({ menusize: 2 })
    } else {
      this.setState({ menusize: 3 })
    }
  }
  componentDidMount() {

    window.addEventListener('resize', this.resize)
    this.resize();

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    const cachedProduct = localStorage.getItem('cart');
    if (cachedProduct) {
      this.setState({ basketitem: JSON.parse(cachedProduct) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem('cart', JSON.stringify(myArray));
    }


    //console.log(JSON.parse(localStorage.getItem('cart')))

    const requestMenu = axios.get(config.get('backendURL') + 'menus/main/' + config.get('companyID'))
      .then(menuresponse => {
        const requestOne = axios.get(config.get('backendURL') + 'pages/byid/' + menuresponse.data[0]._id + '/' + config.get('companyID'))
          .then(response => {
            // console.log(response.data)
            this.setState({ pages: response.data })
          })
          .catch((error) => {
            console.log(error);
          })
      })
      .catch((error) => {
        console.log(error);
      })

    var hour = new Date().getHours();
    this.setState({ greet: "Good " + (hour < 12 && "Morning" || hour < 18 && "Afternoon" || "Evening") })

    const requestCategories = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };

    const cachedCategory = sessionStorage.getItem('menucategories');
    if (cachedCategory) {
      this.setState({ categories: JSON.parse(cachedCategory) });
    } else {
      fetch(config.get('rest_API_productregistry') + '/onlinecategories/' + config.get('OrganisationID'), requestCategories)
        .then(response => response.json())
        .then(data => this.onSetCategoryResult(data.Result, data.Result));
    }

    //console.log(sessionStorage.getItem('menucategories'))
    const requestBrands = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };

    const cachedMenu = sessionStorage.getItem('menubrands');
    if (cachedMenu) {
      this.setState({ brands: JSON.parse(cachedMenu) });
    } else {
      fetch(config.get('rest_API_productregistry') + '/onlinebrands/' + config.get('OrganisationID'), requestBrands)
        .then(response => response.json())
        .then(data => this.onSetBrandResult(data.Result, data.Result));
    }
  }

  state = { visible: false, childrenDrawer: false };
  handlePageClick = (value) => {
    this.props.parentPageCallback(value)
  }

  onLogout = () => {
    sessionStorage.setItem('loggedin', false);
    sessionStorage.setItem('username', "");
    localStorage.setItem('fullUserDetails', "");
    sessionStorage.setItem('deliverycost', '');
    window.location.reload();
  };
  onSetCategoryResult = (result, key) => {
    this.setState({ categories: result })
    sessionStorage.setItem('menucategories', JSON.stringify(result));
  };
  onSetBrandResult = (result, key) => {
    this.setState({ brands: result })
    sessionStorage.setItem('menubrands', JSON.stringify(result));
  };

  showDrawer = () => {
    const cachedCart = localStorage.getItem('cart');
    this.setState({
      visible: true,
      basketitem: JSON.parse(cachedCart)
    });

  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    this.state.offer = (this.state.basketitem.reduce((accumulator, currentofferValue) => accumulator + parseFloat(currentofferValue.OfferAmount * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
    this.state.total = (this.state.basketitem.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.ProductQty * currentValue.ProductPrice * localStorage.getItem('ecurrencyratio')), 0)).toFixed(2)
    this.state.cartqty = (this.state.basketitem.reduce((accumulator, currentqtyValue) => accumulator + parseFloat(currentqtyValue.ProductQty), 0))
    const { basketitem, total, cartqty, offer } = this.state;

    const { categories } = this.state;
    const { brands } = this.state;
    const isBasketNotEmpty = this.state.basketitem.length;
    let button;



    if (isBasketNotEmpty) {
      button = <div>

        <Row>
          <Col xs={8} md={9} className="text-right">
            Sub Total
          </Col>
          <Col xs={4} md={3}>
            {localStorage.getItem('ecurrencyCode')} {this.state.total}
          </Col></Row><hr />


        {

          this.state.offer != 0 ? (<>
            <hr />
            <Row>
              <Col xs={8} md={9} className="text-right">
                <p class="text-danger">Offer Discount:</p>
              </Col>
              <Col xs={4} md={3}>
                <p class="text-danger">
                  {localStorage.getItem('ecurrencyCode')} -{parseFloat(this.state.offer).toFixed(2)}</p>
              </Col></Row>
          </>) : ''
        }


        <Row>
          <Col xs={8} md={9} className="text-right">
            <b>Total</b>
          </Col>
          <Col xs={4} md={3}>
            <b> {localStorage.getItem('ecurrencyCode')} {parseFloat(this.state.total - this.state.offer).toFixed(2)}</b>
          </Col></Row><hr /><hr />
        <Row>
          <Col md={1}></Col>
          <Col md={8}>
            <Button href="/cart/" key="cart" type="primary" block>
              View Cart
      </Button>

          </Col>
          <Col md={3}></Col>
        </Row>
      </div>;
    } else {
      button = <div><Button href="/products/" type="primary">
        Start Shopping
      </Button></div>;
    }

    return (
      <Navbar bg="light" expand="lg" >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            <NavDropdown title="CATEGORIES" id="basic-nav-dropdown1">
              {categories.map(category =>
                <NavDropdown.Item href={'/group/' + category.ProductCategory.ProductCategoryID + '/' + category.ProductCategory.Category} key={'cat' + category.ProductCategory.ProductCategoryID}>{category.ProductCategory.Category}</NavDropdown.Item>
              )}
            </NavDropdown>
            <NavDropdown title="BRANDS" id="basic-nav-dropdown">
              {brands.map(brand =>
                <NavDropdown.Item href={'/brand/' + brand.BrandID + "/" + brand.BrandName} key={'brand' + brand.BrandID}>{brand.BrandName}</NavDropdown.Item>
              )}
            </NavDropdown>
            <Nav.Link href='/products/'>SHOP NOW</Nav.Link>



            {
              this.state.pages.slice(0, this.state.menusize).map(currentpage =>
                <Nav.Link href="#" onClick={this.handlePageClick.bind(this, currentpage.slug)}>{currentpage.pagetitle.toUpperCase()}</Nav.Link>
              )
            }

            {this.state.menusize > 0 ? (<NavDropdown title="More" id="basic-nav-dropdown">
              {
                this.state.pages.slice(this.state.menusize - 1, 100).map(currentpage =>
                  <NavDropdown.Item href="#" onClick={this.handlePageClick.bind(this, currentpage.slug)}>{currentpage.pagetitle.toUpperCase()}</NavDropdown.Item>
                )
              }
            </NavDropdown>) : ('')
            }

          </Nav>
        </Navbar.Collapse>

        <Nav>
          {config.get('HelpPage') != "" ? (
            <Nav.Item>
              <Nav.Link href={config.get('HelpPage')}><FontAwesomeIcon icon={faInfoCircle} /> Help</Nav.Link>
            </Nav.Item>
          ) : (<></>
            )}
          {config.get('WishListEnabled') == true ? (
            <Nav.Item>
              <Nav.Link href="#" eventKey="faHeart"><FontAwesomeIcon icon={faHeart} /> Wishlist</Nav.Link>
            </Nav.Item>
          ) : (<></>
            )}
          <Nav.Item>
            {sessionStorage.getItem('loggedin') == "true" ? (

              <Nav.Link href="/account/member" eventKey="faUserLock"><FontAwesomeIcon icon={faUserEdit} /> {this.state.greet}, {sessionStorage.getItem('username')}</Nav.Link>

            ) : (

                <Nav.Link href="/account/login" eventKey="faUserLock"><FontAwesomeIcon icon={faUserLock} /> Sign In</Nav.Link>

              )}
          </Nav.Item>

          <Nav.Item>
            {sessionStorage.getItem('loggedin') == "true" ? (
              <Nav.Link eventKey="faUserShield" href="/account/login" onClick={this.onLogout.bind()}><FontAwesomeIcon icon={faSignOutAlt} /><span className="logout"> Logout</span></Nav.Link>
            ) : (
                <Nav.Link href="/account/register" eventKey="faUserShield" ><FontAwesomeIcon icon={faUserShield} /> Register</Nav.Link>
              )}

          </Nav.Item>
          <Nav.Item className="carticon">

            <a onClick={this.showDrawer} ><FontAwesomeIcon icon={faShoppingCart} color="white" /><span className="mini-cart-total-label"><sup id="cartcount">{

              this.state.cartqty
            }</sup></span></a>
            <Drawer
              title="My Cart"
              width={520}
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
            >

              <div>
                {this.state.basketitem.length ? (
                  this.state.basketitem.map(item => (
                    <Row key={item.ProductID}>

                      <Col md={2} xs={12}>{
                        item.ProductImage !== '' ?
                          <img src={item.ProductImage} width="80" />
                          : <img src="/images/noimage.jpg" alt="." width="80" />
                      }</Col>
                      <Col xs={6} md={5}>{item.ProductName}</Col>
                      <Col xs={2} md={2}>{item.ProductQty}</Col>
                      <Col xs={3} md={3}>{localStorage.getItem('ecurrencyCode')} {(item.ProductQty * item.ProductPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)}</Col>
                      <Col md={12}><hr></hr></Col>
                    </Row>
                  )
                  )
                ) : (
                    <span>
                      <h6>Your cart is empty</h6>
                      <p>Find hundred of quality products at bargain prices everyday!</p>
                    </span>
                  )}
                {button}
              </div>
            </Drawer>

          </Nav.Item>
        </Nav>
      </Navbar>

    );
  }
}