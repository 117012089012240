import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Col, Breadcrumb, Table, Modal } from 'react-bootstrap';
import { Skeleton, Button, Form, Input, Descriptions, Collapse } from 'antd';
import Grid from "@material-ui/core/Grid";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "../../shop/imagegallery";
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const ProductDetails = (props) => (


  <>

    {
      props.params.ploaded ? (
        props.params.productsdtlsbyid ? (
          props.params.productsdtlsbyid.map(product =>
            <>

              <div className="container breadcrumb-container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="breadcrumb-in">
                      <ul className="site-breadcrumb">
                        <li><a href='/'>Home</a></li>
                        <li><a href='/products'>Products</a></li>
                        <li class="current">{product.Name}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container'>

                <div className='row'>

                  <div className='col-md-6'>

                    <div className='pro-img-wrapper'>





                      <div className='prd-img-large'>

                        {
                          product.OnLineImages.PrimaryImages !== null ? (
                            <>
                              <Grid container xs={12} spacing={4}>
                                <Grid item xs={12}><ImageGallery params={{ ProductMainImage: product.OnLineImages.PrimaryImages, SpecialImages: product.OnLineImages.SpecialImages, productsdtlsbyid: props.params.productsdtlsbyid }} /></Grid>
                                <Grid container spacing={2} item xs={12} direction="column">
                                  <Grid item>
                                    <div id="myPortal" />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <input type="hidden" id={'prodprimimage' + product.ProductID} value={product.OnLineImages.PrimaryImages.Main} />

                            </>) : (

                            <>
                              <figure>
                                <div className="ps-wrapper">
                                  <div className="ps-product__gallery" data-arrow="true">
                                    <div className="item"><img src="/images/no-image.jpg" alt={product.Name} height="220" /></div>
                                    <input type="hidden" id={'prodprimimage' + product.ProductID} value='' />
                                  </div>
                                </div>
                              </figure>

                            </>
                          )
                        }

                      </div>
                    </div>

                  </div>

                  <div className='col-md-6'>

                    <div className="prd-details-text">

                      <p className="prd-title">{product.Name}</p>
                      <div className="prd-more-details">
                        {product.ProductOnlineDescription ? (<>
                          <p>Product Ref No: {product.ProductRefNo}</p>
                          <Descriptions title="Product Information">
                            <Descriptions.Item><div dangerouslySetInnerHTML={{ __html: product.ProductOnlineDescription }} /></Descriptions.Item>
                          </Descriptions>
                        </>) : ('')
                        }
                      </div>

                      <h6 className="prd-price"> <input type="hidden" id={'prodprice' + product.ProductID} value={product.SellingPricesArray.SellingPrice} />
                        {localStorage.getItem('ecurrencyCode')} {String(product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio'))}
                      </h6>
                      {
                        product.Discount != 0 ?
                          <div className="priceright col-sm-6">was {localStorage.getItem('ecurrencyCode')}{(parseInt(product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(product.Discount)} <br /><span className="dispricetxt">save {localStorage.getItem('ecurrencyCode')}{product.Discount}</span></div>
                          : ''
                      }
                      <div className="prd-count-inner p-det">

                        <div className='select-weight'>
                          <div class="form-group">
                            <label for="weight" className='ft-bld'>Weight</label>
                            <select class="form-control" id="weight">
                              <option>500g</option>
                              <option>1Kg</option>
                              <option>5Kg</option>
                            </select>
                          </div>
                        </div>

                        <div className='select-qty'>


                        {product.IsOutOfStock ? (<div className="text-center"> <p className="inputandbtn"><button className="notify" onClick={props.onNotify.bind(this, product.ProductID, product.Name, product.MaxOnlineOrderQty)} id={product.ProductID}><FontAwesomeIcon icon={faEnvelope} />Notify</button></p> <Modal
                        show={props.params.visible}
                        onHide={props.handleCancel}
                        centered
                        aria-labelledby="example-modal-sizes-title-sm"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-sm">
                            Notify
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form
                            onFinish={props.onFinish}
                            name="basic"
                            initialValues={{ remember: true }}
                          >
                            <Form.Item
                              label="Email Address"
                              name="email"
                              rules={[{ required: true, type: 'email' }]}
                            >
                              <Input placeholder="Email Address" />
                            </Form.Item>



                            <Form.Item  >
                              <Input name="prdt" type="hidden" id="prdt" value={product.ProductID} />
                              <Button type="primary" htmlType="submit" danger >
                                Submit
                              </Button>
                            </Form.Item>
                          </Form>

                        </Modal.Body>
                      </Modal></div>) : (
                        <>



                          <div className="prd-count">
                          <p class='ft-bld'>Quantity</p>
                            <div className="prd-count-inner qty-wrapper">
                              <span >
                                <button onClick={props.onQtyReduce.bind(this, product.ProductID)} className="icon-sub a-fix">
                                  <i className="fas fa-minus" />
                                  {/* <i class="far fa-trash-alt"></i> */}
                                </button>
                              </span>
                              <span className="qty-no a-fix">
                                <input type="text" min={1} max={product.MaxOnlineOrderQty == 0 ? 10000 : product.MaxOnlineOrderQty} precision={0} id={'prodqty' + product.ProductID} value={1} snap placeholder={1} />
                              </span>
                              <span >
                                <button onClick={props.onQtyIncrese.bind(this, product.ProductID)} className="icon-add a-fix">
                                  <i className="fas fa-plus" />
                                </button>
                              </span>
                            </div>
                          </div>


                        </>

                      )}

                         
                         
 
                        </div>

                      </div>


                      {
                        product.SellingPricesArray.UnitChartid > 0 ? (

                          <Table striped bordered hover size="sm" width="50%">
                            <thead>
                              <tr>
                                <th>Pack Size</th>
                                <th>Weight</th>
                                <th>Type</th>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                product.UnitCharts.length > 0 ? (
                                  product.UnitCharts.map(elu =>
                                    <tr>
                                      <td>{elu.Quantity}</td>
                                      <td>{product.Weight}</td>
                                      <td>{elu.UnitTypeName}</td>
                                    </tr>

                                  )
                                ) : (

                                  <tr>
                                    <td>1</td>
                                    <td>{product.Weight}</td>
                                    <td>EA</td>
                                  </tr>
                                )
                              }


                            </tbody>
                          </Table>
                        ) : ('')
                      }
                      <div className='qty-select-details'>
                        <p>Available Options (Weight) Pack</p>
                        <ul>
                          <li>500g</li>
                          <li>1Kg</li>
                          <li>5kg</li>
                        </ul>
                      </div>

                      <div className="prd-buttons">
                        <a href="#" className="prd-add" onClick={props.onAddItem.bind(this, 'add', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.offerdetails ? (product.offerdetails.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.offerdetails ? (product.offerdetails.Offer.PromotionQty) : '', product.offerdetails ? (product.offerdetails.Offer.Price) : '')} id={product.ProductID} >ADD TO CART</a>
                        <a href='/cart' onClick={props.onAddItem.bind(this, 'buy', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.offerdetails ? (product.offerdetails.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.offerdetails ? (product.offerdetails.Offer.PromotionQty) : '', product.offerdetails ? (product.offerdetails.Offer.Price) : '')}  className="prd-checkout">
                          Buy This Now
                        </a>
                      </div>

                    </div>

                  </div>
                  
                  {product.Offers[0] ? (product.Offers[0].Offer.OfferType == 3 ? (<>

<Collapse
  bordered={true}

  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
  className="multioffer"
>
  <Panel header="Multi Buy Offers - Click to View" key="1" className="site-collapse-custom-panel">
    <b class="text-danger">{product.Offers[0].Offer.OfferTypeDesc}</b><br />
    <b class="text-success">{product.Offers[0].Offer.PromotionName}</b><br /><br />

    {
      props.params.offerproducts.length > 2 ? (
        <><b>Buy Any {product.Offers[0].Offer.PromotionQty} of these</b></>)
        : (<><b>Buy {product.Offers[0].Offer.PromotionQty} of these</b></>)
    }
    <p>

      {

        props.params.offerproducts.map(PO =>
          <>
            {
              PO[0].Name != null ? (
                <><a href={'/product/' + PO[0].Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + PO[0].ProductID}>{PO[0].Name} - {PO[0].ProductRefNo}</a><br /></>
              ) : ''
            }
          </>
        )
      }

    </p>
  </Panel>

</Collapse>
</>) : '') : ''}

                </div>



                <div className='comm-details'>
                  <div className='row'>

                    <div className="col-sm-12">

                      <div className="accordion" id="accordionExample">
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Shipping & Return Policy
                              </button>
                            </h2>
                          </div>

                          <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                              We want you to be really happy with your purchase. In the exceptional circumstance that you're not, simply return the product to us by following the simple instructions below and we'll gladly exchange or refund it.

                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingTwo">
                            <h2 className="mb-0">
                              <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How to Return Products
                              </button>
                            </h2>
                          </div>
                          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div className="card-body">
                              We want you to be really happy with your purchase. In the exceptional circumstance that you're not, simply return the product to us by following the simple instructions below and we'll gladly exchange or refund it.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingThree">
                            <h2 className="mb-0">
                              <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Delivery
                              </button>
                            </h2>
                          </div>
                          <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div className="card-body">
                              We want you to be really happy with your purchase. In the exceptional circumstance that you're not, simply return the product to us by following the simple instructions below and we'll gladly exchange or refund it.
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>


              </div>

            </>

          )
        ) : (
          <Col sm={12}><br />
            <Skeleton active avatar paragraph={{ rows: 4 }} />
          </Col>
        )
      ) : (<Col sm={12}><br />
        <Skeleton active avatar paragraph={{ rows: 4 }} />
      </Col>)
    }

 
  </>

);
export default ProductDetails;
