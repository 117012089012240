import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingCart, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import * as NumericInput from "react-numeric-input";
import { Row, Col, Breadcrumb, Table, Modal } from 'react-bootstrap';
import LeftMenu from "../themes/default/leftmenusearch.component";
import jwt from 'jsonwebtoken';
import LazyLoad from 'react-lazyload';
import { renderEmail } from 'postonents';
import { Skeleton, message, Button, Form, Input } from 'antd';
import config from 'react-global-configuration';
import NotifyEmail from '../email/NotifyEmail';
import ReactPaginate from 'react-paginate';
import ProducBoxApparel from './product-box';
import ProducBoxMartFury from '../themes/martfury/product-box';
import $ from 'jquery';
import LeftApparelCasiMenu from "../themes/apparel-casi/leftmenu.component";


import {
  LoadingOutlined,
} from '@ant-design/icons';

import ProducBoxSpiceway from '../themes/spiceway/product-box';
import LeftSpicewayMenu from "../themes/spiceway/leftmenu.component";

const key = 'updatable';


class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = { category: 'Search results for "' + this.props.match.params.id + '"', productscount: '', perpage: 12, products: [], basketitem: [], basketqty: [], loaded: false, regexp: /^[0-9\b]+$/ };
  }


  componentDidMount() {
    document.getElementById("search").value = this.props.match.params.id
    document.getElementById("searchcat").value = this.props.match.params.catid
    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    if(this.props.match.params.catid) {

      const serequestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify({
          "Name": this.props.match.params.id.trim(),
          "ProductCategoryIDs": Number(this.props.match.params.catid)||0,
        })
      };
      fetch(config.get('rest_API_productregistry_V2') + `/smartfilter/pagination/${config.get('OrganisationID')}/${JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID}/0/12`, serequestOptions)
        .then(response => response.json())
        .then(async data => {
          const products = data.Result;
          // console.log(data)
          this.setState({ products: products, ploaded: true })
        })


      const requestCountOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify({
          "Name": this.props.match.params.id.trim(),
          "ProductCategoryIDs": Number(this.props.match.params.catid) || 0,
        })
      };


      fetch(config.get('rest_API_productregistry_V2') +  `/smartfilter/count/${config.get('OrganisationID')}/${JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID}`, requestCountOptions)
        .then(responsecount => responsecount.json())
        .then(datacount => this.setState({ productscount: datacount.Result }));
    } else {
      const serequestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify({
          "OrgID": config.get('OrganisationID'),
          "ProductName": this.props.match.params.id,
          "ProductCategoryID": this.props.match.params.catid,
          "Skip": 0,
          "Limit": 12,
          "LocationID": (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID)
        })
      };
      fetch(config.get('rest_API_productregistry_V2') + '/pagination/name', serequestOptions)
        .then(response => response.json())
        .then(async data => {
          const products = data.Result;
          // console.log(data)
          this.setState({ products: products, ploaded: true })
        })


      const requestCountOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify({ "OrgID": config.get('OrganisationID'), "ProductName": this.props.match.params.id, "ProductCategoryID": this.props.match.params.catid, "Skip": 0, "Limit": 0 })
      };


      fetch(config.get('rest_API_productregistry') + '/count/name', requestCountOptions)
        .then(responsecount => responsecount.json())
        .then(datacount => this.setState({ productscount: datacount.Result }));
    }

    const cachedCart = localStorage.getItem('cart');
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {
      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem('cart', JSON.stringify(myArray));
    }
  }

  onChangeValue = event => {
    this.setState({ value: '1' });
    // document.getElementsByClassName("basketadded").style.visibility = "hidden"
  };

  onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
    this.setState({
      visible: true,
    });
  }
  handleCancel = e => {
    //   console.log(e);
    this.setState({
      visible: false,
    });
  };


  onAddItem = (buyoradd, ProductID, ProductName, MaxOnlineOrderQty, ProductCategoryID, offerItems, offerqty, offerprice) => {
    let prodqty = '';

    const basketitem = this.state.basketitem;

    const prodprice = document.getElementById("prodprice" + ProductID).value
    const prodprimimage = document.getElementById("prodprimimage" + ProductID).value

    var index = this.state.basketitem.findIndex(a => a.ProductID === ProductID);
    prodqty = document.getElementById("prodqty" + ProductID).value

    if (!this.state.regexp.test(prodqty)) {
      message.error('Please enter only valid number for the product quantity.');
      return;
    }

    if (index !== -1) {

      const aprodqty = basketitem[index]["ProductQty"];

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < (parseInt(prodqty) + parseInt(aprodqty))) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }


      basketitem[index]["ProductQty"] = parseFloat(basketitem[index]["ProductQty"]) + parseFloat(document.getElementById("prodqty" + ProductID).value);
      this.setState({ basketitem: basketitem });
      localStorage.setItem('cart', JSON.stringify(basketitem));
      document.getElementById("cartcount").innerHTML = parseInt(document.getElementById("cartcount").innerHTML) + parseInt(aprodqty);

    } else {
      prodqty = document.getElementById("prodqty" + ProductID).value

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }

      document.getElementById("cartcount").innerHTML = parseInt(document.getElementById("cartcount").innerHTML) + parseInt(prodqty);
      var menucategoriesList = JSON.parse(sessionStorage.getItem('menucategories'))
      var ProductsDtl = {
        ProductID: ProductID,
        ProductName: ProductName,
        ProductQty: prodqty,
        ProductPrice: prodprice,
        ProductImage: prodprimimage,
        OfferAmount: 0,
        VatRate: menucategoriesList.length > 0 ? menucategoriesList.find(category => category.ProductCategory.ProductCategoryID === ProductCategoryID).ProductCategory.VatRate : 0
      }

      //  console.log(ProductsDtl)
      this.setState(state => {
        {
          basketitem: state.basketitem.push(ProductsDtl)
          localStorage.setItem('cart', JSON.stringify(state.basketitem));
        }
      });
    }

    setTimeout(() => {

      //offer
      var offeri = [];
      offeri = JSON.parse("[" + offerItems + "]");


      let checker = (arr, target) => target.some(v => arr.includes(v));



      // console.log(offerItems);

      //console.log(JSON.parse(localStorage.getItem('cart')));


      let op = JSON.parse(localStorage.getItem('cart')).map(({ ProductID }) => ProductID)

      var offer = 0;

      if (checker(op, offeri)) {



        var totalitems = 0;
        var totalamounts = 0;
        var newArray = offeri.map(function (el) {

          var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);
          //  console.log(cindex)

          if (cindex !== -1) {
            totalitems = totalitems + parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"])
            totalamounts = totalamounts + (parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"]) * parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductPrice"]))
            basketitem[cindex]["OfferAmount"] = 0;
          }
        });

        var quotient = Math.floor(totalitems / offerqty);
        var remainder = totalitems % offerqty;
        //console.log('total:' + totalamounts)
        //console.log('offer total:' + quotient * offerprice)
        //console.log('remainder: ' + remainder)
        offer = (parseFloat(totalamounts - (prodprice * remainder)) - parseFloat(quotient * offerprice))
        //console.log('singleprice:' + prodprice * remainder)

        var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);

        basketitem[clickedindex]["OfferAmount"] = offer;

        if (totalitems < offerqty) {
          basketitem[clickedindex]["OfferAmount"] = 0;
        }

        this.setState({ basketitem: basketitem });
        localStorage.setItem('cart', JSON.stringify(basketitem));

        //console.log(localStorage.getItem('cart'))

      }
    }, 100);

    message.loading({ content: 'Adding...', key });
    setTimeout(() => {
      message.success({ content: 'Added to the cart!', key, duration: 2 });
      if (buyoradd == 'buy') {
        window.location.href = "/cart"
      }

    }, 800);
  };


  handlePageClick = (data) => {

    $("html, body").animate({ scrollTop: $(".container-fluid").offset().top - 40 }, "slow");

    this.setState({ ploaded: false })

    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perpage);

    // console.log(offset)


    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({
        "OrgID": config.get('OrganisationID'),
        "ProductName": this.props.match.params.id,
        "ProductCategoryID": this.props.match.params.catid,
        "Skip": offset,
        "Limit": 12,
        "LocationID": (JSON.parse(localStorage.getItem('setting'))[0].SalesLocationID)
      })
    };
    fetch(config.get('rest_API_productregistry_V2') + '/pagination/name', requestOptions)
      .then(response => response.json())
      .then(async data => {
        const products = data.Result;
        this.setState({ products: products, ploaded: true })
      })

  };

  onQtyIncrese = (ProductID) => {
    document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) + 1
  };
  onQtyReduce = (ProductID) => {
    if (document.getElementById("prodqty" + ProductID).value > 1) {
      document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) - 1
    }
  };
  handleCallback = (childData, ploaded, category, productscount, catid) => {
    this.setState({ products: childData, category: category, productscount: productscount, catid: catid })
  }

  render() {
    const onFinish = values => {

      const Notifydata = {
        companyPageTitle: config.get('companyPageTitle'),
        SenderEmail: config.get('SenderEmail'),
        companyLogoCDN: config.get('companyLogoCDN'),
        prdtName: document.getElementById("prdt").value
      }

      const emailBody = renderEmail(NotifyEmail, { lang: 'en', Notifydata });
      async function postData() {
        const ecoEmail = {
          "to": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
          "bcc": "suren.sivalingam@ecologital.com",
          "from": values.email,
          "subject": "Product Notification Request",
          "html": emailBody
        };

        const response = await fetch(config.get('backendURL') + 'mail/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(ecoEmail)
        })
        return response.json();
      }

      postData()
        .then(data => {
          message.success("Email has been sent to " + config.get('companyPageTitle'));
          this.handleCancel();
        });


    };

    const { products } = this.state;
    const { basketitem } = this.state;
    return (
      <>
        <br />
        <div className='row'>

          {
            this.state.ploaded ? (
              products ? (
                products.map(product =>

                  <>
                    {(() => {

                      switch (config.get('Theme')) {
                        case 'Martfury':
                          return (<div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                            <div className="list">
                              <ProducBoxMartFury params={{ product: product, visible: this.state.visible, loaded: this.state.loaded }} handleCancel={this.handleCancel} onNotify={this.onNotify} onAddItem={this.onAddItem} onQtyIncrese={this.onQtyIncrese} onQtyReduce={this.onQtyReduce} onFinish={this.onFinish} />
                            </div>
                          </div>)
                        case 'Apparel':
                          return (<div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 ">
                            <div className="list"> <ProducBoxApparel params={{ product: product, visible: this.state.visible, loaded: this.state.loaded }} handleCancel={this.handleCancel} onNotify={this.onNotify} onAddItem={this.onAddItem} onQtyIncrese={this.onQtyIncrese} onQtyReduce={this.onQtyReduce} onFinish={this.onFinish} /></div></div>)
                        case 'Spiceway':
                          return (

                            <>
                              <div className="hero-banner inner products">
                                <div className="hero-text-wrapper">
                                  <h3>Our Products</h3>
                                  <h6>All Products are hygienically packaged with the help of our technically
                                    advanced packaging machine. An air-tight packing is done with caution to let
                                    it be moisture free. This keeps our spices fresh and bacteria free.</h6>
                                </div>
                              </div>


                              <div className='section products'>

                                <div className='container-fluid'>



                                  <div className='row'>

                                    <div className='col-md-4 col-lg-3 col-xl-2'>
                                      <div class="list-group d-none d-md-block">


                                        <LeftSpicewayMenu parentCallback={this.handleCallback} params={{ catid: this.state.catid, catname: this.state.category }} />


                                      </div>

                                    </div>

                                    <div className='col-md-8 col-lg-9 col-xl-10'>

                                      <div className='row'>
                                        <div className='col'>
                                          <h4 className='prd-cat-title'>{this.state.category}</h4>
                                        </div>
                                      </div>

                                      <div className='row'>




                                        {
                                          this.state.ploaded ? (
                                            products ? (
                                              products.map(product =>
                                                <>





                                                  <ProducBoxSpiceway params={{ product: product, visible: this.state.visible, loaded: this.state.loaded }} handleCancel={this.handleCancel} onNotify={this.onNotify} onAddItem={this.onAddItem} onQtyIncrese={this.onQtyIncrese} onQtyReduce={this.onQtyReduce} onFinish={this.onFinish} />



                                                </>
                                              )
                                            ) : (
                                              <>
                                                <Skeleton active avatar paragraph={{ rows: 4 }} />
                                              </>
                                            )
                                          ) : (<>
                                            <Skeleton active avatar paragraph={{ rows: 4 }} />
                                          </>)
                                        }



                                      </div>


                                    </div>

                                    <div className='col-12 col-md-12 col-lg-12'>

                                      <ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.productscount / this.state.perpage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-link'}
                                        nextClassName={'page-link'}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                      />
                                    </div>

                                  </div>


                                </div>



                              </div>










                            </>
                          )
                        case 'Apparel-casi':
                          return (<>
                            <div>
                              {/* Product Hero */}
                              <div className="prd-hero">
                                <div className="prd-hero-header">
                                  <h4 className>{this.state.category}</h4>
                                </div>
                                <div className="prd-hero-img">
                                  <img src="https://cdn.neurolage.com/retailpacx/casipillai/retailpacx-1639865646642.jpg" className="img-fluid" />
                                </div>
                              </div>
                              {/* /Product Hero */}
                              {/* Breadcrumb */}
                              <div className="breadcrumb-container">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="breadcrumb-in">
                                      <ul className="site-breadcrumb">
                                        <li><a href="index.html">Home</a></li>
                                        <li>{this.state.category}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* /Breadcrumb */}
                              {/* Filter Area */}
                              <div className="prd-filter-area">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="filter-inner">
                                      <div className="filter-top">
                                        <p className="cat-name">{this.state.category}</p>
                                        <p className="cat-count">{this.state.productscount} Products Found</p>
                                      </div>
                                      <LeftApparelCasiMenu parentCallback={this.handleCallback} params={{ ECDrawer: this.state.ECDrawer, catid: this.state.catid, catname: this.state.category }} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* /Filter Area */}



                              {/* List Items */}
                              <div className="prd-list-items">
                                <div className="row">


                                  {
                                    this.state.ploaded ? (
                                      products ? (
                                        products.map(product =>


                                          <div className="col-sm-6 col-md-4 col-lg-3">
                                            <div className="item-wrapper wow bounceInUp" data-wow-delay="0.1s" data-wow-offset={10}>
                                              <a key={'pid' + product.ProductID} href={'/product/' + product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + product.ProductID} title="Quick View" >
                                                <div className="img-container">
                                                  {
                                                    product.OnLineImages.PrimaryImages !== null ?
                                                      <div style={{ width: 'auto' }}>
                                                        <a key={'pid' + product.ProductID} href={'/product/' + product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + product.ProductID} title="Quick View" >
                                                          <img src={product.OnLineImages.PrimaryImages.Main} className="img-fluid" alt={product.Name} /></a>
                                                        <input type="hidden" id={'prodprimimage' + product.ProductID} value={product.OnLineImages.PrimaryImages.Main} />
                                                      </div>
                                                      : <div><a key={'pid' + product.ProductID} href={'/product/' + product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + product.ProductID} title="Quick View" >
                                                        <img src="/images/no-image.jpg" className="img-fluid" alt={product.Name} /></a>
                                                        <input type="hidden" id={'prodprimimage' + product.ProductID} value='' /></div>
                                                  }
                                                  <span className="item-price">    <p className={product.Discount != 0 ? 'ps-product__price sale' : 'ps-product__price'}>

                                                    <>
                                                      {localStorage.getItem('ecurrencyCode')}{
                                                        String((product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2))
                                                      } {
                                                        product.Discount != 0 ?
                                                          <><del>{localStorage.getItem('ecurrencyCode')}{(parseInt(product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(product.Discount)}</del></>
                                                          : ''
                                                      }

                                                      <input type="hidden" id={'prodprice' + product.ProductID} value={product.SellingPricesArray.SellingPrice} />

                                                    </>


                                                  </p></span>
                                                </div>
                                                <div className="item-text"><p>{product.Name}</p></div>
                                              </a>
                                            </div>
                                          </div>



                                        )
                                      ) : (
                                        <div className="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 "><br />
                                          <Skeleton active avatar paragraph={{ rows: 4 }} />
                                        </div>
                                      )
                                    ) : (<div className="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-6 "><br />
                                      <Skeleton active avatar paragraph={{ rows: 4 }} />
                                    </div>)
                                  }






                                </div>
                              </div>
                              {/* /List Items */}


                              {/* Pagination */}
                              <div className="prd-pagination">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <nav aria-label="...">

                                      <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        nextClassName={'page-link'}
                                        previousClassName={'page-link'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.productscount / this.state.perpage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        pageClassName={'page-item'}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                      />


                                    </nav>
                                  </div>
                                </div>
                              </div>
                              {/* /Pagination */}
                            </div>

                          </>
                          )
                        default:
                          return (<>
                            <Row>
                              <Col sm={12}>
                                <Breadcrumb>
                                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                  <Breadcrumb.Item href="/products/">
                                    Search Results
                                  </Breadcrumb.Item>

                                  <Breadcrumb.Item href="/products/">
                                    Results for {this.props.match.params.id}
                                  </Breadcrumb.Item>
                                </Breadcrumb>
                              </Col>
                              <LeftMenu parentCallback={this.handleCallback} params={{ catname: 'search', catid: this.props.match.params.id }} />
                              <Col sm={9}>
                                <Col sm={12}>
                                  <Col sm={9}>
                                    <h3>Search results for {this.props.match.params.id}</h3>

                                  </Col>
                                </Col>
                                <Row>
                                  <Col>
                                    <div className="list">


                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xs={6} md={6} lg="3">
                                    <div className="list">
                                      <LazyLoad>
                                        <div className="card">
                                          <div md={{ span: 3, offset: 3 }}>


                                            {product.Offers[0] ? (product.Offers[0].Offer.OfferType == 3 ? (<>  <span class="mulitistock">MULTI BUY</span></>) : '') : ''}


                                            {product.IsOutOfStock ? (<> <span class="outofstock">OUT OF STOCK</span></>) : ''}    <FontAwesomeIcon icon={faHeart} /></div>


                                          <a key={'pid' + product.ProductID} href={'/product/' + product.Name.replace(/ /g, '-').replace('/', '-').toLowerCase() + '/' + product.ProductID}>
                                            {
                                              product.OnLineImages.PrimaryImages !== null ?
                                                <div style={{ width: 'auto' }}>
                                                  {this.state.loaded ? null :
                                                    <div className="divLoader">
                                                      <LoadingOutlined style={{ fontSize: '56px', color: '#08c' }} /><br />
                                                      Loading
                                                    </div>
                                                  }
                                                  <img src={product.OnLineImages.PrimaryImages.Main} alt={product.Name} onLoad={() => this.setState({ loaded: true })} style={this.state.loaded ? {} : { display: 'none' }} />
                                                  <input type="hidden" id={'prodprimimage' + product.ProductID} value={product.OnLineImages.PrimaryImages.Main} />
                                                </div>
                                                : <div><img src="/images/noimage.jpg" alt={product.Name} />
                                                  <input type="hidden" id={'prodprimimage' + product.ProductID} value='' /></div>
                                            }
                                          </a>

                                          <h4>{product.Name}</h4>
                                          <p><b>Item Code:</b><br /> {product.ProductRefNo}</p>



                                          <div className="row">
                                            <div className={product.Discount != 0 ? 'priceleft col-sm-6' : 'priceleft col-sm-12'}><p className="price"><sup className="pricex">{localStorage.getItem('ecurrencyCode')}</sup><sub>
                                              {String(product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).split('.')[0]}
                                              <input type="hidden" id={'prodprice' + product.ProductID} value={product.SellingPricesArray.SellingPrice} />
                                            </sub><sup>
                                                {
                                                  String(product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).split('.')[1] != null ?
                                                    String((product.SellingPricesArray.SellingPrice * localStorage.getItem('ecurrencyratio')).toFixed(2)).split('.')[1]
                                                    : '00'
                                                }
                                              </sup></p></div>
                                            {
                                              product.Discount != 0 ?
                                                <div className="priceright col-sm-6">was {localStorage.getItem('ecurrencyCode')}{(parseInt(product.SellingPricesArray.SellingPrice) * parseInt(localStorage.getItem('ecurrencyratio'))) + parseInt(product.Discount)} <br /><span className="dispricetxt">save {localStorage.getItem('ecurrencyCode')}{product.Discount}</span></div>
                                                : ''
                                            }
                                          </div>




                                          <Table striped bordered hover size="sm" width="50%">
                                            <thead>
                                              <tr>
                                                <th>Pack Size</th>
                                                <th>Weight</th>
                                                <th>Type</th>
                                              </tr>
                                            </thead>
                                            <tbody>

                                              {

                                                product.UnitCharts.length > 0 ? (

                                                  product.UnitCharts.map(elu =>
                                                    <tr>
                                                      <td>{elu.Quantity}</td>
                                                      <td>{product.Weight}</td>
                                                      <td>{elu.UnitTypeName}</td>
                                                    </tr>

                                                  )
                                                ) : (

                                                  <tr>
                                                    <td>1</td>
                                                    <td>{product.Weight}</td>
                                                    <td>EA</td>
                                                  </tr>
                                                )
                                              }


                                            </tbody>
                                          </Table>



                                          {product.IsOutOfStock ? (<div className="text-center"> <p className="inputandbtn"><button className="notify" onClick={this.onNotify.bind(this, product.ProductID, product.Name, product.MaxOnlineOrderQty)} id={product.ProductID}><FontAwesomeIcon icon={faEnvelope} />Notify</button></p> <Modal
                                            show={this.state.visible}
                                            onHide={this.handleCancel}
                                            centered
                                            aria-labelledby="example-modal-sizes-title-sm"
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title id="example-modal-sizes-title-sm">
                                                Notify
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                              <Form
                                                onFinish={onFinish}
                                                name="basic"
                                                initialValues={{ remember: true }}
                                              >
                                                <Form.Item
                                                  label="Email Address"
                                                  name="email"
                                                  rules={[{ required: true, type: 'email' }]}
                                                >
                                                  <Input placeholder="Email Address" />
                                                </Form.Item>



                                                <Form.Item  >
                                                  <Input name="prdt" type="hidden" id="prdt" value={product.ProductID} />
                                                  <Button type="primary" htmlType="submit" ref="btn" danger >
                                                    Submit
                                                  </Button>
                                                </Form.Item>
                                              </Form>

                                            </Modal.Body>
                                          </Modal></div>) : (
                                            <p className="inputandbtn"><NumericInput min={1} max={product.MaxOnlineOrderQty == 0 ? 10000 : product.MaxOnlineOrderQty} precision={0} className="inputqty" id={'prodqty' + product.ProductID} value={1} snap />
                                              <button onClick={this.onAddItem.bind(this, 'add', product.ProductID, product.Name, product.MaxOnlineOrderQty, product.ProductCategoryID, product.CurrentOffer.ID ? (product.CurrentOffer.OfferProducts.map((prt) => prt.ProductID).join(',')) : '', product.CurrentOffer.ID ? (product.CurrentOffer.Offer.OfferQty) : '', product.CurrentOffer.ID ? (product.CurrentOffer.Offer.OfferPrice) : '')} id={product.ProductID}><FontAwesomeIcon icon={faShoppingCart} /><span>ADD</span></button></p>
                                          )}
                                        </div><br />

                                      </LazyLoad>
                                    </div>
                                  </Col>

                                </Row>

                                <Col>
                                  <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.productscount / this.state.perpage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                  />
                                </Col>


                              </Col>
                            </Row>

                          </>
                          )
                      }

                    })()}

                  </>



                )
              ) : (
                <Col sm={12}><br />
                  <Skeleton active avatar paragraph={{ rows: 4 }} />
                </Col>
              )) : (<Col sm={12}><br />
                <Skeleton active avatar paragraph={{ rows: 4 }} />
              </Col>)
          }

        </div>

        <Row>
          <Col>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.productscount / this.state.perpage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            /></Col>
        </Row>
      </>
    )
  }
}

export default Application;