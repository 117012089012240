import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import LayoutInclude from "./layout-include";
import jwt from 'jsonwebtoken';
import _ from "lodash";
import { Responsive, WidthProvider } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);

export default class mainbody extends Component {

  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 2 },
    isDraggable: false,
    rowHeight: 10,
    isResizable: true,
    onLayoutChange: function () { }
  };
  
  constructor(props) {
    super(props);
    this.state = { basketitem: [], basketqty: [], datax: [], deliveryscheme: [] };
  }

  componentDidMount() {


    this.setState(() => {
      axios.get(config.get('backendURL') + 'homelayout/' + config.get('companyID'))
        .then(response => {
          //    console.log(response.data)
          if (response.data.length > 0) {
            this.setState({
              layout: response.data.map(function (i, key, list) {
                return {
                  i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString()]),
                  x: i.x,
                  y: i.y,
                  w: i.width,
                  h: i.height,
                  add: i === (list.length - 1)
                };
              }), loading: true
            })
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    });

    axios.get(config.get('backendURL') + 'layouts/layout/' + config.get('companyID'))
      .then(response => {
        this.setState({ datax: JSON.parse(response.data[0].itemdetails).sort((a, b) => (a.order - b.order)), slayoutid: response.data[0]._id })
      })
      .catch((error) => {
        console.log(error);
      })

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('SalesjwtAud'),
      "exp": timestamp,
      "iss": config.get('SalesjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('SalesprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    axios.get(config.get('API_salesregistry') + 'deliveryscheme/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        this.setState({ deliveryscheme: response.data.Result });
        //  console.log(response.data) 
      })
      .catch((error) => {
        console.log(error);
      })

      if (window.innerWidth <= 1199) {
 
        var adjest = 0;
        var ulElems = document.getElementsByClassName("react-resizable");
        // console.log(ulElems.length)
   
        setTimeout(function () {
          for (var i = 0; i < ulElems.length; i++) {
            ulElems[i].style=""
            ulElems[i].classList.add('newLayoutList');
            ulElems[i].style.position = "relative"
            document.getElementsByClassName("layout")[0].style.height = "100%";
          }
          var ulElems1 = document.getElementsByClassName("newLayoutList");
          for (var i = 0; i < ulElems1.length; i++) {
            ulElems1[i].classList.remove('react-grid-item', 'cssTransforms', 'react-resizable-hide', 'react-resizable');
            document.getElementsByClassName("layout")[0].style.height = "100%";
          }
        }, 1000);
      }

  };



  generateDOM(el) {

    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };
    const i = el.i;
    return (
      <div key={JSON.parse(i)[0]} data-grid={el}>
        <LayoutInclude params={{ layoutid: JSON.parse(i)[0], width: el.w }} />
      </div>
    );

  }


  onBreakpointChange(breakpoint, cols) {

    if(!breakpoint){
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
   }
  }


  onLayoutChange = layout => {

    // console.log(layout)


    let currentHideNav = (window.innerWidth <= 1024);

    //  console.log(currentHideNav)
    if (!currentHideNav) {

      this.setState(() => {
        axios.get(config.get('backendURL') + 'homelayout/' + config.get('companyID'))
          .then(response => {
            //    console.log(response.data)
            if (response.data.length > 0) {
              this.setState({
                layout: response.data.map(function (i, key, list) {
                  return {
                    i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString()]),
                    x: i.x,
                    y: i.y,
                    w: i.width,
                    h: i.height,
                    add: i === (list.length - 1)
                  };
                }), loading: true
              })
            } else {
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            console.log(error);
          })
      });
    }



  };


  render() {

    const { layout, deliveryscheme } = this.state;

    return (
      <div id="homepage-1" className="ps-container">


<ResponsiveGridLayout
        layout={this.state.layout}
        onLayoutChange={this.onLayoutChange}
       onBreakpointChange={this.onBreakpointChange}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
        {...this.props}
      >
      {_.map(this.state.layout, el => this.generateDOM(el))}
      </ResponsiveGridLayout>

        {/* <div class="ps-site-features">
          <div class="ps-container">
            <div class="ps-block--site-features">
              <div class="ps-block__item">
                <div class="ps-block__left"><i class="icon-rocket"></i></div>
                <div class="ps-block__right">
                  <h4>Free Delivery</h4>
                  {
                    this.state.deliveryscheme.map(ds => {

                      //  console.log(ds)

                      if (ds.CurrencyCode == localStorage.getItem('ecurrencythreeCode')) {
                        const Calculation = ds.Calculation;

                        if (Calculation == "ByInvoiceAmount") {
                          const vat = ds.ByInvoiceAmount;
                          {
                            [vat].map((e2, i2) => {

                              //  console.log(vat)
                              if (this.state.total <= 50 && [vat][i2][50]) {
                                sessionStorage.setItem('deliverycost', [vat][i2][50]);
                              } else if (this.state.total <= 100 && [vat][i2][100]) {
                                sessionStorage.setItem('deliverycost', [vat][i2][100]);
                              } else if (this.state.total <= 150 && [vat][i2][150]) {
                                sessionStorage.setItem('deliverycost', [vat][i2][150]);
                              } else if (this.state.total <= 750 && [vat][i2][750]) {
                                sessionStorage.setItem('deliverycost', [vat][i2][750]);
                              } else {
                                sessionStorage.setItem('deliverycost', [vat][i2]['Above']);
                              }


                            })
                          }
                          sessionStorage.setItem('MinimumInvoiceOrder', ds.MinimumInvoiceOrder);

                        } else {
                          //to do other methods
                        }

                      } else {
                        //to do other countries 
                      }
                    })
                  }
                  <p>For all orders over {
                    localStorage.getItem('ecurrencyCode') + ' ' + sessionStorage.getItem('MinimumInvoiceOrder')
                  }</p>
                </div>
              </div>
              <div class="ps-block__item">
                <div class="ps-block__left"><i class="icon-sync"></i></div>
                <div class="ps-block__right">
                  <h4>90 Days Return</h4>
                  <p>If goods have problems</p>
                </div>
              </div>
              <div class="ps-block__item">
                <div class="ps-block__left"><i class="icon-credit-card"></i></div>
                <div class="ps-block__right">
                  <h4>Secure Payment</h4>
                  <p>100% secure payment</p>
                </div>
              </div>
              <div class="ps-block__item">
                <div class="ps-block__left"><i class="icon-bubbles"></i></div>
                <div class="ps-block__right">
                  <h4>24/7 Support</h4>
                  <p>Dedicated support</p>
                </div>
              </div>
              <div class="ps-block__item">
                <div class="ps-block__left"><i class="icon-gift"></i></div>
                <div class="ps-block__right">
                  <h4>Gift Service</h4>
                  <p>Support gift service</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      </div>
    );
  }
}