import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import {
  LoadingOutlined,
} from '@ant-design/icons';


class App extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = { vimage: [], loaded: false };
  }
  componentDidMount() { 
    this._isMounted = true;
    //console.log(this.props.params.ItemData)
    axios.get(config.get('backendURL')+'homelayout/image/' + this.props.params.ItemData)
    .then(response => {
   //   console.log((response.data)[0].img)
      this.setState({
        vtype: (response.data)[0].img.contentType,
        vimage: (response.data)[0].img.data.toString()
      })
    })
    .catch(function (error) {
      console.log(error);
    })

};

componentWillUnmount() {
  this._isMounted = false;
}
 
  render() {
 //console.log(this.state.vimage)
   
      return (
        <>
        {
          this.state.loaded ? null :
          <div className="divLoader">
          <LoadingOutlined  style={{ fontSize: '56px', color: '#08c' }} /><br/>
           </div>
        }
<img className={this.props.params.className} src={`data:${this.state.vtype};base64,${this.state.vimage}`} alt={this.props.params.ItemHeading} onLoad={() => this.setState({loaded: true})} style={this.state.loaded ? {} : {display: 'none'}} />
 </>
      )
  }
}

export default App;
