import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import config from 'react-global-configuration';
import LayoutInclude from "./layout-include";
import jwt from 'jsonwebtoken';
import _ from "lodash";
import { Responsive, WidthProvider } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);

export default class mainbody extends Component {

  static defaultProps = {
    className: "layout",
    isDraggable: false,
    rowHeight: 20,
    isResizable: true
  };

  constructor(props) {
    super(props);
    this.state = { basketitem: [], basketqty: [], datax: [], deliveryscheme: [] };
  }
  resize = () => {

    if (window.innerWidth < 1681) {
      console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {

        for (var i = 0; i < ulElems.length; i++) {
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            var whight = ulElems[i].style.height
            ulElems[i].getElementsByClassName("w-100")[0].style.height = whight
          }
        }
      }, 1000);
    }


    if (window.innerWidth < 1200) {
      // console.log('md')
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)

      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          var newh = ulElems[i].style.height.replace('px', '') - 10
          ulElems[i].style.height = newh + "px"
          if (ulElems[i].getElementsByClassName("w-100").length > 0) {
            ulElems[i].getElementsByClassName("w-100")[0].style.height = newh + "px"
          }
        }
      }, 1000);
    }
 
    if (window.innerWidth <= 1199) {
 
      var adjest = 0;
      var ulElems = document.getElementsByClassName("react-resizable");
      // console.log(ulElems.length)
 
      setTimeout(function () {
        for (var i = 0; i < ulElems.length; i++) {
          ulElems[i].style=""
          ulElems[i].classList.add('newLayoutList');
          ulElems[i].style.position = "relative"
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
        var ulElems1 = document.getElementsByClassName("newLayoutList");
        for (var i = 0; i < ulElems1.length; i++) {
          ulElems1[i].classList.remove('react-grid-item', 'cssTransforms', 'react-resizable-hide', 'react-resizable');
          document.getElementsByClassName("layout")[0].style.height = "100%";
        }
      }, 1000);
    }


  }
  componentDidMount() {


    window.addEventListener('resize', this.resize)
    this.setState(() => {
      axios.get(config.get('backendURL') + 'homelayout/' + config.get('companyID'))
        .then(response => {
          //    console.log(response.data)
          if (response.data.length > 0) {
            this.setState({
              layout: response.data.map(function (i, key, list) {
                return {
                  i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString()]),
                  x: i.x,
                  y: i.y,
                  w: i.width,
                  h: i.height,
                  add: i === (list.length - 1)
                };
              }), loading: true
            })
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    });

    axios.get(config.get('backendURL') + 'layouts/layout/' + config.get('companyID'))
      .then(response => {
        this.setState({ datax: JSON.parse(response.data[0].itemdetails).sort((a, b) => (a.order - b.order)), slayoutid: response.data[0]._id })
      })
      .catch((error) => {
        console.log(error);
      })

    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('SalesjwtAud'),
      "exp": timestamp,
      "iss": config.get('SalesjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('SalesprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);

    axios.get(config.get('API_salesregistry') + 'deliveryscheme/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token } })
      .then(response => {
        this.setState({ deliveryscheme: response.data.Result });
        //  console.log(response.data) 
      })
      .catch((error) => {
        console.log(error);
      })


      if (window.innerWidth <= 1199) {

        var adjest = 0;
        var ulElems = document.getElementsByClassName("react-resizable");
        // console.log(ulElems.length)
   
        setTimeout(function () {
          for (var i = 0; i < ulElems.length; i++) {
            ulElems[i].style=""
            ulElems[i].classList.add('newLayoutList');
            ulElems[i].style.position = "relative"
            document.getElementsByClassName("layout")[0].style.height = "100%";
          }
          var ulElems1 = document.getElementsByClassName("newLayoutList");
          for (var i = 0; i < ulElems1.length; i++) {
            ulElems1[i].classList.remove('react-grid-item', 'cssTransforms', 'react-resizable-hide', 'react-resizable');
            document.getElementsByClassName("layout")[0].style.height = "100%";
          }
        }, 1000);
      }
  };



  generateDOM(el) {

    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };
    const i = el.i;
    return (
      <div key={JSON.parse(i)[0]} data-grid={el}>
        <LayoutInclude params={{ layoutid: JSON.parse(i)[0], width: el.w }} />
      </div>
    );

  }


  onBreakpointChange(breakpoint, cols) {

    if (!breakpoint) {
      this.setState({
        breakpoint: breakpoint,
        cols: cols
      });
    }
  }


  onLayoutChange = layout => {

    console.log(layout)



    this.setState(() => {
      axios.get(config.get('backendURL') + 'homelayout/' + config.get('companyID'))
        .then(response => {
          //    console.log(response.data)
          if (response.data.length > 0) {
            this.setState({
              layout: response.data.map(function (i, key, list) {
                return {
                  i: JSON.stringify([i._id.toString(), i.itemheading.toString(), i.item.toString(), i.itemdata.toString(), i.type.toString()]),
                  x: i.x,
                  y: i.y,
                  w: i.width,
                  h: i.height,
                  add: i === (list.length - 1)
                };
              }), loading: true
            })
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    });



  };


  render() {

    const { layout, deliveryscheme } = this.state;

    return (


      <div id="homepage-1" className="ps-container">


        <ResponsiveGridLayout
          layouts={this.state.layout}
          onLayoutChange={this.onLayoutChange}
          onBreakpointChange={this.onBreakpointChange}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          {...this.props}
        >
          {_.map(this.state.layout, el => this.generateDOM(el))}
        </ResponsiveGridLayout>



      </div>
    );
  }
}