import { List, Typography, Space, Tag, Card, Avatar } from 'antd';
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';
import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment'
import config from 'react-global-configuration';
import { UserOutlined } from '@ant-design/icons';
import Truncate from 'react-truncate';
const { Meta } = Card;

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const { Title, Paragraph, Text, Link } = Typography;
const gridStyle = {
  width: '100%'
};
const { CheckableTag } = Tag;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { listData: [], selectedTags: [], loading: false };
  }
  componentDidMount() {

    if (config.get('Theme') == 'ComingSoon') {
      require('../comingsoon/css/style.css');
    }


    this.props.params.ItemData ? (
      axios.get(config.get('backendURL') + 'articles/bycategory/' + this.props.params.ItemData + '/' + config.get('companyID'))
        .then(response => {
          console.log(response)
          this.setState({
            loading: true,
            listData: response.data,
            alltags: JSON.stringify(response.data.map((d) => d.tags.join(",")) + ",").substring(1).slice(0, -2)
          });
        })
        .catch((error) => {
          console.log(error);
        })
    ) : (
      axios.get(config.get('backendURL') + 'articles/bycomid/' + config.get('companyID'))
        .then(response => {
          console.log(response)
          this.setState({
            loading: true,
            listData: response.data,
            alltags: JSON.stringify(response.data.map((d) => d.tags.join(",")) + ",").substring(1).slice(0, -2)
          });
        })
        .catch((error) => {
          console.log(error);
        })
    )
    this._isMounted = true;
  }

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    console.log('You are interested in: ', nextSelectedTags);
    var url = '';
    if (nextSelectedTags.length > 0) {
      url = config.get('backendURL') + 'articles/bytags/' + nextSelectedTags + '/' + config.get('companyID')
    } else {
      url = config.get('backendURL') + 'articles/bycomid/' + config.get('companyID')
    }
    axios.get(url)
      .then(response => {
        console.log(response)

        
        this.setState({
          loading: true,
          listData: response.data
        });
      })
      .catch((error) => {
        console.log(error);
      })
    this.setState({ selectedTags: nextSelectedTags });
  }
  render() {
    const { listData, selectedTags, alltags, loading } = this.state;
    return (
      <>
{(() => {

switch (config.get('Theme')) {
  case 'Apparel-casi':
    return (
      <>
        {/* Weaving Process */}
      <div className="blogs page-inner">
        <h4>New Blogs</h4>
        <div className="page-inner-content">
          <div className="row">
            <div className="col-sm-12">
              <div className="blogs-tabs">				
                <ul className="nav nav-tabs tab-container">
                {
            loading ? (
              Array.from(new Set(alltags.split(','))).map(tag => (
            <li className="nav-item"><a data-toggle="tab" href={'#'+tag.replace(/ /g, '')} className="nav-link">{tag}</a></li>			
              ))
            ) : ('')
          }
                </ul>
                {/* Tab Content Wrapper */}
                <div className="tab-content">
                  {/* Tab Content 1  */}
                  {
            listData.map(item => (
              <>
                          <div id={item.tags[0]} className="tab-pane show">
                    <div className="row">
                      <div className="Col-sm-12">
                        <div className="blog-items">
                          <div className="blog-items-header">
                          <Meta
                                  avatar={<Avatar icon={<UserOutlined />} />}
                                  title={<a href={'/articles/' + item.slug}>{item.title}</a>}

                                  className=""
                                  description={item.tags.join(", ")}
                                /> 
                          </div>
                          <div className="blog-items-inner-wrapper">
                            <div className="blog-items-img">
                            <img
                                  width={275}
                                  align="left"
                                  alt=""
                                  src={'https://cdn.neurolage.com/retailpacx/' + config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() + '/' + item.featuredimage}
                                />
                            </div>
                            <div className="blog-items-details">
                              <p>
                              <Truncate lines={3} ellipsis={<span>... <a href={'/articles/' + item.slug}>Read more</a></span>}>
                                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                </Truncate>
                              </p>
                            </div>
                          </div>
                        </div>
                        

                      </div>
                    </div>
                  </div>
                   
              </>




            ))
          }

                 
                  
                 
               
                   
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /- Weaving Process */}

      </>
    )

  default:
    return (<>
                     




        <div className={this.props.params.className}>
          {
            loading ? (
              Array.from(new Set(alltags.split(','))).map(tag => (
                <CheckableTag
                  color="green"
                  key={tag}
                  checked={selectedTags.indexOf(tag) > -1}
                  onChange={checked => this.handleChange(tag, checked)}
                >
                  {tag}
                </CheckableTag>
              ))
            ) : ('')
          }
          {
            listData.map(item => (
              <>
                
                        <Card.Grid style={gridStyle} className="CustomCard">
                          <Row>
                            <Col>

                              <div>
                                <img
                                  width={272}
                                  align="left"
                                  alt=""
                                  src={'https://cdn.neurolage.com/retailpacx/' + config.get('CDNPath').replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase() + '/' + item.featuredimage}
                                />
                              </div>

                              <div>
                                <Meta
                                  avatar={<Avatar icon={<UserOutlined />} />}
                                  title={<a href={'/articles/' + item.slug}>{item.title}</a>}

                                  className=""
                                  description={item.tags.join(", ")}
                                /> <Truncate lines={3} ellipsis={<span>... <a href={'/articles/' + item.slug}>Read more</a></span>}>
                                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                </Truncate>
                              </div>

                            </Col>
                          </Row>
                        </Card.Grid>


                        

              </>




            ))
          }


        </div>






        </>
                      )
                  }

                })()}



      </>

      
    )
  }
}

export default App;



