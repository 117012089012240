import React from 'react';
import { Divider, Modal, Avatar, Form, Input, Button, Checkbox } from 'antd';
import config from 'react-global-configuration';

const LoginMartFury = (props) => (

    
      <>
        <div className="ps-my-account-2">
        <div className="container">
          <div className="ps-section__wrapper">

            
            <div className="ps-section__left">
              <div className="ps-form--account ps-tab-root">
                 
                <ul className="ps-tab-list">
                  <li className={ props.params.lactive ? 'active' :'' }><a onClick={props.onTaggle.bind(this, 'login')}>Login</a></li>
                  <li  className={ props.params.ractive ? 'active' :'' }><a onClick={props.onTaggle.bind(this, 'register')}>Register</a></li>
                </ul>
                <div className="ps-tabs">

                <Form
                  
                  onFinish={props.onFinish}
                  name="basic"
                  initialValues={{ remember: true }}

                >
                  <div className={props.params.lactive ? 'active ps-tab ' :'ps-tab ' } id="sign-in">
                    <div className="ps-form__content">
                      <h5>Log In Your Account</h5>

                      
                      <div className="form-group">

                      <Form.Item
                      
                      name="username"
                      
                      rules={[{ type:'email', required: true, message: 'Please enter a valid email!' }]}
                    >
                      <Input placeholder="Username or email address" className="form-control" />
                    </Form.Item>

                            </div>
                      <div className="form-group form-forgot">

                      <Form.Item
                       
                      name="password"
                      rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                      <Input.Password className="form-control"  placeholder="Password" />
                    </Form.Item>

                       
                      </div>
                      <div className="form-group">
                        <div >
                         
                       
                      <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                      </Form.Item>
                      <a href="/account/forgot" className="forgotpassword">Forgot?</a>
                        </div>
                      </div>
        
                    <div className="form-group submit">
                        <button className="ps-btn ps-btn--fullwidth" id="btn"  >{props.params.disabled ? 'Please wait...' : 'Login'}</button>
                      </div>

                    </div>
                  </div>
                   </Form>

                   <Form
                  
                  onFinish={props.onRegFinish}
                  name="basic"
                  initialValues={{ remember: true }}

                >
                  <div className={props.params.ractive? 'active  ps-tab' :'ps-tab '  } id="register">
        <div className="ps-form__content">
          <h5>Register An Account</h5>
          <div className="form-group">
          <Form.Item
                                name="Email"
                                label=""
                                rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
                              >
                                <Input placeholder="Please enter email" className="form-control" />
                              </Form.Item>
          </div>
   
          <div className="ps-form__decs">
           
            <p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our<a href="#">privacy policy.</a></p>
          </div>
          <div className="form-group submit">
            <button className="ps-btn ps-btn--fullwidth" type="submit">Register</button>
          </div>
        </div>
      </div>

      </Form>
                </div>
               
                </div>
            </div>
            <div className="ps-section__right">
              <figure className="ps-section__desc">
                <figcaption>Sign up today and you will be able to:</figcaption>
                <p>{config.get('companyPageTitle')} Buyer Protection has you covered from click to delivery. Sign up or sign in and you will be able to:</p>
                <ul className="ps-list">
                  <li><i className="icon-credit-card" /><span>SPEED YOUR WAY THROUGH CHECKOUT</span></li>
                  <li><i className="icon-clipboard-check" /><span>TRACK YOUR ORDERS EASILY</span></li>
                  <li><i className="icon-bag2" /><span>KEEP A RECORD OF ALL YOUR PURCHASES</span></li>
                </ul>
              </figure>
            
            </div>
          </div>
        </div>
      </div>
      
     </>

   

);
export default LoginMartFury;
