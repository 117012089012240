import React from 'react';
import { Col } from 'react-bootstrap';
import jwt from 'jsonwebtoken';
import { renderEmail } from 'postonents';
import { Skeleton, message } from 'antd';
import config from 'react-global-configuration';
import NotifyEmail from '../../email/NotifyEmail';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProducBoxMartFury from './product-box';
import axios from 'axios';

const key = 'updatable';
const catid = 1;
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1600 },
    items: 6,
    slidesToSlide: 6 // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 4,
    slidesToSlide: 6 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
class Application extends React.Component {
  constructor(props) {
    super(props);

    this.state = { products: [], basketitem: [], basketqty: [], loaded: false, regexp: /^[0-9\b]+$/ };
  }

  componentDidMount() {
    const timestamp = Math.floor(Date.now() / 1000) + 1000;

    var payload = {
      "aud": config.get('ProductjwtAud'),
      "exp": timestamp,
      "iss": config.get('ProductjwtIss')
    };

    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('ProductprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
    // console.log(token)

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };

    var payload2 = {
      "aud": config.get('SalesjwtAud'),
      "exp": timestamp,
      "iss": config.get('SalesjwtIss')
    };

    var privateKey2 = config.get('SalesprivateKey');
    var token2 = jwt.sign(payload2, privateKey2, signOptions);

    axios.get(config.get('API_salesregistry') + 'setting/' + config.get('OrganisationID') + '/26', { headers: { "Authorization": 'Bearer ' + token2 } })
      .then(response => {
        localStorage.setItem('setting', JSON.stringify(response.data.Result));
        fetch(config.get('rest_API_productregistry') + '/' + this.props.params.Type + '/' + config.get('OrganisationID') + '/' + response.data.Result[0].SalesLocationID + '/0/20', requestOptions)
          .then(response => response.json())
          .then(async data => {
            const products = data.Result;
            this.setState({ ploaded: false })
            this.setState({ products: products, ploaded: true })
          })
      })
      .catch((error) => {
        console.log(error);
      })

    const cachedCart = localStorage.getItem('cart');
    if (cachedCart) {
      this.setState({ basketitem: JSON.parse(cachedCart) });
    } else {

      const myArray = [];
      this.setState({ basketitem: myArray });
      localStorage.setItem('cart', JSON.stringify(myArray));
    }
  }

  onChangeValue = event => {
    this.setState({ value: '1' });
  };

  onNotify = (ProductID, ProductName, MaxOnlineOrderQty) => {
    this.setState({
      visible: true,
    });
  }
  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  onAddItem = (buyoradd, ProductID, ProductName, MaxOnlineOrderQty, ProductCategoryID, offerItems, offerqty, offerprice) => {
    let prodqty = '';

    const basketitem = this.state.basketitem;



    const prodprice = document.getElementById("prodprice" + ProductID).value
    const prodprimimage = document.getElementById("prodprimimage" + ProductID).value

    var index = this.state.basketitem.findIndex(a => a.ProductID === ProductID);
    prodqty = document.getElementById("prodqty" + ProductID).value

    if (!this.state.regexp.test(prodqty)) {
      message.error('Please enter only valid number for the product quantity.');
      return;
    }

    if (index !== -1) {

      const aprodqty = basketitem[index]["ProductQty"];

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < (parseInt(prodqty) + parseInt(aprodqty))) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }

      basketitem[index]["ProductQty"] = parseFloat(basketitem[index]["ProductQty"]) + parseFloat(document.getElementById("prodqty" + ProductID).value);
      this.setState({ basketitem: basketitem });
      localStorage.setItem('cart', JSON.stringify(basketitem));
      document.getElementById("cartcount").innerHTML = parseInt(document.getElementById("cartcount").innerHTML) + parseInt(aprodqty);

    } else {
      prodqty = document.getElementById("prodqty" + ProductID).value

      if (MaxOnlineOrderQty != 0 && MaxOnlineOrderQty < prodqty) {
        message.error('Please add less than ' + MaxOnlineOrderQty + " items in total to cart");
        return;
      } else if (prodqty > 1000) {
        message.error('You are not allowed to add more than 1000 ' + ProductName + ', if you need more than 1000 please contact us.');
        document.getElementById("prodqty" + ProductID).value = 1
        return;
      }

      document.getElementById("cartcount").innerHTML = parseInt(document.getElementById("cartcount").innerHTML) + parseInt(prodqty);
      var ProductsDtl = {
        ProductID: ProductID,
        ProductName: ProductName,
        ProductQty: prodqty,
        ProductPrice: prodprice,
        ProductImage: prodprimimage,
        OfferAmount: 0,
        VatRate: JSON.parse(sessionStorage.getItem('menucategories')).find(category => category.ProductCategory.ProductCategoryID === ProductCategoryID).ProductCategory.VatRate
      }

      this.setState(state => {
        {
          basketitem: state.basketitem.push(ProductsDtl)
          localStorage.setItem('cart', JSON.stringify(state.basketitem));
        }
      });
    }

    setTimeout(() => {

      const cachedCart = localStorage.getItem('cart');
      if (cachedCart) {
        this.setState({ basketitem: JSON.parse(cachedCart) });
      } else {
  
        const myArray = [];
        this.setState({ basketitem: myArray });
        localStorage.setItem('cart', JSON.stringify(myArray));
      }   

      const basketitem = this.state.basketitem;
      //offer
      var offeri = [];
      offeri = JSON.parse("[" + offerItems + "]");


      let checker = (arr, target) => target.some(v => arr.includes(v));



      //console.log(offerItems);

     // console.log(JSON.parse(localStorage.getItem('cart')));


      let op = JSON.parse(localStorage.getItem('cart')).map(({ ProductID }) => ProductID)

      var offer = 0;

      if (checker(op, offeri)) {



        var totalitems = 0;
        var totalamounts = 0;
        var newArray = offeri.map(function (el) {

          var cindex = JSON.parse(localStorage.getItem('cart')).findIndex(a => a.ProductID === el);
         // console.log(cindex)

          if (cindex !== -1) {
            totalitems = totalitems + parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"])
            totalamounts = totalamounts + (parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductQty"]) * parseFloat(JSON.parse(localStorage.getItem('cart'))[cindex]["ProductPrice"]))
            basketitem[cindex]["OfferAmount"] = 0;
          }
        });

        var quotient = Math.floor(totalitems / offerqty);
        var remainder = totalitems % offerqty;
        //console.log('total:' + totalamounts)
        //console.log('offer total:' + quotient * offerprice)
       // console.log('remainder: ' + remainder)
        offer = (parseFloat(totalamounts - (prodprice * remainder)) - parseFloat(quotient * offerprice))
       // console.log('singleprice:' + prodprice * remainder)

        var clickedindex = this.state.basketitem.findIndex(a => a.ProductID === ProductID);

        basketitem[clickedindex]["OfferAmount"] = offer;

       
          
          if(totalitems < offerqty){
              basketitem[clickedindex]["OfferAmount"] = 0;
          }

        this.setState({ basketitem: basketitem });
        localStorage.setItem('cart', JSON.stringify(basketitem));

        console.log(this.state.basketitem)

      }else{
        this.setState({ basketitem: basketitem });
        localStorage.setItem('cart', JSON.stringify(basketitem));
      }
    }, 100);


    message.loading({ content: 'Adding...', key });
    setTimeout(() => {
      message.success({ content: 'Added to the cart!', key, duration: 2 });
      if (buyoradd == 'buy') {
        window.location.href = "/cart"
      }

    }, 800);
  };

  onQtyIncrese = (ProductID) => {
    document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) + 1
  };
  onQtyReduce = (ProductID) => {
    if (document.getElementById("prodqty" + ProductID).value > 1) {
      document.getElementById("prodqty" + ProductID).value = parseFloat(document.getElementById("prodqty" + ProductID).value) - 1
    }
  };


  render() {
    const onFinish = values => {

      const Notifydata = {
        companyPageTitle: config.get('companyPageTitle'),
        SenderEmail: values.email,
        prdtName: document.getElementById("prdt").value,
        companyLogoCDN: config.get('companyLogoCDN'),
        prdtURL: document.getElementById("prdtURL").value
      }

      const emailBody = renderEmail(NotifyEmail, { lang: 'en', Notifydata });
      async function postData() {
        const ecoEmail = {
          "to": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
          "bcc": "suren.sivalingam@ecologital.com",
          "from": values.email,
          "subject": "Product Notification Request",
          "html": emailBody
        };

        const response = await fetch(config.get('backendURL') + 'mail/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(ecoEmail)
        })
        return response.json();
      }
      postData()
        .then(data => {
          message.success("Email has been sent to " + config.get('companyPageTitle'));
          this.handleCancel();
        });


    };

    const { products } = this.state;



    return (


      <div class="ps-container">

        <div className="ps-section__header">
          <div style={{display:'flex', alignItems:'baseline'}}>
            <h2>
              {this.props.params.ItemHeading.replace("Products", "")}<span style={{color:'red', fontWeight:'bolder'}}> Products</span>.</h2>
            <div style={{width:'64px'}}></div>
            <div className='shop-all-link'>
              <a href={`/promoted/${this.props.params.Type}`}>SHOP ALL</a>
            </div>
          </div>
          
          
          <hr />
        </div>
        {
          this.state.ploaded ? (
            products ? (

              <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsive}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px">
                {
                  products.map(product =>


                    <div className='promoted-item'>

                      <div className="list">

                        <ProducBoxMartFury params={{ product: product, visible: this.state.visible, loaded: this.state.loaded }} handleCancel={this.handleCancel} onNotify={this.onNotify} onAddItem={this.onAddItem} onQtyIncrese={this.onQtyIncrese} onQtyReduce={this.onQtyReduce} onFinish={this.onFinish} />


                        <br />

                        <br />

                      </div>

                    </div>
                  )
                }

              </Carousel>
            ) : (
                <Col sm={12}><br />
                  <Skeleton active avatar paragraph={{ rows: 4 }} />
                </Col>
              )
          ) : (<Col sm={12}><br />
            <Skeleton active avatar paragraph={{ rows: 4 }} />
          </Col>)
        }

      </div>


    )
  }
}

export default Application;