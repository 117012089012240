import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import "bootstrap/dist/css/bootstrap.min.css";
import "../themes/default/css/default.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "../themes/default/header.component";
import Navbar from "../themes/default/navbar.component";
import Footer from "../themes/default/footer.component";
import Pages from "./pages";
import HomePage from "./home";
import Articles from './articles'

import ClothingHeader from "../themes/cloths/header.component";
import ClothingFooter from "../themes/cloths/footer.component";
import ClothingHomePage from "../themes/cloths/home";

import MartfuryHeader from "../themes/martfury/header.component";
import MartfuryFooter from "../themes/martfury/footer.component";
import MartfuryHomePage from "../themes/martfury/home";

import ApparelHeader from "../themes/apparel/header.component";
import ApparelFooter from "../themes/apparel/footer.component";
import ApparelHomePage from "../themes/apparel/home";

import ApparelcasiHeader from "../themes/apparel-casi/header.component";
import ApparelcasiFooter from "../themes/apparel-casi/footer.component";
import ApparelcasiHomePage from "../themes/apparel-casi/home";


import WitmegHeader from "../themes/witmeg/header.component";
import WitmegFooter from "../themes/witmeg/footer.component";

import SpicewayNavbar from "../themes/spiceway/navbar.component";
import SpicewayHeader from "../themes/spiceway/header.component";
import SpicewayFooter from "../themes/spiceway/footer.component";
import SpicewayHomePage from "../themes/spiceway/home";

import MeatShopHeader from "../themes/meatshop/header.component";
import MeatShopFooter from "../themes/meatshop/footer.component";
import MeatShopHomePage from "../themes/meatshop/home";

import StationaryHeader from "../themes/stationary/header.component";
import StationaryFooter from "../themes/stationary/footer.component";
import StationaryHomePage from "../themes/stationary/home";


import Products from "../shop/products";
import searchItems from "../shop/products-search";
import ProductsByID from "../shop/productsbyid";
import ProductsByBrandID from "../shop/productsbybrandid";
import ProductsDetailsByID from "../shop/productdetails";
import PromotedProducts from '../shop/promoted';
import Login from "../shop/login";
import MyCart from "../shop/cart";
import Checkout from "../shop/checkout";
import Payment from "../shop/payment";
import AccountLogin from "../shop/account-login";
import AccountForgot from "../shop/account-forgot";
import AccountMember from "../shop/account-member";
import AccountPayment from "../shop/account-payment";
import AccountRegister from "../shop/account-register";
import AccountVerify from "../shop/account-register-verify";
import AccountYourDetails from "../shop/account-register-yourdetails";
import OrderSuccess from "../shop/order-success";
import AccountMyInfo from "../shop/account-my-info";
import AccountAddresses from "../shop/account-addresses";
import AccountOrder from "../shop/account-order";
import AccountMyWishList from "../shop/account-my-wishlists";
import CookiesPolicy from './spiceway/CookiesPolicy';
import PrivacyPolicy from './spiceway/PrivacyPolicy';
import TermsOfUse from './spiceway/TermsOfUse'

import { Container } from 'react-bootstrap';
import config from 'react-global-configuration';
import "../config.js";
import axios from 'axios';
import PaymentSuccess from '../shop/payment-success.jsx';

const Loader = () => (
    <div className="divLoader">
        <svg className="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
    </div>
);

class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = { companyresult: [], loading: true, pageid: '' };
    }
    handlePageClick = (pageid) => {

        this.setState({
            pageid: pageid,
            key: Math.random()
        })

        window.history.replaceState(null, null, '/pages/' + pageid)
    }


    componentDidMount() {
        // RMEOVE spiceway.throbox.com WHEN DONE TO WINDOW.LOCATION.hostname
        // "spiceway.throbox.com"

        const hostname = window.location.hostname
        // const hostname = 'shankarwholesale.throbox.com'

        axios.get(config.get('backendURL') + 'company/bydomain/' + hostname)
            .then(result => this.setState({ companyresult: result, loading: false }))
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {

        console.log(this.state.companyresult)
        if (this.state.companyresult.length !== 0) {


            config.set({
                companyID: (this.state.companyresult.data)[0]?._id,
                SenderEmail: (this.state.companyresult.data)[0]?.Email,
                Theme: (this.state.companyresult.data)[0]?.theme,
                favicon: (this.state.companyresult.data)[0]?.favicon,
                metaDescription: (this.state.companyresult.data)[0]?.Meta_Description,
                OrganisationID: (this.state.companyresult.data)[0]?.OrganisationID,
                companyLogo: (this.state.companyresult.data)[0]?.Logo,
                companyLogoCDN: (this.state.companyresult.data)[0]?.LogoCDN,
                companyPageTitle: (this.state.companyresult.data)[0]?.Name,
                LocationID: (this.state.companyresult.data)[0]?.LocationID,
                WorldPayServiceKey: (this.state.companyresult.data)[0]?.WorldPayServiceKey,
                WorldPayClientKey: (this.state.companyresult.data)[0]?.WorldPayClientKey,
                WishListEnabled: (this.state.companyresult.data)[0]?.WishListEnabled,
                HelpPage: (this.state.companyresult.data)[0]?.HelpPage,
                SocialMedia: (this.state.companyresult.data)[0]?.SocialMedia,
                DeliveryMessage: (this.state.companyresult.data)[0]?.DeliveryMessage,
                ShopNowItems: (this.state.companyresult.data)[0]?.ShopNowItems,
                FBappid: (this.state.companyresult.data)[0]?.FBappid,
                FBpageid: (this.state.companyresult.data)[0]?.FBpageid,
                CDNPath: (this.state.companyresult.data)[0]?.CDNPath,
                backendURL: config.get('backendURL'),
                API_URL_cust: config.get('API_URL_cust'),
                API_productregistry: config.get('API_productregistry'),
                rest_API_productregistry: config.get('rest_API_productregistry'),
                rest_API_productregistry_V2: config.get('rest_API_productregistry_V2'),
                rest_API: config.get('rest_API'),
                ProductjwtAud: config.get('ProductjwtAud'),
                ProductjwtIss: config.get('ProductjwtIss'),
                ProductjwtExp: config.get('ProductjwtExp'),
                ProductprivateKey: config.get('ProductprivateKey'),
                API_salesregistry: config.get('API_salesregistry'),
                SalesjwtAud: config.get('SalesjwtAud'),
                SalesjwtIss: config.get('SalesjwtIss'),
                SalesjwtExp: config.get('SalesjwtExp'),
                SalesprivateKey: config.get('SalesprivateKey'),
                LoginprivateKey: config.get('LoginprivateKey'),
                CustjwtAud: config.get('CustjwtAud'),
                CustjwtIss: config.get('CustjwtIss'),
                CustjwtExp: config.get('CustjwtExp'),
                LoginAuthorizationKey: config.get('LoginAuthorizationKey'),
                WorldPayUrl: config.get('WorldPayUrl'),
                WorldPayTransactionMode: config.get('WorldPayTransactionMode')

            }, { freeze: false });
            document.getElementById("root").setAttribute("class", config.get('Theme'))

        }
        return (

            <React.Fragment>
                {this.state.loading ? <Loader /> : (
                    <Router>
                        <Container fluid >
                            {(() => {

                                switch (config.get('Theme')) {
                                    case 'Cloths':
                                        return (
                                            <>
                                                <ClothingHeader />
                                                <Route exact path="/" component={ClothingHomePage} />
                                            </>
                                        )
                                    case 'Martfury':
                                        return (
                                            <>
                                                <MartfuryHeader parentPageCallback={this.handlePageClick} />
                                                <Route exact path="/" component={MartfuryHomePage} />
                                            </>
                                        )
                                    case 'Apparel':
                                        return (
                                            <>
                                                <ApparelHeader parentPageCallback={this.handlePageClick} />
                                                <Route exact path="/" component={ApparelHomePage} />
                                            </>
                                        )
                                    case 'Apparel-casi':
                                        return (
                                            <>
                                                <ApparelcasiHeader parentPageCallback={this.handlePageClick} />
                                                <Route exact path="/" component={ApparelcasiHomePage} />
                                            </>
                                        )

                                    case 'Spiceway':
                                        return (
                                            <>
                                                <SpicewayNavbar parentPageCallback={this.handlePageClick} />
                                                <SpicewayHeader parentPageCallback={this.handlePageClick} />
                                                <Route exact path="/" component={SpicewayHomePage} />
                                            </>
                                        )
                                    case 'MeatShop':
                                        return (
                                            <>
                                                <MeatShopHeader parentPageCallback={this.handlePageClick} />
                                                <Route exact path="/" component={MeatShopHomePage} />
                                            </>
                                        )
                                    case 'Stationary':
                                        return (
                                            <>
                                                <StationaryHeader parentPageCallback={this.handlePageClick} />
                                                <Route exact path="/" component={StationaryHomePage} />
                                            </>
                                        )

                                    case 'Witmeg':
                                        return (
                                            <>
                                                <WitmegHeader />
                                                <Route exact path="/" component={ApparelHomePage} />
                                            </>
                                        )
                                    default:
                                        return (<>
                                            <Header />
                                            <Navbar parentPageCallback={this.handlePageClick} />
                                            <Route exact path="/" component={HomePage} />
                                        </>
                                        )
                                }

                            })()}
                            <div className="ps-container">


                                {this.state.pageid == '' ? (
                                    <Route exact path="/pages/:id" component={Pages} />
                                ) : (
                                    <><Pages params={{ pageid: this.state.pageid }} key={this.state.key} /></>
                                )
                                }
                                <Route exact path="/articles/:id" component={Articles} />
                                <Route exact path="/products" component={Products} />
                                <Route exact path="/group/:id/:name" component={ProductsByID} />
                                <Route exact path="/search/:id" component={searchItems} />
                                <Route exact path="/search/:id/:catid" component={searchItems} />
                                <Route exact path="/brand/:id/:name" component={ProductsByBrandID} />
                                <Route exact path="/product/:id/:id" component={ProductsDetailsByID} />
                                <Route axact path="/promoted/:promotion" component={PromotedProducts} />
                                <Route exact path="/cart" component={MyCart} />
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/payment" component={Payment} />
                                <Route exact path="/paymentsuccess" component={PaymentSuccess} />
                                <Route exact path="/account/login" component={AccountLogin} />
                                <Route exact path="/account/forgot" component={AccountForgot} />
                                <Route exact path="/account/" component={AccountMember} />
                                <Route exact path="/account/member" component={AccountMember} />
                                <Route exact path="/account/register" component={AccountRegister} />
                                <Route exact path="/account/register/verify/:id" component={AccountVerify} />
                                <Route exact path="/account/register/yourdetails" component={AccountYourDetails} />
                                <Route exact path="/account/payment" component={AccountPayment} />
                                <Route exact path="/account/my-info" component={AccountMyInfo} />
                                <Route exact path="/account/addresses" component={AccountAddresses} />
                                <Route exact path="/account/my-wishlists" component={AccountMyWishList} />
                                <Route exact path="/account/order-history" component={AccountOrder} />
                                <Route exact path="/checkout" component={Checkout} />
                                <Route exact path="/order-success" component={OrderSuccess} />
                                <Route exact path="/cookies-policy" component={CookiesPolicy} />
                                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                                <Route exact path="/terms-of-use" component={TermsOfUse} />
                            </div>
                            {(() => {

                                switch (config.get('Theme')) {
                                    case 'Cloths':
                                        return (
                                            <>
                                                <ClothingFooter />
                                            </>
                                        )
                                    case 'Martfury':
                                        return (
                                            <>
                                                <MartfuryFooter />
                                            </>
                                        )
                                    case 'Apparel':
                                        return (
                                            <>
                                                <ApparelFooter />
                                            </>
                                        )
                                    case 'Apparel-casi':
                                        return (
                                            <>
                                                <ApparelcasiFooter />
                                            </>
                                        )
                                    case 'ComingSoon':
                                        return (
                                            <>

                                            </>
                                        )
                                    case 'Witmeg':
                                        return (
                                            <>
                                                <WitmegFooter />
                                            </>
                                        )
                                    case 'Spiceway':
                                        return (
                                            <>
                                                <SpicewayFooter />
                                            </>
                                        )
                                    case 'Stationary':
                                        return (
                                            <>
                                                <StationaryFooter />
                                            </>
                                        )
                                    case 'MeatShop':
                                        return (
                                            <>
                                                <MeatShopFooter />
                                            </>
                                        )
                                    default:
                                        return (<>
                                            <Footer />
                                        </>
                                        )
                                }

                            })()}
                            <div>
                                <MessengerCustomerChat
                                    pageId={config.get('FBpageid')}
                                    appId={config.get('FBappid')}
                                />
                            </div>
                        </Container>

                    </Router>
                )}
            </React.Fragment>

        );
    }
}

export default Application;