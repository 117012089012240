import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import {
  LoadingOutlined,
} from '@ant-design/icons';


class App extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = { vimage: [], loaded: false, itemdata:'' };
  }
  componentDidMount() { 
    this._isMounted = true;
    axios.get(config.get('backendURL') + 'homelayout/byid/' + this.props.params.id)
    .then(response => {
      console.log(response)
      this.setState({
        itemdata: response.data.itemdata
      })
    })
    .catch(function (error) {
      console.log(error);
    })

};

componentWillUnmount() {
  this._isMounted = false;
}
 
  render() {
 //console.log(this.state.vimage)
   
      return (
        <span dangerouslySetInnerHTML={{ __html: this.state.itemdata }} />
         
      )
  }
}

export default App;
