import React from 'react';
import { Select, Modal } from 'antd';
import { Form, Button } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
const { Option } = Select;
class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = { country: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].Country, region: JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].StateProvinceRegion };
  }

 


  componentDidUpdate() {
    var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));
 }
  componentDidMount() {
     
  }
  selectCountry(val) {

    
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  handleSubmit = (event) => {

    this.setState({ disabled: true });
    this.refs.btn.setAttribute("disabled", "disabled");
 
    var fullUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'));
    fullUserDetails["Addresses"][this.props.params.addressId].AddressType = document.getElementById("AddressType").value
    fullUserDetails["Addresses"][this.props.params.addressId].AddressLine1 = document.getElementById("AddressLine1").value
    fullUserDetails["Addresses"][this.props.params.addressId].AddressLine2 = document.getElementById("AddressLine2").value
    fullUserDetails["Addresses"][this.props.params.addressId].City = document.getElementById("City").value
    fullUserDetails["Addresses"][this.props.params.addressId].StateProvinceRegion = document.getElementById("StateProvinceRegion").value
    fullUserDetails["Addresses"][this.props.params.addressId].PostZipCode = document.getElementById("PostZipCode").value
    fullUserDetails["Addresses"][this.props.params.addressId].Country = document.getElementById("Country").value
    fullUserDetails["Addresses"][this.props.params.addressId].Phone = document.getElementById("Phone").value
    fullUserDetails["Addresses"][this.props.params.addressId].Fax = document.getElementById("Fax").value
    fullUserDetails["Addresses"][this.props.params.addressId].Email = document.getElementById("Email").value

    localStorage.setItem('fullUserDetails', JSON.stringify(fullUserDetails));

    const timestamp = Math.floor(Date.now()/1000) + 1000 ;  

    var payload = {
      "aud": config.get('CustjwtAud'),
      "exp": timestamp,
      "iss": config.get('CustjwtIss') ,
      "usertoken":  sessionStorage.getItem('AccessToken')
    };


    var signOptions = {
      "algorithm": "RS256"
    };

    var privateKey = config.get('LoginprivateKey');
    var token = jwt.sign(payload, privateKey, signOptions);
     console.log(token);

    const requestUserDtlsOptions = {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(fullUserDetails)
    };
console.log(config.get('API_URL_cust') + 'update/'+config.get('OrganisationID')+'/'+fullUserDetails.UserID)
   console.log(fullUserDetails)
    fetch(config.get('API_URL_cust') + 'update/'+config.get('OrganisationID')+'/'+fullUserDetails.UserID, requestUserDtlsOptions)
      .then(response => response.json())
      .then(function (data) {
         console.log(data.Result);
 

        Modal.success({
          title: 'Address Details',
          content: 'Address has been updated',
           onOk() { window.location.href = "/account/addresses" },
        });
    
      });


  
  };


  render() {

  
    const { region, country} = this.state;
 
  
    return (

      <>

        <Form onSubmit={this.handleSubmit}>
          <Form.Group >
            <Form.Label>Address Type</Form.Label>

            <Form.Control as="select" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].AddressType} id="AddressType" custom>
            <option value="Billing">Billing</option>
              <option value="Delivery">Delivery</option>
              <option value="Home">Home</option>
              <option value="Other">Other</option>
    </Form.Control>
  </Form.Group>
  
            
 
          <Form.Group>
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control id="AddressLine1" required type="text" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].AddressLine1} placeholder="Please enter Address Line 1" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control id="AddressLine2" type="text" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].AddressLine2} placeholder="Please enter Address Line 2" />
          </Form.Group>
          <Form.Group >
            <Form.Label>City</Form.Label>
            <Form.Control id="City" required type="text" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].City} placeholder="Please enter City" />
          </Form.Group>
          <Form.Group >
            <Form.Label>State / Province / Region</Form.Label>


            <RegionDropdown
              country={country}
              value={region}
              countryValueType="short"
              id="StateProvinceRegion" 
              classes="ant-input"
              onChange={(val) => this.selectRegion(val)} />

          </Form.Group>
          <Form.Group >
            <Form.Label>Post /Zip Code</Form.Label>
            <Form.Control id="PostZipCode" required type="text" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].PostZipCode} placeholder="Please enter Post / Zip / Code" />
          </Form.Group>
          <Form.Group >
            <Form.Label>Country</Form.Label>

            <CountryDropdown
              value={country}
              valueType="short"
              id="Country" 
              classes="ant-input"
              onChange={(val) => this.selectCountry(val)} />
          </Form.Group>
          <Form.Group >
            <Form.Label>Phone</Form.Label>
            <Form.Control id="Phone" required type="text" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].Phone} placeholder="Please enter Phone" />
          </Form.Group>
          <Form.Group >
            <Form.Label>Landline</Form.Label>
            <Form.Control id="Fax" type="text" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].Fax} placeholder="Please enter landline" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control id="Email" type="email" defaultValue={JSON.parse(localStorage.getItem('fullUserDetails')).Addresses[this.props.params.addressId].Email} placeholder="Please enter Email" />
          </Form.Group>

          
          <Button type="button" ref="btn" onClick={this.handleSubmit}>{this.state.disabled ? 'Please wait...' : 'Update Address'}</Button>
        </Form>



      </>
    )
  }
}

export default Application;