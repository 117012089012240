import React from 'react';
import { Redirect } from 'react-router-dom'
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Divider } from 'antd';
import AccountLeftMenu from "./account-left-menu";
import config from 'react-global-configuration';

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem('loggedin') === 'true';

  }
  componentDidMount() {
    document.title = "My Wish List | " + config.get('companyPageTitle')
  }


  render() {

    if(!this.loggedIn) {
        return <Redirect to='/account/login'/>;
    }
   
    return (
      <div>
        <Row>

          <Col sm={12} >
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/account/member">
                Your Account
  </Breadcrumb.Item> <Breadcrumb.Item>
                My Wish List
  </Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col sm={3}>
            <AccountLeftMenu />
          </Col>

          <Col sm={9}>
            <div className="fade alert alert-light show">
              <Row className="justify-content-md-center">

                <Col sm={12}>
    <Divider orientation="left"><h5>MY WISH LIST</h5></Divider>
    You have no current wishlists

             </Col>
              </Row>

            </div>

          </Col>



        </Row>
      </div>
    )
  }
}

export default Application;