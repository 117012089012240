import React from 'react';
import { Modal, Form, Button, Input, Divider } from 'antd';
const AccountRegisterVerifyApparel = (props) => (

    
      <>
           {/* Register Verification */}
      <div className="register-verify">
        <div className="row">
          <div className="col-sm-6">
            <div className="register-details-wrapper">
              <ul className="list-group">
                <li className="list-group-item">
                  <i className="far fa-user" />
                  <a href="#">Create an Account</a>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-sign-in-alt" />
                  <a href="#">Login</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6">

          <Form
className="ps-form--account-setting"
name="basic"
initialValues={{ remember: true }}
onFinish={props.onFinish}
>
            <div className="register-text">
              <p className="text-header">Register for Online Shopping</p>
              <div className="form-group">
                <label>Verification Code</label>
                <Form.Item
        name="verificationcode"
        label=""
        rules={[{ required: true, message: 'Please enter a valid verification code' }]}
      >
        <Input placeholder="Please enter your verification code" />
      </Form.Item>
              </div>
              <p>We have sent an email with a verification to your email address. In order to complete the sign-up process, Please check your email and enter the verification code above.
                If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form.
                If you need assistance, please contact us.</p>
              
              <button className="btn-act-primary btn-verify ps-btn">
                    {props.disabled ? 'Please wait...' : 'Verify Now'}

                    </button>

            </div>

            </Form>

          </div>
        </div>
      </div>
      {/* /Register Verification */}



            
         </>
  

);
export default AccountRegisterVerifyApparel;
