import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import moment from 'moment';

const myArrayItems = [];
export async function ViewProductDetailById(productID, ProductQty) {

  const timestamp = Math.floor(Date.now()/1000) + 1000 ;  


  var payload = {
    "aud": config.get('ProductjwtAud'),
    "exp": timestamp,
    "iss": config.get('ProductjwtIss')
  };

  var signOptions = {
    "algorithm": "RS256"
  };

  var privateKey = config.get('ProductprivateKey');
  var token = jwt.sign(payload, privateKey, signOptions);

    

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + token,
    },
    body: JSON.stringify({ "OrgID": config.get('OrganisationID'), "ProductID": productID })
  };

 
  return await  fetch(config.get('rest_API_productregistry') + '/productid', requestOptions)
  
  .then(response => response.json())
    .then(getProduct => new Promise(function (resolve, reject) {
    
        resolve(getProduct);

  
    }))
    .then(getProduct => {
     // console.log(getProduct.Result[0])

    // console.log('ddd')
  
  

      myArrayItems.push(getProduct.Result[0])
      localStorage.setItem('newitems', JSON.stringify(myArrayItems));
    //  document.getElementById("ordernow").style.display="block"
    })
    .catch((error) => { console.warn(error); })
 
}

