import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Theme from './themes/default'

if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
}
ReactDOM.render(<Theme />, document.getElementById('root'));


serviceWorker.unregister();
